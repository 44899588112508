import { FC } from "react";

interface IHeaderSearchBlock {
    blockTitle?: string;
    data: any;
}

export const HeaderSearchBlock: FC<IHeaderSearchBlock> = (
    {
        data,
        blockTitle,
    },
) => {
    const getFirstLetter = (text: any) => {
        return text && text.slice(0, 1);
    };

    function timeAgo(timestamp: number): string {
        const now = Date.now();
        const diffInSeconds = Math.floor((now - timestamp * 1000) / 1000);

        // Меньше минуты
        if (diffInSeconds < 60) {
            return `${diffInSeconds} секунд`;
        }

        // Меньше часа
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) {
            return `${diffInMinutes} минут`;
        }

        // Меньше дня
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) {
            return `${diffInHours} часов`;
        }

        // Меньше месяца
        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInDays < 30) {
            return `${diffInDays} дней`;
        }

        // Больше месяца
        const diffInMonths = Math.floor(diffInDays / 30);
        return `${diffInMonths} месяцев`;
    }

    return (
        <div className="header-search-block">
            {data?.data && data?.data.map((item: any, idx: any) => (
                <div
                    className="header-search-block__item"
                    key={idx}
                    onClick={() => data.onClick(item)}
                >
                    <div className="header-search-block__item__left">
                        {(item?.image || item?.photo)? (
                            <img src={item?.image || item?.photo} alt=""
                                 className="header-search-block__item__left__image" />
                        ) : (
                            <>
                                <h5 className="header-search-block__item__left__blank">{getFirstLetter(item?.title || item?.name)}</h5>
                            </>
                        )}

                        <div className="header-search-block__item__left__info">
                            <p className="header-search-block__item__left__info__name">{["ideas"].includes(data.key) ? item?.title : item?.name || item?.title}</p>

                            <p className="header-search-block__item__left__info__extra">{blockTitle}</p>
                        </div>
                    </div>

                    <div className="header-search-block__item__date">
                        {item?.date ? timeAgo(item?.date) : ""}
                    </div>
                </div>
            ))}
        </div>
    );
};