import React, { useEffect, useState } from "react";
import { ArrowRightIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import { isMd } from "../../utils";
import { $getBirthdays } from "../../api/requests/main";
import { BirthdayData } from "../../api/requests/main/interface";
import moment from "moment";

export const BirthdaysCard = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [birthdaysData, setBirthdaysData] = useState<BirthdayData[]>([]);

    const countOverallList = () => {
        const screen = window.screen.width;
        if (window.screen.width >= 870 && isMobile) {
            return 5;
        } else if (window.screen.width >= 570 && window.screen.width < 870) {
            return 4;
        } else if (window.screen.width < 570) {
            return 3;
        }
    };

    useEffect(() => {
        init();
    }, []);

    function init() {
        $getBirthdays(1, { newApi: true }).then(res => {
            if (res.error || res.response) return;

            setBirthdaysData(res.data.data);
        });
    }

    // if (isMobile) return (
    //     <div className="layout-birthdaysMobile__birthdaysBody">
    //         <div className="feed-controls">
    //             <h3>Дни рождения</h3>
    //
    //             <div onClick={() => navigate("birthdays")}>
    //                 <h4>Показать все</h4>
    //                 <ArrowRightIcon color="#000" />
    //             </div>
    //         </div>
    //
    //         <div className="layout-birthdaysMobile__birthdaysList">
    //             {birthdaysData && birthdaysData?.slice(0, countOverallList()).map((
    //                 item: any,
    //                 idx: number
    //             ) => (
    //                 <div key={idx}>
    //                     <img src={item.photo} alt="" />
    //
    //                     <p>{item.name}</p>
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // );

    return (
        <div className="layout-birthdays">
            <div className="layout-birthdays__header">
                <div className="layout-birthdays__titles">
                    <h1>День Рождения!</h1>

                    <p>{moment().format("DD MMMM, YYYY")}</p>
                </div>

                <img src="/testImages/badges/badges-5.png" alt=""
                     className="layout-birthdays__header-image" />
            </div>

            <div className="layout-birthdays__content">
                {birthdaysData?.map((
                    item: any,
                    idx: number
                ) => (
                    <div key={idx} className="layout-birthdays__block cursor-pointer" onClick={() => item?.id && navigate(`/user/${item.uniq}`)}>
                        {item.photo ? (
                            <img src={item?.photo} alt="" />
                        ) : (
                            <div className="layout-birthdays__block__noneImage">
                                {item.name[0]}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <button
                onClick={() => navigate("/birthdays")}
                className="layout-birthdays__button"
            >
                Показать все
            </button>
        </div>
    );
};