import { IApiHandlerParams } from "../../interface"
import api from '../../../api'

export const $getInfiniteScrollItems = (
    path: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(path, data, {
        handler,
    })

export const $getNewInfiniteScrollItems = (
    url: string,
    params: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(url, {
    request: { params },
    handler
});
