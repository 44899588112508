import { ArrowExitIcon } from "../../../img";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { isMd } from "../../../utils";
import { FavoritesNewsCard } from '../../../components/favorites'
import { $getUserFavoritesAll } from '../../../api/requests/favorites'
import { changeHeaderTitle } from "../../../redux/reducers/header/reducer";
import { useDispatch } from "react-redux";

export const FavoritesNewsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [ newsData, setNewsData ] = useState([]);

    const handleNavigation = () => {
        navigate(-1);
    };

    useEffect(() => {
        $getUserFavoritesAll('news', {newApi: true}).then(res => {
            setNewsData(res.data)
        })

    }, [isLoading])

    useEffect(() => {
        dispatch(changeHeaderTitle("Новости"))
    }, [])

    return (
        <div className='favorites-news'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Новости</h1>
                    </div>
                ) }
            </div>

            <div className='container favorites-news-content'>
                { newsData.map((
                    item: any,
                    idx
                ) => (
                    <div onClick={() => navigate(`/news/show/${item.id}`)} key={idx}>
                    <FavoritesNewsCard
                        data={item}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        type={"news"}
                    />
                    </div>
                )) }
            </div>
        </div>
    )
}