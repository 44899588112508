import { useDispatch, useSelector } from "react-redux";

import moment, { Moment } from "moment";
import { isMd } from "../../../utils";
import {
    getPersonData,
    getProfileVarsData,
    setOpenPopup,
    setPopupData
} from "../../../redux";
import {
    vacationColors,
    vacationStatus, vacationStatusBusinessColor,
    vacationStatusColor,
    vacationStatusMedicalColor
} from "./constans";
import Button from "../../../components/ui/form/Buttons/Button";
import { useRef } from "react";
import { ArrowRightIcon } from "../../../img";
import { getNoun } from "../../../utils/field/getNoun";

interface VacationUserData {
    panel: string;
    expand: string;
    handleExpand: (val: string) => void
    indicator: number;
    data: any;
}

export const VacationUser = (
    {
        panel,
        expand,
        handleExpand,
        indicator,
        data
    }: VacationUserData
) => {
    const isMobile = isMd();

    const dispatch = useDispatch();
    const currentUser = useSelector(getPersonData);

    const isAdmin = currentUser?.workspace_role?.role === 1;

    const vars = useSelector(getProfileVarsData);

    const color = indicator >= 0 && vacationColors[indicator % vacationColors.length];

    const contentHeight = useRef<any>(null);

    const formatDate = (start: string, end: string) => {
        return start && end && `${moment(start)?.format("DD.MM")} - ${moment(end)?.format("DD.MM")}`;
    };

    const handleMonth = (date: Moment) => {
        // dispatch(setMonth(moment(date).format('YYYY-MM-DD')));
    };

    const handleOpenBookingPopup = () => {
        handleMonth(moment());

        dispatch(
            setPopupData({
                ...data
            })
        );
        dispatch(setOpenPopup());
    };

    const handleOpenEditPopup = () => {
        handleMonth(data.date_from);

        dispatch(
            setPopupData({
                ...data,
                start_date: data.date_from,
                end_date: data.date_to,
                indicator,
                isEdit: true,
                isCancel: data?.isProgress
            })
        );
        dispatch(setOpenPopup());
    };

    const handleOpenConfirmPopup = () => {
        handleMonth(data.date_from);

        dispatch(
            setPopupData({
                ...data,
                start_date: data.date_from,
                end_date: data.date_to,
                indicator,
                isConciliate: true,
                isAccept: data?.isProgress,
                isRefuse: data?.isProgress,
                isCancel: data?.isProgress
            })
        );
        dispatch(setOpenPopup());
    };

    const getTextVacationTitle = () => {
        if (data?.type === "medical") return "Даты работы в больничный"
        if (data?.type === "business") return "Даты"

        return "Даты отпуска";
    }

    function handleChangeAccordion() {
        if (!data?.date_from && !data?.date_to) return;

        if (isMobile) {
            isAdmin && data?.isProgress
                ? handleOpenEditPopup()
                : isAdmin && indicator === undefined
                    ? handleOpenBookingPopup()
                    : handleOpenConfirmPopup()
        } else {
            handleExpand(panel === expand ? "" : panel)
        }
    }

    return (
        <>
            <div
                className={"accordion__wrapper"}
            >
                <div
                    className={`accordion__question-container`}
                    id={`panel-${panel}-header`}
                    onClick={handleChangeAccordion}
                >
                    <div className={"accordion__title"}>
                        <div
                            className={"accordion__avatar"}
                            style={{
                                backgroundColor: `${vars.colors.secondaryColor}1a`
                            }}
                        >
                            {data?.user?.photo && <img src={data?.user?.photo} />}

                            {!data?.user?.photo && data?.user?.name && data?.user?.name.slice(0, 1)}
                        </div>

                        <div className={"accordion__info"}>
                            <div className={"accordion__name"}>{`${data?.user?.name}`}</div>

                            <div className={"accordion__position"}>{data?.user?.staff?.title || data?.user?.position}</div>

                            {(data?.isStatus && data.type === "vacation") && (
                                <div
                                    className={"accordion__status"}
                                    style={{
                                        backgroundColor: vars.colors[vacationStatusColor[data?.isProgress ? 1 : 0]] ? vars.colors[vacationStatusColor[data?.isProgress ? 1 : 0]] : vacationStatusColor[data?.isProgress ? 1 : 0]
                                    }}
                                >
                                    {vacationStatus[data?.isProgress ? 1 : 0]}
                                </div>
                            )}

                            {(data?.isStatus && data.type === "medical") && (
                                <div
                                    className={"accordion__status"}
                                    style={{
                                        backgroundColor: vars.colors[vacationStatusColor[data?.isProgress ? 1 : 0]] ? vars.colors[vacationStatusColor[data?.isProgress ? 1 : 0]] : vacationStatusMedicalColor[data?.isProgress ? 1 : 0]
                                    }}
                                >
                                    {data?.isProgress ? vacationStatus[1] : "Больничный"}
                                </div>
                            )}

                            {(data?.isStatus && data.type === "business") && (
                                <div
                                    className={"accordion__status"}
                                    style={{
                                        backgroundColor: vars.colors[vacationStatusColor[data?.isProgress ? 1 : 0]] ? vars.colors[vacationStatusColor[data?.isProgress ? 1 : 0]] : vacationStatusBusinessColor[data?.isProgress ? 1 : 0]
                                    }}
                                >
                                    {data?.isProgress ? vacationStatus[1] : "Командировка"}
                                </div>
                            )}
                        </div>
                    </div>

                    {(!isMobile && data?.date_from && data?.date_to) && (
                        <div
                            className={`accordion__arrow ${(!isMobile && expand === panel) ? "active" : ""}`}
                        >
                            <ArrowRightIcon color={"#00000080"} />
                        </div>
                    )}
                </div>

                <div
                    ref={contentHeight}
                    className={`answer-container ${(!isMobile && expand === panel) ? "active" : ""}`}
                    style={
                        (!isMobile && expand === panel)
                            ? { height: contentHeight?.current?.scrollHeight || 0 }
                            : { height: "0px" }
                    }
                >
                    <div className={"accordion__content"}>
                        <div className={"accordion__department"}>{data?.user?.structure?.title}</div>

                        <div className={"accordion__vacation"}>
                            {/*{data?.created_at && (*/}
                            {/*    <div className={"accordion__item"}>*/}
                            {/*        {"Забронировано"}*/}

                            {/*        <span*/}
                            {/*            className={"accordion__date"}>{moment(data?.created_at).format("DD MMMM - HH:mm")}</span>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {(data?.date_from && data?.date_to) && (
                                <>
                                    <div className={"accordion__item"}>
                                        {getTextVacationTitle()}

                                        <span className={"accordion__date"}>
                                            {data?.date_from && data?.date_to && formatDate(data?.date_from, data?.date_to)}
                                        </span>
                                    </div>

                                    {!!data?.days && (
                                        <div className={"accordion__item"}>
                                            {data?.type === "vacation" && "Дней отпуска"}
                                            {data?.type === "medical" && "Дней работы в больничный"}
                                            {data?.type === "business" && "Общее количество дней"}

                                            <span>
                                                {`${data?.days} ${getNoun(
                                                    data?.days,
                                                    "день",
                                                    "дня",
                                                    "дней"
                                                )}`}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div className={"accordion__buttons"}>
                            {/*{isAdmin && indicator === undefined && (*/}
                            {/*    <Button*/}
                            {/*        text={"Забронировать"}*/}
                            {/*        className={"btn btn-primary accordion__button accordion__action"}*/}
                            {/*        onClick={handleOpenBookingPopup}*/}
                            {/*    />*/}
                            {/*)}*/}

                            {(indicator >= 0) && (
                                <>
                                    {/*{isAdmin && (*/}
                                    {/*    <Button*/}
                                    {/*        text={"Редактировать"}*/}
                                    {/*        className={"btn btn-primary accordion__button accordion__action"}*/}
                                    {/*        onClick={handleOpenEditPopup}*/}
                                    {/*        style={{*/}
                                    {/*            color: vars.colors.fourthColor,*/}
                                    {/*            backgroundColor: vars.colors.secondaryColor,*/}
                                    {/*            "&:hover": {*/}
                                    {/*                backgroundColor: vars.colors.secondaryColor*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*)}*/}

                                    {(!!data?.isProgress && data.may_agree) && (
                                        <Button
                                            text={"Согласовать"}
                                            className={"btn btn-primary accordion__button accordion__action ml-0"}
                                            onClick={handleOpenConfirmPopup}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
