import React, { useEffect, useState } from "react";
import { $getDashboardBlogs } from "../../../api/requests/blogs";
import { ArrowExitIcon } from "../../../img";
import { isMd } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { BlogsCard } from "../../../components/blogs";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
import { useDispatch } from "react-redux";
import { changeListLength, changeModerationListLength } from "../../../redux";

export const BlogsModerationPage = () => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const [moderationData, setModerationData] = useState<IBlogsRes[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        $getDashboardBlogs({newApi: true}).then(res => {
            if (!res.data) return;

            setModerationData(res?.data?.data)
        })
    }, [isLoading])

    return (
        <div className='blogs-moderation'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ () => navigate('/blogs') }>
                            <ArrowExitIcon />
                        </div>

                        <h1>Модерация блога</h1>
                    </div>
                ) }
            </div>

            <div className='container blogs-moderation__content'>
                { moderationData.map((
                    blog,
                    idx
                ) => (
                    <div className={ `blogs-moderation__item` }
                         key={ idx }
                    >
                        <BlogsCard data={ blog } isLoading={isLoading} setIsLoading={setIsLoading}/>
                    </div>
                )) }
            </div>
        </div>
    )
}