import { FC } from "react";
import { IBaseIconProps } from "../types";

export const SliderHomeIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "black"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 16.9999H15.5M17.5 20.9999H7.5C6.43913 20.9999 5.42172 20.5785 4.67157 19.8284C3.92143 19.0782 3.5 18.0608 3.5 16.9999V10.7079C3.49999 10.0202 3.67732 9.34399 4.01487 8.74473C4.35242 8.14548 4.83879 7.64338 5.427 7.28694L10.427 4.25694C11.0521 3.87814 11.7691 3.67786 12.5 3.67786C13.2309 3.67786 13.9479 3.87814 14.573 4.25694L19.573 7.28694C20.1611 7.6433 20.6473 8.14523 20.9849 8.7443C21.3224 9.34336 21.4998 10.0193 21.5 10.7069V16.9999C21.5 18.0608 21.0786 19.0782 20.3284 19.8284C19.5783 20.5785 18.5609 20.9999 17.5 20.9999Z"
                stroke="currentColor" strokeWidth="1.99" stroke-linecap="round"
                strokeLinejoin="round" />
        </svg>
    );
};
