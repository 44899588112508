import DialogContainer from "../../ui/dialog/DialogContainer";
import React, {FC, ReactNode, useEffect} from "react";
import {CloseIcon} from "../../../img";
import TextField from "../../ui/form/Fields/TextField";
import {Controller, useForm} from "react-hook-form";
import {CreateGroupFormData} from "../../../containers/workspace-settings/groups/WorkspaceSettingGroupEdit";
import {$editGroup} from "../../../api/requests/groups";
import {useSelector} from "react-redux";
import {getCurrentGroupData} from "../../../redux/reducers/workplace/workplaceSlice";

export interface StateData {
    key: string;
    name: string;
    url: string;
    value: number;
    icon: ReactNode;
    isEdit?: boolean,
    module_information_title?: string,
}

interface IWorkspaceSettingGroupModulesModal {
    isOpen: boolean,
    onClose: () => void,
    data: StateData | null
}

export const WorkspaceSettingGroupModulesModal: FC<IWorkspaceSettingGroupModulesModal> = (
    {
        isOpen,
        onClose,
        data,
    }) => {
    const currentGroup = useSelector(getCurrentGroupData);

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm<CreateGroupFormData>();

    useEffect(() => {
        setValue('title', data?.module_information_title || '')
    }, [data])

    function onSubmit () {
        const requestData: any = {
            id: currentGroup.id,
            module_information_title: watch()?.title
        };

        $editGroup(requestData, { formData: true }).then(res => {
            onClose()
        });
    }
    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={onClose}
            isShowCloseBtn={false}
        >
            <div className="services-create-modal__header">
                <h1
                    className="services-create-modal__header__title">НАСТРОЙКИ МОДУЛЯ</h1>

                <div className="services-create-modal__header__close" onClick={onClose}>
                    <CloseIcon color="#000" />
                </div>
            </div>

            <div className="services-create-modal__content">
                <form
                    onSubmit={() => ""}
                    className="services-create-form"
                >
                    <Controller
                        name={'title'}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Напишите название"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                value={value || ""}
                                className=""
                                title="Название модуля"
                                placeholder={"Без названия"}
                                name="name"
                                onChange={onChange}
                            />
                        )}
                    />

                    <div className="services-create-modal__buttons">
                        <div onClick={onSubmit}
                             className="services-create-modal__buttons__item services-create-modal__buttons__add">
                            Сохранить
                        </div>

                    </div>
                </form>
            </div>
        </DialogContainer>
    )
}