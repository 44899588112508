import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import DialogContainer from "../../ui/dialog/DialogContainer";
import { CloseIcon } from "../../../img";
import TextField from "../../ui/form/Fields/TextField";
import Button from "../../ui/form/Buttons/Button";
import { ClipIcon } from "../../../img/icons/ClipIcon";
import { $mainPageNewData, $workSpaceEdit } from "../../../api/requests/main";
import { useDispatch, useSelector } from "react-redux";
import { changeMainPage, getPersonData } from "../../../redux";
import { successNotify } from "../../../utils";

interface WorkspaceSettingSocModalProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
}

interface FormData {
    url: string;
}

export const WorkspaceSettingSocModal: FC<WorkspaceSettingSocModalProps> = (
    {
        isOpen,
        onClose,
        data
    }
) => {
    const dispatch = useDispatch();

    const { workspace_id } = useSelector(getPersonData);

    const {
        handleSubmit,
        control,
        reset,
    } = useForm<FormData>({
        values: {
            url: data?.url || data?.defaultUrl
        }
    });

    function onSubmit(select: any) {
        if (!workspace_id) return;

        $workSpaceEdit(
            {
                id: workspace_id,
                [data.key]: select.url
            },
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            successNotify("Ссылка успешно сохранена").then()
            reset();

            $mainPageNewData().then((res) => {
                onClose();
                if (!res || res.error) return;

                dispatch(changeMainPage(res));
            });
        })
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={onClose}
            label={data?.name}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"services-show-modal__content"}>
                    <Controller
                        name="url"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                value={value}
                                prepend={<ClipIcon />}
                                title="Ссылка на страницу"
                                placeholder="Введите ссылку на источник"
                                name="url"
                                onChange={onChange}
                            />
                        )}
                    />
                </div>

                <div className="services-create-modal__buttons">
                    <Button
                        type={"submit"}
                        text={"Сохранить"}
                        className={"btn btn-primary w-100"}
                    />
                </div>
            </form>
        </DialogContainer>
    );
};