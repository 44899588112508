import { IBanners } from "../../utils";
import { FC } from "react";
import { BlankImage } from "../ui/blank-image/BlankImage";

interface IBannerCard {
    data: IBanners;
    className?: string;
}

export const BannerCard: FC<IBannerCard> = (
    {
        data,
        className = "",
    }
) => {
    return (
        <div className={`layout-banner ${className}`}>
            <BlankImage image={data?.image} classname={"layout-banner__image"} />

            <div className="layout-banner__content">
                <div className="layout-banner__desc">
                    {/*<p>{data?.src}</p>*/}
                    {/*<h1 className='layout-banner__title'>Карьерный рост</h1>*/}
                </div>

                <a href={data?.src} className="layout-banner__button">
                    Перейти
                </a>
            </div>
        </div>
    );
};