import { fabric } from 'fabric';

import { generateUUID, getDrawnShapePosition, setDrawnShapeSettings } from './utils';
import { API_URL_NEW } from "../../../api/config";

function getPhotoLoad(item: any) {
    if (!item || !item.member) return `${API_URL_NEW}booking_plus.png`;

    return item.member?.photo || `${API_URL_NEW}photoDefault.png`;
}

export function getWorkplaceShape(
    container: any,
    canvas: any,
    details: any,
    onSuccess: any
) {
    const center = getDrawnShapePosition(canvas);

    const defaultOption = {
        id: generateUUID(),
        left: center.x,
        top: center.y,
    };

    const circleSize = 40 / 2;

    defaultOption.left -= circleSize;
    defaultOption.top -= circleSize;

    const photo = getPhotoLoad(details);

    fabric.Image.fromURL(photo, (img: any) => {
        img.scaleToWidth(40);
        img.scaleToHeight(40);

        img.top = 0;
        img.left = 0;

        const roundedCorners = (
            fabricObject: any,
            cornerRadius: any
        ) => {
            const excessHeight = fabricObject.height - (fabricObject.height * 0.02)

            const rect = new fabric.Rect({
                width: excessHeight,
                height: excessHeight,
                rx: cornerRadius / fabricObject.scaleX,
                ry: cornerRadius / fabricObject.scaleY,
                left: -excessHeight / 2,
                top: -excessHeight / 2,
            });

            return rect
        }

        img.set('clipPath', roundedCorners(img, 20));

        const item = new fabric.Group([img], {
            ...defaultOption,
            width: 40,
            height: 40,
        });

        setDrawnShapeSettings(item);

        onSuccess(item);
    });
}

export class renderWorkplaceShape {}
