import { FC } from "react";
import { IBaseIconProps } from "../types";

export const FacebookIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "black"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.4042 12.6647L16.8811 9.55522H13.8977V7.53823C13.8977 6.68733 14.3137 5.8574 15.65 5.8574H17.0072V3.2101C17.0072 3.2101 15.776 3 14.5994 3C12.1413 3 10.5361 4.48961 10.5361 7.18525V9.55522H7.80469V12.6647H10.5361V20.1822C11.0844 20.2684 11.6454 20.3125 12.2169 20.3125C12.7884 20.3125 13.3493 20.2684 13.8977 20.1822V12.6647H16.4042Z"
                stroke={color}
                strokeWidth="1.4"
            />
        </svg>
    );
};