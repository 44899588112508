import { FC } from 'react'
import { IBaseIconProps } from './types'

export const InWorkIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = '#f6d400',
    },
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.625 14.25L12.25 13.125V8.42087M12.25 21C15.5813 21 18.4898 19.1901 20.046 16.5M3 11.5C3 6 7.27944 3 12.25 3C17.2206 3 21.25 7.02944 21.25 12M18.7811 11.0123L21.0311 13.2623L23.2811 11.0123"
                stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.93128 13.7313C6.69377 12.7562 5.30623 12.7562 5.06872 13.7313C5.03326 13.8778 4.96371 14.0138 4.86573 14.1284C4.76775 14.243 4.6441 14.3328 4.50486 14.3905C4.36561 14.4482 4.2147 14.4723 4.0644 14.4607C3.9141 14.4491 3.76867 14.4022 3.63993 14.3238C2.7824 13.8013 1.80112 14.7825 2.32364 15.64C2.66115 16.1938 2.36176 16.9163 1.73174 17.0694C0.756086 17.3063 0.756086 18.6945 1.73174 18.9307C1.8783 18.9662 2.01439 19.0358 2.12895 19.1339C2.24351 19.2319 2.33328 19.3557 2.39097 19.495C2.44866 19.6343 2.47263 19.7853 2.46093 19.9356C2.44923 20.086 2.40219 20.2314 2.32364 20.3601C1.80112 21.2176 2.7824 22.1989 3.63993 21.6764C3.76864 21.5978 3.91409 21.5508 4.06443 21.5391C4.21477 21.5274 4.36574 21.5514 4.50507 21.609C4.64439 21.6667 4.76811 21.7565 4.86617 21.8711C4.96423 21.9856 5.03384 22.1217 5.06935 22.2683C5.30623 23.2439 6.6944 23.2439 6.93065 22.2683C6.96628 22.1218 7.03596 21.9858 7.13404 21.8713C7.23212 21.7568 7.35582 21.6671 7.4951 21.6094C7.63438 21.5518 7.7853 21.5278 7.9356 21.5394C8.0859 21.551 8.23133 21.598 8.36007 21.6764C9.2176 22.1989 10.1989 21.2176 9.67637 20.3601C9.59795 20.2314 9.55102 20.0859 9.53939 19.9356C9.52776 19.7854 9.55176 19.6344 9.60943 19.4952C9.6671 19.3559 9.75682 19.2322 9.8713 19.1341C9.98578 19.036 10.1218 18.9663 10.2683 18.9307C11.2439 18.6938 11.2439 17.3057 10.2683 17.0694C10.1217 17.0339 9.98561 16.9643 9.87105 16.8663C9.7565 16.7682 9.66672 16.6445 9.60903 16.5052C9.55134 16.3658 9.52737 16.2149 9.53907 16.0645C9.55077 15.9142 9.59781 15.7688 9.67637 15.64C10.1989 14.7825 9.2176 13.8013 8.36007 14.3238C8.23136 14.4023 8.08591 14.4494 7.93557 14.4611C7.78523 14.4728 7.63426 14.4488 7.49494 14.3911C7.35561 14.3334 7.23189 14.2436 7.13383 14.1291C7.03577 14.0145 6.96678 13.8778 6.93128 13.7313Z"
                  stroke={color} strokeWidth="1.11111" />
            <path
                d="M7.11111 18C7.11111 18.6137 6.61365 19.1111 6 19.1111C5.38635 19.1111 4.88889 18.6137 4.88889 18C4.88889 17.3864 5.38635 16.8889 6 16.8889C6.61365 16.8889 7.11111 17.3864 7.11111 18Z"
                stroke={color} strokeWidth="1.11111" />
        </svg>
    )
}