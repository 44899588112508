import DialogContainer from "../../ui/dialog/DialogContainer";
import React, { FC, useEffect, useState } from "react";
import { AirplaneIcon, CloseIcon, PlusIcon2 } from "../../../img";
import { Controller, useForm } from "react-hook-form";
import FileField from "../../ui/form/FileField/FileField";
import TextField from "../../ui/form/Fields/TextField";
import { ServicesEmployeeModal } from "./ServicesEmployeeModal";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { IUsers } from "../../../api/requests/user/interface";
import {
    $createServiceCategory,
    $deleteServiceCategory,
    $editServiceCategory
} from "../../../api/requests/services";
import {
    IServiceCategories,
    IServiceCategory

} from "../../../api/requests/services/interface";
import { BlankAva } from "../../ui/avatars/BlankAva";

interface IServicesCreateGroupModal {
    editedData?: IServiceCategories
    isEdit: boolean,
    isOpen: boolean,
    onClose: () => void,
    setIsLoading: any
}

export const ServicesCreateGroupModal: FC<IServicesCreateGroupModal> = (
    {
        editedData,
        isEdit,
        isOpen,
        onClose,
        setIsLoading
    }
) => {
    const [responsibleData, setResponsibleData] = useState<any>();

    const [modalOpen, setModalOpen] = useState(false);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<any>(
        (isEdit && editedData) ? {
            values: {}
        } : {}
    );

    useEffect(() => {
        if (isEdit) {
            setValue("title", editedData?.title);
            setValue("icon", editedData?.icon_url);
            setResponsibleData({
                id: editedData?.owner_id,
                name: editedData?.owner_name
            });
        }
    }, [editedData, isEdit]);

    function onSubmit() {
        const requestList: IServiceCategory = {
            title: watch()?.title,
            sort: 0,
            owner_id: responsibleData ? responsibleData?.id : 0,
        };

        if (isEdit && editedData) {
            setIsLoading(true);
            $editServiceCategory(editedData.id, {
                    ...requestList,
                    ...(typeof watch()?.icon !== "string" && { icon: watch()?.icon })
                },
                { newApi: true, formData: true }).then(res => {
                setIsLoading(false);
                onClose();
            });
        } else {
            setIsLoading(true);

            $createServiceCategory({ ...requestList, icon: watch()?.icon }, {
                newApi: true,
                formData: true
            }).then(res => {
                setValue("title", "");
                setValue("icon", "");
                setResponsibleData(undefined);
                setIsLoading(false);
                onClose();
            });
        }
    }

    function deleteCategory () {
        setIsLoading(true)
        if (editedData) {
            $deleteServiceCategory(editedData.id, {newApi: true}).then(res => {
                setIsLoading(false)
                onClose()
            })
        }
    }

    function onCloseModal() {
        setValue("icon", "");
        setValue("title", "");
        setResponsibleData(undefined);
        onClose();
    }

    return (
        <DialogContainer isOpen={isOpen} closeModal={onCloseModal} isShowCloseBtn={false}>
            {modalOpen && (
                <ServicesEmployeeModal
                    isEdit={isEdit}
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    data={responsibleData}
                    setData={setResponsibleData}
                />
            )}

            <div className="services-create-modal__header">
                <h1
                    className="services-create-modal__header__title">{isEdit ? "Редактор раздела" : "Новый раздел"}</h1>

                <div className="services-create-modal__header__close" onClick={onClose}>
                    <CloseIcon color="#000" />
                </div>
            </div>

            <div className="services-create-modal__content">
                <form
                    onSubmit={() => ""}
                    className="services-create-form"
                >
                    <div className="services-create-form__icon">
                        <Controller
                            name="icon"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    iconFile={watch()?.icon ? " " : <AirplaneIcon />}
                                    className={"form-image"}
                                    upload={value}
                                    label="Иконка"
                                    accept="image/svg+xml"
                                    setUpload={onChange}
                                />
                            )}
                        />

                        <p className="services-create-form__icon__text">
                            Добавьте иконку в формате .svg не более 500 кб
                        </p>
                    </div>

                    <Controller
                        name="title"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Напишите название"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                value={value || ""}
                                className=""
                                title="Заголовок"
                                placeholder={"Без названия"}
                                name="name"
                                onChange={onChange}
                            />
                        )}
                    />

                    <div className="services-create-form__responsible"
                         onClick={() => setModalOpen(true)}
                    >
                        <p className="services-create-form__responsible__label">Ответственный</p>

                        <div className="services-create-form__responsible__block">
                            {responsibleData ? (
                                <div className={"services-create-form__employees__list"}
                                     onClick={() => setModalOpen(true)}>
                                    <div className="layout-vacation-modal__block">
                                        <div className="layout-vacation-modal__block__right">
                                            {responsibleData?.photo ? (
                                                <img src={responsibleData?.photo} alt="" />
                                            ) : (
                                                <BlankAva colorBg={"#CCD9E0"}
                                                          colorText={"black"}
                                                          text={responsibleData?.name}/>
                                            )}

                                            <div className="layout-vacation-modal__block__desc">
                                                <h1>{responsibleData?.name}</h1>

                                                <p className="layout-vacation-modal__block__desc__position">HR
                                                    менеджер</p>

                                                <p className="layout-vacation-modal__block__desc__department">Департамент
                                                    испытания и сертификации</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <PlusIcon2 />
                            )}
                        </div>
                    </div>
                </form>

                <div className="services-create-modal__buttons">
                    <div onClick={onSubmit}
                         className="services-create-modal__buttons__item services-create-modal__buttons__add">
                        {isEdit ? "Сохранить" : "Добавить"}
                    </div>

                    {isEdit && (
                        <div
                            onClick={() => deleteCategory()}
                            className="services-create-modal__buttons__item services-create-modal__buttons__delete">
                            Удалить
                        </div>
                    )}
                </div>
            </div>
        </DialogContainer>
    );
};