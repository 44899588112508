import noImageBanner from "../../../img/noimage.png";
import { useSelector } from "react-redux";
import { getRedactorNewsListData } from "../../../redux";
import { EditorShow } from "../../../utils/field/editor-show";
import { useEffect, useState } from "react";

interface NewsCreateEditPreviewProps {
    data: any;
}
const NewsCreateEditPreview = (
    {
        data
    }: NewsCreateEditPreviewProps
) => {
    const htmlData = useSelector(getRedactorNewsListData);

    const [upload, setUpload] = useState("");

    const handleImageUpload = (file: File): void => {
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === 'string') {
                    setUpload(reader.result)
                }
            }

            reader.readAsDataURL(file)
        }
    }

    useEffect(() => {
        if (typeof data.image === "string") {
            setUpload(data.image)
        } else {
            handleImageUpload(data.image)
        }
    }, [data.image]);

    return (
        <div className="contests__create__preview-items">
            <div className="contests__create__preview-img">
                <img src={upload || noImageBanner} />
            </div>

            <div className="contests__create__preview-item">
                <div className="contests__create__preview-item__header">
                    <div className={"d-flex align-items-center gap-3"}>
                        <h3>{data.title || "Название"}</h3>
                    </div>
                </div>

                <div className="contests__create__preview-item__content sport__create__preview news-show-editor">
                    <EditorShow
                        htmlData={htmlData}
                        className={"contests__create__preview-item__content__text"}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewsCreateEditPreview;