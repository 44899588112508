import React, { useState } from "react";
import {
    ServicesEmbulanceIcon,
    ServicesTripIcon,
    ServicesVacationIcon
} from "../../img";
import { useNavigate } from "react-router-dom";
import HorizonNavigate from "../../components/navigation/HorizonNavigate";
import { UsersMultiIcon } from "../../img/icons/workspace/UsersMultiIcon";
import { FeedEventsContent } from "../../components/main";
import { isTillMd } from "../../utils";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";

export const CalendarPage = () => {
    const isMobile = isTillMd();
    const navigate = useNavigate();

    const personalData = useSelector(getPersonData);
    const isLeader = personalData.is_leader;

    const [servicesStaticData, setServicesStaticData] = useState([
        {
            image: <ServicesVacationIcon />,
            title: "Отпуск",
            category: "Календарь",
            url: "vacation",
            isLeaderCheck: true
        },
        {
            image: <ServicesTripIcon />,
            title: "Командировка",
            category: "Календарь",
            url: "business",
            isLeaderCheck: true
        },
        {
            image: <ServicesEmbulanceIcon />,
            title: "Больничный",
            category: "Календарь",
            url: "medical",
            isLeaderCheck: true
        },
        {
            image: <UsersMultiIcon />,
            title: "Мероприятия",
            category: "Календарь",
            url: "events",
            isLeaderCheck: false
        }
    ]);

    return (
        <div className={"calendar-page"}>
            {!isMobile && <HorizonNavigate isScroll={false} />}

            <div className="container">
                <div className={"calendar-page__wrapper"}>
                    <div className="calendar-page__wrapper__header">
                        <h3 className={""}>Календарь</h3>
                    </div>

                    <div className="vacation-content">
                        {servicesStaticData?.map((item, idx) => {
                            if (item.isLeaderCheck && !isLeader) return null;

                            return (
                                <div
                                    className="services-block"
                                    key={idx}
                                    onClick={() => {
                                        navigate(item.url);
                                    }}
                                >
                                    <div className="services-block__image">
                                        {item.image}
                                    </div>

                                    <h1 className="services-block__title">{item.title}</h1>

                                    <div className="services-block__category">
                                        {item.category}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="container">
                <FeedEventsContent/>
            </div>
        </div>
    );
};