import DialogContainer from "../ui/dialog/DialogContainer";
import {FC, useEffect, useState} from "react";
import * as repl from "repl";
import {EmailIcon, PenIcon, PhoneIcon, SearchIcon} from "../../img";
import {SearchItem} from "../ui/form/SearchItem";
import {useSelector} from "react-redux";
import {getPersonData} from "../../redux";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {BlankImage} from "../ui/blank-image/BlankImage";
import {
    $createServiceReplacer,
    $getAllSubstituteUsers,
    $getServicesSubstituteUsers, $getStaffReplacementById
} from "../../api/requests/services/substitute";

interface IProfileDepartmentBlock {
    id?: number,
    name: string;
    image: string;
    position: string;
    icon: string;
    isMessage?: boolean;
    department?: string;
}

interface IVacationCard {
    data?: IProfileDepartmentBlock[]
}

export const VacationCard: FC<IVacationCard> = ({}) => {
    const navigate = useNavigate();

    const profileData = useSelector(getPersonData);
    const isLeader = profileData.is_leader;

    const isMobile = window.screen.width < 768

    const [isDialog, setIsDialog] = useState(false)

     const [data, setData] = useState<any>([])

    const [selectedReplacer, setSelectedReplacer] = useState<number>(-1)
    const [replacer, setReplacer] = useState<any>(null)

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        $getServicesSubstituteUsers({newApi: true}).then(res => {
            if (!res.data) return;

            setData(res?.data)
        })

    }, [])

    useEffect(() => {
        $getStaffReplacementById({id: "1"},{newApi: true}).then(res => {
            if (!res.data) return;

            setReplacer(res?.data?.replace)
            setSelectedReplacer(res?.data?.replace?.id)
        })
    }, [isLoading])

    const handleSelectReplacer = () => {
        if (selectedReplacer === -1) return

        setIsLoading(true)

        $createServiceReplacer({user_id: selectedReplacer}, {newApi: true}).then(res => {
            setIsDialog(false)
            setIsLoading(true)
        })
    }

    function onClose () {

    }

    return (
        <div className='layout-vacation'>
            <div className='layout-vacation__header'>
                <h5>До отпуска</h5>

                <h1>{profileData?.vacation?.extra_days || 0} дней</h1>
            </div>


            <div className='layout-vacation__dateWrapper'>
                <h3>Ближайший отпуск</h3>

                <div className='layout-vacation__dateContent'>
                    {profileData?.vacation?.start_date && profileData?.vacation?.end_date ? (
                        <>
                            <p>{profileData?.vacation?.start_date}</p>
                            <p>{profileData?.vacation?.end_date }</p>
                        </>
                    ) : (
                        <p>Недоступно</p>
                    )}
                </div>
            </div>

            <div className='layout-vacation__body'>
                <p>Количество неотгуленных дней</p>
                <h3>{profileData?.vacation?.days_until_nearest || '0'}</h3>
            </div>

            {replacer && (
                <div className='layout-vacation-replacer'>
                    <p className='layout-vacation-replacer__title'>Замещающий</p>

                    <div className='layout-vacation-replacer__content'>
                        <div className='layout-vacation-replacer__content__top'>
                            <BlankImage image={replacer?.image}
                                        classname='layout-vacation-replacer__content__top__image'/>

                            <div onClick={() => setIsDialog(true)}>
                                <PenIcon/>
                            </div>
                        </div>

                        <div className='layout-vacation-replacer__content__desc'>
                            <h1>{replacer?.name}</h1>

                            {replacer?.phone ? (
                                <div className='layout-vacation-replacer__content__contact'>
                                    <PhoneIcon/>
                                    <p>+{replacer?.phone}</p>
                                </div>
                            ) : ''}

                            {replacer?.mail ? (
                                <div className='layout-vacation-replacer__content__contact'>
                                    <EmailIcon/>
                                    <p>{replacer?.mail}</p>
                                </div>
                            ) : ''}
                        </div>

                    </div>
                </div>
            )}

            {(!replacer || isLeader) && (
                <div className='layout-vacation__buttons'>
                    {!replacer &&
                        <button
                            disabled={!profileData?.vacation?.extra_days }
                            onClick={() => setIsDialog(true)}
                            className='layout-vacation__replaceButton'
                            style={{opacity: !profileData?.vacation?.extra_days ? '0.5' : ''}}
                        >
                            Выбрать замещающего
                        </button>
                    }

                    {isLeader && (
                        <button
                            className="layout-vacation__calendarButton"
                            onClick={() => navigate("/calendar/vacation")}
                        >
                            Календарь отпусков
                        </button>
                    )}
                </div>
            )}

            <DialogContainer
                isOpen={isDialog}
                closeModal={() =>{
                    setSelectedReplacer(replacer.id)
                    setIsDialog(false)
                }
            }
            >
                <div className="layout-vacation-modal">
                    <div className='layout-vacation-modal__header'>
                        <h1 className='layout-vacation-modal__title'>Выбор замещающего</h1>

                        <div className='layout-vacation-modal__search'>
                            <SearchIcon color='#000'/>

                            <input type="search" placeholder='Поиск'/>
                        </div>
                    </div>

                    <div className='layout-vacation-modal__content'>
                        {data?.map((item: any, idx: number) => (
                            <div key={idx} className='layout-vacation-modal__block'>
                                <input
                                    type="checkbox"
                                    checked={item?.id == selectedReplacer}
                                    onChange={() => setSelectedReplacer(item?.id || 0)}
                                />

                                <div className='layout-vacation-modal__block__right'>
                                    <BlankImage image={item?.photo}/>

                                    <div className='layout-vacation-modal__block__desc'>
                                        <h1>{item?.name}</h1>

                                        <p className='layout-vacation-modal__block__desc__position'>{item?.position}</p>

                                        <p className='layout-vacation-modal__block__desc__department'>{item?.department}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='layout-vacation-modal__linearButton'>
                        <button style={{opacity: selectedReplacer === -1 ? '0.5' : 'unset'}}
                                className='layout-vacation-modal__button' onClick={() => handleSelectReplacer()}>
                            Выбрать
                        </button>
                    </div>
                </div>
            </DialogContainer>
        </div>
    )
}