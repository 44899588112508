import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import { IMedicalLeaveReq } from "./interface";

export const $createMedicalLeave = (
    data: IMedicalLeaveReq,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/services/medical-leaves/store", data, {
    handler
});

export const $getMedicalLeaveDiapason = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/medical-leaves/diapason`, {
    handler
});

export const $getMedicalProcessingList = (
    params: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/medical-leaves/list/processing`, {
    request: { params },
    handler
});

export const $getMedicalHistoryList = (
    params: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/medical-leaves/list/history`, {
    request: { params },
    handler
});

export const $changeMedicalStatus = (
    id: string | number,
    data: { status: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`api/v1/services/medical-leaves/statusChange/${id}`, data, {
    // mock: require('./mocks/vacation.booking.json'),
    handler: {
        ...handler,
        newApi: true
    }
});

export const $revertMedicalStatus = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`api/v1/services/medical-leaves/revert/${id}`, {}, {
    // mock: require('./mocks/vacation.booking.json'),
    handler: {
        ...handler,
        newApi: true
    }
});

export const $getMedicalCalendarList = (
    params: { start_date: string, end_date: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.get("api/v1/services/medical-leaves/staff/calendar", {
    // mock: require('./mocks/vacation.booking.json'),
    request: { params },
    handler: {
        ...handler,
        newApi: true
    }
});

export const $getMedicalIndexList = (
    params: { start_date: string, end_date: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.get("api/v1/services/medical-leaves/index", {
    // mock: require('./mocks/vacation.booking.json'),
    request: { params },
    handler: {
        ...handler,
        newApi: true
    }
});