import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VacationUsersCalendarList } from "./VacationUsersCalendarList";
import { VacationUsersAllList } from "./VacationUsersAllList";
import {
    getIsOwner,
    getOpenPopup,
    getPersonData,
    setCurrentUser,
    setIsOwner, setIsVacation,
    setOpenPopup,
    setPopupData
} from "../../../redux";
import { Skeleton } from "../../../components/ui/skeleton";
import { SingleSkeleton } from "../../../components/ui/skeleton/Single";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import { BookingPopup } from "../../../components/vacation/CalendarBlock/BookingPopup";

interface VacationLeftContentProps {
    usersList?: any[];
}

export const VacationLeftContent = (
    {
        usersList
    }: VacationLeftContentProps
) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(getPersonData);
    const isOpenPopup = useSelector(getOpenPopup);
    const isOwner = useSelector(getIsOwner);

    const isAdmin = currentUser?.workspace_role?.role === 1;

    const [expand, setExpand] = useState<string>("");
    const [filter, setFilter] = useState("");

    const [isStaffLoading, setIsStaffLoading] = useState(true);
    const [userAllList, setUserAllList] = useState<any[]>([]);

    const handleExpand = (panel: string) => {
        setExpand(panel);
    };

    const handleClosePopup = () => {
        dispatch(setOpenPopup());

        setTimeout(() => dispatch(setPopupData(null)), 500);
    };

    const handleClosePopupRepeat = () => {
        dispatch(setOpenPopup());

        setTimeout(() => dispatch(setPopupData(null)), 500);
        dispatch(setIsVacation(true));
    };

    useEffect(() => {
        if (currentUser) {
            setIsStaffLoading(false);

            dispatch(setCurrentUser(currentUser));
            dispatch(
                setIsOwner(currentUser?.new_vacations?.can_change_status)
            );

            const staffList = currentUser?.structure?.team?.staff ? currentUser?.structure?.team?.staff.map((item: any) => ({
                ...item,
                date_from: item.near_vacation_start,
                date_to: item.near_vacation_end,
                user: {
                    ...item.user,
                    position: item?.title,
                    staff: {
                        title: item?.user?.title
                    }
                }
            })) : []

            const list = usersList || staffList || []

            if (list) {
                // dispatch(setUserList(list));
                setUserAllList(list);
            }
        }
    }, [currentUser, usersList]);

    return (
        <div className={"vacation__content__left__calendar"}>
            <div className={"vacation__content__left__calendar-header"}>
                <h2 className={"vacation__content__left__calendar-title"}>Мой департамент</h2>

                {(isAdmin || isOwner) &&
                  <SearchItem
                    selectItem={filter}
                    setValueProps={setFilter}
                    onChange={() => {}}
                  />
                }
            </div>

            {!isAdmin && !isOwner && !currentUser && <SingleSkeleton />}

            {(isAdmin || isOwner) && isStaffLoading && (
                <div className={"vacation__content__left__calendar-block"}>
                    {[1, 2, 3, 4, 5].map((_, index) => (
                        <Skeleton key={index} />
                    ))}
                </div>
            )}

            {!isStaffLoading && (
                <VacationUsersCalendarList
                    isAdmin={isAdmin}
                    isOwner={isOwner}
                    expand={expand}
                    handleExpand={handleExpand}
                    filter={filter}
                />
            )}

            {!isStaffLoading && (
                <VacationUsersAllList
                    isAdmin={isAdmin}
                    isOwner={isOwner}
                    expand={expand}
                    handleExpand={handleExpand}
                    usersList={userAllList}
                />
            )}

            <BookingPopup
                isOpen={isOpenPopup}
                onClose={handleClosePopupRepeat}
                setIsOpen={handleClosePopup}
            />
        </div>
    );
};
