import { AcceptedIcon, ArrowExitIcon, RejectedIcon, ReturnIcon } from "../../../img";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import { ServicesApplicationsList } from "../../../components/services";
import {
    ServicesFormingApplicationModal
} from "../../../components/services/services-education/modals";
import React, { useEffect, useState } from "react";
import { isMd } from "../../../utils";
import { useNavigate } from "react-router-dom";
import {
    $getServiceTripsApproving, $getServiceTripsHistory,
    $getServiceTripsProcessing, $getServiceTripsTypes, $updateServiceTripStatus
} from "../../../api/requests/services/trip";
import moment from "moment";
import { BlankAva } from "../../../components/ui/avatars/BlankAva";
import { withDebounce } from "../../../functions";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesTripPage = () => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const activeType: any = searchParams.get('activeType') ;

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const [search, setSearch] = useState('')
    const [type, setType] = useState("");
    const [trip_date, setTripDate] = useState("");
    const [date, setDate] = useState("");

    const [types, setTypes] = useState([
            {
                id: 1,
                key: "applications",
                title: "Мои заявки"
            },
            {
                id: 2,
                key: "acceptance",
                title: "Согласование заявок"
            },
            {
                id: 3,
                key: "history",
                title: "История заявок"
            }
        ]
    );

    const [typesList, setTypesList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [processingList, setProcessingList] = useState([]);
    const [approvingList, setApprovingList] = useState([]);
    const [historyList, setHistoryList] = useState([]);

    const isBigTable = activeType == 2 || activeType == 3;

    const columns = [
        {
            key: "id",
            title: "ID заявки",
            width: isBigTable ? 7 : 10,
            class: "id",
            render: (value: number | string) => (
                <div className="services-acceptance__block__id" style={{cursor: activeType == 1 ? 'pointer' : 'unset'}}
                     onClick={() => activeType == 1 && navigate(`/services/trip/view/${value}`)}>
                    {value}
                </div>
            )
        },
        { key: "tripDate", title: "Дата командировки", width: isBigTable ? 15 : 20 },
        { key: "created_at", title: "Дата заявки", width: isBigTable ? 10 : 10 },
        { key: "type", title: "Тип", width: isBigTable ? 7 : 10 },
        (activeType == 2 || activeType == 3 ? {
            key: "user", title: "Отправитель", width: 20, render: (value: any) => (
                <div className="services-acceptance__block__user">
                    {value?.photo ? (
                        <img src={value.photo} alt=""
                             className="services-acceptance__block__admin__image" />
                    ) : (
                        <BlankAva
                            size={35}
                            style={{ borderRadius: 8 }}
                            colorBg={"#CCD9E0"}
                            colorText={"black"}
                            text={value?.name}
                        />
                    )}

                    <h5 className="services-acceptance__block__admin__name">{value?.name}</h5>
                </div>

            )
        } : { width: 0 }),
        {
            key: "approvers", title: "Согласант", width: 20, render: (value: any) => (
                <div className="services-acceptance__block__users">
                    {value.map((user: any, idx: number) => (
                        <div className="services-acceptance__block__user" key={idx}>
                            <div className="services-acceptance__block__user__left">
                                {value?.photo ? (
                                    <img src={value.photo} alt=""
                                         className="services-acceptance__block__admin__image" />
                                ) : (
                                    <BlankAva
                                        size={35}
                                        style={{ borderRadius: 8 }}
                                        colorBg={"#CCD9E0"}
                                        colorText={"black"}
                                        text={value?.name}
                                    />
                                )}

                                <h5>{user.name || "-"}</h5>
                            </div>

                            {user.status === "approved" && (
                                <AcceptedIcon />
                            )}

                            {user.status === "rejected" && (
                                <RejectedIcon />
                            )}
                        </div>
                    ))}
                </div>
            )
        },
        (activeType == 2 ? {
            key: "document_file",
            title: "Заявление",
            width: isBigTable ? 8 : 10,
            render: (e: string) => (
                <div className="services-acceptance__block__file"
                     onClick={() => downloadFile(e, "fdsfds")}>
                    Скачать
                </div>
            )
        } : { width: 0 }),

        (activeType == 1 || activeType == 3 ? {
            key: "status", title: "Статус", width: 15, render: (value: string, row: any) => (
                <div
                    className={`services-applications-create__status ${value === "Рассмотрение" ? "waiting" : (value === "Одобрено" ? "accepted" : "rejected")}`}
                >
                    {value}
                </div>
            )
        } : { width: 0 }),
        (activeType == 2 ? {
            key: "actions", title: "", width: isBigTable ? 7 : 10, render: (_: any, row: any) => (
                <div className="services-acceptance__block__status">
                    {!row.checked ? (
                        <>
                            <div className="services-acceptance__block__status__item"
                                 onClick={() => handleChangeStatus(row.id, "approved")}
                            >
                                <AcceptedIcon /></div>
                            {row.can_reject && (
                                <div className="services-acceptance__block__status__item"
                                     onClick={() => handleChangeStatus(row.id, "rejected")}
                                >
                                    <RejectedIcon /></div>
                            )}
                        </>
                    ) : ''}
                </div>
            )
        } : { width: 0 })
    ];

    const {
        control,
        formState: { isValid },
        setValue,
        watch
    } = useForm<any>();


    const handleUpdatedList = (data: any) => {
        return data.map((item: any) => {
            return {
                ...item,
                tripDate: `${moment(item?.start_date).format("DD.MM.YYYY")} - ${moment(item?.end_date).format("DD.MM.YYYY")}`,
                created_at: moment(item?.created_at).format("DD.MM.YYYY")
            };
        });
    };

    useEffect(() => {
        $getServiceTripsTypes({ newApi: true }).then(res => {
            if (!res.data) return;

            const transformedArray: any = Object.entries(res.data).map(([key, label], idx) => ({
                key,
                label,
                value: idx
            }));

            setTypesList(transformedArray);
        });
    }, []);

    useEffect(() => {
        init();
    }, [isLoading, type, date, trip_date, activeType, page]);

    useEffect(() => {
        setPage(1)
        setLastPage(1)
    }, [activeType])

    useEffect(() => {
        withDebounce(() => init())
    }, [search]);


    function init() {
        const requestData = {
            search: search || undefined,
            page,
            type: type || undefined,
            date: date || undefined,
            trip_date: trip_date || undefined,
        }

        if (activeType == 1) {
            $getServiceTripsProcessing(requestData).then(res => {
                if (!res?.data?.data) return;

                setProcessingList(handleUpdatedList(res?.data?.data));
                setLastPage(res?.data?.last_page)
            });
        }

        if (activeType == 2) {
            $getServiceTripsApproving(requestData).then(res => {
                if (!res?.data?.data) return;

                setApprovingList(handleUpdatedList(res?.data?.data));

                setLastPage(res?.data?.last_page)
            });
        }

        if (activeType == 3) {
            $getServiceTripsHistory(requestData).then(res => {
                if (!res?.data?.data) return;

                setHistoryList(handleUpdatedList(res?.data?.data));
                setLastPage(res?.data?.last_page)
            });
        }
    }

    function handleChangeStatus(trip_id: number | string, status: string) {
        setIsLoading(true);

        $updateServiceTripStatus({ trip_id, status }, { newApi: true }).then(res => {
            setIsLoading(false);
        });
    }

    function downloadFile(url: string, filename?: string) {
        const link: any = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = getFileName(url || "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function getFileName(url: string) {
        const parts: any = url?.split("/");
        return parts[parts?.length - 1];
    }

    return (
        <div className="services-education">
            <div className="container services-education-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon"
                             onClick={() => navigate("/services")}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Командировка</h1>
                    </div>
                )}

                <div className="services-education-header__right">
                    <SearchItem onChange={() => ''} setValueProps={(e) => setSearch(e)} selectItem={search}/>
                </div>
            </div>

            <div className="services-education-content">
                <div className="container services-education-content__top">
                    {types.map((
                        item,
                        idx
                    ) => (
                        <div
                            key={idx}
                            className={`services-education-content__top__block ${activeType == item.id ? "active" : ""}`}
                            onClick={() => navigate(`/services/trip/list?activeType=${item.id}`)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>

                <div className="services-education-wrapper">
                    <div className="container services-education-wrapper__header">
                        <div className="services-education-wrapper__buttons">
                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Все"}
                                            label={"Тип"}
                                            options={[{key: '', label: 'Все', value: -1}, ...typesList]}
                                            value={value}
                                            onChange={(e) => setType(e.key)}
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="trip_date"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата командировки"
                                            placeholder={"Не выбрано"}
                                            onChange={(e) => {
                                                setTripDate(e ? moment(e).format("YYYY-MM-DD") : '')}}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="date"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={"Не выбрано"}
                                            onChange={(e) => setDate(e ? moment(e).format("YYYY-MM-DD") : '')}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        {activeType == 1 && (
                            <div className="services-education-wrapper__header__button"
                                 onClick={() => navigate("/services/trip/create")}>
                                Подать заявку
                            </div>
                        )}
                    </div>

                    <ServicesApplicationsList
                        columns={columns}
                        list={activeType == 1 ? processingList : (activeType == 2 ? approvingList : historyList)}
                        urlRedirect={"trip"}
                        setPage={setPage}
                        page={page}
                        lastPage={lastPage}
                    />
                </div>
            </div>

            {isOpenModal && (
                <ServicesFormingApplicationModal isOpen={isOpenModal}
                                                 onClose={() => setIsOpenModal(false)} />
            )}
        </div>
    );
};