import DialogContainer from "../../ui/dialog/DialogContainer";
import React, { FC, useEffect, useState } from "react";
import { IPolls, IPolls2 } from "../../../api/requests/polls/interface";
import { AnalyticsDownloadIcon, CoinCompetitionIcon, TickIcon } from "../../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { $answerPollsItem, $downloadPollExcel, $viewPollsItem } from "../../../api/requests/polls";
import { IServicesField } from "../../../api/requests/services/interface";
import { $uploadFile } from "../../../api/requests/upload";
import moment from "moment";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { Trackbar } from "../../ui/trackbar";
import Slider from "rc-slider";
import { Tooltip } from "react-tooltip";
import downloadExel from "../../../utils/file/download-exel";
import { API_URL, API_URL_NEW } from "../../../api/config";
import axios from "axios";
import { $subscribeEvent } from "../../../api/requests/events";
import { useSelector } from "react-redux";
import { getCurrentRole } from "../../../redux";
import Button from "../../ui/form/Buttons/Button";
import { data } from "jquery";
import { find } from "lodash";

interface IPollsQuestionsModal {
    isEvent?: boolean,
    isLoading?: boolean,
    setIsLoading?: any,
    onClose?: () => void,
    isOpenModal: boolean,
}

export const PollsQuestionsModal: FC<IPollsQuestionsModal> = (
    {
        isEvent = false,
        isLoading,
        setIsLoading,
        onClose,
        isOpenModal = true
    }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id: any = searchParams.get("id") || searchParams.get("poll_id");
    const eventId = searchParams.get("event_id")

    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [isAlreadyReplied, setIsAlreadyReplied] = useState(false);
    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const [pollData, setPollData] = useState<any>({});

    const [answers, setAnswers] = useState<any>({});
    const [personalAnswers, setPersonalAnswers] = useState<any>({});
    const [trackbarAnswers, setTrackbarAnswers] = useState({});

    const [currentStep, setCurrentStep] = useState(0);
    const [next, setNext] = useState(0)

    const currentQuestion = pollData.text && pollData.text[currentStep];

    function reorderQuestions(questions: any, startId: number | string) {
        const visited = new Set();
        const result: any = [];

        function traverse(currentId: number | string) {
            if (!currentId || visited.has(currentId)) return;

            const currentQuestion = questions?.find((q: any) => q.question_id === currentId);
            if (!currentQuestion) return;

            visited.add(currentId);
            result.push(currentQuestion);

            traverse(currentQuestion.next);
        }

        traverse(startId);
        return result;
    }


    const now: any = Date.now() / 1000;

    const statusData =
        pollData.status == 0 || pollData.data > now
            ? "hidden"
            : pollData.end_date > now
                ? "active"
                : "completed";

    const handleSelect = (question_id: any, answer_id: any, isPersonal: any) => {
        setAnswers((prev: any) => {
            const current = prev[question_id] || [];

            if (currentQuestion?.type === "radio") {
                return {
                    ...prev,
                    [question_id]: current.includes(answer_id.toString())
                        ? []
                        : [answer_id.toString()]
                };
            }

            if (current.includes(answer_id.toString())) {
                return {
                    ...prev,
                    [question_id]: current.filter((id: any) => id !== answer_id.toString())
                };
            } else {
                return { ...prev, [question_id]: [...current, answer_id.toString()] };
            }
        });

        if (!isPersonal) {
            setPersonalAnswers((prev: any) => ({ ...prev, [question_id]: "" }));
        }
    };

    const handlePersonalInputChange = (question_id: any, value: any) => {
        setPersonalAnswers((prev: any) => ({ ...prev, [question_id]: value }));
    };


    useEffect(() => {
        if (currentQuestion?.type === "trackbar") {
            setAnswers((prev: any) => {

                return {
                    ...prev,
                    [currentQuestion.question_id]: [1]
                };
            });

        }
    }, [currentQuestion]);

    useEffect(() => {
        if (id) {
            $viewPollsItem(id, { formData: true }).then(res => {
                setPollData(res);

                if (res.status === 0) {
                    setIsAlreadyReplied(true);
                }

                if (res?.user_answers?.length > 0) {
                    const initialAnswers = res.user_answers.reduce((acc: any, curr: any) => {
                        acc[curr.question_id] = curr.answers;
                        return acc;
                    }, {});

                    const initialPersonalAnswers = res.user_answers.reduce((acc: any, curr: any) => {
                        if (curr.personal_answer) {
                            acc[curr.question_id] = curr.personal_answer;
                        }
                        return acc;
                    }, {});

                    setAnswers(initialAnswers);
                    setPersonalAnswers(initialPersonalAnswers);
                    setIsAlreadyReplied(true);
                }
            });
        }
    }, [id]);

    function moveFirstToEnd(array: any) {
        if (array) {
            if (array?.length == 0) return array;
            const [firstItem, ...rest] = array;
            return [...rest, firstItem];
        }
    }

    function onAnswer() {
        const updatedList = pollData?.text?.map((item: any, idx: number) => {
            return {
                question_id: item.question_id,
                answers: answers[idx + 1],
                ...(item?.type !== "trackbar" ?
                    { personal_answer: personalAnswers[idx + 1] || "" }
                    :
                    { personal_answer: personalAnswers[idx + 1] || item.min })
            }}
        );

        if (Object.keys(answers)?.length == pollData.text?.length && currentStep == pollData.text.length - 1 ) {
            setIsLoading(true)

            $answerPollsItem({
                id: pollData.id,
                text: JSON.stringify(updatedList)
            }, { formData: true }).then(res => {
                setIsStatusComplete(true);
                setIsLoading(false)
                // navigate(-1);

                if (eventId && isEvent) {
                    setIsLoading(true)

                    $subscribeEvent(+eventId, {newApi: true}).then(res => {
                        setIsLoading(false)
                    })
                }
            });
        }
        else if (next) {
            setCurrentStep(next - 1)
            setNext(0)
        }
        else if (currentQuestion.next && !checkKeyExists(currentQuestion.next)) {
            setCurrentStep(currentQuestion.next - 1)
        }
        else if (currentStep != pollData.text.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            setCurrentStep(findMissingKey() - 1)
        }
    }

    function changeStep(idx: number) {
        if ((answers[idx] && answers[idx]?.length > 0) || idx <= currentStep) {
            setCurrentStep(idx);
        }
    }

    function toStartAgain() {
        if (pollData.award_amount == 0 && pollData.awarding_enabled == 0) {
            setCurrentStep(0);
            setIsAlreadyReplied(false);
            setAnswers({});
            setPersonalAnswers({});
        }
    }

    function onSubscribe () {
        setIsLoading(true)
        if (eventId) {
            $subscribeEvent(+eventId, {newApi: true}).then(res => {
                setIsLoading(false)

                navigate(-1)
            })
        }
    }

    async function onDownloadExcel() {
        try {
            const url = `${API_URL}api/interviews/statexcel`;

            const formData = new FormData();
            formData.append("id", pollData.id);

            const response = await axios.post(url, formData, {
                responseType: "blob",
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = `${pollData.title}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();

            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }

        // $downloadPollExcel({id: pollData.id}, {formData: true,}).then(res => {
        //     downloadExel(res, `${pollData.title}.xls`);
        // })
    }

    function handleCloseModal() {
        onClose && onClose();
        setIsStatusComplete(false);
    }

    function checkKeyExists(number: number) {
        return answers.hasOwnProperty(number.toString());
    }

    function findMissingKey() {
        const keys = Object.keys(answers).map(Number).sort((a, b) => a - b);

        for (let i = 0; i < keys.length - 1; i++) {
            if (keys[i + 1] - keys[i] > 1) {
                return keys[i] + 1;
            }
        }
        return 0;
    }

    return (
        <>
            {pollData.text && (
                <DialogContainer
                    classNameHeader="polls-modal__header"
                    isOpen={isOpenModal}
                    isShowCloseBtn={!isStatusComplete}
                    closeModal={handleCloseModal}
                >
                    {isStatusComplete ? (
                        <div className={"sport__view__modal"}>
                            <div className={"sport__view__modal__content-complete"}>
                                <span>😊</span>

                                <h3>Благодарим</h3>

                                <p>
                                    Спасибо что помогаетее нам стать лучше!
                                    Ваше мнение для нас очень ценно
                                </p>
                            </div>

                            <div className={"sport__view__modal__btn"}>
                                <Button
                                    text={"Закрыть"}
                                    className={"btn btn-light w-100"}
                                    onClick={() => {
                                        handleCloseModal();
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="polls-modal">
                            <BlankImage image={pollData?.image} classname="polls-modal__image" />

                            <div className="polls-modal__content">
                                <div className="polls-modal__circles">
                                    {pollData?.text?.map((item: any, idx: number) => (
                                        <div
                                            key={idx}
                                            className={`polls-modal__circles-item ${idx === 0 ? "last" : ""} 
                                            ${idx <= currentStep ? "active" : ""}`}
                                        >
                                            <div
                                                className="polls-modal__circles-item-line">
                                            </div>

                                            <div
                                                onClick={() => changeStep(idx)}
                                                className="polls-modal__circles-item-circle">
                                                {checkKeyExists(idx + 1) &&
                                                    <TickIcon size={10} />}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="polls-modal-question">
                                    <h3 className="polls-modal-question__title">{currentQuestion?.text || "Пустой"}</h3>

                                    {currentQuestion?.type === "trackbar" && (
                                        <div>
                                            <Trackbar
                                                disabled={isAlreadyReplied || statusData == "completed"}
                                                min={currentQuestion.min}
                                                max={currentQuestion.max}
                                                value={personalAnswers[currentQuestion.question_id] || currentQuestion.min}
                                                onChange={(e: any) => {
                                                    handlePersonalInputChange(currentQuestion.question_id, e);
                                                }
                                                }
                                            />

                                            <p className="polls-modal-question__comment__label">Для
                                                выбора, передвиньте ползунок</p>
                                        </div>
                                    )}

                                    <div className="polls-modal-question__content">
                                        {moveFirstToEnd(currentQuestion?.answers)?.map((answer: any) => (
                                            <div>
                                                <div key={answer.id}
                                                     className="polls-modal-question__block">
                                                    {(answer.id == 0 && answer.personal_answer == 1) ? (
                                                        <>
                                                            <input
                                                                disabled={isAlreadyReplied || statusData == "completed"}
                                                                type="checkbox"
                                                                checked={(answers[currentQuestion.question_id] || []).includes(answer.id.toString())}
                                                                onChange={() => {
                                                                    setNext(answer.next || 0)
                                                                    handleSelect(currentQuestion.question_id, answer.id, answer.personal_answers)
                                                                }
                                                                }
                                                            />

                                                            <p>Другое</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {answer.text ? (
                                                                <>
                                                                    <input
                                                                        disabled={isAlreadyReplied || statusData == "completed"}
                                                                        type="checkbox"
                                                                        checked={(answers[currentQuestion.question_id] || []).includes(answer.id.toString())}
                                                                        onChange={() => {
                                                                            setNext(answer.next || 0)
                                                                            handleSelect(currentQuestion.question_id, answer.id, answer.personal_answers)
                                                                        }
                                                                        }
                                                                    />

                                                                    <p>{answer?.text || ''}</p>
                                                                </>
                                                            ) : ''}
                                                        </>
                                                    )}
                                                </div>

                                                {answer.id == 0 &&
                                                    (answers[currentQuestion.question_id] || []).includes(answer.id.toString()) ? (
                                                        <>
                                                            <div
                                                                className="polls-modal-question__comment">
                                                                <h1 className="polls-modal-question__comment__label">Ваш
                                                                    ответ</h1>

                                                                <input
                                                                    disabled={isAlreadyReplied || statusData == "completed"}
                                                                    type="text"
                                                                    value={personalAnswers[currentQuestion.question_id] || ""}
                                                                    className="polls-modal-question__comment__input"
                                                                    onChange={(e) =>
                                                                        handlePersonalInputChange(currentQuestion.question_id, e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    ) : ''}
                                            </div>
                                        ))}

                                        {!!+pollData?.awarding_enabled && (
                                            <div className="polls-create-preview__point mt-0">
                                                <h1 className="polls-create-preview__point__title">Награда:</h1>

                                                <div className="polls-create-preview__point__stats">
                                                    <CoinCompetitionIcon />

                                                    <p>{pollData?.award_amount}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="polls-modal-question__buttons">
                                        {!isEvent && (
                                            <>
                                                {statusData !== "completed" ? (
                                                    <>
                                                        {isAlreadyReplied ? (
                                                            <>
                                                                {+pollData.award_amount == 0 && +pollData.awarding_enabled == 0 ? (
                                                                    <div
                                                                        className="polls-modal-question__button"
                                                                        onClick={() => toStartAgain()}>
                                                                        Пройти опрос заново
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="polls-modal-question__button"
                                                                        onClick={() => navigate("/polls")}
                                                                    >
                                                                        Вернуться к опросам
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div
                                                                style={{ opacity: answers[currentStep + 1]?.length > 0 ? "" : "0.5" }}
                                                                className="polls-modal-question__button"
                                                                onClick={onAnswer}
                                                            >
                                                                Ответить
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <div
                                                        className="polls-modal-question__button"
                                                        onClick={() => navigate("/polls")}
                                                    >
                                                        Вернуться к опросам
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {isEvent && (
                                            <>
                                                {isAlreadyReplied ? (
                                                    <div
                                                        style={{ opacity: statusData === 'completed' ? 0.5 : 1 }}
                                                        className="polls-modal-question__button"
                                                        onClick={() => statusData !== 'completed' && onSubscribe()}
                                                    >
                                                        Записаться
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{ opacity: answers[currentStep + 1]?.length > 0 ? "" : "0.5" }}
                                                        className="polls-modal-question__button"
                                                        onClick={() => answers[currentStep + 1]?.length > 0 && onAnswer}
                                                    >
                                                        Ответить
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {!isEvent && isAdmin && (
                                            <>
                                                <div
                                                    onClick={() => navigate(`/polls/edit?poll_id=${pollData.id}`)}
                                                    className="polls-modal-question__button polls-modal-question__button__edit">
                                                    Редактировать
                                                </div>

                                                <div
                                                    onClick={onDownloadExcel}
                                                    className="polls-modal-question__button polls-modal-question__button__edit">
                                                    <AnalyticsDownloadIcon color="#00A7B5" />
                                                    Посмотреть ответы
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </DialogContainer>
            )}
        </>
    );
};