import { useState, useEffect, useRef, FC } from "react";
import { SearchIcon } from "../../../img";
import { isMd, isTillMd } from "../../../utils";
import { $headerSearchPost } from "../../../api/requests/workplace";
import { HeaderSearchPopup } from "./HeaderSearchPopup";
import { HeaderSearchBar } from "./HeaderSearchBar";
import DialogContainer from "../../ui/dialog/DialogContainer";

interface IHeaderSearch {
    isPopup: boolean,
    setPopup: any
}

export const HeaderSearch: FC<IHeaderSearch> = ({
                                                    isPopup,
                                                    setPopup
                                                }) => {
    const isMobile = isMd();

    // const { user, updateUser } = useContext(authContext);

    // const [ isPopup, setPopup ] = useState(false);
    const [isSearch, setSearch] = useState<any>("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [isData, setIsData] = useState<any>();
    const popupRef = useRef<HTMLDivElement>(null);
    const popupSecondRef = useRef<HTMLDivElement>(null);

    const inputRef = useRef<any>(null);

    const defaultSearchData = {
        workspaces: [],
        users: [],
        groups: [],
        partners: [],
        contests: [],
        news: [],
        ideas: [],
        interviews: []
    };
    const [isSearchResults, setSearchResults] = useState(defaultSearchData);

    useEffect(() => {

    }, [isSearch]);

    const handleSendData = () => {
        setIsLoaded(true);
        $headerSearchPost({ search: isSearch }, { formData: true }).then(res => {
            setIsData(res);
            setSearchResults(res);

            setIsLoaded(false);
        });
    };

    useEffect(() => {
        let timer: any;
        if (isSearch) {
            // setLocalSearch(isSearch);
            // markInstance.unmark();
            timer = setTimeout(() => handleSendData(), 500);
        }
        return () => clearTimeout(timer);
    }, [isSearch]);

    useEffect(() => {
        if (isLoaded) {
        }
    }, [isLoaded]);

    useEffect(() => {
        if (!isSearch) {
            setSearchResults(defaultSearchData);
        }
    }, [isSearch]);

    useEffect(() => {
        if (!isMobile) {
            if (isPopup) {
                document.body.classList.add("header-search__clickable");
            } else {
                document.body.classList.remove("header-search__addClickable");
            }
        }
        if (isMobile) {
            if (isPopup) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        }
    }, [isPopup, isMobile]);

    useEffect(() => {
        if (window.localStorage.getItem("search")) {
            setSearch(window.localStorage.getItem("search"));
        }
    }, []);

    useEffect(() => {
        if (!isPopup) {
            // setSearch('');
            setSearchResults(defaultSearchData);
        }

        const footer: any = document.querySelector(".menu__footer");
        if (footer) {
            if (isPopup) {
                footer.style.zIndex = "7";
            } else {
                footer.style.zIndex = "10"; // Restore original zIndex
            }
        }
        return () => {
            // Clean up when the component unmounts
            if (footer) footer.style.zIndex = "10";
        };
    }, [isPopup]);

    const handleOutsideClick = (event: MouseEvent) => {
        if ((popupRef.current && !popupRef.current.contains(event.target as Node))) {
            setSearch("");
            setPopup(false);
        }
    };

    useEffect(() => {
        if (isPopup) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isPopup]);

    useEffect(() => {
        if (isPopup && !isMobile && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isPopup, isMobile]);


    return (
        <div ref={popupRef}>
            {isPopup && !isMobile ? (
                <div className="header-search">
                    <HeaderSearchBar isSearch={isSearch} setSearch={setSearch} isLoading={isLoaded}
                                     setPopup={setPopup} inputRef={inputRef} />
                </div>
            ) : (
                <>
                    {!isMobile && (
                        <div ref={popupSecondRef} className="header-search__closed"
                             onClick={() => setPopup(true)}>
                            <SearchIcon color={"#000"} />
                        </div>
                    )}
                </>
            )}


            {isPopup && (
                <HeaderSearchPopup
                    isSearchResults={isSearchResults}
                    setSearchResults={setSearchResults}
                    setPopup={setPopup}
                    setSearch={setSearch}
                    isSearch={isSearch}
                    isLoading={isLoaded}
                />
            )}
        </div>
    );
};