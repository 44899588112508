import React, { ReactNode, useEffect, useState } from "react";
import { ArrowExitIcon, CloseIcon, SearchIcon } from "../../../img";
import { withDebounce } from "../../../functions";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";
import noImageBanner from "../../../img/noimage.png";
import DialogContainer from "./DialogContainer";

export interface UserData {
    name: string,
    photo: any,
    user_id: number,
    workplace?: string,
    status?: number | string,
    position?: string;
    department?: string;
}

export interface BtnDataItem {
    text: string | ReactNode;
    className?: string;
    disabled?: boolean;
    key: number;
}

interface UserSelectListModalProps {
    isOpen: boolean,
    list: UserData[];
    btnList?: BtnDataItem[];
    title?: string;
    isSearch?: boolean;
    isClose?: boolean;
    onBack?: () => void;
    onClose: (btnItem: BtnDataItem | null, list: number[]) => void;
    isSingleSelect?: boolean;
    isSearchApi?: (val: string) => void;
}

const UserSelectListDialog = (
    {
        isOpen,
        list,
        btnList,
        title,
        isSearch,
        onBack,
        onClose,
        isClose,
        isSingleSelect,
        isSearchApi
    }: UserSelectListModalProps
) => {
    const [filterUserList, setFilterUserList] = useState(list);

    const [selectedReplacer, setSelectedReplacer] = useState<number[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(() => {
        list && setFilterUserList(list);
    }, [list]);

    function onChangeSearch(value: string) {
        setSearchValue(value);

        isSearchApi && isSearchApi(value);

        const updateList = [...list].filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

        setFilterUserList(updateList);
    }

    function onChangeCheckbox(idx: number) {
        if (isSingleSelect) {
            if (!btnList) onClose(null, [idx]);

            setSelectedReplacer(() => [idx]);

            return;
        }

        selectedReplacer.includes(idx) ?
            setSelectedReplacer((prev) => [...prev].filter(i => i !== idx)) :
            setSelectedReplacer((prev) => [...prev, idx]);
    }

    function handleChangeBtn(btnItem: BtnDataItem) {
        onClose(btnItem, selectedReplacer);
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={428}
        >
            <div className={"contests__winner"}>
                <div className={"contests__winner__header"}>
                    {onBack && (
                        <div className={"back"} onClick={onBack}>
                            <ArrowExitIcon color={"#00A7B5"} size={14} strokeWidth={4} />
                        </div>
                    )}

                    <h3>{title}</h3>

                    {isClose && (
                        <div
                            className={"d-flex align-items-center ml-auto cursor-pointer"}
                            onClick={() => onClose(null, selectedReplacer)}
                        >
                            <CloseIcon color={"black"} opacity={"1"} />
                        </div>
                    )}
                </div>

                {isSearch && (
                    <div className={"contests__winner__search"}>
                        <TextField
                            value={searchValue}
                            onChange={(value: string) => {
                                setSearchValue(value);
                                withDebounce(() => onChangeSearch(value));
                            }}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"black"} />}
                            className={`search__block-textField`}
                        />
                    </div>
                )}

                <div className={"contests__winner__list"}>
                    {filterUserList?.map((item, idx) => (
                        <div
                            key={idx}
                            className={`layout-vacation-modal__block ${isSingleSelect && selectedReplacer.includes(idx) ? "active" : ""}`}
                        >
                            {!isSingleSelect && (
                                <input
                                    type="checkbox"
                                    checked={selectedReplacer.includes(idx)}
                                    onChange={() => onChangeCheckbox(idx)}
                                />
                            )}

                            <div
                                className={"layout-vacation-modal__block__right"}
                                onClick={() => onChangeCheckbox(idx)}
                            >
                                <img src={item.photo || noImageBanner} alt="" />

                                <div className="layout-vacation-modal__block__desc">
                                    <h1>{item.name}</h1>

                                    <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                    <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                                </div>
                            </div>

                            {/*{item.right && (*/}
                            {/*    <>*/}
                            {/*        {item.right.icon(item.id)}*/}
                            {/*    </>*/}
                            {/*)}*/}
                        </div>
                    ))}
                </div>

                {btnList && (
                    <div className={"contests__winner__btns"}>
                        {btnList.map((item, idx) => (
                            <Button
                                key={`btn-win-item-${idx}`}
                                text={item.text}
                                className={`btn ${item.className}`}
                                onClick={() => handleChangeBtn(item)}
                                disabled={item.disabled || !selectedReplacer.length}
                            />
                        ))}
                    </div>
                )}
            </div>
        </DialogContainer>
    );
};

export default UserSelectListDialog;