import {
    ArrowExitIcon,
    HeartIcon,
    PhoneIcon,
    EmailIcon,
    ThreePeopleIcon,
    StarIcon
} from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import DialogContainer from "../../components/ui/dialog/DialogContainer";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { isMd } from "../../utils";

import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { isEmpty } from "lodash";
import { $getNewEmployees, $getStruct } from "../../api/requests/employees";
import { $toggleUserFavorites } from "../../api/requests/favorites";

interface IEmployeesContent {
    id: number;
    name: string;
    text: string;
    position: string;
    image: string;
    isLiked: boolean;
    number: string;
    email: string;
    birth: string;
    skills: any;
    leader: any;
    replacer: any;
    uniq?: string;
}

let lastUrl = '';
let busyCat = false;

export const EmployeesPage = () => {
    const isMobile = isMd();
    const isMobileSecond = window.screen.width < 570
    const location = useLocation();
    const navigate = useNavigate();

    const [dialog, setDialog] = useState(false)
    const [activePerson, setActivePerson] = useState<IEmployeesContent>()

    const searchParams = new URLSearchParams(location.search);
    const getCatId = searchParams.get('struct') || null;

    const profileData = useSelector(getPersonData);

    const [isLoading, setIsLoading] = useState(false);

    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [categorySelect, setCategorySelect] = useState<any | null>(null);

    const [employeesContent, setEmployeesContent] = useState<IEmployeesContent[]>([])

    const hasLoaded = useRef(false);

    useEffect(() => {
        if (!isEmpty(profileData)) {
            if (!hasLoaded.current) {
                if (!categoryList.length && !busyCat) {
                    busyCat = true;
                    $getStruct(profileData?.workspace_id || null).then(res => {
                        busyCat = false;

                        if (!res || res.error || !res.data) return;
                        const updateList = [...res.data].map((item: any) => ({
                            ...item,
                            value: item.id,
                            label: item.title,
                        }))

                        setCategoryList(updateList)

                        hasLoaded.current = true;
                    }).catch(() => {
                        busyCat = false;
                    })
                }
            }

            if (getCatId) {
                setCategorySelect(categoryList.find(i => i.value == getCatId))
            }

            if (location.pathname + location.search != lastUrl || (!isLoading && !employeesContent?.length)) {
                loadData(profileData);
            }
        }
    }, [profileData, location, getCatId]);

    function loadData(profileData: any) {
        const requestData: any = {
            workspace_id: profileData?.workspace_id || null,
            // offset: 0,
            // limit: 30
        }

        if (getCatId) {
            requestData['struct'] = getCatId || '';
        }

        $getNewEmployees(requestData).then(res => {
            setIsLoading(false);
            if (!res || res.error) return;
            setEmployeesContent(res.data);
        }).catch(e => {
            setIsLoading(true);
            console.log('error', e);
        })
    }

    const handleLike = (id: number, isLiked: boolean) => {
        setIsLoading(true);

        $toggleUserFavorites({ type: 'user', type_id: id, add_to_favorites: !isLiked }, {
            newApi: true,
        }).then(res => {
            setIsLoading(false);
            const updatedList: any = employeesContent?.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isLiked: !item.isLiked
                    }
                } else {
                    return item
                }
            })

            const getActivePerson = updatedList.find((item: IEmployeesContent) => item.id === id)

            setEmployeesContent(updatedList)
            setActivePerson(getActivePerson)
        });
    };

    function handleCategory(select: any) {
        setCategorySelect(select);
        navigate(`?${select.value ? `&struct=${select.value}` : ''}`)
    }

    const handleNavigation = () => {
        navigate(-1);
    };

    return (
        <div className='container employees'>
            <div className='layout-inner__header birthdays-header'>
                {!isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Новые сотрудники</h1>
                    </div>
                )}

                <div className='employees-header__right'>
                    <Dropdown
                        className='workspace-select employees-header__right__select'
                        value={categorySelect}
                        placeholder={'Показать все'}
                        options={[
                            {
                                label: "Показать все",
                                value: ""
                            },
                            ...categoryList
                        ]}
                        onChange={handleCategory}
                    />

                    {!isMobileSecond && (
                        <div
                            className='employees-header__structure cursor-pointer'
                            onClick={() => navigate("/structure")}
                        >
                            <ThreePeopleIcon />
                            <p>Структура компании</p>
                        </div>
                    )}
                </div>
            </div>

            <div className='employees-content'>
                {employeesContent && employeesContent?.map((
                    item,
                    idx
                ) => (
                    <div className='employees-block' key={idx} >
                        <div className='employees-block__top'>
                            <div
                                className={"structure__content__item-avatar"}
                                style={item?.image ? {
                                    backgroundImage: `url(${item?.image})`,
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                    cursor: "pointer"
                                } : {}}
                                onClick={() => {
                                    item?.uniq && navigate(`/user/${item.uniq}`)
                                }}
                            >
                                {!item?.image && <p>{item?.name?.charAt(0)}</p>}
                            </div>

                            <div
                                onClick={() => {
                                    handleLike(item.id, item.isLiked)
                                }}
                                className={`employees-block__likeIcon ${item.isLiked ? 'active' : ''}`}
                            >
                                <StarIcon opacity={item.isLiked ? 1 : 0.2} />
                            </div>
                        </div>

                        <div className='employees-block__content' onClick={() => {
                            setDialog(true)
                            setActivePerson({ ...item })
                        }}>
                            <h1 className='employees-block__title'>{item?.name}</h1>

                            <p className='employees-block__text'>{item?.text}</p>

                            <p className='employees-block__position'>{item?.position}</p>
                        </div>
                    </div>
                ))}
            </div>

            {isMobileSecond && (
                <div
                    className='employees-header__structure'
                    onClick={() => navigate("/structure")}
                >
                    <ThreePeopleIcon />
                    <p>Структура компании</p>
                </div>
            )}

            {activePerson && (
                <DialogContainer
                    isOpen={dialog}
                    closeModal={() => setDialog(false)}
                >
                    <div className='employees-modal'>
                        <div className='employees-modal__top'>
                            <div
                                className={"structure__content__item-avatar"}
                                style={activePerson.image ? {
                                    backgroundImage: `url(${activePerson.image})`,
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover"
                                } : {}}
                            >
                                {!activePerson.image && <p>{activePerson.name?.charAt(0)}</p>}
                            </div>
                            <h1>
                                {activePerson?.name}
                            </h1>
                        </div>

                        <div className='employees-modal__contacts'>
                            <div className='employees-modal__contacts__block'>
                                <PhoneIcon />
                                <p>{activePerson?.number || `Не указан`}</p>
                            </div>

                            <div className='employees-modal__contacts__block'>
                                <EmailIcon />
                                <p>{activePerson?.email || `Не указан`}</p>
                            </div>
                        </div>

                        <div className='employees-modal__content'>

                            <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Департамент</p>
                                <h1 className='employees-modal__content__value'>{activePerson?.text || `Не указан`}</h1>
                            </div>

                            <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Должность</p>
                                <h1 className='employees-modal__content__value'>{activePerson?.position}</h1>
                            </div>

                            <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Дата рождения</p>
                                <h1 className='employees-modal__content__value'>{activePerson?.birth}</h1>
                            </div>

                            {activePerson?.skills?.length > 0 && <div className='employees-modal__content__block'>
                                <p className='employees-modal__content__placeholder'>Навыки</p>

                                <div className={'colleagues-modal__content__list'}>
                                    {activePerson?.skills.map((item: string) => (
                                        <div className='employees-modal__content__list__block'>
                                            <span></span>
                                            <p>{item}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </div>

                        <div
                            onClick={() => handleLike(activePerson?.id, activePerson?.isLiked)}
                            className={`employees-modal__button ${activePerson?.isLiked ? 'active' : ''}`}>
                            <div
                                className={`employees-block__likeIcon 
                                ${activePerson?.isLiked ? 'active' : ''}`
                                }
                            >
                                <StarIcon opacity={activePerson.isLiked ? 1 : 0.2} />
                            </div>

                            {activePerson?.isLiked ? (
                                <p>
                                    В избранном
                                </p>
                            ) : (
                                <p>
                                    Добавить в избранные
                                </p>
                            )}
                        </div>

                        <div className='employees-modal__people'>

                            {activePerson.leader && <div className='employees-modal__people__content'>
                                <p className='employees-modal__content__placeholder'>Руководитель</p>

                                <div className='employees-modal__people__block'>
                                    <div
                                        className={"structure__content__item-avatar"}
                                        style={activePerson.leader?.image ? {
                                            backgroundImage: `url(${activePerson.leader?.image})`,
                                            backgroundPosition: "center center",
                                            backgroundSize: "cover"
                                        } : {}}
                                    >
                                        {!activePerson.leader?.image && <p>{activePerson.leader?.name?.charAt(0)}</p>}
                                    </div>

                                    <div className='employees-modal__people__block__content'>
                                        <h1>{activePerson.leader?.name}</h1>

                                        <div>
                                            <PhoneIcon />

                                            <p>{activePerson.leader?.number || `Не указан`}</p>
                                        </div>

                                        <div>
                                            <EmailIcon />

                                            <p>{activePerson.leader?.email || `Не указан`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {activePerson.replacer && <div className='employees-modal__people__content'>
                                <p className='employees-modal__content__placeholder'>Замещающий</p>

                                <div className='employees-modal__people__block'>
                                    <div
                                        className={"structure__content__item-avatar"}
                                        style={activePerson.replacer?.image ? {
                                            backgroundImage: `url(${activePerson.replacer?.image})`,
                                            backgroundPosition: "center center",
                                            backgroundSize: "cover"
                                        } : {}}
                                    >
                                        {!activePerson.replacer?.image && <p>{activePerson.replacer?.name?.charAt(0)}</p>}
                                    </div>
                                    <div className='employees-modal__people__block__content'>
                                        <h1>{activePerson.replacer?.name}</h1>

                                        <div>
                                            <PhoneIcon />

                                            <p>{activePerson.replacer?.number || `Не указан`}</p>
                                        </div>

                                        <div>
                                            <EmailIcon />

                                            <p>{activePerson.replacer?.email || `Не указан`}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        </div>
                    </div>
                </DialogContainer>
            )}
        </div>
    )
}