import MobileImageAuth from "../../../components/auth/MobileImageAuth";
import ResetForm from "../../../components/auth/ResetForm";
import { Link } from "react-router-dom";

export default function Reset() {
    return (
        <>
            <Link to={'/auth'} className="auth__steps registration__click">
                <span className="auth__steps-icon">
                    <img
                        width={24}
                        height={24}
                        src="/images/icons/close.svg"
                        alt="close"
                    />
                </span>

                <span className="auth__steps-text">Восстановление пароля</span>
            </Link>

            <div className="auth__content_left_block">
                <div className="auth__content_left_block">
                    <MobileImageAuth />

                    <div className="auth__content_left_block_header">
                        <img
                            src="/images/logo.png"
                            alt="banner"
                            width={157}
                            height={60}
                        />
                    </div>

                    <div className="auth__content_left_block_content">
                        <div className="auth__login auth__click">
                            <ResetForm />
                        </div>
                    </div>

                    <div className="auth__content_left_block_footer">
                        <div className="auth__content_help">
                            <p>
                                Помощь{' '}
                                <a href="tel:88007707867">8 (800) 770-78-67</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
