import { HeartIcon } from "../../img";
import { useEffect, useState } from "react";
import { $getUserFavoritesPreview } from "../../api/requests/favorites";
import {useSelector} from "react-redux";
import {getPersonData} from "../../redux";

export const FavoritesCard = () => {
    const personalData = useSelector(getPersonData);
    const [favoritesLength, setFavoritesLength] = useState(0);

    useEffect(() => {
        $getUserFavoritesPreview({ newApi: true }).then((res) => {
            if (!res.data) return;

            let count = 0;

            res.data.forEach((item: any) => {
               count = count + (item.items?.length || 0)
            })

            setFavoritesLength(count)
        });

    }, []);

    return (
        <div className='layout-favorites'>
            <div className='layout-favorites__left'>
                <HeartIcon/>

                <h1>Избранное</h1>
            </div>

            <div className='layout-favorites__count'>
                {personalData?.favorites_count || 0}
            </div>
        </div>
    )
}