import React, {useEffect, useState} from "react";
import { ArrowExitIcon} from "../../../img";
import {SearchItem} from "../../../components/ui/form/SearchItem";
import { ServicesApplicationsList} from "../../../components/services";
import {isMd} from "../../../utils";
import {useNavigate} from "react-router-dom";
import {IServicesApplications} from "../../../api/requests/services/interface";
import {Controller, useForm} from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import {ServicesFormingApplicationModal} from "../../../components/services/services-education/modals";
import moment from "moment/moment";
import {
    $getServiceTripsApproving, $getServiceTripsHistory,
    $getServiceTripsProcessing
} from "../../../api/requests/services/trip";
import {$getMedicalHistoryList, $getMedicalProcessingList} from "../../../api/requests/services/medical-leave";
import { withDebounce } from "../../../functions";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesMedicalPage = () => {
    const searchParams = new URLSearchParams(location.search);
    const activeType: any = searchParams.get('activeType') ;

    const navigate = useNavigate()
    const isMobile = isMd();

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [ servicesApplicationsData, setServicesApplicationsData ] = useState<IServicesApplications[]>([])

    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const [search, setSearch] = useState('')
    const [type, setType] = useState("");
    const [medical_date, setMedicalDate] = useState("");
    const [date, setDate] = useState("");

    const [processingList, setProcessingList] = useState([]);
    const [approvingList, setApprovingList] = useState([]);
    const [historyList, setHistoryList] = useState([]);

    const [types, setTypes] = useState([
        {
            id: 1,
            key: 'applications',
            title: 'Мои заявки'
        },
        {
            id: 3,
            key: 'history',
            title: 'История заявок'
        }
    ])

    const columns = [
        { key: "id", title: "ID заявки", width: 25, class: "id", render: (value: number | string,) => (
                <div className="services-acceptance__block__id">
                    {value}
                </div>
            ) },
        { key: "medical_date", title: "Дата больничного", width: 25 },
        { key: "created_at", title: "Дата заявки", width: 25 },
        {
            key: "status", title: "Статус", width: 25, render: (value: any, row: any) => (
                <div
                    className={`services-applications-create__status ${value === 'pending' ? 'waiting' : (value === 'approved' ? 'accepted' : 'rejected')}`}
                >
                    {value === 'pending' ? 'Рассмотрение' : (value === 'approved' ? 'Одобрено' : 'Отклонено')}
                </div>
            )
        }
    ];

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: {isValid},
        setValue,
    } = useForm<any>();

    const handleUpdatedList = (data: any) => {
        return data.map((item: any) => {
            return {
                ...item,
                medical_date: `${moment(item?.start_date).format("DD.MM.YYYY")} - ${moment(item?.end_date).format("DD.MM.YYYY")}`,
                created_at: moment(item?.created_at).format("DD.MM.YYYY")
            };
        });
    };

    useEffect(() => {
        init();
    }, [type, date, medical_date, activeType, search]);

    useEffect(() => {
        setPage(1)
        setLastPage(1)
    }, [activeType])

    useEffect(() => {
        withDebounce(() => init())
    }, [search, page]);

    function init() {
        const requestData = {
            search: search || undefined,
            page,
            type: type || undefined,
            date: date || undefined,
            medical_date: medical_date || undefined,
        }

        if (activeType == 1) {
            $getMedicalProcessingList(requestData,{ newApi: true }).then(res => {
                if (!res.data.data) return;

                setLastPage(res?.data?.last_page)
                setProcessingList(handleUpdatedList(res.data.data));
            });
        }

        if (activeType == 3) {
            $getMedicalHistoryList(requestData,{ newApi: true }).then(res => {
                if (!res.data.data) return;

                setLastPage(res?.data?.last_page)
                setHistoryList(handleUpdatedList(res.data.data));
            });
        }
    }

    return (
        <div className='services-education'>
            <div className='container services-education-header'>
                { !isMobile && (
                    <div className='services-education-header__left'>
                        <div className='header__exitIcon' onClick={ () => navigate('/services') }>
                            <ArrowExitIcon />
                        </div>

                        <h1>Больничный</h1>
                    </div>
                ) }

                <div className="services-education-header__right">
                    <SearchItem onChange={() => ''} setValueProps={(e) => setSearch(e)} selectItem={search}/>
                </div>
            </div>

            <div className='services-education-content'>
                <div className='container services-education-content__top'>
                    { types?.map((
                        item,
                        idx
                    ) => (
                        <div
                            key={ idx }
                            className={ `services-education-content__top__block ${ activeType == item.id ? 'active' : '' }` }
                            onClick={ () => navigate(`/services/medical/list?activeType=${item.id}`) }
                        >
                            { item.title }
                        </div>
                    )) }
                </div>

                <div  className='services-education-wrapper'>
                    <div className='container services-education-wrapper__header'>
                        <div className='services-education-wrapper__buttons'>
                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="medical_date"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата больничного"
                                            placeholder={"Не выбрано"}
                                            onChange={(e) => setMedicalDate(e ? moment(e).format("YYYY-MM-DD") : '')}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>

                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="date"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={"Не выбрано"}
                                            onChange={(e) => setDate(e ? moment(e).format("YYYY-MM-DD") : '')}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        {activeType == 1 && (
                            <div className='services-education-wrapper__header__button' onClick={() => navigate('/services/medical/create')}>
                                Подать заявку
                            </div>
                        )}
                    </div>

                    <ServicesApplicationsList
                        columns={columns}
                        list={activeType == 1 ? processingList : historyList}
                        urlRedirect={""}
                        page={page}
                        setPage={setPage}
                        lastPage={lastPage}
                    />
                </div>
            </div>

            {isOpenModal && (
                <ServicesFormingApplicationModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}/>
            )}
        </div>
    )
}