import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import {
    getCurrentRole,
    getIsOwner, getPersonData,
    getPopupData,
    getProfileVarsData
} from "../../../../redux";

import { MembersBlock } from "./MembersBlock";
import { failureNotify } from "../../../../utils";
import {
    $additionalGet,
    $setVacationBooking,
} from "../../../../api/requests/vacation";
import Button from "../../../ui/form/Buttons/Button";
import DialogContainer from "../../../ui/dialog/DialogContainer";
import styles from "./CalendarBookingPopup.module.scss";
import { UserSingle } from "./MembersBlock/UserSingle";
import { getNoun } from "../../../../utils/field/getNoun";
import { $changeMedicalStatus } from "../../../../api/requests/services/medical-leave";
import { $updateServiceTripStatus } from "../../../../api/requests/services/trip";
import { FileIcon } from "../../../../img/icons/menu/FileIcon";
import { DownloadIcon } from "../../../../img/icons/DownloadIcon";

interface BookingPopupData {
    isOpen: boolean;
    onClose: () => void;
    setIsOpen: () => void;
}

export const BookingPopup = (
    {
        isOpen,
        onClose,
        setIsOpen
    }: BookingPopupData) => {
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const typeBusiness: any = {
        "RF": "По РФ",
        "Abroad": "По Зарубеж"
    }

    const user = useSelector(getPopupData);
    const isOwner = useSelector(getIsOwner);
    const vars = useSelector(getProfileVarsData);
    const personalData = useSelector(getPersonData);

    const {
        id,
        for_user,
        created_at,
        start_date,
        end_date,
        days_count,
        reason,
        files,
        conciliated_by_id,
        is_special: isSpecial,
        isEdit,
        isConciliate,
        isAccept,
        isRefuse,
        isCancel,
        isProcess,
        isShow,
        isProgress,
        isSuccess
    } = user || {};

    const initialBooking = {
        isDirty: false,
        isAdmin: false,
        isEdit: false,
        isConciliate: false,
        isShow: false,
        isAccept: false,
        isRefuse: false,
        isCancel: false,
        isProcess: false,
        request: {
            start_date: moment(new Date()).format("yyyy-MM-DD"),
            end_date: moment(new Date()).add(1, "days").format("yyyy-MM-DD"),
            // end_date: '',
            for_user_id: null,
            reason: "",
            files: null
        },
        id: null,
        member: null,
        conciliator: null,
        created: null,
        days: null,
        work_days: null,
    };

    const [booking, setBooking] = useState(initialBooking);

    const isValid = booking?.request?.start_date && booking?.request?.end_date;

    const handleSave = (is_approve: boolean = false) => {
        if (user.type === "vacation") {
            $setVacationBooking(
                id,
                { is_approve }
            ).then(res => {
                onClose();
            });
        }

        if (user.type === "medical") {
            $changeMedicalStatus(
                id,
                { status: is_approve ? "approved" : "rejected" }
            ).then(res => {
                onClose();
            });
        }

        if (user.type === "business") {
            $updateServiceTripStatus(
                { trip_id: id, status: is_approve ? "approved" : "rejected" },
                { newApi: true }
            ).then(res => {
                onClose();
            });
        }
    };

    const getTextByType = () => {
        if (user?.type === "vacation") {
            if (isProgress) return "Согласование отпуска"

            if (isSuccess) return "Отпуск"
        }

        if (user?.type === "medical") {
            if (isProgress) return "Согласование работы в больничный"

            if (isSuccess) return "Больничный"
        }

        if (user?.type === "business") {
            if (isProgress) return "Согласование командировки"

            if (isSuccess) return "Командировка"
        }
    }

    const style: any = {
        "--lightBlue": vars.colors.secondaryColor,
        "--whiteColor": vars.colors.fourthColor
    };

    useEffect(() => {
        let timeout: any;

        if (!isOpen) {
            timeout = setTimeout(() => setBooking(initialBooking), 500);
        }

        return () => clearTimeout(timeout);
    }, [isOpen]);

    useEffect(() => {
        if (user) {
            setBooking((prev) => {
                return {
                    ...prev,
                    isAdmin,
                    isEdit,
                    isConciliate,
                    isShow,
                    isAccept,
                    isRefuse,
                    isCancel,
                    isProcess,
                    request: {
                        ...prev?.request,
                        start_date: start_date || moment(new Date()).format("yyyy-MM-DD"),
                        end_date: end_date || moment(new Date()).add(1, "days").format("yyyy-MM-DD"),
                        // end_date: end_date || '',
                        for_user_id: for_user?.id,
                        reason: reason || "",
                        files
                    },
                    id,
                    member: for_user,
                    conciliator: conciliated_by_id,
                    created: created_at,
                    daysCount: days_count
                };
            });
        }
    }, [user]);

    useEffect(() => {
        if (
            isOpen &&
            !isSpecial &&
            !isConciliate &&
            !isCancel &&
            !isShow &&
            booking?.request?.start_date &&
            booking?.request?.end_date &&
            booking?.request?.for_user_id
        ) {
            const requestData = `for_user_id=${booking?.request?.for_user_id}&start_date=${booking?.request?.start_date}&end_date=${booking?.request?.end_date}${isEdit ? "&update=1" : ""}`;

            $additionalGet(requestData).then(res => {
                if (booking?.isDirty && !res.data) {
                    failureNotify(res.message);

                    return;
                }

                setBooking((prev) => {
                    return {
                        ...prev,
                        additionalDays: res?.data?.available_days
                    };
                });
            });
        }
    }, [isEdit, booking?.request?.start_date, booking?.request?.end_date, booking?.request?.for_user_id]);

    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                widthProps={420}
                label={<UserSingle isShort data={user} />}
                classNameHeader={"align-items-start"}
            >
                <div className={"calendar__booking-popup__wrapper"} style={style}>
                    <div className={"calendar__booking-popup__form"}>
                        <div className={"calendar__booking-popup__block"}>
                            <div className={"calendar__booking-popup__label"}>
                                <span>Департамент</span>

                                <p>{personalData?.structure?.title || "Не определено"}</p>
                            </div>
                        </div>

                        <div className={"calendar__booking-popup__block"}>
                            <div className={"calendar__booking-popup__label"}>
                                <span>Должность</span>

                                <p>{user?.user?.staff?.title || "Не определено"}</p>
                            </div>
                        </div>
                    </div>

                    <div className={"calendar__booking-popup__form"}>
                        <div className={"calendar__booking-popup__block"}>
                            <div className={"calendar__booking-popup__label"}>
                                {getTextByType()}
                            </div>

                            <div className={"calendar__booking-popup__block"}>
                                <div className={"calendar__booking-popup__label"}>
                                    <span>
                                        {user?.type === "vacation" && "Даты отпуска"}
                                        {user?.type === "medical" && "Даты работы в больничный"}
                                        {user?.type === "business" && "Даты"}
                                    </span>

                                    c {moment(booking?.request?.start_date).format("DD.MM.YYYY")} {" "}
                                    по {moment(booking?.request?.end_date).format("DD.MM.YYYY")}
                                </div>
                            </div>

                            {user?.file && (
                                <div
                                    className={"profile-colleagues-modal__vacationContent__file"}
                                    onClick={() => window.open(user?.file, "_blank")}
                                >
                                    <div className={"profile-colleagues-modal__vacationContent__file__left"}>
                                        <FileIcon opacity={1}/>
                                    </div>

                                    <div className={"profile-colleagues-modal__vacationContent__file__text"}>
                                        <p className={"title"}>Файл</p>

                                        <p className={"text"}>{user?.filename}</p>
                                    </div>

                                    <div className={"profile-colleagues-modal__vacationContent__file__right"}>
                                        <DownloadIcon/>
                                    </div>
                                </div>
                            )}

                            {!!user?.days && (
                                <div className={"calendar__booking-popup__block"}>
                                    <div className={"calendar__booking-popup__label"}>
                                        <span>
                                            {user?.type === "vacation" && "Дней отпуска"}
                                            {user?.type === "medical" && "Дней работы в больничный"}
                                            {user?.type === "business" && "Общее количество дней"}
                                        </span>

                                        {`${user?.days} ${getNoun(
                                            user?.days,
                                            "день",
                                            "дня",
                                            "дней"
                                        )}`}
                                    </div>
                                </div>
                            )}

                            {!!user?.types && (
                                <div className={"calendar__booking-popup__block"}>
                                    <div className={"calendar__booking-popup__label"}>
                                    <span>Тип командировки</span>

                                        {typeBusiness[user?.types]}
                                    </div>
                                </div>
                            )}
                        </div>

                        {isAdmin && !isEdit && !isConciliate && !isShow && (
                            <div className={"calendar__booking-popup__block"}>
                                <MembersBlock booking={booking} setBooking={setBooking} />
                            </div>
                        )}
                    </div>

                    {(user?.may_agree && user?.isProgress) && (
                        <div className={"calendar__booking-popup__buttons"}>
                            <Button
                                className={`btn btn-primary calendar__booking-popup__button calendar__booking-popup__action 
                                       ${styles.action}
                                       ml-0
                                    `}
                                disabled={!isValid}
                                onClick={() => handleSave(true)}
                                text={"Согласовать"}
                            />

                            <Button
                                className={`btn btn-red calendar__booking-popup__button calendar__booking-popup__remove w-100 ml-0`}
                                disabled={false}
                                onClick={() => handleSave()}
                                text={"Отклонить"}
                            />
                        </div>
                    )}
                </div>
            </DialogContainer>
        </>
    );
};
