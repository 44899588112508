import { EmailIcon, PhoneIcon } from "../../img";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { $getStaffReplacementById } from "../../api/requests/services/substitute";
import { BlankImage } from "../ui/blank-image/BlankImage";
import { phoneNumberFieldMask } from "../../utils";

interface Props {
    user: any;
}

export const UserVacationCard = (
    {
        user
    }: Props
) => {
    const [replacer, setReplacer] = useState<any>(null);

    useEffect(() => {
        if (user?.id) {
            $getStaffReplacementById({ id: user.id }).then(res => {
                if (!res?.data?.replace) return;

                setReplacer(res.data.replace);
            })
        }
    }, [user]);

    return (
        <div className="user-vacation">
            <div className="user-vacation__dateWrapper">
                <h3>Отпуск</h3>

                {(user?.vacation?.start_date && user?.vacation?.end_date) ? (
                    <div className="user-vacation__dateContent">
                        <p>{moment(user?.vacation?.start_date).format("DD.MM.YYYY")}</p>
                        <p>{moment(user?.vacation?.end_date).format("DD.MM.YYYY")}</p>
                    </div>
                ) : (
                    <div className="user-vacation-replacer__content__contact">
                        <p>Отпуска нету</p>
                    </div>
                )}
            </div>

            {replacer && (
                <div className="user-vacation-replacer">
                    <p className="user-vacation-replacer__title">Замещающий</p>

                    <div className="user-vacation-replacer__content">
                        <div className="user-vacation-replacer__content__top">
                            <BlankImage
                                image={replacer?.photo || ''}
                                classname='blogs-card-user__image'
                            />

                            <h1>{replacer?.name}</h1>
                        </div>

                        <div className="user-vacation-replacer__content__desc">
                            {replacer?.phone && (
                                <div className="user-vacation-replacer__content__contact">
                                    <PhoneIcon />

                                    <p>{phoneNumberFieldMask.getMaskedValue(replacer.phone.toString())}</p>
                                </div>
                            )}

                            {replacer?.mail && (
                                <div className="user-vacation-replacer__content__contact">
                                    <EmailIcon />

                                    <p>{replacer.mail}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};