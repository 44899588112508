import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogContainer from "../../ui/dialog/DialogContainer";
import {CloseIcon, ReloadIcon} from "../../../img";
import { UploadIcon } from "../../../img/icons/files/UploadIcon";
import { FileUploadInput } from "../input/FileUploadInput";
import { useSelector } from "react-redux";
import { $addFileItem } from "../../../api/requests/files";
import Preloader from "../../ui/preloader/Preloader";
import {failureNotify} from "../../../utils";
import {$addObjectToMediaFile, $deleteObjectOfMediaFile} from "../../../api/requests/media-files";
import {FileIcon} from "../../../img/icons/menu/FileIcon";
import {SpinnerIcon} from "../../../img/SpinnerIcon";

interface IAddFileModalProps {
    isOpen: boolean;
    onClose: (toUpdate: boolean) => void;
    categoryId: number;
}

export const AddFileModal: FC<IAddFileModalProps> = (
    {
        categoryId,
        isOpen,
        onClose
    }
) => {
    const uploadFileRef = useRef<HTMLInputElement>(null);

    const [fileStatuses, setFileStatuses] = useState<any>([]);
    const [isFile, setFile] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    async function handleUploadFiles(newFiles: any) {
        const newFilesArr: any[] = Object.values(newFiles);

        if (newFilesArr.length > 10) {
            failureNotify('Выберите не более 10 файлов')
        }

        if (newFilesArr && newFilesArr.length > 0 && newFilesArr.length <= 10) {
            setIsLoading(true);

            setFile((prevFiles: any[]) => [...prevFiles, ...newFilesArr]);

            setFileStatuses((prevStatuses: any[]) => [
                ...prevStatuses,
                ...newFilesArr.map(() => ({ status: 'pending' })),
            ]);

            for (let i = 0; i < newFilesArr.length; i++) {
                const fileIndex = fileStatuses.length + i;

                setFileStatuses((prevStatuses: any[]) =>
                    prevStatuses.map((status: any, index: number) =>
                        index === fileIndex ? { status: 'uploading' } : status
                    )
                );

                try {
                    const response: any = await $addFileItem(
                        {
                            parent_id: categoryId,
                            item: newFilesArr[i],
                        },
                        { formData: true, newApi: true, disableLoader: true }
                    );

                    if (response.data) {
                        setFileStatuses((prevStatuses: any[]) =>
                            prevStatuses.map((status: any, index: number) =>
                                index === fileIndex ? { status: 'success', id: response?.data?.id } : status
                            )
                        );
                    } else {
                        setFileStatuses((prevStatuses: any[]) =>
                            prevStatuses.map((status: any, index: number) =>
                                index === fileIndex ? { status: 'error' } : status
                            )
                        );
                    }
                } catch (error) {
                }
            }

            setIsLoading(false);
        }
    }

    function handleDeleteFile (id: number | string, index: number) {
        $deleteObjectOfMediaFile(+id, {newApi: true}).then(res => {
            const updatedList = isFile?.filter((item: any, idx: number) => idx != index)
            const updatedStatusList = fileStatuses.filter((item: any) => item.id != id)

            setFile(updatedList)
            setFileStatuses(updatedStatusList)
        })
    }

    return (
        <DialogContainer classNameModal="dialog" isOpen={isOpen} isShowCloseBtn={false}
                         closeModal={() => {
                             if (!isLoading) {
                                 onClose(false);
                                 setFile([])
                             }
                         }}>
            <div className="dialog-mobileLine"></div>

            <div className="dialog-header">
                <h5 className="dialog-header__title">Загрузка файлов</h5>

                {!isLoading && (
                    <div onClick={() => onClose(false)} className="dialog-header__closeIcon">
                        <CloseIcon color={"#000"} />
                    </div>
                )}
            </div>

            <div className="files-add-dialog">
                <div className="files-add-dialog__upload"
                     onClick={() => {
                         if (!isLoading && uploadFileRef.current) {
                             uploadFileRef.current.click();
                         }
                     }}>

                    {isLoading ? (
                        <div className="files-add-dialog__loader">
                            <Preloader/>
                        </div>
                    ) : (
                        <>
                            <FileUploadInput
                                inputRef={uploadFileRef}
                                onSuccess={(e: any) => handleUploadFiles(e.target.files)}
                                accept="allDocsImages"
                                onError={(e: any) => {
                                }}
                                multiple
                            />
                            <UploadIcon size={45}/>
                            <div className="files-add-dialog__upload__text">Ограничения на загрузку
                                файла не
                                более 100 мб.
                            </div>
                        </>
                    )}
                </div>

                <div className="files-add-dialog__content">
                    Видеофайлы: AVI, MP4, MP3, WAV<br />
                    Архивные: ZIP, RAR<br />
                    Графические: PNG, JPG, JPEG, GIF, BMP, SVG, EPS, AI, PSD<br />
                    Текстовые и табличные: DOC, DOCX, PDF, XLS, XLSX, PPT, PPTX, TXT, CSV<br />
                </div>

                <div className='files-add-dialog__statuses'>
                    {isFile?.map((file: any, index: number) => {
                        const isSuccess = fileStatuses[index]?.status === 'success';
                        const isUploading = fileStatuses[index]?.status === 'uploading';
                        const isPending = fileStatuses[index]?.status === 'pending';
                        const isError = fileStatuses[index]?.status === 'error';

                        const classNames = `${isSuccess && 'success'}
                            ${isUploading && 'uploading'}
                            ${isPending && 'pending'}
                            ${isError && 'error'}
                            `

                        return (
                            <div key={index} className={`files-add-dialog__statuses__block ${classNames}`}
                            >
                                <div className='files-add-dialog__statuses__block__left'>
                                    <div className={`files-add-dialog__statuses__block__file ${classNames}`}>
                                        <FileIcon opacity={1}/>
                                    </div>

                                    <div className={`files-add-dialog__statuses__block__info ${classNames}`}>
                                        <h6>{isSuccess ? 'Файл загружен' : (isError ? 'Ошибка загрузки' : '')}</h6>

                                        <h5 className={`files-add-dialog__statuses__block__info__title`}>{file.name}</h5>
                                    </div>
                                </div>

                                {isSuccess && (
                                    <div className={'files-add-dialog__statuses__closeIcon'}
                                         onClick={() => handleDeleteFile(fileStatuses[index]?.id, index)}
                                    >
                                        <CloseIcon color={'#FF0000'}/>
                                    </div>
                                )}

                                {isError && (
                                    <div className={'files-add-dialog__statuses__closeIcon'}>
                                        <ReloadIcon/>
                                    </div>
                                )}

                                {isUploading && (
                                    <div className={'files-add-dialog__statuses__closeIcon'}>
                                        <SpinnerIcon/>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </DialogContainer>
    );
};