import {IApiHandlerParams} from "../../../interface";
import api from "../../../index";

export const $changeReplacementStatus = (
    isConfirm: boolean,
    data: { replace_id: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.post(isConfirm ? "api/v1/staffreplace/confirm" : "api/v1/staffreplace/decline", data,{
    // mock: require('./mocks/vacation.booking.json'),
    handler: {
        ...handler,
        newApi: true
    },
})