import {AcceptedIcon, ArrowExitIcon, RejectedIcon} from "../../../img";
import React, {useEffect, useState} from "react";
import {failureNotify, isMd} from "../../../utils";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import {
    ServiceEducationsEmployeesModal
} from "../../../components/services/services-education/modals/ServiceEducationsEmployeesModal";
import {ServicesSubstituteEmployeeModal} from "../../../components/services";
import {BlankAva} from "../../../components/ui/avatars/BlankAva";
import Button from "../../../components/ui/form/Buttons/Button";
import {$createServiceReplacer, $getServicesSubstituteReplacement} from "../../../api/requests/services/substitute";
import moment from "moment";
import {BlankImage} from "../../../components/ui/blank-image/BlankImage";
import {useSelector} from "react-redux";
import {getPersonData} from "../../../redux";
import {SpinnerIcon} from "../../../img/SpinnerIcon";

export const ServicesSubstitutePage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const profileData = useSelector(getPersonData);

    const [user, setUser] = useState<any>()

    const [data, setData] = useState<any>({})
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [isAlreadySend, setIsAlreadySend] = useState(false)

    const [statusesData, setStatusesData] = useState([
        {
            label: 'Отклонен',
            value: -1,
        },
        {
            label: 'Рассмотрение',
            value: 0,
        },
        {
            label: 'Успешно',
            value: 1,
        },
    ])

    const findStatus = statusesData.find(item => item.value == data?.status)

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: {errors, isValid},
        trigger,
    } = useForm<any>()

    const btnListRemove = [
        {
            text: 'Отлично',
            key: 1,
            className: 'btn btn-light w-100',
        },
    ]

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    useEffect(() => {
        $getServicesSubstituteReplacement({newApi: true, dontShowErrorMessage: true, showModalOnError: false}).then(res => {
                if (!res.data) return;

                setIsAlreadySend(true)
                setData(res.data)
                setUser(res?.data?.replace)

        })
    }, [isLoading])


    function onSubmit() {
        setIsLoading(true)

        if (moment(data?.date_to).diff(moment(data?.date_from), "days") > 10) {
            failureNotify("Вы можете выбрать замену, если дней меньше 10")
        }
        else {
            $createServiceReplacer({user_id: user?.id,}, {newApi: true}).then(res => {
            setIsLoading(false)
        })
        }

    }

    return (
        <>
            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate('/services')}>
                            <ArrowExitIcon/>
                        </div>

                        <h1>Замещающий сотрудник</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <div className="services-applications-create__block__left">
                        <h5 className="services-applications-create__title">Выбор замещающего</h5>

                        {(data.status || data.status == 0) && (
                            <div className={`services-applications-create__status ${data?.status == 0 ? 'waiting' : (data.status == 1 ? 'accepted' : 'rejected') }`}>

                                {findStatus?.label}
                            </div>
                        )}
                    </div>

                    <p className="services-applications-create__text">Москва, Мясницкая 35, 3-й этаж</p>

                    <div className='services-applications-create__info'>
                        <h5 className='services-applications-create__info__title'>Количество накопленных дней
                            отпуска</h5>
                        <h5 className='services-applications-create__info__days'>{moment(data?.date_to).diff(moment(data?.date_from), "days")} дней</h5>
                    </div>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit(() => onSubmit())}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <div className="services-applications-create__dates">
                                <Controller
                                    name={"start_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            disabled={true}
                                            value={profileData?.near_vacation_start}
                                            className=""
                                            label="Дата начала"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                                <Controller
                                    name={"end_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            disabled={true}
                                            value={profileData?.near_vacation_end}
                                            className=""
                                            label="Дата завершения"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            {data?.admin && (
                                <div className="services-applications-create__file">
                                    <div className="services-applications-create__file__header">
                                        <p className="services-applications-create__file__header__label">Согласующий</p>
                                    </div>


                                    <div className={"services-create-form__employees"}
                                         style={{padding: '0 10px', opacity: '.5', position: 'relative'}}
                                    >
                                        <div className="layout-vacation-modal__block">
                                            <div className="layout-vacation-modal__block__right">
                                                <BlankImage image={data?.admin?.photo}/>

                                                <div className="layout-vacation-modal__block__desc">
                                                    <h1>{data?.admin?.name}</h1>

                                                    <p className="layout-vacation-modal__block__desc__position">{data?.admin?.position || 'Не указан'}</p>
                                                </div>
                                            </div>

                                            <div className="services-applications-create__approvers__status">
                                                {data?.status == 0 && (
                                                    <SpinnerIcon />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="services-applications-create__file">
                                <div className="services-applications-create__file__header">
                                    <p className="services-applications-create__file__header__label">Замещающий</p>
                                </div>

                                {user && (
                                    <div className={"services-create-form__employees"}
                                         onClick={() => setIsModalOpen(true)}
                                         style={{padding: '0 10px', opacity: isAlreadySend ? 0.5 : 1}}
                                    >
                                        <div className="layout-vacation-modal__block">
                                            <div className="layout-vacation-modal__block__right">
                                                <BlankImage image={user?.photo}/>

                                                <div className="layout-vacation-modal__block__desc">
                                                    <h1>{user?.name}</h1>

                                                    <p className="layout-vacation-modal__block__desc__position">{user?.position}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!user?.id && (
                                    <div className="services-create-form__employees">
                                        <div className={"services-create-form__employees__bottom"}>
                                            <div className="services-create-form__employees__addButton"
                                                 onClick={() => setIsModalOpen(true)}>
                                                <PlusIcon2/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!isAlreadySend && (
                                <Button
                                    disabled={!user || (!profileData.near_vacation_start && !profileData.near_vacation_end)}
                                    type={'submit'}
                                    text={'Отправить'}
                                    className={'btn btn-primary w-100'}
                                />
                            )}
                        </form>
                    </div>
                </div>
            </div>

            <ServicesSubstituteEmployeeModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={user}
                setData={setUser}
            />

        </>
    )
}