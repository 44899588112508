import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPersonData, setPopupData, updateRouteTitle} from "../../../redux";
import {ArrowExitIcon, CommentIcon, LikeIcon, ShareIcon} from "../../../img";
import {getDateByFormat, isMd, successNotify} from "../../../utils";
import moment from "moment";
import {EyeIcon2} from "../../../img/icons/news/EyeIcon2";
import {
    $getEventById, $getEventItemMembers,
    $getEventLastList,
    $getEventList, $subscribeEvent,
    $unsubscribeEvent
} from "../../../api/requests/events";
import {EventCard} from "../../../components/events/card/EventCard";
import {BlankImage} from "../../../components/ui/blank-image/BlankImage";
import {LocationIcon} from "../../../img/icons/LocationIcon";
import {ClockIcon} from "../../../img/icons/ClockIcon";
import {SubscribeIcon} from "../../../img/icons/event/SubscribeIcon";
import {PollsQuestionsModal} from "../../../components/polls";
import EventsCalendarModal from "../../../components/calendar/EventsCalendarModal";
import EventSuccessModal from "../../../components/events/modal/EventSuccessModal";
import UserSelectListModal from "../../../components/ui/dialog/UserSelectListModal";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import {EventMembersModal} from "../../../components/events/modal/EventMembersModal";
import {BlankAva} from "../../../components/ui/avatars/BlankAva";

export const EventsShowPage = () => {
    const isMobile = isMd();
    const profileData = useSelector(getPersonData);

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const dispatch = useDispatch();

    const {id: eventId} = useParams();

    const pollId: any = searchParams.get("poll_id");

    const [isLoading, setIsLoading] = useState(false);

    const [eventsList, setEventsList] = useState([]);
    const [eventData, setEventData] = useState<any>([]);

    const [membersList, setMembersList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [showSuccess, setShowSuccess] = useState(false);

    const isEventEnded = moment(eventData?.end_date).isBefore(moment());

    useEffect(() => {
        $getEventLastList({count: 4}, {newApi: true}).then(res => {
            if (!res) return;

            setEventsList(res.data);
        });
    }, []);

    useEffect(() => {
        getEvent();
        getEventMembers()
    }, [eventId, isLoading]);

    useEffect(() => {
        dispatch(updateRouteTitle({key: "events_show", title: eventData?.title}));
    }, [eventData]);

    function getEvent() {
        if (!eventId) return;

        $getEventById(eventId, {newApi: true}).then(res => {
            if (!res.data) return;

            setEventData(res.data);
        });
    }

    function getEventMembers() {
        if (!eventId) return;

        $getEventItemMembers(eventId, {newApi: true}).then(res => {
            if (!res.data) return;

            setMembersList(res.data)
        })
    }

    function handleShare() {
        const url = window.location.href;
        navigator.clipboard.writeText(url);

        successNotify("Ссылка успешно скопирована").then();
    }

    function onUnsubscribe() {
        setIsLoading(true);

        if (eventId) {
            $unsubscribeEvent(+eventId, {newApi: true}).then(res => {
                setIsLoading(false);
            });
        }
    }

    function requestWriteLogs() {
        // if (!newsData?.id) return;
        //
        // $writeNewsLogs({
        //     news_id: newsData.id,
        //     type: 1,
        //     title: "Мне нравится"
        // }, { newApi: true }).then();
    }

    function changeCustomLike() {
        // if (!newsData?.id) return;
        //
        // $newsCustomLike({
        //     id: newsData?.id,
        //     like: isLikesCustomValidate ? 0 : 1
        // }, { formData: true }).then((res) => {
        //     getNews();
        // });
        //
        // requestWriteLogs();
        //
        // setIsLikesCustomValidate(!isLikesCustomValidate);
    }

    function handleSubscribe() {
        if (!eventData.is_answered_to_interview && eventData?.type === "with_interview") {
            navigate(`?poll_id=${eventData?.interview_id}&event_id=${eventData?.id}`);
        } else {
            setIsLoading(true);

            $subscribeEvent(eventId ? +eventId : 0, {newApi: true}).then(res => {
                setIsLoading(false);

                setTimeout(() => {
                    setShowSuccess(true);
                }, 500);
            });
        }
    }

    return (
        <div className="events-show">
            <EventSuccessModal
                isOpen={showSuccess}
                onClose={() => setShowSuccess(false)}
            />

            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate("/events")}>
                            <ArrowExitIcon/>
                        </div>
                        <h1>{eventData?.title}</h1>
                    </div>
                )}
            </div>

            <div className="container events-show-content">
                <div className="events-show-content__left">
                    <div className="events-show-info">
                        <div className="events-show-editor">
                            <div className="events-show-editor__image">
                                <BlankImage image={eventData?.cover}/>
                            </div>

                            <div className="events-show-editor__content">
                                <div className="events-show-info__category">
                                    {eventData?.category?.title ? eventData.category.title : "Без категории"}
                                </div>

                                <div className="events-show-info__content">
                                    {isMobile && (
                                        <div className="events-show-info__header">
                                            <h5 className="events-show-info__title">{eventData?.title}</h5>
                                        </div>
                                    )}

                                    <div className="mt-3"
                                         dangerouslySetInnerHTML={{ __html: eventData?.body }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="events-show-extra">
                        <h2 className="events-show-extra__title">
                        Дата проведения
                        </h2>
                        <div className="events-show-extra__desc">
                            {getDateByFormat(eventData?.start_date, "DD MMMM, HH:mm")}{eventData?.end_date && eventData?.start_date != eventData?.end_date ? ` - ${getDateByFormat(eventData?.end_date, "DD MMMM, HH:mm")}` : ""}
                        </div>
                    </div>

                    <div className="events-show-extra">
                        <h2 className="events-show-extra__title">
                            Место проведения
                        </h2>
                        <div className="events-show-extra__desc">
                            <LocationIcon/>
                            {eventData?.location}
                        </div>
                    </div>

                    <div className="events-show-extra">
                        <h2 className="events-show-extra__title">
                            Организатор
                        </h2>

                        <div className="events-show-author">
                            <BlankImage image={eventData?.author?.image}/>

                            <div className="events-show-author__content">
                                <div className="events-show-author__name">
                                    {eventData?.author?.name}
                                </div>
                                <div className="events-show-author__role">
                                    {eventData?.author?.job_title}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='events-show-members__content'>
                        <p className='events-show-members__label'>Участники</p>

                        <div className='events-show-members' onClick={() => setIsModalOpen(true)}>
                            <div className='events-show-members__left'>
                                {membersList?.slice(0, 10)?.map((member: any, idx) => (
                                    <div className={`events-show-members__block ${idx == 0 ? 'first' : ''}`} key={idx}>
                                        <BlankImage image={member?.photo}/>
                                    </div>
                                ))}
                            </div>

                            {membersList.length - 10 > 0 && (
                                <div className='events-show-members__count'>
                                    {membersList.length - 10 > 99 ? 99 : membersList.length - 10}
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="events-show-date">
                        <ClockIcon/>
                        <div className="events-show-date__text">
                            {moment(eventData?.updated_at).format("DD MMMM, YYYY HH:mm")}
                        </div>
                    </div>

                    <div className="events-show-info__bottom">
                        <div className={`news-show-info__buttons`}>
                            {eventData.is_participating ? (
                                <button
                                    style={{opacity: isEventEnded ? "0.5" : 1}}
                                    disabled={isEventEnded}
                                    className="news-show-info__buttons__participatingButton"
                                    onClick={onUnsubscribe}
                                >
                                    <p>Отменить запись</p>
                                </button>
                            ) : (
                                <>
                                    {eventData.type != "only_introductory" && (
                                        <button
                                            style={{opacity: isEventEnded ? "0.5" : 1}}
                                            disabled={isEventEnded}
                                            className={`news-show-info__buttons__primary`}
                                            onClick={() => handleSubscribe()}
                                        >
                                            <SubscribeIcon
                                                size={24}
                                                color="#FFFFFF"
                                            />
                                            <p>Записаться</p>
                                        </button>
                                    )}
                                </>

                            )}

                            <button
                                className="news-show-info__buttons__shareButton"
                                onClick={handleShare}
                            >
                                <ShareIcon size={24} color="#00A7B5"/> <p>Поделиться</p>
                            </button>

                        </div>

                        <div className="news-show-info__stats">
                            {/*<div className="news-show-info__stats__block">*/}
                            {/*    <EyeIcon2 color="#000" size={20} />*/}

                            {/*    <p>0 /!** newsData?.views **!/</p>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    {/*{newsData?.date && (*/}
                    {/*    <div className="news-show-info__date">*/}
                    {/*        <ClockIcon />*/}
                    {/*        <p>{moment(newsData?.date * 1000).format("DD MMMM, YYYY HH:mm")}</p>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    {/*{isMobile && (*/}

                    {/*)}*/}


                    {/*    {!isMobile && (*/}
                    {/*        <div className="news-show-info__stats">*/}
                    {/*            <div className="news-show-info__stats__block">*/}
                    {/*                <LikeIcon color="#000" size={20} />*/}

                    {/*                <p>{newsData?.likes_count}</p>*/}
                    {/*            </div>*/}

                    {/*            <div className="news-show-info__stats__block">*/}
                    {/*                <CommentIcon color="#000" size={20} />*/}

                    {/*                <p>{newsData?.comments_count}</p>*/}
                    {/*            </div>*/}

                    {/*            <div className="news-show-info__stats__block">*/}
                    {/*                <EyeIcon2 color="#000" size={20} />*/}

                    {/*                <p>{newsData?.views}</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>

                <div className="events-show-content__right">
                    {eventsList.slice(0, 4).map((
                        item,
                        idx
                    ) => (
                        <EventCard key={`news-show-right-item-${idx}`} data={item}/>
                    ))}
                </div>
            </div>

            <EventMembersModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                members={membersList}
            />

            {pollId && (
                <PollsQuestionsModal
                    isEvent={true}
                    isOpenModal={true}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    onClose={() => navigate(`/events/show/${eventData.id}`)}
                />
            )}
        </div>
    );
};