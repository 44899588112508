import React, { FC, useState } from "react";
import { IServicesApplications } from "../../../api/requests/services/interface";
import { useForm } from "react-hook-form";
import { ServicesImprovementModal } from "./modals";
import { ServicesAddingAgreerModal } from "./modals/ServicesAddingAgreerModal";
import { useNavigate } from "react-router-dom";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface IServicesApplicationsList {
    data?: IServicesApplications[],
    headerTable?: any,
    columns?: any,
    list?: any,
    isAdmin?: boolean,
    isHr?: boolean,
    urlRedirect?: string;
    setPage?: any,
    page?: number,
    lastPage?: number,
}

export const ServicesApplicationsList: FC<IServicesApplicationsList> = (
    {
        columns,
        list,
        setPage,
        page,
        lastPage
    }) => {
    const navigate = useNavigate();

    const [isOpenDialog, setIsOpenDialog] = useState(0);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<any>();

    function downloadMore() {
        if (page) {
            setPage(page + 1);
        }
    }

    return (
        <>
            <div className="container services-acceptance">
                <div className="services-acceptance__header">
                    {columns?.map((item: any, idx: number) => (
                        <div key={idx} className={`column `}
                             style={{ width: `${item.width}%` }}>{item.title}</div>
                    ))}

                </div>

                <div className=" services-acceptance__content">
                    {list?.map((row: any, index: number) => (
                        <div key={index} className="services-acceptance__block">
                            {columns.map((col: any, idx: number) => (
                                <div className={`services-acceptance__block__item ${col.class}`}
                                     style={{ width: `${col.width}%` }}
                                     key={idx}>{col.render ? col.render(row[col.key], row) : row[col.key]}</div>
                            ))}
                        </div>
                    ))}
                </div>

                {page !== lastPage && (
                    <div className="services-applications__download" onClick={() => downloadMore()}>
                        <p>
                            Показать еще
                        </p>
                    </div>
                )}
            </div>


            {isOpenDialog === 1 && (
                <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                          id={0}
                                          onClose={() => setIsOpenDialog(0)} />
            )}

            {isOpenDialog === 2 && (
                <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                           onClose={() => setIsOpenDialog(0)} />
            )}
        </>
    );
};