import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import TextField from "../../../components/ui/form/Fields/TextField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm/index";
import DatePickerTimeField from "../../../components/ui/form/DatePickerForm/DatePickerTimeField";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../../components/ui/form/Buttons/Button";

import { ArrowExitIcon } from "../../../img";
import { isMd } from "../../../utils";
import Dropdown, { DropdownItem } from "../../../components/ui/form/Dropdown/Dropdown";
import FileField from "../../../components/ui/form/FileField/FileField";
import { useSelector } from "react-redux";
import {
    getCurrentRole,
    getPersonData,
} from "../../../redux";
import { EventItemData } from "../../../api/requests/events/interface";
import {
    $createEvent,
    $deleteEvent,
    $editEvent,
    $getEventCategories,
    $getEventListById
} from "../../../api/requests/events";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import { $uploadImage } from "../../../api/requests/upload";
import EventsEditPreview from "../preview/EventEditPreview";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { EventSelectAuthorModal } from "../../../components/events/modal/EventSelectAuthorModal";
import moment from "moment";
import {LocationIcon} from "../../../img/icons/LocationIcon";
import {$getPollsList} from "../../../api/requests/polls";
import CompleteModal, { BtnDataItem } from "../../../components/ui/dialog/CompleteModal";

export interface CreateEventFormData {
    category?: any,
    category_id: number;
    start_date: Date | undefined;
    end_date: Date | undefined;
    is_active: boolean;
    cover: any;
    cover_for_banner: any;
    title: string;
    location?: string;
    description: string;
    body: string;
    status?: number;
    type?: string;
    interview_id?: number | string,
}

const EventEditPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const profileData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const pathname = location.pathname;
    const { id } = useParams();

    const isEdit: boolean = pathname.includes("edit");

    const [editData, setEditData] = useState<CreateEventFormData | null>(null);

    const [isShow, setIsShow] = useState(true);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);
    const [pollsList, setPollsList] = useState<DropdownItem[]>([])

    const [hasBanner, setHasBanner] = useState(true);
    const [author, setAuthor] = useState<any | null>(null);
    const [showAuthorModal, setShowAuthorModal] = useState(false);
    const [isOpenDeleteBanner, setIsOpenDeleteBanner] = useState(false);

    const testBtnCreate = isEdit ? "Сохранить" : "Опубликовать";

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-red w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm<CreateEventFormData>(
        (isEdit && editData) ? {
            values: {
                category_id: editData?.category?.id,
                start_date: editData?.start_date,
                end_date: editData?.end_date,
                cover: editData?.cover,
                cover_for_banner: editData?.cover_for_banner,
                title: editData?.title,
                location: editData?.location,
                description: editData?.description,
                body: editData?.body,
                status: 1,
                is_active: false,
                type: editData?.type,
                interview_id: editData?.interview_id,
            }
        } : {
            values: {
                category_id: 0,
                start_date: undefined,
                end_date: undefined,
                cover: "",
                cover_for_banner: "",
                title: "",
                location: "",
                description: "",
                body: "",
                status: 1,
                is_active: false,
                type: "without_interview"
            }
        }
    );

    const handleNavigation = () => {
        navigate(-1);
    };

    const onChangeFormItem = (item: EventItemData): CreateEventFormData => {

        return {
            ...item,
            cover: item.cover,
            cover_for_banner: item.cover_for_banner,
            start_date: new Date(item.start_date),
            end_date: new Date(item.end_date),
            category_id: item.category_id,
            title: item.title,
            description: item.description,
            body: item.body,
            location: item.location,
            type: item.type,
            interview_id: item.interview_id,
        };
    };

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (author == null) {
            setAuthor({
                id: profileData.id,
                name: profileData.name,
                image: profileData.photo
            });
        }
    }, [profileData]);

    useEffect(() => {
        if (isEdit && id) {
            $getEventListById(id, { newApi: true }).then(res => {
                if (res.code) return;

                setHasBanner(res.data.cover_for_banner != null);
                setEditData(onChangeFormItem(res.data));

                setAuthor({
                    id: res.data.author.id,
                    name: res.data.author.name,
                    image: res.data.author.image
                });
            });
        }
    }, []);

    function init() {
        getCategory();
    }

    function getCategory() {
        $getEventCategories(isAdmin,{ newApi: true }).then(res => {
            if (!res || !res.data.data.length) return;

            setCategoryList([...res.data.data].map(i => ({
                ...i,
                label: i.title,
                value: i.id
            })));
        });

        $getPollsList().then(res => {
            if (!res) return;

            const filteredList = res.filter((item: any) => item.status == 1 && item.is_adaptation == '0')

            setPollsList([...filteredList].map(i => ({
                ...i,
                label: i.title,
                value: i.id
            })));
        });
    }

    function handleAuthor(data: any) {
        setShowAuthorModal(false);

        setAuthor(data);
    }

    function onSubmit(data: CreateEventFormData, isDraft?: boolean) {
        const requestData: any = {
            ...editData,
            ...data,
            interview_id: data.type == "only_introductory" ? 0 : data.interview_id || 0,
            is_active: isDraft ? 0 : 1,
            start_date: moment(data.start_date).format('yyyy-MM-DD HH:mm:ss'),
            end_date: moment(data.end_date).format('yyyy-MM-DD HH:mm:ss'),
            author_id: author.id
        };

        if (!hasBanner)
            delete requestData.cover_for_banner;

        if (isDraft && !data.title) return;

        if (isEdit) return handleEditData(requestData);

        if (data.cover && data.cover instanceof File) {
            $uploadImage({
                file_content: data.cover,
                target_type: "events"
            }).then(cover => {
                if (hasBanner && data.cover_for_banner && data.cover_for_banner instanceof File) {
                    $uploadImage({
                        file_content: data.cover_for_banner,
                        target_type: "events"
                    }).then(bannerCover => {
                        sendCreateRequest({
                            ...requestData,
                            cover: cover?.file?.url,
                            cover_for_banner: bannerCover?.file?.url
                        });
                    });

                    return;
                }

                sendCreateRequest({
                    ...requestData,
                    cover: cover?.file?.url
                });
            });
        } else {
            if (hasBanner && data.cover_for_banner && data.cover_for_banner instanceof File) {
                $uploadImage({
                    file_content: data.cover_for_banner,
                    target_type: "events"
                }).then(bannerCover => {
                    sendCreateRequest({
                        ...requestData,
                        cover_for_banner: bannerCover?.file?.url
                    });
                });

                return;
            }

            sendCreateRequest(requestData);
        }
    }

    function sendCreateRequest(requestData: CreateEventFormData) {
        $createEvent(requestData, { newApi: true, formData: false })
        .then(res => {
            if (res.code) return;

            reset();
            navigate("/events");
        });
    }

    function handleEditData(requestData: any) {
        if (requestData.cover && requestData.cover instanceof File) {
            $uploadImage({
                file_content: requestData.cover,
                target_type: "events"
            }).then(cover => {
                if (hasBanner && requestData.cover_for_banner && requestData.cover_for_banner instanceof File) {
                    $uploadImage({
                        file_content: requestData.cover_for_banner,
                        target_type: "events"
                    }).then(bannerCover => {
                        sendEditRequest({
                            ...requestData,
                            cover: cover?.file?.url,
                            cover_for_banner: bannerCover?.file?.url
                        });
                    });

                    return;
                }

                sendEditRequest({
                    ...requestData,
                    cover: cover?.file?.url
                });
            });
        } else {
            if (hasBanner && requestData.cover_for_banner && requestData.cover_for_banner instanceof File) {
                $uploadImage({
                    file_content: requestData.cover_for_banner,
                    target_type: "events"
                }).then(bannerCover => {
                    sendEditRequest({
                        ...requestData,
                        cover_for_banner: bannerCover?.file?.url
                    });
                });

                return;
            }

            sendEditRequest(requestData);
        }
    }

    function sendEditRequest(requestData: any) {
        $editEvent(id, requestData, { newApi: true }).then(res => {
            if (res.code) return;

            reset();
            navigate(`/events/show/${id}`);
        });
    }

    function handleDelete(select: BtnDataItem) {
        if (select.key === 0) {
            if (!id) return;

            $deleteEvent(id, { newApi: true, formData: true }).then(res => {
                if (res.code) return;

                reset();
                navigate("/events");
            });
        }

        setIsOpenDeleteBanner(false);
    }

    return (
        <div className="contests__create">
            <EventSelectAuthorModal
                isOpen={showAuthorModal}
                onClose={() => setShowAuthorModal(false)}
                onSelect={handleAuthor}
            />

            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{editData ? "Редактирование" : "Создать мероприятие"}</h1>

                        {(editData?.is_active == false) && <h5>Черновик</h5>}
                    </div>
                )}
            </div>

            <div className="contests__create__block">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                onSubmit={handleSubmit((obj) => onSubmit(obj))}
                                className="contests__create__form"
                                onKeyDown={(e) => checkKeyDown(e)}
                            >

                                <div className="d-flex" style={{ gap: 60 }}>
                                    <div className="d-flex flex-column gap-2">
                                        <label className={"form-label mb-0"}>
                                            Изображение
                                        </label>

                                        <div style={{ width: 157, height: 200 }}>
                                            <Controller
                                                name="cover"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Прикрепите картинку"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <FileField
                                                        className={"form-image"}
                                                        upload={value}
                                                        label=""
                                                        setUpload={onChange}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex align-items-center">
                                            <label className={"form-label mb-0"}>
                                                Отображать в баннере
                                            </label>

                                            <div style={{ width: "auto" }}>
                                                <ToggleBlock
                                                    isChecked={hasBanner}
                                                    className="contests__create__form-toggle"
                                                    desc=""
                                                    handleChange={(value) => setHasBanner(value)}
                                                />
                                            </div>
                                        </div>

                                        {hasBanner && (
                                            <div style={{ width: 317, height: 172 }}>
                                                <Controller
                                                    name="cover_for_banner"
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: false,
                                                            message: "Прикрепите картинку"
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <FileField
                                                            className={"form-image"}
                                                            upload={value}
                                                            label=""
                                                            setUpload={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Заполните заголовок"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Заголовок"
                                            placeholder={"Введите заголовок"}
                                            name="title"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextArea
                                            value={value}
                                            className=""
                                            title="Короткое описание"
                                            maxLength={200}
                                            placeholder={"Введите короткое описание"}
                                            name="title"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="body"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextArea
                                            value={value}
                                            className=""
                                            title="Описание"
                                            placeholder={"Введите описание"}
                                            name="title"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <div className="sport__create__form-date">
                                    <div className={"sport__create__form-date__items"}>
                                        <div className="sport__create__form-date__item">
                                            <Controller
                                                name={"start_date"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Выберете дату"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <DatePickerForm
                                                        startDate={value}
                                                        minDate={new Date()}
                                                        className=""
                                                        label="Дата начала"
                                                        placeholder={"Выберите дату"}
                                                        onChange={onChange}
                                                        isIcon
                                                        isRightIcon
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name={"start_date"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Выберете время"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <DatePickerTimeField
                                                        startDate={value as Date}
                                                        // timeIntervals={1}
                                                        label="Время начала"
                                                        placeholder={"Выберите время"}
                                                        onChange={onChange}
                                                        isRightIcon
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="sport__create__form-date">
                                    <div className={"sport__create__form-date__items"}>
                                        <div className="sport__create__form-date__item">
                                            <Controller
                                                name={"end_date"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Выберете дату"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <DatePickerForm
                                                        startDate={value}
                                                        minDate={watch().start_date}
                                                        className=""
                                                        label="Дата окончания"
                                                        placeholder={"Выберите дату"}
                                                        onChange={onChange}
                                                        isIcon
                                                        isRightIcon
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name={"end_date"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Выберете время"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <DatePickerTimeField
                                                        startDate={value as Date}
                                                        // timeIntervals={1}
                                                        label="Время окончания"
                                                        placeholder={"Выберите время"}
                                                        onChange={onChange}
                                                        isRightIcon
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Controller
                                    name="category_id"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете категорию"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория не выбрана"}
                                            label={"Категория"}
                                            options={categoryList}
                                            value={getValue(categoryList, value)}
                                            onChange={(select) => onChange(select.value)}
                                        />
                                    )}
                                />

                                <Controller
                                    name="location"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Введите место проведения"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value ?? ""}
                                            className=""
                                            title="Место проведения"
                                            placeholder={"Введите место проведения"}
                                            name="location"
                                            onChange={onChange}
                                            errors={errors}
                                            prepend={<LocationIcon/>}
                                        />
                                    )}
                                />

                                <div className="events-show-extra" style={{ cursor: "pointer" }}
                                     onClick={() => setShowAuthorModal(true)}>
                                    <h2 className="events-show-extra__title">
                                        Организатор
                                    </h2>

                                    <div className="events-show-author">
                                        <BlankImage image={author?.image} />

                                        <div className="events-show-author__content">
                                            <div className="events-show-author__name">
                                                {author?.name}
                                            </div>
                                            <div className="events-show-author__role">
                                                {author?.job?.name}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value == "with_interview"}
                                            className="contests__create__form-toggle"
                                            desc="Опрос"
                                            handleChange={(value) => onChange(value ? "with_interview" : "without_interview")}
                                        />
                                    )}
                                />

                                {watch().type === 'with_interview' && (
                                    <Controller
                                        name="interview_id"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете опрос"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Dropdown
                                                className={"form-select"}
                                                placeholder={"Опрос не выбран"}
                                                label={""}
                                                options={pollsList}
                                                value={getValue(pollsList, value)}
                                                onChange={(select) => onChange(select.value)}
                                            />
                                        )}
                                    />
                                )}

                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value == "only_introductory"}
                                            className="contests__create__form-toggle"
                                            desc="Для ознакомления"
                                            handleChange={(value) => onChange(value ? "only_introductory" : "without_interview")}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3"}>
                                    {isEdit && (
                                        <Button
                                            text={"Удалить"}
                                            className={"btn btn-red w-100"}
                                            onClick={() => setIsOpenDeleteBanner(true)}
                                        />
                                    )}

                                    <Button
                                        text={"В черновик"}
                                        className={"btn btn-light w-100"}
                                        onClick={() => onSubmit(watch(), true)}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={testBtnCreate}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="contests__create__block-right">
                            <div className={"contests__create__preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="contests__create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={setIsShow}
                                />

                                {isShow && (
                                    <EventsEditPreview
                                        data={watch()}
                                        author={author}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CompleteModal
                isOpen={isOpenDeleteBanner}
                setIsOpen={() => {
                    setIsOpenDeleteBanner(false);
                }}
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить мероприятие?"}
                btnList={btnListRemove}
                onClick={handleDelete}
            />
        </div>
    );
};

export default EventEditPage;