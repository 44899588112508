import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogContainer from "../../ui/dialog/DialogContainer";
import { CloseIcon, SearchIcon } from "../../../img";
import { withDebounce } from "../../../functions";
import TextField from "../../ui/form/Fields/TextField";
import { FileCategoryData, FileCategoryDetailsData } from "../../../api/requests/files/interface";
import { $getFileCategoryById, $moveFileItem } from "../../../api/requests/files";
import { updateRouteTitle } from "../../../redux";
import { FolderIcon } from "../../../img/icons/files/FolderIcon";
import { BackIcon } from "../../../img/icons/form/BackIcon";
import Preloader from "../../ui/preloader/Preloader";

interface IReorderFilesModalProps {
    isOpen: boolean;
    onClose: (toUpdate: boolean) => void;
    currentFileId: number | null,
    currentFolderId: string | undefined
}

export const MoveFileModal: FC<IReorderFilesModalProps> = (
    {
        isOpen,
        onClose,
        currentFileId,
        currentFolderId
    }
) => {
    const navigate = useNavigate();

    const [baseCategoryId, setBaseCategoryId] = useState<string>("");
    const [baseCategory, setBaseCategory] = useState<FileCategoryDetailsData>();

    const [selectedCategory, setSelectedCategory] = useState<FileCategoryData | null>(null);

    const [searchValue, setSearchValue] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    useEffect(() => {
        getBaseCategory();
    }, [baseCategoryId]);

    function getBaseCategory() {
        setIsLoading(true);

        $getFileCategoryById(baseCategoryId, { newApi: true }).then((data) => {
            let category: FileCategoryDetailsData = data.data;

            setBaseCategory(category);
            setIsLoading(false);
        });
    }

    function openCategory(item: FileCategoryData) {
        setBaseCategoryId(`${item.id}`);
        setSelectedCategory(null);
    }

    function backToRecentCategory() {
        setBaseCategoryId(`${baseCategory?.parent.id}`);
        setSelectedCategory(null);
    }

    function move() {
        if (selectedCategory == null)
            return;

        console.log(currentFolderId, currentFileId)

        $moveFileItem({
            "current_folder_id": currentFolderId,
            "file_item_id": currentFileId,
            "new_folder_id": selectedCategory.id
        }, { newApi: true }).then(() => {
            onClose(true);
        });
    }

    return (
        <DialogContainer classNameModal="dialog" isOpen={isOpen} isShowCloseBtn={false}
                         closeModal={() => {
                             onClose(false);
                         }}>
            <div className="dialog-mobileLine"></div>

            <div className="dialog-header">
                <h5 className="dialog-header__title">Перемещение файла</h5>

                <div onClick={() => onClose(false)} className="dialog-header__closeIcon">
                    <CloseIcon color={"#000"} />
                </div>
            </div>

            <div className="files-move-dialog">
                <TextField
                    value={searchValue}
                    onChange={(value: string) => withDebounce(() => setSearchValue(value))}
                    placeholder={"Поиск"}
                    prepend={<SearchIcon color={"black"} />}
                    className={`search__block-textField`}
                />

                {
                    baseCategoryId != "1" && (
                        <div className="files-move-dialog__back" onClick={backToRecentCategory}>
                            <BackIcon color={"#000"} />
                            <p className="files-move-dialog__back__text">Назад</p>
                        </div>
                    )
                }

                {isLoading && <>
                    <div className="files-move-dialog__loader">
                        <Preloader />
                    </div>
                </>}

                <div className="files-move-dialog__content">
                    {!isLoading && baseCategory?.children && baseCategory?.children.map(item => (
                        <div
                            className={`files-move-dialog__item ${selectedCategory == item && "files-move-dialog__item__active"}`}
                            onClick={() => setSelectedCategory(item)}
                            onDoubleClick={() => openCategory(item)}>
                            <FolderIcon />
                            <p className="files-move-dialog__item__text">{item.name}</p>
                        </div>
                    ))}
                </div>

                <div
                    className={`files-move-dialog__button ${selectedCategory != null && "files-move-dialog__button__active"}`}
                    onClick={move}>
                    Переместить
                </div>
            </div>
        </DialogContainer>
    );
};