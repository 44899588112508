export const LikedIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.7244 17.0467H3.41406C3.13792 17.0467 2.91406 16.8228 2.91406 16.5467V8.38C2.91406 8.10386 3.13792 7.88 3.41406 7.88H5.72041C6.30585 7.88 6.84836 7.57283 7.14956 7.07083L9.40823 3.30634C9.89565 2.49407 11.0436 2.41685 11.6353 3.15655C11.9142 3.50521 12.0041 3.96863 11.8758 4.3963L11.0238 7.23633C10.9276 7.55713 11.1678 7.88 11.5027 7.88H15.3153C16.4142 7.88 17.2124 8.925 16.9232 9.98517L15.3323 15.8185C15.1346 16.5437 14.476 17.0467 13.7244 17.0467Z"
                fill="#00A7B5" />
            <path d="M5.83594 17.0467V7.88" stroke="white" strokeWidth="1.66667"
                  stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};