import React, { useEffect, useMemo, useState } from "react";
import { ArrowExitIcon, CloseIcon, SearchIcon } from "../../../img";
import ContestCreateEditPreview
    from "../../../containers/contests/ContestsCreateEdit/ContestCreateEditPreview";
import Button from "../../ui/form/Buttons/Button";
import DialogContainer from "../../ui/dialog/DialogContainer";
import ContestsViewWinnerWin from "../../../containers/contests/ContestsView/ContestsViewWinnerWin";
import ContestsViewWinnerExclude
    from "../../../containers/contests/ContestsView/ContestsViewWinnerExclude";
import TextField from "../../ui/form/Fields/TextField";
import { withDebounce } from "../../../functions";
import { IUsers } from "../../../api/requests/user/interface";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { BlankAva } from "../../ui/avatars/BlankAva";
import { $getWorkspaceUsers } from "../../../api/requests/workspace-settings";

interface ContestsViewCompletedProps {
    isEdit?: boolean,
    isOpen: boolean;
    onClose: () => void;
    data?: any;
    setData: any;
}

export const ServicesEmployeeModal = (
    {
        isEdit,
        data,
        isOpen,
        onClose,
        setData
    }: ContestsViewCompletedProps) => {
    const { workspace_id: wsId } = useSelector(getPersonData);

    const [usersList, setUsersList] = useState<IUsers[]>([]);

    const [selectedUser, setSelectedUser] = useState<any>(data);

    const [searchValue, setSearchValue] = useState<string>("");

    const handleAddData = () => {
        setData(selectedUser);
        onClose();
    };

    useEffect(() => {
        if (isEdit && data) {
            setSelectedUser(data);
            setUsersList([data]);
        }
    }, [data]);

    useEffect(() => {
        if (wsId) {
            $getWorkspaceUsers({ id: wsId, search: searchValue }, { formData: true }).then(res => {
                if (!res.users) return;

                if (selectedUser?.id || data) {
                    const updatedList: any = res.users.filter(item => item?.id != selectedUser?.id);

                    if (selectedUser) {
                        setUsersList([selectedUser, ...updatedList]);
                    }
                    else {
                        setUsersList([...updatedList]);
                    }

                } else {
                    setUsersList(res.users);
                }
            });
        }

    }, [selectedUser, searchValue]);

    function onChangeCheckbox(item: IUsers) {
        if (selectedUser?.id === item.id) {
            setSelectedUser(undefined);
        } else {
            setSelectedUser(item);
        }
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);
    }

    function closeModal() {
        setSelectedUser(data);
        setSearchValue("");
        // setUsersList([]);
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={closeModal}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"contests__winner"}>
                <div className="services-create-modal__header" style={{ marginBottom: 20 }}>
                    <h5 className="services-create-modal__header__title">Пользователи</h5>

                    <div className="services-create-modal__header__close" onClick={onClose}>
                        <CloseIcon color="#000" />
                    </div>
                </div>

                <div className={"contests__winner__search"}>
                    <TextField
                        value={searchValue}
                        onChange={(value: string) => {
                            setSearchValue(value);
                            withDebounce(() => onChangeSearch(value));
                        }}
                        placeholder={"Поиск"}
                        prepend={<SearchIcon color={"black"} />}
                        className={`search__block-textField`}
                    />
                </div>

                <div className={"contests__winner__list"}>
                    {usersList.map((item, idx) => (
                        <div key={idx} className="layout-vacation-modal__block">
                            <input
                                type="checkbox"
                                checked={item?.id == selectedUser?.id || false}
                                onChange={() => onChangeCheckbox(item)}
                            />

                            <div className="layout-vacation-modal__block__right">
                                {item?.photo ? (
                                    <img src={item?.photo} alt="" />
                                ) : (
                                    <BlankAva
                                        colorBg={"#CCD9E0"}
                                        colorText={"black"}
                                        text={item?.name}
                                    />
                                )}

                                <div className="layout-vacation-modal__block__desc">
                                    <h1>{item?.name}</h1>

                                    <p className="layout-vacation-modal__block__desc__position">HR
                                        менеджер</p>

                                    <p className="layout-vacation-modal__block__desc__department">Департамент
                                        испытания и сертификации</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={"contests__winner__btns"}>
                    <Button
                        text={"Выбрать пользователя"}
                        className={"btn btn-primary w-100"}
                        onClick={() => handleAddData()}
                        disabled={!selectedUser}
                    />
                </div>
            </div>
        </DialogContainer>
    );
};