import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMainPage, changePersonData, getMainPageData, getPersonData } from "../../../redux";
import {
    getCurrentGroupData,
    getIsOpenCreateBanner,
    setIsOpenCreateBanner
} from "../../../redux/reducers/workplace/workplaceSlice";
import {
    GetGroupsBannerResponseData
} from "../../../api/requests/workspace-settings/banners/interface";
import {
    $editMainBanner,
} from "../../../api/requests/workspace-settings/banners";
import { $mainPageData, $workSpaceEdit } from "../../../api/requests/main";
import CompleteModal, { BtnDataItem } from "../../../components/ui/dialog/CompleteModal";
import { $editPoll } from "../../../api/requests/polls";
import { HamburgerIcon, SettingIcon } from "../../../img";
import { BannerCard, ContestCard } from "../../../components/layout-components";
import Button from "../../../components/ui/form/Buttons/Button";
import { TrashIcon } from "../../../img/icons/TrashIcon";
import WorkspaceSettingGroupModalCreateEditBanner
    from "./WorkspaceSettingGroupModalCreateEditBanner";
import {
    $createGroupsBanner,
    $deleteGroupsBannerById,
    $editGroupsBanner,
    $getGroupsBannerList
} from "../../../api/requests/groups/banners";
import { $editGroup } from "../../../api/requests/groups";
import { $getPersonalData } from "../../../api/requests/user";

const WorkspaceSettingGroupBanners = () => {
    const dispatch = useDispatch();

    const personalData = useSelector(getPersonData);
    const currentGroup = useSelector(getCurrentGroupData);
    const isOpenCreateBanner = useSelector(getIsOpenCreateBanner);
    const mainData = useSelector(getMainPageData);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-red w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const [tabList, setTabList] = useState([
        {
            key: "main",
            text: "Главные баннеры",
        },
        {
            key: "inner",
            text: "Доп баннеры",
        },
    ]);
    const [selectKey, setSelectKey] = useState("main");

    const [state, setState] = useState<GetGroupsBannerResponseData[]>([]);
    const [stateInner, setStateInner] = useState<any[]>([]);

    const [isOpenDeleteBanner, setIsOpenDeleteBanner] = useState(false);
    const [isOpenCreateEditBanner, setIsOpenCreateEditBanner] = useState(false);
    const [selectBanner, setSelectBanner] = useState<GetGroupsBannerResponseData | null>(null);

    const [selectInterview, setSelectInterview] = useState<any>(null);
    const [selectDeleteInterview, setSelectDeleteInterview] = useState<any>(null);

    const getLayoutBanners = (banners: any[]) => {
        const sortList = typeof personalData?.components_sort_home === "string"
            ? (typeof JSON.parse(personalData.components_sort_home) === "string"
                ? JSON.parse(JSON.parse(personalData.components_sort_home))
                : JSON.parse(personalData.components_sort_home))
            : (Array.isArray(personalData?.components_sort_home)
                ? personalData?.components_sort_home
                : []);

        const filterBannersList = [...banners];

        if (!sortList[0]?.left?.length) {
            const sortListUpdate = !sortList[0]?.left ? [{ left: [], center: [], right: [] }] : sortList

            sortListUpdate[0]?.left.push({
                key: 'survey',
                id: null,
            })

            const request = { components_sort_home: JSON.stringify(sortListUpdate), id: currentGroup.id }

            $editGroup(
                request,
                { formData: true }
            ).then(res => {
                if (!res || res.error) return;

                updateMainPage();
            })
        }

        return sortList[0]?.left?.map((item: any, idx: number) => {
            if (item.key === "survey") return {
                ...item,
                ...mainData?.interview
            };

            const findBanner = filterBannersList?.find(i => i?.id === item?.id);

            if (findBanner) {
                const index = filterBannersList.findIndex(banner => banner.id === findBanner.id);
                if (index !== -1) filterBannersList.splice(index, 1);

                return {
                    ...item,
                    ...findBanner
                };
            }

            const fallbackBanner = filterBannersList.shift();

            if (fallbackBanner) {
                return {
                    ...item,
                    ...fallbackBanner
                };
            }

            return item;
        });
    };

    const getIsShowBtnDelete = (select: any): boolean => {
        return select.key === "survey" ? (!!select.id) : true
    }

    useEffect(() => {
        if (currentGroup?.id && mainData) {
            init();
        }
    }, [currentGroup, mainData]);
    useEffect(() => {
        setIsOpenCreateEditBanner(isOpenCreateBanner);
    }, [isOpenCreateBanner]);

    function init() {
        $getGroupsBannerList().then(res => {
            if (!res) return;

            const defaultList = res.filter(item => item?.type && +item?.type === 0);
            const innerList = res.filter(item => item?.type && +item?.type > 0);

            setState(defaultList);
            setStateInner(getLayoutBanners(innerList));
        });
    }

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === "modules") {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...state];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleEdit(targetIndex, workValue[sourceIndex], workValue);
        }

        if (type === "inner") {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...stateInner];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleEditInner(targetIndex, workValue[sourceIndex], workValue);
        }
    }

    function handleEditInner(targetIndex: number, currentItem: any, workValue: any[]) {
        const requestData: any = {
            id: currentItem.id,
            sort: targetIndex
        };

        setStateInner(workValue);

        currentItem.key === "banner" && $editMainBanner(requestData, { formData: true }).then(res => {});

        const leftList = [...workValue].map(item => ({
            key: item?.key,
            id: item?.id,
            sort: item?.sort,
            type: item?.type
        }));

        const list = [
            { left: leftList },
            { center: mainData.components_sort_home[0].center },
            { right: mainData.components_sort_home[0].right }
        ];

        const request = { components_sort_home: JSON.stringify(list), id: currentGroup.id }

        $editGroup(
            request,
            { formData: true }
        ).then(res => {
            if (!res || res.error) return;

            updateMainPage();
        })
    }

    function handleEdit(targetIndex: number, currentItem: any, workValue: any[]) {
        const requestData: any = {
            id: currentItem.id,
            sort: targetIndex
        };

        $editGroupsBanner(requestData, { formData: true }).then(res => {
            setState(workValue);
        });
    }

    function handleEditBanner(select: any) {
        if (select.key === "survey") {
            setSelectInterview(select);

            return;
        }

        setSelectBanner(select);
        setIsOpenCreateEditBanner(true);
    }

    function handleDeleteBanner(select: any) {
        if (select.key === "survey") {
            setSelectDeleteInterview(select);

            return;
        }

        setSelectBanner(select);
        setIsOpenDeleteBanner(true);
    }

    function handleClickBtn(select: BtnDataItem) {
        if (select.key === 0 && selectBanner) {
            $deleteGroupsBannerById(
                { id: selectBanner?.id?.toString() },
                { formData: true }
            ).then(res => {
                setIsOpenDeleteBanner(false);
                setSelectBanner(null);

                const sortList = typeof personalData?.components_sort_home === "string"
                    ? (typeof JSON.parse(personalData.components_sort_home) === "string"
                        ? JSON.parse(JSON.parse(personalData.components_sort_home))
                        : JSON.parse(personalData.components_sort_home))
                    : (Array.isArray(personalData?.components_sort_home)
                        ? personalData?.components_sort_home
                        : []);

                const leftList = [...sortList[0]?.left].filter(item => +item?.id !== +selectBanner?.id);

                const list = [
                    { left: leftList },
                    { center: sortList[0]?.center || [] },
                    { right: sortList[0]?.right || [] }
                ];

                const request = { components_sort_home: JSON.stringify(list), id: currentGroup.id }

                $editGroup(
                    request,
                    { formData: true }
                ).then(res => {
                    if (!res || res.error) return;

                    updateMainPage();
                })
            });

            return;
        }

        setIsOpenDeleteBanner(false);
        setSelectBanner(null);
    }

    function handleClickInterviewBtn(select: BtnDataItem) {
        if (select.key === 0 && selectDeleteInterview) {
            const requestData = {
                main_page: 0,
                id: selectDeleteInterview.id
            }

            $editPoll(requestData, { formData: true }).then((res: any) => {
                if (!res || !res?.id) return;

                setSelectDeleteInterview(null);

                const sortList = typeof personalData?.components_sort_home === "string"
                    ? (typeof JSON.parse(personalData.components_sort_home) === "string"
                        ? JSON.parse(JSON.parse(personalData.components_sort_home))
                        : JSON.parse(personalData.components_sort_home))
                    : (Array.isArray(personalData?.components_sort_home)
                        ? personalData?.components_sort_home
                        : []);

                const leftList = [...sortList[0]?.left].map(item => {
                    if (selectDeleteInterview.id === item.id) return {
                        key: 'survey',
                        id: null,
                    }

                    return item
                });

                const list = [
                    { left: leftList },
                    { center: sortList[0]?.center || [] },
                    { right: sortList[0]?.right || [] }
                ];

                const request = { components_sort_home: JSON.stringify(list), id: currentGroup.id }

                $editGroup(
                    request,
                    { formData: true }
                ).then(res => {
                    if (!res || res.error) return;

                    updateMainPage();

                    $mainPageData().then((response) => {
                        if (!response || response.error) return;

                        dispatch(changeMainPage(response));
                    });
                })
            });

            return;
        }

        setSelectDeleteInterview(null);
    }

    function updateMainPage() {
        $getPersonalData().then((response) => {
            if (!response || response.error) return;

            dispatch(changePersonData(response?.data));
        });
    }

    return (
        <>
            {/*<Button*/}
            {/*    className="btn btn-primary"*/}
            {/*    text={'Сбросить'}*/}
            {/*    onClick={() => {*/}
            {/*        const leftList = [*/}
            {/*            {*/}
            {/*                key: 'survey',*/}
            {/*                id: null,*/}
            {/*            },*/}
            {/*            // {*/}
            {/*            //     key: 'banner',*/}
            {/*            //     id: null,*/}
            {/*            // },*/}
            {/*            // {*/}
            {/*            //     key: 'banner',*/}
            {/*            //     id: null,*/}
            {/*            // },*/}
            {/*        ];*/}

            {/*        const list = [*/}
            {/*            { left: leftList },*/}
            {/*            { center: mainData?.components_sort_home[0]?.center || [] },*/}
            {/*            { right: mainData?.components_sort_home[0]?.right || [] }*/}
            {/*        ];*/}

            {/*        const request = { components_sort_home: JSON.stringify(list), id: currentGroup.id }*/}

            {/*        $editGroup(*/}
            {/*            request,*/}
            {/*            { formData: true }*/}
            {/*        ).then(res => {*/}
            {/*            if (!res || res.error) return;*/}

            {/*            updateMainPage();*/}
            {/*        })*/}
            {/*    }}*/}
            {/*/>*/}

            <div className={"workspace-settings__banners__title vacation__header__title"}>
                {tabList.map((item, idx) => {
                    if (item.key === selectKey) return (
                        <h2 key={`title-item-${idx}`}>{item.text}</h2>
                    )

                    return (
                        <p
                            key={`title-item-${idx}`}
                            onClick={() => setSelectKey(item.key)}
                        >
                            {item.text}
                        </p>
                    )
                })}
            </div>

            {selectKey === "inner" ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list" type="inner">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={"workspace-settings-group__banners"}
                            >
                                {stateInner?.length ? stateInner?.map((item, index) => (
                                    <React.Fragment key={`banners-item-${index}`}>
                                        <Draggable
                                            draggableId={`banners-item-${index}`}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={"drag-and-drop__block workspace-settings__banners__inner workspace-settings-group__banners__left"}
                                                >
                                                    <div className={"left"}>
                                                        <HamburgerIcon color={"#00000080"} />

                                                        <p>{index + 1}</p>
                                                    </div>

                                                    {(item?.key === "survey") ? (
                                                        <ContestCard data={item} isNavigate={false}/>
                                                    ) : (
                                                        <BannerCard
                                                            data={item}
                                                            className={"workspace-settings__banners__inner__content"}
                                                        />
                                                    )}

                                                    <div className="right">
                                                        <Button
                                                            className={"btn-icon"}
                                                            onClick={() => handleEditBanner(item)}
                                                            text={(
                                                                <SettingIcon
                                                                    width={24}
                                                                    height={24}
                                                                    color={"#00A7B5"}
                                                                />
                                                            )}
                                                        />

                                                        {getIsShowBtnDelete(item) ? (
                                                            <Button
                                                                className={"btn-icon"}
                                                                onClick={() => handleDeleteBanner(item)}
                                                                text={<TrashIcon />}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    </React.Fragment>
                                )) : (
                                    <div className={"empty__list"}>
                                        <h4>Нет баннеров</h4>

                                        <p>На данный момент вы еще не добавили ни одного баннера</p>
                                    </div>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list" type="modules">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={"workspace-settings-group__banners"}
                            >
                                {state.length ? state.map((item, index) => (
                                    <React.Fragment key={`banners-item-${index}`}>
                                        <Draggable
                                            draggableId={`banners-item-${index}`}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={"drag-and-drop__block workspace-settings-group__banners__left"}
                                                >
                                                    <div className={"left"}>
                                                        <HamburgerIcon color={"#00000080"} />

                                                        <p>{index + 1}</p>
                                                    </div>

                                                    <div className={"slider-banners w-100"}>
                                                        <div className={"w-100"}>
                                                            <img
                                                                src={item.image}
                                                                className="removeBackFace"
                                                                style={{ position: "absolute" }}
                                                            />
                                                        </div>

                                                        <div className="slider-banners__content">
                                                            {/*<h3>*/}
                                                            {/*    {item.src}*/}
                                                            {/*</h3>*/}

                                                            {/*<p>*/}
                                                            {/*    {item.text}*/}
                                                            {/*</p>*/}
                                                        </div>

                                                        <div className="slider-banners__setting right">
                                                            <Button
                                                                className={"btn-icon"}
                                                                onClick={() => handleEditBanner(item)}
                                                                text={(
                                                                    <SettingIcon
                                                                        width={24}
                                                                        height={24}
                                                                    />
                                                                )}
                                                            />

                                                            <Button
                                                                className={"btn-icon"}
                                                                onClick={() => handleDeleteBanner(item)}
                                                                text={<TrashIcon color={"white"} />}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    </React.Fragment>
                                )) : (
                                    <div className={"empty__list"}>
                                        <h4>Нет баннеров</h4>

                                        <p>На данный момент вы еще не добавили ни одного баннера</p>
                                    </div>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <WorkspaceSettingGroupModalCreateEditBanner
                isOpen={isOpenCreateEditBanner}
                onClose={() => {
                    setIsOpenCreateEditBanner(false);
                    dispatch(setIsOpenCreateBanner(false));
                    init();
                }}
                editData={selectBanner}
                setEditData={setSelectBanner}
                createRequest={$createGroupsBanner}
                editRequest={$editGroupsBanner}
                selectKey={selectKey}
                workSpaceEdit={$editGroup}
                idParams={currentGroup?.id}
                typeNew={1}
            />

            <CompleteModal
                isOpen={isOpenDeleteBanner}
                setIsOpen={() => {
                    setIsOpenDeleteBanner(false);
                    setSelectBanner(null);
                }}
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить баннер?"}
                btnList={btnListRemove}
                onClick={handleClickBtn}
            />

            <CompleteModal
                isOpen={!!selectDeleteInterview}
                setIsOpen={() => {
                    setSelectDeleteInterview(null);
                }}
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить опрос?"}
                btnList={btnListRemove}
                onClick={handleClickInterviewBtn}
            />
        </>
    );
};

export default WorkspaceSettingGroupBanners;