import { useEffect, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { CloseIcon } from "../../../img";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { BlankAva } from "../../../components/ui/avatars/BlankAva";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface UserProfileGratitudeProps {
    selectData: any;
    isOpen: boolean;
    setIsOpen: () => void;
}

const UserProfileGratitudeSingleModal = (
    {
        selectData,
        isOpen,
        setIsOpen
    }: UserProfileGratitudeProps
) => {
    const navigate = useNavigate();

    const [selectBadge, setSelectBadge] = useState<any>(null);
    const [userList, setUserList] = useState<any[]>([]);

    useEffect(() => {
        isOpen && init();
    }, [isOpen]);

    function init() {
        setSelectBadge(selectData);
        setUserList(selectData?.usersList || [])
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"profile-gratitude"}>
                <div className={"profile-gratitude__header"}>
                    <h3>{selectBadge?.workspace_badge?.title}</h3>

                    <div
                        className={"d-flex align-items-center cursor-pointer"}
                        onClick={() => setIsOpen()}
                    >
                        <CloseIcon color={"black"} opacity={"1"} />
                    </div>
                </div>

                <div className={"profile-gratitude__content profile-gratitude__content__users"}>
                    <div className={"profile-gratitude__content__text"}>
                        <div className={"img"}>
                            <div>
                                <BlankImage
                                    classname="notification-card__image"
                                    image={selectBadge?.workspace_badge?.image ?? ""}
                                />
                            </div>
                        </div>

                        <p>{selectBadge?.workspace_badge?.description}</p>
                    </div>

                    <div className="profile-gratitude__content__users__block">
                        <p>Отправители: {userList.length} человек</p>

                        <div className={"profile-gratitude__content__users__items"}>
                            {userList.map((item, idx) => (
                                <div
                                    className="dialog-block cursor-pointer"
                                    key={`badge-dialog-item-${idx}`}
                                >
                                    <div
                                        className="dialog-block-left"
                                        onClick={() => {
                                            item?.sender?.uniq && navigate(`/user/${item.sender.uniq}`)
                                        }}
                                    >
                                        {item?.sender?.photo ? (
                                            <img src={item?.sender?.photo} alt=""
                                                 className="employees-block__image" />
                                        ) : (
                                            <BlankAva
                                                colorBg={"#CCD9E0"}
                                                colorText={"black"}
                                                text={item?.sender?.name || "От организации"}
                                            />
                                        )}

                                        <div className="dialog-block-left__user">
                                            <h5>{item?.sender?.name || "От организации"}</h5>

                                            <p>Дата: {(item?.date && +item?.date) ? moment(item?.date * 1000).format("DD.MM.yyyy") : "Отсутствует"}</p>
                                            <p>Причина: {item?.causes || "Отсутвует"}</p>
                                            <p>Комментарий: {item?.comment || "Отсутвует"}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </DialogContainer>
    );
};

export default UserProfileGratitudeSingleModal;