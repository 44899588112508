import React, { useMemo, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import { CloseIcon } from "../../../img";
import {
    CompetitionsApplicationCard
} from "../../../components/competitions/CompetitionsApplicationCard";
import FileClipField from "../../../components/ui/form/FileField/FileClipField";
import ContestsViewWinnerWin from "../ContestsView/ContestsViewWinnerWin";
import ContestsViewWinnerExclude from "../ContestsView/ContestsViewWinnerExclude";
import { getPersonData } from "../../../redux";
import { useSelector } from "react-redux";

interface ContestsViewApplicationProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
    contestData?: any;
    contestId?: any;
    isAdmin?: any;
}

const ContestsViewApplication = (
    {
        data,
        contestData,
        isAdmin = false,
        contestId,
        isOpen,
        onClose,
    }: ContestsViewApplicationProps
) => {
    function handleCloseModal() {
        onClose();
    }

    function handleOpenWinner() {
        setStatusSelectItem("winner");
    }

    function handleOpenExclude() {
        setStatusSelectItem("exclude");
    }

    function handleDeleteComment() {
        setStatusSelectItem("exclude");

    }
    const profileData = useSelector(getPersonData);

    const [statusSelectItem, setStatusSelectItem] = useState("start");

    let isWinner = data?.winner;

    const now = Date.now() / 1000;
    let statusFix = contestData?.status == '0' || parseInt(contestData?.date) > now ? '0' : parseInt(contestData?.date_end) > now ? '1' : '2';

    function handleDownload(item: any) {
        window.open(item.attachment, "_blank")
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"contests__view"}>
                {statusSelectItem === "start" && <>
                    <div className={"contests__view__close"} onClick={handleCloseModal}>
                        <CloseIcon color={"black"} />
                    </div>

                    <CompetitionsApplicationCard
                        data={data}
                    />

                    <div className="contests__view__application__content">
                        <div className={"form-file"}>
                            <p>Прикрепленные файлы</p>
                        </div>

                        {!!data?.attachments?.length ? data?.attachments.map((item: any, idx: number) => (
                            <div onClick={() => handleDownload(item)}>
                                <FileClipField
                                    key={`file-field-item-${idx}`}
                                    label={""}
                                    upload={item}
                                    uploadName={item.file_name}
                                    className={"form-file"}
                                    isSingleDoc
                                    isLockFile
                                />
                            </div>
                        )) : <p className={"mb-3 pb-1"}>Нет файлов</p>}
                    </div>

                    {(isAdmin && statusFix != "2") ? (
                        <div className={"contests__view__application__btn"}>
                            {isWinner == 1 && (<Button
                                text={"Победитель!"}
                                className={"btn btn-primary w-100"}
                                disabled={true}
                            />)}

                            {(isWinner == 0 || isWinner == 2) && (<Button
                                text={"Принять"}
                                className={"btn btn-primary w-100"}
                                onClick={handleOpenWinner}
                            />)}

                            {isWinner != 2 && (<Button
                                text={"Отклонить"}
                                className={"btn btn-red w-100"}
                                onClick={handleOpenExclude}
                            />)}
                        </div>
                    ) : null}

                    {statusFix != "2" && ((isAdmin && isWinner == 2) || (isWinner == 0 && profileData.id == data.user_id)) && (
                        <div className={"contests__view__application__btn"}>
                            <Button
                                text={"Удалить"}
                                className={"btn btn-red w-100"}
                                onClick={handleDeleteComment}
                            />
                        </div>
                    )}
                </>}

                {statusSelectItem === "winner" && (
                    <ContestsViewWinnerWin
                        onBack={() => setStatusSelectItem("start")}
                        data={data}
                        contestData={contestData}
                        onSuccess={() => onClose()}
                    />
                )}

                {statusSelectItem === "exclude" && (
                    <ContestsViewWinnerExclude
                        data={data}
                        onBack={() => setStatusSelectItem("start")}
                        onSuccess={onClose}
                        contestData={contestData}
                        isAdmin={isAdmin}
                        onClose={onClose}
                    />
                )}

            </div>
        </DialogContainer>
    );
};

export default ContestsViewApplication;