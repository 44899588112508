import React, { FC, useEffect, useRef, useState } from "react";
import {
    BadgesCard,
    ProfileCard,
    StatsCard,
    VacationCard,
    CongratulatedCard,
    FavoritesCard, StatusCard, MobileVacationCard
} from "../../components/layout-components";
import {
    DepartmentBlock,
    ServicesBlock,
    PersonalWorkBlock,
    PersonalFieldsBlock,
    SportBlock
} from "../../components/profile";
import { ArrowExitIcon, ArrowRightIcon, HeartIcon, PlusIcon2, ThreePeopleIcon } from "../../img";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { useNavigate } from "react-router-dom";
import { getWeekDay, isMd } from "../../utils";
import { useSelector } from "react-redux";
import {
    changeMainPage,
    changeToken,
    getCurrentRole,
    getMonth,
    getPersonData,
    resetProfile
} from "../../redux";
import { BookIcon } from "../../img/icons/BookIcon";
import { $getOwnSportList } from "../../api/requests/sport";
import moment from "moment";
import {
    SportDateData,
    SportItemData,
    SportResponseDateData,
    SportViewItemData
} from "../../api/requests/sport/interface";
import { UsersMultiIcon } from "../../img/icons/workspace/UsersMultiIcon";
import Cookies from "js-cookie";
import Button from "../../components/ui/form/Buttons/Button";
import TrainingSessionViewModalCancel from "../sport/view/TrainingSessionViewModalCancel";

export const ProfilePage: FC = () => {
    const isMobile = isMd();
    const navigate = useNavigate();
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const currentDay = moment().format("yyyy-MM-DD");

    const personalData = useSelector(getPersonData);

    const isLeader = personalData.is_leader;
    const [leaderItem, setLeaderItem] = useState<any>();
    const [isAdaptation, setIsAdaptation] = useState<any>();

    const [departmentItems, setDepartmentItems] = useState<any[]>([]);
    const [cancelData, setCancelData] = useState<any>(null);

    const [sportsData, setSportsData] = useState<SportViewItemData[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const changeDepartmentData = (item: any) => {
        return {
            ...item,
            image: item?.user?.photo,
            name: item?.user?.name,
            position: item?.title,
            icon: "",
            department: ""
        };
    };

    const transformDateData = (data: SportResponseDateData[]): SportDateData[] => {
        return data?.map((item) => {
            const dateObj = new Date(item.date_from_at);
            const weekDay = getWeekDay(item.date_from_at);
            const formattedDate = `${String(dateObj.getDate()).padStart(2, "0")}.${String(dateObj.getMonth() + 1).padStart(2, "0")}`; // Format date as dd.mm
            const timeStart = new Date(item.date_from_at).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
            });
            const timeEnd = new Date(item.date_to_at).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
            });
            const time = `${timeStart} - ${timeEnd}`;

            return {
                ...item,
                week: weekDay,
                // date: formattedDate,
                startTime: timeStart,
                endTime: timeEnd,
                time: time
            };
        });
    };

    const transformContentData = (list: SportItemData[]): SportViewItemData[] => {
        return [...list].map(item => {
            return {
                ...item,
                schedules: transformDateData(item.schedules),
                seats: item.seats - (item?.unique_users?.length || 0)
            };
        });
    };

    useEffect(() => {
        if (personalData) {
            const team = personalData?.structure?.team;
            if (personalData?.adaptationPoll) {
                setIsAdaptation(personalData?.adaptationPoll);
            }
            team?.leader && setLeaderItem(changeDepartmentData(team.leader));
            team?.staff && setDepartmentItems(team.staff.map((item: any) => changeDepartmentData(item)));
        }

        $getOwnSportList(
            { newApi: true }
        ).then((res: any) => {
            if (!res.data) return;

            setSportsData(transformContentData(res?.data));
        });
    }, [personalData, isLoading]);


    const handleNavigation = () => {
        navigate(-1);
    };

    return (
        <div className="profile">
            {!isMobile && (
                <div
                    className={`${!isMobile ? "container" : ""} layout-inner__header profile-header`}>
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Моя страница</h1>
                    </div>
                </div>
            )}

            <MainLayoutContent
                contentGap={40}
                leftComponents={
                    <>
                        <ProfileCard />
                        <StatsCard />

                        <StatusCard
                            userData={personalData}
                        />

                        {isAdaptation && !isMobile ?
                            <div onClick={() => navigate("/polls?id=" + isAdaptation?.id)}
                                 style={{ cursor: "pointer" }}>
                                <div className="layout-adaptation">
                                    <div className="layout-adaptation__left">
                                        <BookIcon />
                                        <h3>Адаптация</h3>
                                    </div>
                                </div>
                            </div>
                            : null}

                        <BadgesCard />
                        <CongratulatedCard />

                        {isMobile && (
                            <>
                                {isAdaptation ?
                                    <div onClick={() => navigate("/polls?id=" + isAdaptation?.id)}
                                         style={{ cursor: "pointer" }}>
                                        <div className="layout-adaptation">
                                            <div className="layout-adaptation__left">
                                                <BookIcon />
                                                <h3>Адаптация</h3>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </>
                        )}

                        <div onClick={() => navigate("/favorites")}>
                            <FavoritesCard />
                        </div>

                        {isLeader && (
                            <div onClick={() => navigate("colleagues")}>
                                <div className="layout-collegues">
                                    <div className="layout-collegues__left">
                                        <UsersMultiIcon color={"white"} size={24} />

                                        <h1>Мои сотрудники</h1>
                                    </div>
                                </div>
                            </div>
                        )}

                        <VacationCard/>
                    </>
                }
            >
                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Моя команда</h1>

                        {!!departmentItems.length && (
                            <div
                                className="layout-right-block__header__link"
                                onClick={() => navigate("my-team")}
                            >
                                <h4>Показать все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        )}
                    </div>

                    <div className="profile-departments__content">
                        {leaderItem && <DepartmentBlock {...leaderItem} />}

                        {!!departmentItems?.length ? departmentItems.slice(0, 4).map((
                            item,
                            idx
                        ) => (
                            <DepartmentBlock key={idx} {...item} />
                        )) : <div>Команда отсутствует</div>
                        }
                    </div>
                </div>

                <PersonalFieldsBlock />

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Моя работа</h1>
                    </div>

                    <PersonalWorkBlock personalData={personalData} />
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title ">Мои сервисы</h1>

                        <div className="layout-right-block__header__right">
                            {/*{isAdmin && !isMobile && (*/}
                            {/*    <button onClick={() => navigate('/profile/colleagues')} className='layout-right-block__header__addButton profile-services-addButton'>*/}
                            {/*        <ThreePeopleIcon />*/}

                            {/*        <p>Мои сотрудники</p>*/}

                            {/*        <span>*/}
                            {/*            3*/}
                            {/*        </span>*/}
                            {/*    </button>*/}
                            {/*)}*/}

                            <button
                                className="layout-right-block__header__addButton profile-services-addButton">
                                <PlusIcon2 />

                                <p onClick={() => navigate("/services")}>Подать новую</p>
                            </button>
                        </div>
                    </div>

                    <ServicesBlock />
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title "> Спорт </h1>

                        <button className="layout-right-block__header__addButton"
                                onClick={() => navigate("/sport")}>
                            <PlusIcon2 />

                            <p>Записаться</p>
                        </button>
                    </div>

                    <div className="profile-sport">
                        {sportsData.map((item, idx) => (
                            <div
                                key={`profile-sport-item-${idx}`}
                            >
                                {item.schedules.length ? item.schedules.map((_item, _idx) => (
                                    <div
                                        key={`profile-sport-schedules-item-${idx}`}
                                        onClick={() => navigate(`/sport/view/${item.id}`)}
                                        style={{ cursor: "pointer" }}
                                        className={"profile-sport__block"}
                                    >
                                        <SportBlock
                                            key={idx}
                                            startTime={_item.startTime}
                                            endTime={_item.endTime}
                                            {...item}
                                        />

                                        <Button
                                            className="btn btn-red mb-0 mt-1 w-100"
                                            text={"Отменить"}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setCancelData(item);
                                            }}
                                        />
                                    </div>
                                )) : null}
                            </div>
                        ))}
                    </div>
                </div>
            </MainLayoutContent>

            <TrainingSessionViewModalCancel
                isOpen={!!cancelData}
                setIsLoading={setIsLoading}
                onClose={(value) => {
                    setCancelData(null);
                }}
                setIsOpen={setCancelData}
                data={cancelData}
            />
        </div>

    );
};