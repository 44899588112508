import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";

import { ArrowExitIcon, CloseIcon, LikeIcon, PlusIcon2 } from "../../../img";
import { isMd } from "../../../utils";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import FileField from "../../../components/ui/form/FileField/FileField";
import { useDispatch, useSelector } from "react-redux";
import { withDebounce } from "../../../functions";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import {
    $createMediaFile,
    $deleteMediaFile,
    $deleteObjectOfMediaFile,
    $editMediaFile,
    $getAdminMediaFileItem,
    $getMediaFileCategories,
    $getMediaFileItem
} from "../../../api/requests/media-files";
import { MediaFileData, MediaFileItemData } from "../../../api/requests/media-files/interface";
import { $uploadImage } from "../../../api/requests/upload";
import CompleteModal, { BtnDataItem } from "../../../components/ui/dialog/CompleteModal";
import { $deleteIdea } from "../../../api/requests/ideas";
import AddIcon from "../../../img/icons/vacation/AddIcon";
import { AddMediaFileModal } from "../../../components/media-files/modal/AddMediaFileModal";
import { MediaFileItem } from "../../../components/media-files/file";

interface DropdownItem {
    label: string;
    value: number | string;
}

export interface CreateMediaFilesFormData {
    banner: File | string | null;
    title: string;
    description: string;
    category_id: string | number;
    status?: number;
}

const MediaFilesEditPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = isMd();

    const pathname = location.pathname;
    const { id } = useParams();

    const isEdit: boolean = pathname.includes("edit");

    const [editData, setEditData] = useState<CreateMediaFilesFormData | null>(null);
    const [files, setFiles] = useState<MediaFileItemData[]>([]);

    const [toAddFile, setToAddFile] = useState<boolean>(false);

    const [isMainLoading, setIsMainLoading] = useState(false)

    const [deleteFileModal, setDeleteFileModal] = useState<MediaFileItemData | null>(null);
    const deleteFileConfiguration = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-danger w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];


    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);

    const testBtnCreate = isEdit ? "Сохранить" : "Опубликовать";

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors, isValid },
        trigger
    } = useForm<CreateMediaFilesFormData>(
        (isEdit && editData) ? {
            values: {
                banner: editData.banner,
                title: editData.title,
                description: editData.description,
                category_id: editData?.category_id
            }
        } : {
            values: {
                banner: "",
                title: "",
                description: "",
                category_id: 0
            }
        }
    );

    const handleNavigation = () => {
        navigate(-1);
    };

    const onChangeFormItem = (item: MediaFileData): CreateMediaFilesFormData => {
        return {
            ...item,
            banner: item.banner,
            title: item.title,
            description: item.description,
            category_id: item.category?.id || ""
        };
    };

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        init();
    }, []);


    useEffect(() => {
        if (isEdit && id) {
            $getAdminMediaFileItem(id, {  newApi: true }).then(res => {
                if (res.code) return;

                setFiles(res.data.files);
                setEditData(onChangeFormItem(res.data));
                // dispatch(setRedactorNewsListData(res.text));
            });
        }
    }, [id]);

    function init() {
        getCategory();
    }

    function getCategory() {
        $getMediaFileCategories({ formData: true, newApi: true }).then(res => {
            if (!res || !res.data.length) return;

            setCategoryList([...res.data].map(i => ({
                ...i,
                label: i.title,
                value: i.id
            })));
        });
    }

    function onSubmit(data: CreateMediaFilesFormData, isDraft?: boolean) {
        const requestData: any = {
            ...editData,
            ...data,
            files,
            title: data.title,
            description: data.description,
            status: isDraft ? 0 : 1
        };

        isDraft && trigger(["title", "category_id"]);

        if (isDraft && !data.title && !data.category_id) return;

        if (isEdit) return handleEditData(requestData);

        $uploadImage({
            file_content: requestData.banner,
            target_type: "media_galleries"
        }).then(res => {
            $createMediaFile({
                ...requestData,
                banner: res?.file?.url,
                is_favourite: 0
            }, { formData: true, newApi: true }).then(res => {
                if (res.code) return;

                reset();
                navigate("/media-files");
            });
        });
    }

    function handleEditData(requestData: any) {
        if (requestData?.banner != editData?.banner) {
            $uploadImage({
                file_content: requestData.banner,
                target_type: "media_galleries"
            }).then(res => {
                $editMediaFile(id, {
                    ...requestData,
                    banner: res?.file?.url
                }, { newApi: true }).then(res => {
                    if (res.code) return;

                    reset();
                    navigate(`/media-files/show/${id}`);
                });
            });

            return;
        }

        $editMediaFile(id, requestData, { newApi: true }).then(res => {
            if (res.code) return;

            reset();
            navigate(`/media-files/show/${id}`);
        });
    }

    function handleDelete() {
        if (!id) return;

        $deleteMediaFile(id, { formData: true, newApi: true }).then(res => {
            if (res.code) return;

            reset();
            navigate("/media-files");
        });
    }

    function handleDeleteFile(select: BtnDataItem) {
        if (select.key == 1) {
            setDeleteFileModal(null);
        } else {
            $deleteObjectOfMediaFile(deleteFileModal!.id, {
                formData: true,
                newApi: true
            }).then(res => {
                setDeleteFileModal(null);
            });
        }
    }



    function onFileAdded(toUpdate: boolean) {
        setToAddFile(false);

        if (toUpdate) {
            init();
        }
    }

    function handleDeleteObjectFile (id: number | string,) {
        $deleteObjectOfMediaFile(+id, {newApi: true}).then(res => {
            const filesFilter = [...files].filter(i => i.id != id);

            setFiles(filesFilter);
        })
    }


    return (
        <div className="contests__create">
            <CompleteModal
                icon={"🗑"}
                title={"Вы уверены, что хотите удалить файл?"}
                isOpen={deleteFileModal != null}
                setIsOpen={() => setDeleteFileModal(null)}
                btnList={deleteFileConfiguration}
                onClick={handleDeleteFile}
            />

            <AddMediaFileModal
                isOpen={toAddFile}
                onClose={onFileAdded}
                galleryId={id}
                files={files}
            />

            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{editData ? "Редактировать медиафайл" : "Создать медиафайл"}</h1>

                        {(editData?.status === 0) && <h5>Черновик</h5>}
                    </div>
                )}
            </div>

            <div className="contests__create__block">
                <div className="container">
                    <div className="contests__create__row row align-items-start">
                        <div className="contests__create__block-left">
                            <form
                                onSubmit={handleSubmit((obj) => onSubmit(obj))}
                                className="contests__create__form"
                                onKeyDown={(e) => checkKeyDown(e)}
                            >
                                <Controller
                                    name="banner"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Прикрепите картинку"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileField
                                            className={"form-image"}
                                            upload={value}
                                            label="Изображение"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Заполните заголовок"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Название медиафайла"
                                            placeholder={"Введите название"}
                                            name="title"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Напишите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextArea
                                            value={value ?? ""}
                                            title="Описание"
                                            name="description"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="category_id"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете категорию"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория не выбрана"}
                                            label={"Категория"}
                                            options={categoryList}
                                            value={getValue(categoryList, value)}
                                            onChange={(select) => onChange(select.value)}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3 mt-5"}>
                                    {isEdit && (
                                        <Button
                                            text={"Удалить"}
                                            className={"btn btn-red w-100"}
                                            onClick={handleDelete}
                                        />
                                    )}

                                    <Button
                                        text={"В черновик"}
                                        className={"btn btn-light w-100"}
                                        onClick={() => onSubmit(watch(), true)}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={testBtnCreate}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        {isEdit && (
                            <div className="media-files-create__right">
                                <div className="media-files-create__header"
                                     onClick={() => setToAddFile(true)}>
                                    <h2 className="media-files-create__title">Файлы</h2>
                                    <AddIcon size={24} color={"#00A7B5"} />
                                </div>

                                <div className="media-files-create__files">
                                    {files && files.map((item: any) => (
                                        <MediaFileItem
                                            type="create"
                                            data={item}
                                            key={item.id}
                                            onDelete={(event) => {
                                                event.stopPropagation();

                                                handleDeleteObjectFile(item.id)
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediaFilesEditPage;