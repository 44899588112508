import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../components/ui/form/Buttons/Button";
import React, { useEffect, useState } from "react";
import { CloseIcon } from "../../../img";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import { $editPoll, $getPollsList } from "../../../api/requests/polls";
import { $mainPageData, $workSpaceEdit } from "../../../api/requests/main";
import {
    changeMainPage,
    changePersonData,
    getMainPageData,
    getPersonData
} from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { $getPersonalData } from "../../../api/requests/user";

export interface CreateEditBannerFormData {
    id?: string;
}

interface WorkspaceSettingModalEditInterviewProps {
    isOpen: boolean;
    onClose: () => void;
    editData?: CreateEditBannerFormData | null;
    setEditData?: (value: any) => void,
}

const WorkspaceSettingModalEditInterview = (
    {
        isOpen,
        onClose,
        editData,
        setEditData,
    }: WorkspaceSettingModalEditInterviewProps
) => {
    const dispatch = useDispatch();

    const mainData = useSelector(getMainPageData);
    const personalData = useSelector(getPersonData);

    const [isLoading, setIsLoading] = useState(false);
    const [isStatusComplete, setIsStatusComplete] = useState(false);
    const [interviewList, setInterviewList] = useState([]);

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm<CreateEditBannerFormData>(
        {
            values: {
                id: editData?.id || "",
            }
        }
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    useEffect(() => {
        const requestData = {
            limit: 300,
            only_active: 1
        }

        $getPollsList(requestData, { formData: true }).then(res => {
            if (!res || res.error) return;

            const filteredList = res
            .map((item: any) => ({
                ...item,
                value: item.id,
                label: item.title
            }))
            .filter((item: any) => item.end_date > moment().unix())

            setInterviewList(filteredList)
        })
    }, []);

    function onSubmit(data: CreateEditBannerFormData) {
        if (!data?.id) return;

        setIsLoading(true);

        const requestData = {
            main_page: 1,
            ...data
        }

        $editPoll(requestData, { formData: true }).then((res: any) => {
            setIsLoading(false);
            if (!res || !res?.id) return;

            const sortList = typeof personalData?.components_sort_home === "string"
                ? (typeof JSON.parse(personalData.components_sort_home) === "string"
                    ? JSON.parse(JSON.parse(personalData.components_sort_home))
                    : JSON.parse(personalData.components_sort_home))
                : (Array.isArray(personalData?.components_sort_home)
                    ? personalData?.components_sort_home
                    : []);

            const leftList = [...sortList[0]?.left].map(item => {
                if (item.key === "survey") return {
                    key: "survey",
                    id: data.id,
                }

                return item
            });

            const list = [
                { left: leftList },
                { center: sortList[0]?.center || [] },
                { right: sortList[0]?.right || [] }
            ];

            const request = { components_sort_home: JSON.stringify(list), id: personalData.workspace_id }

            $workSpaceEdit(
                request,
                { formData: true }
            ).then(res => {
                if (!res || res.error) return;

                $mainPageData().then((response) => {
                    if (!response || response.error) return;

                    dispatch(changeMainPage(response));
                });

                $getPersonalData().then((response) => {
                    if (!response || response.error) return;

                    dispatch(changePersonData(response?.data));
                });
            })

            setIsStatusComplete(true);
        });
    }

    function handleClose() {
        reset();
        setEditData && setEditData(null)
        setIsLoading(false);
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setEditData}
            isShowCloseBtn={false}
            classNameModal={"p-0 overflow-visible"}
            widthProps={427}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal contests__winner"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно добавили опрос на главную страницу
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={() => {
                                handleClose();
                                setIsStatusComplete(false);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className={"contests__winner"}>
                        <div className={"contests__winner__header p-0"}>
                            <h3>Редактирование опроса</h3>

                            <div
                                className={"d-flex align-items-center ml-auto cursor-pointer"}
                                onClick={setEditData}
                            >
                                <CloseIcon color={"black"} opacity={"1"} />
                            </div>
                        </div>
                    </div>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={`contests__create__form workspace-settings-group__main-block pt-2 "workspace-settings__banners__inner__modal__form`}
                    >
                        <Controller
                            name="id"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select"}
                                    placeholder={"Опрос не выбран"}
                                    label={"Опрос"}
                                    options={interviewList}
                                    value={getValue(interviewList, value)}
                                    onChange={(select) => onChange(select.value)}
                                />
                            )}
                        />

                        <div className={"d-flex gap-3"}>
                            <Button
                                type={"submit"}
                                text={"Сохранить"}
                                className={"btn btn-primary w-100"}
                                loading={isLoading}
                            />
                        </div>
                    </form>
                </>
            )}
        </DialogContainer>
    );
};

export default WorkspaceSettingModalEditInterview;