import { FC } from "react";
import { IBaseIconProps } from "../types";

export const SliderServicesIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "black"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.71436 3C4.16236 3 3.71436 3.448 3.71436 4V6C3.71436 6.552 4.16236 7 4.71436 7H6.71436C7.26636 7 7.71436 6.552 7.71436 6V4C7.71436 3.448 7.26636 3 6.71436 3H4.71436ZM11.7144 3C11.1624 3 10.7144 3.448 10.7144 4V6C10.7144 6.552 11.1624 7 11.7144 7H13.7144C14.2664 7 14.7144 6.552 14.7144 6V4C14.7144 3.448 14.2664 3 13.7144 3H11.7144ZM18.7144 3C18.1624 3 17.7144 3.448 17.7144 4V6C17.7144 6.552 18.1624 7 18.7144 7H20.7144C21.2664 7 21.7144 6.552 21.7144 6V4C21.7144 3.448 21.2664 3 20.7144 3H18.7144ZM4.71436 10C4.16236 10 3.71436 10.448 3.71436 11V13C3.71436 13.552 4.16236 14 4.71436 14H6.71436C7.26636 14 7.71436 13.552 7.71436 13V11C7.71436 10.448 7.26636 10 6.71436 10H4.71436ZM11.7144 10C11.1624 10 10.7144 10.448 10.7144 11V13C10.7144 13.552 11.1624 14 11.7144 14H13.7144C14.2664 14 14.7144 13.552 14.7144 13V11C14.7144 10.448 14.2664 10 13.7144 10H11.7144ZM18.7144 10C18.1624 10 17.7144 10.448 17.7144 11V13C17.7144 13.552 18.1624 14 18.7144 14H20.7144C21.2664 14 21.7144 13.552 21.7144 13V11C21.7144 10.448 21.2664 10 20.7144 10H18.7144ZM4.71436 17C4.16236 17 3.71436 17.448 3.71436 18V20C3.71436 20.552 4.16236 21 4.71436 21H6.71436C7.26636 21 7.71436 20.552 7.71436 20V18C7.71436 17.448 7.26636 17 6.71436 17H4.71436ZM11.7144 17C11.1624 17 10.7144 17.448 10.7144 18V20C10.7144 20.552 11.1624 21 11.7144 21H13.7144C14.2664 21 14.7144 20.552 14.7144 20V18C14.7144 17.448 14.2664 17 13.7144 17H11.7144ZM18.7144 17C18.1624 17 17.7144 17.448 17.7144 18V20C17.7144 20.552 18.1624 21 18.7144 21H20.7144C21.2664 21 21.7144 20.552 21.7144 20V18C21.7144 17.448 21.2664 17 20.7144 17H18.7144Z"
                fill='currentColor' />
        </svg>

    );
};
