import {
    ArrowExitIcon,
    SearchIcon,
    SettingIcon
} from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { NewsCard, NewsSlider } from "../../components/news";
import React, { useEffect, useState } from "react";
import { $getListCategories, $getNewsList } from "../../api/requests/news";
import Dropdown, { DropdownItem } from "../../components/ui/form/Dropdown/Dropdown";
import { isMd } from "../../utils";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import Button from "../../components/ui/form/Buttons/Button";
import ModalCatNews from "../../components/categories/ModalCatNews";
import TextField from "../../components/ui/form/Fields/TextField";
import InfiniteScroll from "../../components/ui/InfiniteScroll";

export const NewsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();

    const personalData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1 || personalData?.admin_module?.find((key: string) => key === "news");

    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get('catId') || undefined;

    const [newsData, setNewsData] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [categorySelect, setCategorySelect] = useState<any>(null);
    const [isOpenCatModal, setIsOpenCatModal] = useState(false);

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        init();
    }, [catId])

    function init() {
        getCatList();
    }

    useEffect(() => {
        const filteredList = newsData.filter((item) => item.category_id == catId)

        setFilterList(catId ? filteredList : newsData)
    }, [catId, newsData])

    function getCatList() {
        $getListCategories().then(res => {
            if (!res || !res.length) return;

            const updateList = [...res].map(i => ({
                ...i,
                label: i.title,
                value: i.id,
            }))

            setCategoryList([{ value: "", label: "Все" }, ...updateList])

            if (catId) {
                setCategorySelect(updateList.find(i => i.value === +catId))
            }
        })
    }

    function getNewsList() {
        // $getNewsList(
        //     {
        //         workspace_id: personalData?.workspace_id || undefined,
        //         offset: 0,
        //         limit: 30,
        //         cat_id: catId || ""
        //     }, { formData: true }).then(res => {
        //     if (!res) return;
        //
        //     setNewsData(res);
        //     setFilterList(res);
        // })
    }

    function handleCategory(select: DropdownItem) {
        setCategorySelect(select);

        navigate(`?catId=${select.value}`)
    }

    function handleSetting() {
        setIsOpenCatModal(true);
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const updatedFilterList = [...newsData].filter(item => {
            return (item.title.toLowerCase().includes(value.toLowerCase()));
        });

        setFilterList(updatedFilterList);
    }

    return (
        <InfiniteScroll
            url={'api/news/listnews'}
            data={newsData}
            setData={setNewsData}
            isNewApi={false}
        >
            <div className='news'>
                <div className='container layout-inner__header profile-header'>
                    { !isMobile && (
                        <div className='layout-inner__header__title'>
                            <div className='header__exitIcon' onClick={ () => navigate("/")}>
                                <ArrowExitIcon />
                            </div>
                            <h1>Новости</h1>
                        </div>
                    ) }

                    <div className='news-search'>
                        <div className="search__block-field">
                            <TextField
                                value={searchValue}
                                onChange={onChangeSearch}
                                placeholder={"Поиск"}
                                prepend={<SearchIcon color={"black"} />}
                                className={`search__block-textField`}
                            />
                        </div>
                    </div>
                </div>

                {newsData.length ? <NewsSlider data={newsData?.slice(0, 5)} /> : (
                    <div className={"d-flex align-items-center justify-content-center p-5"}>Новостей нет</div>
                )}

                <div className="container news-content">
                    <div className="news-content__header">
                        <Dropdown
                            value={categorySelect}
                            className="workspace-select"
                            placeholder={"Категория"}
                            options={categoryList}
                            onChange={handleCategory}
                            labelBottom={isAdmin ? (
                                <div className={"dropdown__list__setting"}>
                                    <Button
                                        leftIcon={<SettingIcon color={"currentColor"} />}
                                        text={"Настройки"}
                                        className={"btn btn-primary-revert w-100"}
                                        onClick={handleSetting}
                                    />
                                </div>
                            ) : null}
                        />
                    </div>

                    <div className='news-content__items'>
                        {filterList.slice(catId ? 0 : 5, newsData.length).map((item, idx) => (
                            <div key={idx} onClick={() => navigate(`/news/show/${item?.id}`)}>
                                <NewsCard data={item}/>
                            </div>
                        ))}
                    </div>
                </div>

                <ModalCatNews
                    isOpen={isOpenCatModal}
                    setIsOpen={() => setIsOpenCatModal(false)}
                    catList={categoryList}
                    setCatList={setCategoryList}
                    route={'news'}
                />
            </div>
        </InfiniteScroll>

    )
}