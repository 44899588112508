import { FC } from "react";
import { IBaseIconProps } from "../types";

export const TelegramIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "black"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.2734 5L2.27344 12.5L9.27344 13.5M21.2734 5L18.7734 20L9.27344 13.5M21.2734 5L9.27344 13.5M9.27344 13.5V19L12.5224 15.723"
                stroke={color}
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};