import React, { useEffect, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { $getBadges, $sendBadge } from "../../../api/requests/user";
import { CloseIcon, CoinCompetitionIcon } from "../../../img";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../components/ui/form/Buttons/Button";
import { BackIcon } from "../../../img/icons/form/BackIcon";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import { useSelector } from "react-redux";
import { getCurrentRole } from "../../../redux";

interface UserProfileGratitudeProps {
    title?: string;
    userId: number;
    isOpen: boolean;
    onClose: () => void;
    setIsOpen: () => void;
}

export interface UserBadgeItem {
    id: number;
    title: string;
    description: string;
    image: string;
    causes: [string];
    points: number;
    as_ws: number;
    required_comment: number;
    balance: number;
    limit_per_user: number;
    type_of_limit: number;
    admin_only: number;
    limit: number;
    required_cause: number;
    badge_id: number;
    sort: string;
    owner_id: string;
    balance_convert_state: string;
}

interface FormData {
    causes?: string;
    cost: number;
    as_ws: number;
    comment?: string;
}

const UserProfileGratitudeModal = (
    {
        title,
        userId,
        isOpen,
        onClose,
        setIsOpen
    }: UserProfileGratitudeProps
) => {
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [badges, setBadges] = useState<UserBadgeItem[]>();
    const [statusContent, setStatusContent] = useState("start");
    const [selectBadge, setSelectBadge] = useState<UserBadgeItem | null>(null);
    const [causesList, setCausesList] = useState<any[]>([]);

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
        watch
    } = useForm<FormData>({
        values: {
            cost: selectBadge?.points || 0,
            as_ws: selectBadge?.as_ws || 0,
        }
    })

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    useEffect(() => {
        isOpen && init();
    }, [isOpen]);

    function init() {
        setStatusContent("start");

        $getBadges().then(res => {
            if (!res) return;

            setBadges(res);
        });
    }

    function handleCheck(badge: UserBadgeItem) {
        const updateCauseList = badge.causes.map(item => ({ label: item, value: item }))

        setCausesList(updateCauseList);
        setSelectBadge(badge);
        setStatusContent("check");
    }

    function onSubmit(data: any) {
        $sendBadge(
            {
                id: userId,
                badge_id: selectBadge?.id,
                as_ws: data.as_ws,
                cause: selectBadge?.causes[0],
                comment: data.comment
            },
            { formData: true }
        ).then(res => {
            setStatusContent("completed");
            reset();


        });
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            {(statusContent === "start") && (
                <div className={"profile-gratitude"}>
                    <div className={"profile-gratitude__header"}>
                        <h3>{title ? title : "Поблагодарить"}</h3>

                        <div
                            className={"d-flex align-items-center ml-auto cursor-pointer"}
                            onClick={() => setIsOpen()}
                        >
                            <CloseIcon color={"black"} opacity={"1"} />
                        </div>
                    </div>

                    <div className={"profile-gratitude__content"}>
                        {badges?.map((badge) => (
                            <div
                                className={"profile-gratitude__item"}
                                onClick={() => handleCheck(badge)}
                            >
                                <div className={"img"}>
                                    <BlankImage
                                        classname="notification-card__image"
                                        image={badge.image ?? ""}
                                    />
                                </div>

                                <p className={"profile-gratitude__item__name"}>{badge.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {(statusContent === "check") && (
                <div className={"profile-gratitude"}>
                    <div className={"profile-gratitude__header"}>
                        <div
                            className={"d-flex align-items-center cursor-pointer"}
                            onClick={() => setStatusContent("start")}
                        >
                            <BackIcon />
                        </div>

                        <h3>{selectBadge?.title}</h3>

                        <div
                            className={"d-flex align-items-center cursor-pointer"}
                            onClick={() => setIsOpen()}
                        >
                            <CloseIcon color={"black"} opacity={"1"} />
                        </div>
                    </div>

                    <form
                        className={"profile-gratitude__content"}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className={"profile-gratitude__content__text"}>
                            <div className={"img"}>
                                <div>
                                    <BlankImage
                                        classname="notification-card__image"
                                        image={selectBadge?.image ?? ""}
                                    />
                                </div>
                            </div>

                            <p>{selectBadge?.description}</p>
                        </div>

                        <div className={"profile-gratitude__content__text"}>
                            <p>Стоимость</p>

                            <div className={"profile-gratitude__content__text-count"}>
                                <CoinCompetitionIcon/>

                                <span>{selectBadge?.points}</span>
                            </div>
                        </div>

                        <Controller
                            name="causes"
                            control={control}
                            rules={{
                                required: {
                                    value: selectBadge?.required_cause ? !!+selectBadge?.required_cause : false,
                                    message: "Выберете причину"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    value={getValue(causesList, value)}
                                    options={causesList}
                                    label="Причина"
                                    placeholder={"Выберете причину"}
                                    name="causes"
                                    onChange={(select) => onChange(select.value)}
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="comment"
                            control={control}
                            rules={{
                                required: {
                                    value: selectBadge?.required_comment ? !!+selectBadge?.required_comment : false,
                                    message: "Напишите комментарий"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    value={value || ""}
                                    className=""
                                    title="Комментарий"
                                    placeholder={"Впишите свой комментарий"}
                                    name="comment"
                                    rows={3}
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        {isAdmin && (
                            <Controller
                                name="as_ws"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleBlock
                                        desc={"От имени организации"}
                                        isChecked={!!value}
                                        handleChange={(value) => {
                                            onChange(value ? 1 : 0)
                                        }}
                                    />
                                )}
                            />
                        )}

                        <Button
                            type={"submit"}
                            text={"Подарить"}
                            className={"btn btn-primary w-100"}
                        />
                    </form>
                </div>
            )}

            {(statusContent === "completed") && (
                <div
                    className={`profile-gratitude__congrats ${(statusContent === "completed") ? "fade-in" : "hidden"}}`}>
                    <div className={"profile-gratitude__congrats__content"}>
                        <div className={"profile-gratitude__congrats__sticker"}>👍🏻</div>
                        <div className={"profile-gratitude__congrats__title"}>Отлично!</div>
                        <div className={"profile-gratitude__congrats__desc"}>
                            Вы поблагодарили сотрудника
                        </div>
                    </div>

                    <button onClick={() => onClose()}>Спасибо</button>
                </div>
            )}

        </DialogContainer>
    );
};

export default UserProfileGratitudeModal;