import React, { FC } from 'react'
import { IBaseIconProps } from './types'

export const ReplaceIcon: FC<IBaseIconProps> = ({
    color = '#00A7B5',
    size = 24,
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.62422 15.2007H3.12421C2.1301 15.2007 1.32421 14.3948 1.32422 13.4007L1.32429 2.60077C1.32429 1.60666 2.13018 0.800781 3.12429 0.800781H11.2245C12.2186 0.800781 13.0245 1.60667 13.0245 2.60078V6.20078M4.4745 4.40078H9.8745M4.4745 7.10078H9.8745M4.4745 9.80078H7.1745M11.2564 14.3008L9.4245 12.4601M9.4245 12.4601L11.1736 10.7008M9.4245 12.4601H14.8449C15.5792 12.4601 16.1745 11.8648 16.1745 11.1304C16.1745 10.3961 15.5792 9.80078 14.8449 9.80078H13.0245"
                stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
