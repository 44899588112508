import { AcceptedIcon, ArrowExitIcon, RejectedIcon } from '../../../../img'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getYearMonthDayAndTime, isMd } from '../../../../utils'
import { Controller, useForm } from 'react-hook-form'
import DatePickerForm from '../../../../components/ui/form/DatePickerForm'
import Button from '../../../../components/ui/form/Buttons/Button'
import FileClipField from '../../../../components/ui/form/FileField/FileClipField'
import { UploadIcon } from '../../../../img/icons/files/UploadIcon'
import { $uploadServiceApplicationFile } from '../../../../api/requests/services'
import { useSelector } from 'react-redux'
import { getPersonData } from '../../../../redux'
import { BlankAva } from '../../../../components/ui/avatars/BlankAva'
import {
    $createServiceVacation,
    $getServiceVacation,
    $getServiceVacationItem,
} from '../../../../api/requests/services/vacation'
import moment from 'moment/moment'
import CompleteModal from '../../../../components/ui/dialog/CompleteModal'
import { SpinnerIcon } from '../../../../img/SpinnerIcon'
import { DownloadIcon } from '../../../../img/icons/DownloadIcon'
import Dropdown, { DropdownItem } from '../../../../components/ui/form/Dropdown/Dropdown'

export const ServicesVacationCreate = () => {
    const navigate = useNavigate()
    const isMobile = isMd()

    const location = useLocation()
    const pathname = location.pathname

    const { id: vacationId } = useParams()
    const isView: boolean = pathname.includes('view')

    const personalData = useSelector(getPersonData)
    const leader = personalData?.structure?.team?.leader

    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const [isAlreadySent, setIsAlreadySend] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [data, setData] = useState<any>()
    const [minStartDate, setMinStartDate] = useState<any>(new Date())

    const [uploadFileList, setUploadFileList] = useState<any>(undefined)

    const [statusesData, setStatusesData] = useState([
        {
            label: 'Отклонен',
            value: -1,
        },
        {
            label: 'Рассмотрение',
            value: 0,
        },
        {
            label: 'Успешно',
            value: 1,
        },
    ])

    const causeList = [
        {
            label: 'Ежегодный основной оплачиваемый отпуск',
            value: 'Ежегодный основной оплачиваемый отпуск',
        },
        {
            label: 'Отпуск без сохранения заработной платы',
            value: 'Отпуск без сохранения заработной платы',
        },
        {
            label: 'Учебный отпуск',
            value: 'Учебный отпуск',
        },
    ]

    const findStatus = statusesData.find(item => item.value == data?.status)

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    }

    const getStatusText = (status: number) => {
        switch (status) {
            case -2:
                return 'Отправлено на доработку'

            case -1:
                return 'Отклонено'

            case 1:
                return 'Одобрено'

            case 2:
                return 'Добавлен доп. согласант'

            default:
                return 'На рассмотрении'
        }
    }

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors, isValid },
        trigger,
    } = useForm<any>({
        values: {
            reason: data?.reason,
            date_from: data?.date_from ? moment(data?.date_from).toDate() : null,
            date_to: data?.date_to ? moment(data?.date_to).toDate() : null
        }
    })

    const btnListRemove = [
        {
            text: 'Отлично',
            key: 1,
            className: 'btn btn-light w-100',
        },
    ]

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    const getFileName = (url: string) => {
        const parts: any = url?.split('/')
        return parts[parts?.length - 1]
    }

    const getReasonValue = (value: DropdownItem | null = null) => {
        if (data?.reason) {
            return data?.reason
        }

        return value
    }

    useEffect(() => {
        $getServiceVacation(0, { newApi: true }).then(res => {
        })
    }, [])

    useEffect(() => {
        const val = watch().reason
        if (val && val === 'Ежегодный основной оплачиваемый отпуск') {
            setMinStartDate(moment().add(1, 'weeks').startOf('isoWeek').toDate())
        } else {
            setMinStartDate(new Date())
        }
    }, [watch().reason])

    useEffect(() => {
        if (vacationId) {
            $getServiceVacationItem(+vacationId, { newApi: true }).then(res => {
                if (res?.data) {
                    const data = res?.data

                    setIsAlreadySend(true)
                    setData(data)
                } else {
                    setIsAlreadySend(false)
                }
            })
        }
    }, [isView])

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader()

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === 'string') {
                        $uploadServiceApplicationFile({ file: item.file }, {
                            newApi: true,
                            formData: true,
                        }).then(res => {
                            setUploadFileList({ file: reader.result })
                            setValue('file', res.data.url)
                        })
                    }
                }

                reader.readAsDataURL(item.file)
            }
        })
    }

    function onSubmit(obj: any) {
        const updatedList = {
            ...obj,
            date_from: moment(obj.date_from).format('YYYY-MM-DD'),
            date_to: moment(obj.date_to).format('YYYY-MM-DD'),
        }
        $createServiceVacation(updatedList, { newApi: true }).then(res => {
            if (!res.data) return

            setIsModalOpen(true)
        })
    }

    return (
        <>
            <CompleteModal
                icon={'😊'}
                title={'Успешно'}
                text={'Вы успешно отправили заявку на отпуск. Ожидайте уведомление о результате'}
                isOpen={isModalOpen}
                setIsOpen={() => navigate('/services/vacation/list?activeType=0')}
                btnList={btnListRemove}
                onClick={() => navigate('/services/vacation/list?activeType=0')}
            />

            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Отпуск</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <div className="services-applications-create__block__left">
                        <h5 className="services-applications-create__title">Бронирование отпуска</h5>

                        {vacationId && findStatus?.value === -1 ? (
                            <div
                                className={`services-applications-create__status ${data?.status == 0 ? 'waiting' : (data.status == 1 ? 'accepted' : 'rejected')}`}
                            >

                                {findStatus?.label}
                            </div>
                        ) : ''}

                        {vacationId && findStatus?.value !== -1 ? (
                            <div
                                style={{ background: (findStatus?.value == 1) ? '#00A7B5' : '' }}
                                className={`services-applications-create__status ${findStatus?.value !== 1 ? 'waiting' : ''}`}>

                                {findStatus?.label || 'Рассмотрение'}
                            </div>
                        ) : ''}
                    </div>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit((obj) => onSubmit(obj))}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <div className="services-applications-create__dates">
                                <Controller
                                    name={'date_from'}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            disabled={isAlreadySent}
                                            startDate={value || data?.date_from}
                                            minDate={minStartDate}
                                            maxDate={watch().date_to}
                                            className=""
                                            label="Дата начала"
                                            placeholder={'Выберите дату'}
                                            onChange={onChange}
                                            isIcon
                                            value={value || data?.date_from}
                                            isRightIcon
                                        />
                                    )}
                                />

                                <Controller
                                    name={'date_to'}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            startDate={value || data?.date_to}
                                            disabled={isAlreadySent}
                                            className=""
                                            minDate={watch().date_from}
                                            maxDate={new Date(maxDate)}
                                            label="Дата завершения"
                                            placeholder={'Выберите дату'}
                                            onChange={onChange}
                                            isIcon
                                            value={value}
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <Controller
                                name={'reason'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Выберите основание',
                                    },
                                }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            disabled={isAlreadySent}
                                            className={'form-select'}
                                            placeholder={'Основание'}
                                            label={'Основание'}
                                            options={causeList}
                                            value={getValue(causeList, value)}
                                            onChange={(select) => onChange(select.value)}
                                        />
                                    )
                                }}
                            />

                            {!vacationId ? (
                                <div className="services-applications-create__file">
                                    <div className="services-applications-create__file__header">
                                        <p className="services-applications-create__file__header__label">Согласующий</p>
                                    </div>

                                    <div className="layout-vacation-modal__block"
                                         style={{ background: '#00000008', padding: '10px', borderRadius: 16 }}
                                    >
                                        <div className="layout-vacation-modal__block__right">
                                            {leader?.user?.photo ? (
                                                <img src={leader?.user?.photo} alt="" />
                                            ) : (
                                                <BlankAva
                                                    colorBg={'#CCD9E0'}
                                                    colorText={'black'}
                                                    text={leader?.user?.name}
                                                />
                                            )}

                                            <div className="layout-vacation-modal__block__desc">
                                                <h1>{leader?.user?.name}</h1>

                                                <p className="layout-vacation-modal__block__desc__position">{leader?.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : ''}

                            {data?.coordinators?.length > 0 ? (
                                <div className="services-applications-create__file">
                                    <div className="services-applications-create__file__header">
                                        <p className="services-applications-create__file__header__label">Согласующий</p>
                                    </div>

                                    {data?.coordinators?.map((item: any) => (
                                        <div className="layout-vacation-modal__block"
                                             style={{
                                                 background: '#00000008',
                                                 padding: '10px',
                                                 borderRadius: 16,
                                                 position: 'relative',
                                             }}
                                        >
                                            <div className="layout-vacation-modal__block__right">
                                                {item?.user?.photo ? (
                                                    <img src={item?.user?.photo} alt="" />
                                                ) : (
                                                    <BlankAva
                                                        colorBg={'#CCD9E0'}
                                                        colorText={'black'}
                                                        text={item?.user?.name}
                                                    />
                                                )}

                                                <div className="layout-vacation-modal__block__desc">
                                                    <h1>{item?.user?.name}</h1>

                                                    <p className="layout-vacation-modal__block__desc__position">{item?.user?.staff?.title || 'Не указан'}</p>
                                                </div>
                                            </div>

                                            <div className="services-applications-create__approvers__status">
                                                {(item.status == 1 || item.status == 2) && (
                                                    <AcceptedIcon />
                                                )}

                                                {item.status == -1 && (
                                                    <RejectedIcon />
                                                )}

                                                {item.status !== 1 && item.status !== -1 && item.status !== 2 && (
                                                    <SpinnerIcon />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : ''}

                            <div className={`services-applications-create__file`}>
                                <div className="services-applications-create__file__header">
                                    <p className="services-applications-create__file__header__label">Прикрепить файл</p>

                                    {data?.file ?
                                        <div className="services-applications-create__file__header__right">
                                            <a href={data.file}
                                               className="services-applications-create__file__header__text">
                                                Скачать заявку
                                            </a>

                                            <DownloadIcon size={18} />
                                        </div>
                                        : ''}
                                </div>

                                <Controller
                                    name="file"
                                    disabled={isAlreadySent}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: '',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileClipField
                                            accept={'.docx, .png, .jpg, .pdf, .xlsx'}
                                            label={''}
                                            upload={uploadFileList}
                                            setUpload={handleUploadList}
                                            isLockFile={uploadFileList || isAlreadySent}
                                            uploadName={getFileName(data?.file || '')}
                                            text={'Ограничения на загрузку файла не более 100 мб.'}
                                            icon={<UploadIcon size={44} />}
                                            isSingleDoc
                                        />
                                    )}
                                />
                            </div>

                            {data?.logs?.length > 0 ?
                                <div className="services-view-status__list">
                                    {data?.logs?.map((item: any, idx: number) => (
                                        <div className="services-view-status" key={idx}>
                                            <div className="services-view-status__dots">
                                                {item.status == -1 ? (
                                                    <div className="services-view-status__dots__circle red"></div>
                                                ) : (
                                                    <div
                                                        className={`services-view-status__dots__circle ${item.status !== -1 && item.status !== 1 ? 'gray' : ''}`}></div>
                                                )}

                                                {idx !== data.logs.length - 1 && (
                                                    <div
                                                        className="services-view-status__dots__line"></div>
                                                )}
                                            </div>

                                            <div className="services-view-status__info">
                                                <h5 className="services-view-status__info__title">{getStatusText(item.status)}</h5>

                                                <div className="services-view-status__info__sender">
                                                    <p>Согласователь:</p>

                                                    <h5>{item?.name}</h5>
                                                </div>

                                                {item?.reason && (
                                                    <div
                                                        className="services-view-status__info__sender">
                                                        <p>Комментарий:</p>

                                                        <h5>{item?.reason}</h5>
                                                    </div>
                                                )}

                                                <p className="services-view-status__info__date">
                                                    {getYearMonthDayAndTime(item?.updated_at)}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                : ''}

                            {!isView && (
                                <Button
                                    disabled={
                                        !watch().date_from ||
                                        !watch().date_to ||
                                        !watch().file ||
                                        !watch().reason
                                    }
                                    type={'submit'}
                                    text={'Отправить'}
                                    className={'btn btn-primary w-100'}
                                />
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}