import {FC} from "react";
import {NotificationMutedIcon, NotificationUnmutedIcon} from "../../img";
import {SportViewItemData} from "../../api/requests/sport/interface";
import CheckIcon from "../../img/icons/vacation/CheckIcon";
import {daysOfWeek, modDate} from "../../utils";
import moment from "moment";
import pad from "../../utils/pad/pad";

interface SportContentItemProps extends SportViewItemData {
    isTrainerRole?: boolean;
    isMain?: boolean;
    selectSchedule?: any;
    handleChangeDateWorkout?: (select: any) => void;
    startTime?: string;
    endTime?: string;
}

export const SportBlock: FC<SportContentItemProps> = (
    {
        title,
        schedules,
        handleChangeDateWorkout,
        selectSchedule,
        isMain,
        startTime,
        endTime
    }) => {
    const currentDay = moment().format("yyyy-MM-DD")
    const scheduleList = isMain ? schedules.slice(0, 3) : schedules;

    const weekDays = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье']

    return (
        <div className=''>
            <div className='profile-sport__block__top'>
                <div className='profile-sport__block__left'>
                    <div className='profile-sport__block__day'>
                        {new Date(currentDay || '').getDate()}
                    </div>

                    <div className='profile-sport__block__dates'>
                        <p className='profile-sport__block__week'>{weekDays[new Date(currentDay).getDay() - 1]}</p>

                        <p className='profile-sport__block__month'>{modDate(pad((new Date().getMonth() + 1).toString() ))}</p>
                    </div>
                </div>

                <div className='profile-sport__block__notification'>
                    {/*{isMuted ? <NotificationMutedIcon/> : <NotificationUnmutedIcon/>}*/}
                </div>
            </div>

            <div className='profile-sport__block__content'>
                <p className='profile-sport__block__type'>Тренировка</p>

                <h1 className='profile-sport__block__text'>{title || ''}</h1>

                <div className='profile-sport__block__times mb-3'>
                    <p>{startTime}</p>

                    <span></span>

                    <p>{endTime}</p>
                </div>

                {/*{scheduleList.length ? (*/}
                {/*    <div className="sport__content-card-block-content__bottom">*/}
                {/*        {scheduleList.map((item, idx) => (*/}
                {/*            <div*/}
                {/*                key={`date-item-${idx}`}*/}
                {/*                className="sport__content-card-block-content__bottom__block"*/}
                {/*                onClick={() => handleChangeDateWorkout && handleChangeDateWorkout(item)}*/}
                {/*            >*/}
                {/*                {(selectSchedule && selectSchedule?.id == item.id) && (*/}
                {/*                    <span className={"btn-check"}>*/}
                {/*                    <CheckIcon color={"white"} size={38} />*/}
                {/*                </span>*/}
                {/*                )}*/}

                {/*                <p>{item.time}</p>*/}
                {/*            </div>*/}
                {/*        )) }*/}
                {/*    </div>*/}
                {/*) : ''}*/}
            </div>
        </div>
    )
}