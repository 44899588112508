export const LogoHeaderMobileIcon = () => {
    return (
        <svg width="125" height="20" viewBox="0 0 125 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.53872 7.0773C0.965094 7.0773 0.5 6.6124 0.5 6.03845C0.5 5.46492 0.965094 5 1.53872 5C2.11234 5 2.57744 5.46492 2.57744 6.03845C2.57744 6.6124 2.11234 7.0773 1.53872 7.0773Z"
                fill="#00A7B5" />
            <path
                d="M9.46056 7.0773C8.88697 7.0773 8.42188 6.6124 8.42188 6.03845C8.42188 5.46492 8.88697 5 9.46056 5C10.0343 5 10.4995 5.46492 10.4995 6.03845C10.4995 6.6124 10.0343 7.0773 9.46056 7.0773Z"
                fill="#00A7B5" />
            <path
                d="M9.46056 15C8.88697 15 8.42188 14.5349 8.42188 13.9612C8.42188 13.3874 8.88697 12.9224 9.46056 12.9224C10.0343 12.9224 10.4995 13.3874 10.4995 13.9612C10.4995 14.5349 10.0343 15 9.46056 15Z"
                fill="#00A7B5" />
            <path
                d="M35.4846 0V19.6631H32.2742V11.4328L32.5867 4.46629L22.6147 19.6631H18.8359V0H22.0462V8.23024L21.7623 15.1686L31.7061 0H35.4846Z"
                fill="black" />
            <path d="M50.949 7.19115H45.8635V19.663H42.6529V7.19115H37.5391V4.21326H50.949V7.19115Z"
                  fill="black" />
            <path
                d="M67.9234 11.9381C67.9234 16.5451 64.5428 20 59.8548 20C56.1613 20 52.7807 17.8654 52.0703 14.017H55.3943C55.9625 16.0394 57.8095 17.135 59.8548 17.135C62.3267 17.135 64.1449 15.5336 64.5711 13.1181H58.8036V10.6179H64.5428C64.0883 8.28665 62.298 6.74185 59.8265 6.74185C57.7241 6.74185 55.9342 7.8648 55.3943 9.83145H52.0703C52.7519 6.09512 56.0763 3.87598 59.8265 3.87598C64.5145 3.87598 67.9234 7.33133 67.9234 11.9381Z"
                fill="black" />
            <path
                d="M85.0019 4.21313V19.6629H81.7912V7.19106H76.6205L75.5975 13.6518C74.8306 18.2583 73.0977 19.9998 70.4271 19.9998C69.9155 19.9998 69.3472 19.9158 68.8359 19.8315V16.9662C69.1484 16.9943 69.4609 17.0506 69.7734 17.0506C71.2791 17.0506 71.9328 16.2358 72.4157 13.3706L73.8931 4.21313H85.0019Z"
                fill="black" />
            <path
                d="M105.213 4.21326V19.663H102.002V13.2024L102.23 7.83709L96.86 15.3934H96.7749L91.4056 7.83709L91.6326 13.2024V19.663H88.4219V4.21326H92.2008L96.8032 10.5615L101.434 4.21326H105.213Z"
                fill="black" />
            <path
                d="M120.652 11.9381C120.652 8.98898 118.579 6.91033 115.851 6.91033C113.124 6.91033 111.192 8.98898 111.192 11.9381C111.192 14.9159 113.152 16.9664 115.851 16.9664C118.579 16.9664 120.652 14.9159 120.652 11.9381ZM123.835 4.21335V19.6631H120.624V17.0791C119.488 18.8486 117.613 20 115.17 20C110.993 20 107.953 16.6574 107.953 11.9381C107.953 7.21885 111.05 3.87598 115.141 3.87598C117.613 3.87598 119.488 5.0842 120.624 6.8539V4.21335H123.835Z"
                fill="black" />
        </svg>
    );
};