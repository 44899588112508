import { AcceptedIcon, ArrowExitIcon, RejectedIcon, ReturnIcon } from '../../../img'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMd } from '../../../utils'
import { Controller, useForm } from 'react-hook-form'
import DatePickerForm from '../../../components/ui/form/DatePickerForm'
import Dropdown from '../../../components/ui/form/Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import { getPersonData } from '../../../redux'
import { BlankAva } from '../../../components/ui/avatars/BlankAva'
import {
    $getServiceEducation, $getServiceEducationUsers, $updateStatusEducation,
} from '../../../api/requests/services/education'
import moment from 'moment/moment'
import { SearchItem } from '../../../components/ui/form/SearchItem'
import { ServicesApplicationsList } from '../../../components/services'
import {
    ServicesImprovementModal,
} from '../../../components/services/services-education/modals'
import {
    ServicesAddingAgreerModal,
} from '../../../components/services/services-education/modals/ServicesAddingAgreerModal'
import { PlusOneIcon } from '../../../img/icons/PlusOneIcon'
import { withDebounce } from '../../../functions'
import { InWorkIcon } from '../../../img/icons/InWorkIcon'

interface DropdownItem {
    label: string;
    value: number | string | null;
}

export const ServicesEducationPage = () => {
    const personalData = useSelector(getPersonData)
    const permissions = personalData?.service_training_permissions

    const searchParams = new URLSearchParams(location.search);
    const activeType: any = searchParams.get('activeType') ;

    const canAccept = permissions?.can_accept
    const canAgree = permissions?.can_agree
    const canAdditionalAgree = permissions?.can_additional_agree
    const canCancel = permissions?.can_cancel
    const canRefinement = permissions?.can_refinement

    const isAcceptor = canAccept || canAgree || canAdditionalAgree || canCancel || canRefinement

    const navigate = useNavigate()
    const isMobile = isMd()

    const [search, setSearch] = useState<any>(null)

    const [isOpenDialog, setIsOpenDialog] = useState(0)

    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const [list, setList] = useState<any>([])
    const [filteredList, setFilteredList] = useState<any>(list)

    const [userList, setUserList] = useState<DropdownItem[]>([])

    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: 'Все',
            value: null,
        },

        {
            label: 'Отклонено',
            value: -1,
        },

        {
            label: 'Одобрено',
            value: 1,
        },
    ])

    const [myStatusList, setMyStatusList] = useState<DropdownItem[]>([
        {
            label: 'Все',
            value: null,
        },

        {
            label: 'Доработка',
            value: -2,
        },

        {
            label: 'Рассмотрение',
            value: 0,
        },
    ])

    const [leaderList, setLeaderList] = useState<DropdownItem[]>([])

    const [statusesData, setStatusesData] = useState([
        {
            label: 'Все',
            value: null,
        },

        {
            label: 'Отклонено',
            value: -1,
        },

        {
            label: 'Одобрено',
            value: 1,
        },

        {
            label: 'Доработка',
            value: -2,
        },
    ])

    const [trainingDate, setTrainingDate] = useState<any>(null)
    const [creationDate, setCreationDate] = useState<any>(null)
    const [user, setUser] = useState<any>(null)
    const [leader, setLeader] = useState<DropdownItem | null>(null)
    const [status, setStatus] = useState<any>(null)

    const [types, setTypes] = useState<any[]>([])
    const [serviceId, setServiceId] = useState<any>(null)

    const columns = [
        {
            key: 'id', title: 'ID заявки', width: 7.5, class: 'id', render: (value: number | string) => (
                <div onClick={() => navigate(`/services/education/view/${value}`)}
                     className="services-acceptance__block__id" style={{ cursor: 'pointer' }}>
                    {value}
                </div>
            ),
        },

        { key: 'date_training_at', title: 'Дата обучения', width: 15 },
        { key: 'created_at', title: 'Дата заявки', width: 15 },
        {
            key: 'file', title: 'Файл', width: 5, render: (value: any) => {
                return (
                    <div onClick={e => openFile(e, value)}
                         className="services-acceptance__block__file">
                        Скачать
                    </div>
                )
            },
        },
        {
            key: 'status', title: 'Статус', width: 12, render: (value: any, row: any) => {
                const findStatus = statusesData?.find(item => item?.value == value)

                return (
                    <div className={'services-applications-create__status-container'}>
                        {findStatus?.value === -1 ? (
                            <div
                                style={{ background: '#FF0000' }}
                                className={`services-applications-create__status canceled`}
                            >
                                {findStatus?.label}
                            </div>
                        ) : ''}

                        {findStatus?.value === 1 ? (
                            <div
                                style={{ background: '#00A7B5' }}
                                className={`services-applications-create__status success`}
                            >
                                {findStatus?.label}
                            </div>
                        ) : ''}

                        {findStatus?.value !== -1 && findStatus?.value !== 1 ? (
                            <div
                                className={`services-applications-create__status waiting`}
                            >
                                {findStatus?.label ?? 'Рассмотрение'}
                            </div>
                        ) : ''}
                    </div>
                )
            },
        },
    ]

    const coordinationColumns = [
        {
            key: 'id', title: 'ID заявки', width: 7.5, class: 'id', render: (value: number | string) => (
                <div onClick={() => navigate(`/services/education/view/${value}`)}
                     className="services-acceptance__block__id" style={{ cursor: 'pointer' }}>
                    {value}
                </div>
            ),
        },
        { key: 'date_training_at', title: 'Дата обучения', width: 15 },
        { key: 'created_at', title: 'Дата заявки', width: 15 },
        {
            key: 'user', title: 'Отправитель', width: 17.5, render: (value: any) => (
                <div className="services-acceptance__block__status">
                    {value?.photo ? (
                        <img src={value.photo} alt=""
                             className="services-acceptance__block__admin__image" />
                    ) : (
                        <BlankAva
                            size={35}
                            style={{ borderRadius: 8 }}
                            colorBg={'#CCD9E0'}
                            colorText={'black'}
                            text={value?.name}
                        />
                    )}

                    <h5 className="services-acceptance__block__admin__name">{value?.name}</h5>
                </div>
            ),
        },
        {
            key: 'collaborators', title: 'Согласующий', width: 17.5, render: (values: any) => {
                return (
                    <div className="services-acceptance__block__users">
                        {values?.map((value: any, idx: number) => (
                            <div className="services-acceptance__block__user" key={idx}>
                                <div className="services-acceptance__block__user__left">
                                    {value?.user?.photo ? (
                                        <img src={value?.user?.photo} alt=""
                                             className="services-acceptance__block__admin__image" />
                                    ) : (
                                        <BlankAva
                                            size={35}
                                            style={{ borderRadius: 8 }}
                                            colorBg={'#CCD9E0'}
                                            colorText={'black'}
                                            text={value?.name}
                                        />
                                    )}

                                    <h5>{value.user.name}</h5>
                                </div>

                                {(value.status === 1 || value.service_status === 1) && (
                                    <AcceptedIcon />
                                )}

                                {value.status === -1 && (
                                    <RejectedIcon />
                                )}

                                {value.service_status !== 1 && value.status === 2 && (
                                    <InWorkIcon />
                                )}
                            </div>
                        ))}
                    </div>
                )
            },
        },
        {
            key: 'file', title: 'Файл', width: 5, render: (value: any) => (
                <div onClick={e => openFile(e, value)} className="services-acceptance__block__file">
                    Скачать
                </div>
            ),
        },
        {
            key: 'actions', title: '', width: 10, render: (item: any, row: any) => {
                return (
                    <div className="services-acceptance__block__status">
                        {item.can_accept ?
                            <div onClick={e => updateStatus({
                                e,
                                serviceId: item.id,
                                status: 1,
                            })}
                                 className="services-acceptance__block__status__item">
                                <AcceptedIcon />
                            </div>
                            : ''
                        }
                        {item.can_decline ?
                            <div onClick={e => updateStatus({
                                e,
                                serviceId: item.id,
                                status: -1,
                            })}
                                 className="services-acceptance__block__status__item">
                                <RejectedIcon />
                            </div>
                            : ''
                        }

                        {item.can_extra_decline ?
                            <div onClick={e => {
                                e.stopPropagation()

                                setServiceId(item.id)
                                setIsOpenDialog(1)
                            }}
                                 className="services-acceptance__block__status__item">
                                <ReturnIcon />
                            </div>
                            : ''
                        }

                        {item.can_extra_accept ?
                            <div onClick={e => {
                                e.stopPropagation()

                                setServiceId(item.id)
                                setIsOpenDialog(2)
                            }}
                                 className="services-acceptance__block__status__item">
                                <PlusOneIcon />
                            </div>
                            : ''
                        }
                    </div>
                )
            },
        },
    ]

    const historyColumns = [
        {
            key: 'id', title: 'ID заявки', width: 7.5, class: 'id', render: (value: number | string) => (
                <div onClick={() => navigate(`/services/education/view/${value}`)}
                     className="services-acceptance__block__id" style={{ cursor: 'pointer' }}>
                    {value}
                </div>
            ),
        },
        { key: 'date_training_at', title: 'Дата обучения', width: 15 },
        { key: 'created_at', title: 'Дата заявки', width: 15 },
        {
            key: 'user', title: 'Отправитель', width: 17.5, render: (value: any) => (
                <div className="services-acceptance__block__status">
                    {value?.photo ? (
                        <img src={value.photo} alt=""
                             className="services-acceptance__block__admin__image" />
                    ) : (
                        <BlankAva
                            size={35}
                            style={{ borderRadius: 8 }}
                            colorBg={'#CCD9E0'}
                            colorText={'black'}
                            text={value?.name}
                        />
                    )}

                    <h5 className="services-acceptance__block__admin__name">{value?.name}</h5>
                </div>
            ),
        },
        {
            key: 'collaborators', title: 'Согласующий', width: 17.5, render: (values: any) => (
                <div className="services-acceptance__block__users">
                    {values?.map((value: any, idx: number) => {
                        return (
                            <div className="services-acceptance__block__user" key={idx}>
                                <div className="services-acceptance__block__user__left">
                                    {value?.user?.photo ? (
                                        <img src={value?.user?.photo} alt=""
                                             className="services-acceptance__block__admin__image" />
                                    ) : (
                                        <BlankAva
                                            size={35}
                                            style={{ borderRadius: 8 }}
                                            colorBg={'#CCD9E0'}
                                            colorText={'black'}
                                            text={value?.name}
                                        />
                                    )}

                                    <h5>{value.user.name}</h5>
                                </div>

                                {(value.status === 1 || value.service_status === 1) && (
                                    <AcceptedIcon />
                                )}

                                {value.status === -1 && (
                                    <RejectedIcon />
                                )}

                                {value.service_status !== 1 && value.status === 2 && (
                                    <InWorkIcon />
                                )}
                            </div>
                        )
                    })}
                </div>
            ),
        },
        {
            key: 'file', title: 'Файл', width: 5, render: (value: any) => (
                <div onClick={e => openFile(e, value)} className="services-acceptance__block__file">
                    Скачать
                </div>
            ),
        },
        {
            key: 'status', title: 'Статус', width: 12, render: (value: any, row: any) => {
                const findStatus = statusesData?.find(item => item?.value == value)

                return (
                    <div className={'services-applications-create__status-container'}>
                        {findStatus?.value === -1 ? (
                            <div
                                style={{ background: '#FF0000' }}
                                className={`services-applications-create__status canceled`}
                            >
                                {findStatus?.label}
                            </div>
                        ) : ''}

                        {findStatus?.value === 1 ? (
                            <div
                                style={{ background: '#00A7B5' }}
                                className={`services-applications-create__status success`}
                            >
                                {findStatus?.label}
                            </div>
                        ) : ''}

                        {findStatus?.value !== -1 && findStatus?.value !== 1 ? (
                            <div
                                className={`services-applications-create__status waiting`}
                            >
                                {findStatus?.label ?? 'Рассмотрение'}
                            </div>
                        ) : ''}
                    </div>
                )
            },
        },
    ]

    const {
        control,
        formState: { isValid },
        setValue,
        watch,
    } = useForm<any>()

    useEffect(() => {
        withDebounce(() => handleItems())
    }, [search, page])

    useEffect(() => {
        setPage(1)
        setLastPage(1)
    }, [activeType])

    useEffect(() => {
        setUserList([])
        setLeaderList([])
        setStatus(null)
        setUser(null)
        setLeader(null)
        setTrainingDate(null)
        setCreationDate(null)

        setList([])
        setFilteredList([])
    }, [activeType])

    useEffect(() => {
        handleItems()
    }, [activeType, status, leader, user, creationDate, trainingDate, page])

    useEffect(() => {
        if (isAcceptor) {
            setTypes([
                {
                    id: 0,
                    key: 'applications',
                    title: 'Мои заявки',
                },
                {
                    id: 1,
                    key: 'acceptance',
                    title: 'Согласование заявок',
                },
                {
                    id: 2,
                    key: 'history',
                    title: 'История заявок',
                },
            ])
        } else {
            setTypes([
                {
                    id: 0,
                    key: 'applications',
                    title: 'Мои заявки',
                },

                {
                    id: 2,
                    key: 'history',
                    title: 'История заявок',
                },
            ])
        }
    }, [personalData])

    const openFile = (event: React.MouseEvent<HTMLDivElement>, file: string) => {
        event.stopPropagation()
        window.open(file, '_blank')
    }

    const updateStatus = async (params: {
        e: React.MouseEvent<HTMLDivElement>
        serviceId: number;
        status: number;
    }) => {
        const { status, serviceId, e } = params

        e.stopPropagation()

        const { data } = await $updateStatusEducation(serviceId, {
            status: status,
        }, { newApi: true })

        handleItems()
    }

    const handleItems = () => {
        const formattedTrainingDate = trainingDate ? moment(trainingDate).format('DD.MM.YYYY') : null
        const formattedCreationDate = creationDate ? moment(creationDate).format('DD.MM.YYYY') : null

        const requestData = {
            type: +activeType,
            page,
            creation_date: formattedCreationDate,
            training_date: formattedTrainingDate,
            user_id: user?.value,
            leader_id: leader?.value,
            status: status?.value,
            search: search ?? null,
        }

        $getServiceEducation(requestData, { newApi: true }).then(res => {
            if (!res?.data?.data) return

            const updatedList = res?.data?.data?.map((item: any) => {
                const data = {
                    ...item,
                }

                if (data.collaborators) {
                    data.collaborators = data.collaborators?.map((user: any) => {
                        user.service_status = item.status
                        return user
                    })
                }

                return {
                    ...data,
                    date_training_at: moment(item?.date_training_at).format('DD.MM.YYYY'),
                    created_at: moment(item?.created_at).format('DD.MM.YYYY'),
                    actions: {
                        id: item.id,
                        can_accept: item.can_accept,
                        can_extra_accept: item.can_extra_accept,
                        can_decline: item.can_decline,
                        can_extra_decline: item.can_extra_decline,
                    },
                }
            })

            if (page !== 1) {
                setList([
                    ...list,
                    ...updatedList
                ])
                setFilteredList([
                    ...filteredList,
                    ...updatedList
                ])
            } else {
                setList(updatedList)
                setFilteredList(updatedList)
            }

            setLastPage(res?.data?.last_page)
        })

        if (activeType !== 0) {
            $getServiceEducationUsers({
                type: activeType,
            }, { newApi: true }).then(res => {
                const all: DropdownItem[] = [
                    {
                        label: 'Все',
                        value: null
                    }
                ]

                setUserList([
                    ...all,
                    ...res?.data?.creators
                ])
                setLeaderList([
                    ...all,
                    ...res?.data?.approvers
                ])
            })
        }
    }

    return (
        <div className="services-education">
            <div className="container services-education-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon"
                             onClick={() => navigate('/services')}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Заявка на обучение</h1>
                    </div>
                )}

                <div className="services-education-header__right">
                    <SearchItem
                        setValueProps={setSearch}
                        onChange={() => {
                        }}
                        // onChange={(value) => withDebounce(() => setSearch(value))}
                    />
                </div>
            </div>

            <div className="services-education-content">
                <div className="container services-education-content__top">
                    {types?.map((
                        item,
                        idx,
                    ) => (
                        <div
                            key={idx}
                            className={`services-education-content__top__block ${activeType == item.id ? 'active' : ''}`}
                            onClick={() => navigate(`/services/education/list?activeType=${item.id}`)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>

                <div className="services-education-wrapper">
                    <div className="container services-education-wrapper__header">
                        <div className="services-education-wrapper__buttons">
                            {activeType == 0 && (
                                <div className="services-education-wrapper__buttons__block">
                                    <div className="services-education-wrapper__buttons__block">
                                        <Dropdown
                                            className={'form-select'}
                                            placeholder={'Все'}
                                            label={'Статус'}
                                            options={myStatusList}
                                            value={status}
                                            onChange={(value) => {
                                                setStatus(value)
                                            }}
                                            // onChangeInput={(value) => {
                                            //     setStatus(value)
                                            //     handleItems()
                                            // }}
                                        />
                                    </div>
                                </div>
                            )}

                            {activeType == 2 && (
                                <div className="services-education-wrapper__buttons__block">
                                    <div className="services-education-wrapper__buttons__block">
                                        <Dropdown
                                            className={'form-select'}
                                            placeholder={'Все'}
                                            label={'Статус'}
                                            options={statusList}
                                            value={status}
                                            onChange={(value) => {
                                                setStatus(value)
                                            }}
                                            // onChangeInput={(value) => {
                                            //     setStatus(value)
                                            //     handleItems()
                                            // }}
                                        />
                                    </div>
                                </div>
                            )}

                            {activeType != 0 && (
                                <div className="services-education-wrapper__buttons__block">
                                    <Controller
                                        name="user"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: '',
                                            },
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Dropdown
                                                className={'form-select'}
                                                placeholder={'Все'}
                                                label={'Отправитель'}
                                                options={userList}
                                                value={user}
                                                onChange={(e) => {
                                                    setUser(e)
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            )}

                            {activeType == 2 && (
                                <div className="services-education-wrapper__buttons__block">
                                    <div className="services-education-wrapper__buttons__block">
                                        <Controller
                                            name="leader"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: '',
                                                },
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropdown
                                                    className={'form-select'}
                                                    placeholder={'Все'}
                                                    label={'Согласующий'}
                                                    options={leaderList}
                                                    value={leader}
                                                    onChange={(e) => {
                                                        setLeader(e)
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="training_date"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата обучения"
                                            placeholder={'Не выбрано'}
                                            onChange={(e: any) => {
                                                setTrainingDate(e)
                                            }}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="creation_date"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={'Не выбрано'}
                                            onChange={(e: any) => {
                                                setCreationDate(e)
                                            }}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        {activeType == 0 && (
                            <div className="services-education-wrapper__header__button"
                                 onClick={() => navigate('/services/education/create')}>
                                Подать заявку
                            </div>
                        )}
                    </div>

                    {activeType == 0 ?
                        <ServicesApplicationsList
                            columns={columns}
                            list={filteredList}
                            urlRedirect={'education'}
                            page={page}
                            lastPage={lastPage}
                            setPage={setPage}
                        />
                        : ''}

                    {activeType == 1 ?
                        <ServicesApplicationsList
                            columns={coordinationColumns}
                            list={filteredList}
                            urlRedirect={'education'}
                            page={page}
                            lastPage={lastPage}
                            setPage={setPage}
                        />
                        : ''}

                    {activeType == 2 ?
                        <ServicesApplicationsList
                            columns={historyColumns}
                            list={filteredList}
                            urlRedirect={'education'}
                            page={page}
                            lastPage={lastPage}
                            setPage={setPage}
                        />
                        : ''}
                </div>
            </div>


            {isOpenDialog === 1 && (
                <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                          id={serviceId}
                                          onClose={() => {
                                              setServiceId(null)
                                              setIsOpenDialog(0)
                                              handleItems()
                                          }} />
            )}

            {isOpenDialog === 2 && (
                <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                           id={serviceId}
                                           onClose={() => {
                                               setServiceId(null)
                                               setIsOpenDialog(0)
                                               handleItems()
                                           }} />
            )}
        </div>
    )
}