import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { routeLinks } from "../../../routes/routeLinks";

export const initialStateReducer: any = {
    header_title: ''
}

const headerTitleReducer = createSlice({
    name: 'header_title',
    initialState: initialStateReducer,
    reducers: {
        changeHeaderTitle: (state, action) => {
            state.header_title = action.payload
        },
    },
})

export const {changeHeaderTitle } = headerTitleReducer.actions

export default headerTitleReducer.reducer