import { FC, useState, useEffect } from "react";
import {
    BadgesCard,
    ProfileCard,
    StatsCard,
    EmployeesCard,
    ContestCard, BirthdaysCard, BannerCard, LinksCard
} from "../../components/layout-components";
import {
    FeedGroupsContent,
    FeedEventsContent
} from "../../components/main";
import { ArrowRightIcon, HeartIcon, StarIcon } from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { NewsCard, NewsCardProps } from "../../components/news";
import { IBanners, ILinks } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getMainPageData, getPersonData } from "../../redux";
import { BlankImage } from "../../components/ui/blank-image/BlankImage";
import { changeHeaderTitle } from "../../redux/reducers/header/reducer";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

interface IMainPage {
    isScrolled: boolean;
}

export const MainPage: FC<IMainPage> = ({ isScrolled }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch()

    const isMobile = window.screen.width <= 1020;

    const mainData = useSelector(getMainPageData);
    const personalData = useSelector(getPersonData);

    const searchParams = new URLSearchParams(location.search);

    const groupId = searchParams.get("group_id") || personalData?.group_id;
    const isGroupOpen = groupId && +groupId !== 0;

    const isShowNews = isGroupOpen ? !!+mainData?.group_news : !!+mainData?.workspace_news;
    const isShowGroups = isGroupOpen ? false : !!+mainData?.workspace_groups;
    const isShowBirthday = !!+mainData?.workspace_birthday;

    const [newsData, setNewsData] = useState([]);
    const [linksData, setLinksData] = useState<ILinks[]>([]);
    const [banners, setBanners] = useState<IBanners[]>([]);

    const [employeesContent, setEmployeesContent] = useState<IEmployeesContent[]>([]);

    const getLayoutBanners = (side: string) => {
        if (!banners) return;

        if (side === "right") {
            return banners?.filter((item: IBanners) => +item.type === 0);
        } else {
            const sortList = typeof personalData?.components_sort_home === "string"
                ? (typeof JSON.parse(personalData.components_sort_home) === "string"
                    ? JSON.parse(JSON.parse(personalData.components_sort_home))
                    : JSON.parse(personalData.components_sort_home))
                : (Array.isArray(personalData?.components_sort_home)
                    ? personalData?.components_sort_home
                    : []);
            const filterBannersList = [...banners]?.filter((item: IBanners) => (item?.layout === side) || (+item?.type > 0));

            return sortList[0]?.left?.map((item: any, idx: number) => {
                if (item.key === "survey") return item;

                const findBanner = filterBannersList?.find(i => i?.id === item?.id);

                if (findBanner) {
                    const index = filterBannersList.findIndex(banner => banner.id === findBanner.id);
                    if (index !== -1) filterBannersList.splice(index, 1);

                    return {
                        ...item,
                        ...findBanner
                    };
                }

                const fallbackBanner = filterBannersList.shift();

                if (fallbackBanner) {
                    return {
                        ...item,
                        ...fallbackBanner
                    };
                }

                return item;
            });
        }
    };

    useEffect(() => {
        dispatch(changeHeaderTitle(""))
    }, [])

    useEffect(() => {
        if (mainData) {

            setLinksData(mainData.links);
            setNewsData(mainData.news);
            setBanners(mainData.banners);
        }

    }, [mainData]);

    const handleLike = (id: number) => {
        const updatedList: any = employeesContent.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isLiked: !item.isLiked
                };
            } else {
                return item;
            }
        });

        setEmployeesContent(updatedList);
    };

    return (
        <div className={`${!isMobile && 'container'} feed`}>
            <div className="feed-left">
                {isMobile ? (
                    <>
                        <BadgesCard />

                        {isShowBirthday && <BirthdaysCard />}

                        <EmployeesCard />
                    </>
                ) : (
                    <>
                        {!isGroupOpen && (
                            <>
                                <ProfileCard />
                                <StatsCard />
                                <BadgesCard />
                            </>
                        )}

                        {isShowBirthday && <BirthdaysCard />}

                        {!isGroupOpen && (
                            <>
                                <EmployeesCard />

                                {/*{ getLayoutBanners('left')[0].map((banner: IBanners) => (*/}
                                {/*  <BannerCard data={ banner } />*/}
                                {/*)) }*/}
                            </>
                        )}

                        <LinksCard data={linksData} />

                        {getLayoutBanners("left")?.map((item: any, idx: number) => {
                            if (item?.key === "survey" && item.id && mainData?.interview?.id) return (
                                <ContestCard
                                    data={mainData?.interview}
                                />
                            );

                            if (item?.image) return (
                                <BannerCard
                                    key={`layout-banner-item-${idx}`}
                                    data={item}
                                />
                            );

                            return null
                        })}
                    </>
                )}
            </div>

            <div className="feed-right">
                {!!+mainData.groups_first_then_news ? (
                    <>
                        {isShowGroups && <FeedGroupsContent />}

                        {isShowNews && (
                            <div className="feed-news">
                                <div className="feed-controls feed-news__controls">
                                    {/* <div className={allViewsClass}>{allViews}</div> */}
                                    <h3>Новости</h3>

                                    <div className="layout-right-block__header__link"
                                         onClick={() => navigate("news")}>
                                        <h4>Больше новостей</h4>
                                        <ArrowRightIcon color={"#000"} />
                                    </div>
                                </div>

                                <div className="feed-news__content">
                                    {newsData?.slice(0, 6).map((
                                        item: NewsCardProps,
                                        idx: number
                                    ) => (
                                        <NewsCard data={item} key={idx} />
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {isShowNews && (
                            <div className="feed-news">
                                <div className="feed-controls feed-news__controls">
                                    {/* <div className={allViewsClass}>{allViews}</div> */}
                                    <h3>Новости</h3>

                                    <div className="layout-right-block__header__link"
                                         onClick={() => navigate("news")}>
                                        <h4>Больше новостей</h4>
                                        <ArrowRightIcon color={"#000"} />
                                    </div>
                                </div>

                                <div className="feed-news__content">
                                    {newsData?.slice(0, 6).map((
                                        item: NewsCardProps,
                                        idx: number
                                    ) => (
                                        <NewsCard data={item} key={idx} />
                                    ))}
                                </div>
                            </div>
                        )}

                        {isShowGroups && <FeedGroupsContent />}
                    </>
                )}

                <div className="feed-banners__block">
                    {/*<img className="feed-banners__block__icon" src={banner?.icon}*/}
                    {/*     alt="icon" />*/}
                    <BlankImage
                        image={getLayoutBanners("right").length <= 1 ? "" : getLayoutBanners("right")[0]?.image}
                        classname="feed-banners__block__image" style={{ objectFit: "cover" }}
                        onClick={() => window.open(getLayoutBanners("right")[0]?.src)}
                    />

                    {/*<h1>*/}
                    {/*    {banner?.title}*/}
                    {/*</h1>*/}
                </div>

                {isMobile && <LinksCard data={linksData} />}

                {isMobile && (
                    <div style={{ padding: "0 1rem" }}>
                        {getLayoutBanners("left")?.map((item: any, idx: number) => {
                            if (item?.key === "survey" && item.id && mainData?.interview?.id) return (
                                <ContestCard
                                    data={mainData?.interview}
                                />
                            );

                            if (item?.image && item?.src) return (
                                <BannerCard
                                    key={`layout-banner-item-${idx}`}
                                    data={item}
                                />
                            );

                            return null
                        })}
                    </div>
                )}

                {!isGroupOpen && <FeedEventsContent />}
            </div>
        </div>
    );
};