import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMainPage, getMainPageData, getPersonData } from "../../../redux";
import {
    HamburgerIcon,
} from "../../../img";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import Button from "../../ui/form/Buttons/Button";
import { TrashIcon } from "../../../img/icons/TrashIcon";
import {
    $createNamesField,
    $deleteNamesField,
    $editNamesField,
    $getNamesList
} from "../../../api/requests/workspace-settings/field-users";
import { NamesField } from "../../../api/requests/workspace-settings/field-users/interface";
import { withDebounce } from "../../../functions";
import DialogDragAndDropContainer from "../../ui/dialog/DialogDragAndDropContainer";
import FileField from "../../ui/form/FileField/FileField";
import {
    $createLinksField,
    $deleteLinksField,
    $editLinksField
} from "../../../api/requests/main/links";
import { successNotify } from "../../../utils";
import { $deleteWelcome } from "../../../api/requests/workspace-settings/welcome";

interface LinksEditModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    data: any;
}

interface LinksField {
    title: string;
    src: string;
    image: string;
    id?: number;
    sort?: number;
}

const LinksEditModal = (
    {
        isOpen,
        setIsOpen,
        data
    }: LinksEditModalProps
) => {
    const dispatch = useDispatch();

    const mainData = useSelector(getMainPageData);

    const [state, setState] = useState<LinksField[]>([]);

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        setState(data)
    }, [data]);

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'modules-setting') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue: LinksField[] = [...state];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            const requestData = {
                ...deletedItem,
                sort: targetIndex,
            };

            handleEdit(requestData);
        }
    }

    function handleCreate(requestData: LinksField, index: number) {
        $createLinksField(requestData, { formData: true }).then(res => {
            if (!res || res.error) return;

            setState((prevState) => ([res, ...prevState]));

            dispatch(changeMainPage({...mainData, links: res}));

            if (inputRefs.current[index]) {
                inputRefs.current[index]?.blur();
            }
        })
    }

    function handleEdit(requestData: LinksField) {
        $editLinksField(requestData, { formData: true }).then(res => {
            if (!res || res.error) return;

            setState(res);

            dispatch(changeMainPage({...mainData, links: res}));
        });
    }

    function handleChangeSelect(value: string | File, select: LinksField, key: string, index: number) {
        const updateObj = {
            ...select,
            [key]: value,
        }

        if (!select?.id && (select.title && select.src)) {
            withDebounce(() => handleCreate(updateObj, index))
        }

        if (select?.id) {
            withDebounce(() => handleEdit(updateObj))
        }

        const updateList = [...state].map(item => {
            if (item.id === select.id) return {
                ...updateObj,
            }

            return item
        });

        setState(updateList);
    }

    function handleDeleteModule(idx: number, isNew: boolean) {
        if (isNew) {
            setState(state.filter((item, _idx) => {
                return _idx != idx;

            }))

            successNotify("Успешно удалено").then();
        } else {
            if (!state[idx]?.id) return;

            $deleteLinksField({ id: state[idx].id }, { formData: true }).then(res => {
                if (!res || res.error) return;
                successNotify("Успешно удалено").then();

                const filterList = state.filter((item, _idx) => {
                    return _idx != idx;
                })

                setState(filterList)
                dispatch(changeMainPage({...mainData, links: filterList}));
            })
        }
    }
    function handleAdd() {
        const request = {
            title: "",
            src: "",
            image: ""
        }

        setState((prevState) => ([request, ...prevState]))
    }

    return (
        <DialogDragAndDropContainer
            closeModal={() => setIsOpen(false)}
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            label={"Полезные ссылки"}
            widthProps={600}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='list-setting' type="modules-setting">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={"layout-links-modal workspace-settings-group__modules pl-0 pt-1 pb-2"}
                        >
                            {state.map((item, index) => (
                                <div key={`${item.id}-item-${index}`} className={"menuBlock"}>
                                    <Draggable
                                        draggableId={`${item.id}-item-${index}`}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={"drag-and-drop__block workspace-settings-group__modules-block"}
                                            >
                                                <div className={"left w-100 justify-content-between"}>
                                                    <div className={"left layout-links-modal-form"}>
                                                        <HamburgerIcon color={"#00000080"} />

                                                        <FileField
                                                            className={"form-image"}
                                                            upload={item.image}
                                                            label=""
                                                            setUpload={(file) => handleChangeSelect(file, item, "image", index)}
                                                            name="image"
                                                        />

                                                        <div className={"left layout-links-modal-fields"}>
                                                            <input
                                                                value={item.title}
                                                                placeholder={"Введите название"}
                                                                className={"workspace-settings-group__modules-field"}
                                                                onChange={(e) => handleChangeSelect(e.target.value, item, "title", index)}
                                                                autoFocus
                                                            />

                                                            <input
                                                                value={item.src}
                                                                placeholder={"Введите ссылку"}
                                                                className={"workspace-settings-group__modules-field"}
                                                                onChange={(e) => handleChangeSelect(e.target.value, item, "src", index)}
                                                                autoFocus
                                                                ref={el => inputRefs.current[index] = el}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={"gap-0"}>
                                                        <Button
                                                            className="btn btn-text ml-auto"
                                                            text={<TrashIcon />}
                                                            onClick={() => handleDeleteModule(index, !item?.id)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                </div>
                            ))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className={"pt-3 mt-1"}>
                <Button
                    text={"Добавить"}
                    className={"btn btn-primary w-100"}
                    onClick={handleAdd}
                />
            </div>
        </DialogDragAndDropContainer>
    )
}

export default LinksEditModal;