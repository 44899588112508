import { CloseIcon, DragAndDropIcon, PenIcon } from "../../img";
import { TrashIcon } from "../../img/icons/TrashIcon";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import TextField from "../ui/form/Fields/TextField";
import Button from "../ui/form/Buttons/Button";
import CheckIcon from "../../img/icons/vacation/CheckIcon";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import {
    $createBlogCategory,
    $deleteBlogCategory,
    $editBlogCategory
} from "../../api/requests/blogs";
import DialogDragAndDropContainer from "../ui/dialog/DialogDragAndDropContainer";

interface ModalCatNewsProps {
    isOpen: boolean;
    setIsOpen: () => void;
    catList: any[];
    setCatList: (val: any) => void;
    route: string,
    isNewApi?: boolean
}

const ModalCatBlogs = (
    {
        isOpen,
        setIsOpen,
        catList,
        setCatList,
        route,
        isNewApi = false,
    }: ModalCatNewsProps
) => {
    const profileData = useSelector(getPersonData);

    const [editBlock, setEditBlock] = useState<string | null>(null);
    const [catEditValue, setCatEditValue] = useState<string>("");

    const onDragEnd = (result: any) => {
        const { destination, source, type, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'group') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...catList];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleChangeCatList(targetIndex, deletedItem, workValue, +draggableId);
        }
    };

    function handleChangeCatList(targetIndex: number, deletedItem: any, workValue: any[], id: number) {
        const requestObj = {
            title: deletedItem.title,
            position: targetIndex,
            is_active: true
        }

        $editBlogCategory(id, requestObj, route, {newApi: true}).then(res => {
                    changeCatListByDropdown(workValue);
        })
    }

    function handleEdit(id: string) {
        setEditBlock(id);

        const findEdit = [...catList].find(item => item.id === id)

        findEdit && setCatEditValue(findEdit.title)
    }

    function handleDelete(id: string) {
        $deleteBlogCategory(id, route,{newApi: true}).then(res => {
            const updateList = [...catList].filter(item => item.id !== id);

            changeCatListByDropdown(updateList)
        })
    }

    function handleAddCat() {
        const requestObj = {
            title: 'Новая категория',
            is_active: true,
            position: catList.length + 1
        }
        $createBlogCategory(requestObj, route,{newApi: true}).then(res => {
                changeCatListByDropdown([res.data, ...catList])
        })
    }

    function handleSaveChange(id: string, position: number | string) {
        const requestObj = {
            position,
            title: catEditValue,
            is_active: true,
        }
        if (editBlock) {
            $editBlogCategory(+id, requestObj, route,{newApi: true}).then((res) => {
                const updateList = [...catList].map(item => {
                    if (item.id == id) return requestObj

                    return item
                })

                changeCatListByDropdown(updateList);
                handleCloseChange();
            })
        }
    }

    function handleCloseChange() {
        setEditBlock(null);
        setCatEditValue("");
    }

    function changeCatListByDropdown(list: any[]) {
        const updateList = [...list].map((item) => ({
            ...item,
            label: item.title,
            value: item.id,
            is_active: true
        }))

        setCatList(updateList)
    }

    return (
        <DialogDragAndDropContainer
            closeModal={setIsOpen}
            isOpen={isOpen}
            label={"Управление категориями"}
            classNameModal={"modal__category"}
            widthProps={427}
        >
            <div className={"modal__category__list"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppabl' type="group">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={"modal__category__drag"}
                            >
                                {catList.map((item, idx) => (
                                    <Draggable
                                        draggableId={`${item?.id}`}
                                        key={`items-${idx}-block-${item?.id}`}
                                        index={idx}
                                    >
                                        {(_provided) => (
                                            <div
                                                {..._provided.draggableProps}
                                                {..._provided.dragHandleProps}
                                                ref={_provided.innerRef}
                                                className="drag-and-drop__block"
                                            >
                                                {editBlock === item?.id ? (
                                                    <TextField
                                                        value={catEditValue}
                                                        className="w-100"
                                                        placeholder={"Напишите категорию"}
                                                        onChange={setCatEditValue}
                                                        append={(
                                                            <div>
                                                                    <span
                                                                        className={"btn-check"}
                                                                        onClick={() => handleSaveChange(item?.id, item?.position)}
                                                                    >
                                                                        <CheckIcon
                                                                            color={"white"} />
                                                                    </span>

                                                                <span
                                                                    onClick={handleCloseChange}
                                                                >
                                                                        <CloseIcon
                                                                            color={"#FF0000"}
                                                                            width={14}
                                                                            height={14}
                                                                        />
                                                                    </span>
                                                            </div>
                                                        )}
                                                    />
                                                ) : (
                                                    <>
                                                        <div className={"left"}>
                                                            <DragAndDropIcon color={"black"} />

                                                            <p style={{
                                                                width: 250,
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden'
                                                            }}>{item?.label}</p>
                                                        </div>

                                                        <div className={"right"}>
                                                            <div
                                                                onClick={() => handleEdit(item?.id)}>
                                                                <PenIcon
                                                                    size={24}
                                                                    color={"#00A7B5"}
                                                                />
                                                            </div>

                                                            <div
                                                                onClick={() => handleDelete(item?.id)}>
                                                                <TrashIcon />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <div className={"dropdown__list__setting"}>
                <Button
                    text={"Добавить"}
                    className={"btn btn-primary w-100"}
                    onClick={handleAddCat}
                />
            </div>
        </DialogDragAndDropContainer>
    )
}

export default ModalCatBlogs