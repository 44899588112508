import Button from "../../../components/ui/form/Buttons/Button";
import { ArrowExitIcon, PlusIcon } from "../../../img";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { ArrowDropdownIcon } from "../../../img/icons/arrow/ArrowDropdownIcon";
import { getMonth, setMonth } from "../../../redux";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";

interface VacationHeaderProps {
    setCalendarBtn: (value: string) => void
}

const VacationHeader = (
    {
        setCalendarBtn
    }: VacationHeaderProps
) => {
    const navigate = useNavigate();
    const { key } = useParams();

    const dispatch = useDispatch();
    const month = useSelector(getMonth);

    const titleList = [
        {
            key: "vacation",
            text: "Отпуска",
            url: "/calendar/vacation"
        },
        {
            key: "medical",
            text: "Больничный",
            url: "/calendar/medical"
        },
        {
            key: "business",
            text: "Командировки",
            url: "/calendar/business"
        },
    ]

    const [calendarBtnList, setCalendarBtnList] = useState([
        {
            key: "week",
            text: "Неделя",
            active: false
        },
        {
            key: "month",
            text: "Месяц",
            active: true
        }
    ]);

    const handleChangeActive = (item: any) => {
        const updateList = [...calendarBtnList].map(i => {
            i.active = item.key === i.key;

            return i
        })

        setCalendarBtnList(updateList);
        setCalendarBtn(item.key)
    };

    const handleBack = () => {
        navigate(-1);
    };

    function handleChangeDate(isPrev: boolean) {
        const prevDate = moment(month).clone().subtract(1, 'months')
        const nextDate = moment(month).clone().add(1, 'months')
        const date = isPrev ? prevDate : nextDate;

        dispatch(setMonth(date.format("YYYY-MM-DD")));
    }

    return (
        <div className={'vacation__header'}>
            <div className={'vacation__header__title'}>
                <div className={'back'} onClick={handleBack}>
                    <ArrowExitIcon color={"black"}/>
                </div>

                {titleList
                .sort((a, b) => (a.key === key ? -1 : b.key === key ? 1 : 0))
                .map((item, idx) => {
                    if (item.key === key) return (
                        <h2 key={`title-item-${idx}`}>{item.text}</h2>
                    )

                    return (
                        <p
                            key={`title-item-${idx}`}
                            onClick={() => navigate(item.url)}
                        >
                            {item.text}
                        </p>
                    )
                })}
            </div>

            <div className="vacation__header__btns">
                <div className={"vacation__header__btns-calendar"}>
                    {/*{calendarBtnList.map((item, idx) => (*/}
                    {/*    <Button*/}
                    {/*        key={`calendar-btn-item-${idx}`}*/}
                    {/*        className={`btn btn-${item.active ? "blue" : "secondary"}`}*/}
                    {/*        onClick={() => handleChangeActive(item)}*/}
                    {/*        text={item.text}*/}
                    {/*    />*/}
                    {/*))}*/}

                    <Button
                        className={`btn btn-primary`}
                        onClick={() => {}}
                        text={moment(month).format('MMMM YYYY')}
                        leftIcon={(
                            <span
                                className={"left"}
                                onClick={() => handleChangeDate(true)}
                            >
                                <ArrowDropdownIcon size={24} color={"white"} />
                            </span>
                        )}
                        rightIcon={(
                            <span
                                className={"right"}
                                onClick={() => handleChangeDate(false)}
                            >
                                <ArrowDropdownIcon size={24} color={"white"} />
                            </span>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default VacationHeader;