import React, { useEffect, useState } from "react";
import noImageBanner from "../../../img/noimage.png";

interface Props {
    image: any,
    style?: React.CSSProperties,
    classname?: string,
    onClick?: () => void
}

export const BlankImage = (
    {
        image,
        style,
        classname,
        onClick
    }: Props) => {
    const [imageUrl, setImageUrl] = useState<any>(null);

    useEffect(() => {
        if (image) {
            const img = new Image();
            img.src = image;

            img.onload = () => {
                setImageUrl(image);
            };
            img.onerror = () => {
                setImageUrl(null);
            };
        } else {
            setImageUrl(null);
        }
    }, [image]);

    return (
        <img src={imageUrl || noImageBanner} alt="" style={style}
             className={`blank-image ${classname}`} onClick={onClick} />
    );
};
