import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { SetRoleUsersRequestData } from "./interface";
import { IRequestUsers, IResponseUsers } from "../user/interface";

export const $getWorkspaceUsers = (
    body: IRequestUsers,
    handler?: IApiHandlerParams
): Promise<IResponseUsers> =>
    api.post("api/workspaces/users", body, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $changeWorkspaceDisableItems = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/disableItems", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $setBalanceWorkspace = (
    data: { id: string, user_id: string, balance: string },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/setbalance", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $getSearchCity = (
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get("backend/v1/search/city", {
        // mock: require('./mocks/complete.form.calculate.json'),
        request: { params },
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $setRoleUsers = (
    data: SetRoleUsersRequestData,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/workspaces/setrole", data, {
    handler
});