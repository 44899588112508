import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { CalendarItem } from "../../vacation/CalendarBlock/CalendarItem";
import { EventCalendarItems } from "../../../api/requests/events/interface";

export interface CalendarEventsDayProps {
    dateWork: WorkDate;
    list: EventCalendarItems[];
    onClickPropsAdd: (item: EventCalendarItems, isEdit?: boolean) => void;
    selectDate: Date;
}

export interface WorkDate {
    start: string;
    end: string;
}

const CalendarEventsDay = (
    {
        dateWork,
        list,
        onClickPropsAdd,
        selectDate
    }: CalendarEventsDayProps
) => {
    const [dateWorkList, setDateWorkList] = useState<string[]>([]);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [isHovered, setIsHovered] = useState(false);
    const [eventPopup, setEventPopup] = useState<MouseEvent | null>(null);

    const time = useRef(null);
    const blockAdd = useRef(null);

    const timeToHour = (time: string) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return hours;
    };

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [fontSizeLocal, setFontSizeLocal] = useState(16);

    const deviceList = [
        {
            icon: "",
            title: "ПК",
            key: 0
        },
        {
            icon: "",
            title: "Консоли",
            key: 1
        },
        {
            icon: "",
            title: "VR",
            key: 2
        },
        {
            icon: "",
            title: "Спортивные зоны",
            key: 3
        }
    ];

    const getDateByFormat = (date: Date): string => {
        return date.toISOString().split("T")[0];
    };

    const getIsShowCurrentTime = (): boolean => {
        return (currentTime === 0) ? (getDateByFormat(selectDate) !== getDateByFormat(new Date())) : false;
    };

    const getFullName = (user: any): string => {
        if (user) {
            return `${user?.name || ""}_${user?.username || ""}_${user?.lastName || ""}`;
        } else return "Админ";
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (screenWidth >= 1800) {
            const pixelsToAdd = Math.floor((screenWidth - 1800) / 100);
            setFontSizeLocal(16 + pixelsToAdd);
        } else {
            setFontSizeLocal(16);
        }
    }, [screenWidth]);

    useEffect(() => {
        const dateStart = timeToHour(dateWork.start);
        const dateEnd = timeToHour(dateWork.end);

        const listTime: string[] = [];

        for (let i = dateStart; i <= dateEnd; i++) {
            if (i < 10) listTime.push(`0${i}:00`, `0${i}:30`);
            else if (i === dateEnd) listTime.push(`${i}:00`);
            else listTime.push(`${i}:00`, `${i}:30`);
        }

        setDateWorkList(listTime);
    }, []);

    useEffect(() => {
        if (dateWorkList.length) {
            const updateCurrentTime = () => {
                const currentDate = new Date(); // '2024-05-18T05:00:00.000Z'
                const hours = String(currentDate.getHours()).padStart(2, "0");
                const minutes = String(currentDate.getMinutes()).padStart(2, "0");
                const newTime = `${hours}:${minutes}:00`;

                if (+hours < 5) return setCurrentTime(time.current && (time.current as any).clientWidth || 0);

                if (+hours >= 5 && +hours < 8) return setCurrentTime(0);

                setCurrentTime(getDateStart(newTime));
            };

            updateCurrentTime();

            const intervalId = setInterval(updateCurrentTime, 60000);

            return () => clearInterval(intervalId);
        }
    }, [dateWorkList, list]);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            const container = blockAdd.current;

            if (container) {
                const containerRect = (container as HTMLElement).getBoundingClientRect();
                const newX = e.clientX - containerRect.left - 50;
                const newY = e.clientY - containerRect.top - 50;

                setPosition({
                    x: Math.max(0, Math.min(newX, containerRect.width - 24 - 73)),
                    y: Math.max(0, Math.min(newY, containerRect.height - 24 - 73))
                });
            }
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    useEffect(() => {
        if (getDateByFormat(selectDate) !== getDateByFormat(new Date())) setCurrentTime(0);
    }, [list]);

    function getDateStart(startTime: string): number {
        const dateTime = startTime.slice(0, 5);
        const dateFindIdx = dateWorkList.indexOf(dateTime);

        const containerWidth = ((time.current && (time.current as any).offsetWidth)) || 0;
        const currentPaddingPx = 61.3 / 16 * fontSizeLocal;

        const positionInPixels = getPositionOfTime(dateWorkList, (containerWidth - currentPaddingPx), dateFindIdx);

        if (dateFindIdx !== -1) {
            // Если время найдено в списке, вычисляем позицию без добавления недостающих минут
            return positionInPixels;
        } else {
            // Время не найдено в списке, найдем ближайший временной блок
            const closeTimeObj = findClosestTime(dateTime, dateWorkList);

            const closestTime = closeTimeObj.closestTime;
            const closestTimeIdx = dateWorkList.indexOf(closestTime);

            const currentPaddingPx = 36 / 16 * fontSizeLocal;

            // Вычисляем позицию для ближайшего временного блока
            const closestPosition = getPositionOfTime(dateWorkList, containerWidth - currentPaddingPx, closestTimeIdx);

            // Вычисляем количество минут между ближайшим временным блоком и startTime
            const minutesDiff = calculateMinutesDifference(closestTime, startTime);

            // Добавляем нехватающие минуты к позиции ближайшего временного блока
            return closeTimeObj.isCurrentMinLong ? (
                closestPosition - (containerWidth / (dateWorkList.length - 1)) * (minutesDiff / 30)
            ) : (
                closestPosition + (containerWidth / (dateWorkList.length - 1)) * (minutesDiff / 30)
            );
        }
    }

    // Находит ближайшее время в списке к указанному времени
    function findClosestTime(targetTime: string, timeList: string[]): any {
        let closestTime = timeList[0];
        let minTimeDifference = Math.abs(timeToMinutes(targetTime) - timeToMinutes(closestTime));

        for (let i = 1; i < timeList.length; i++) {
            const currentTime = timeList[i];
            const timeDifference = Math.abs(timeToMinutes(targetTime) - timeToMinutes(currentTime));

            if (timeDifference < minTimeDifference) {
                closestTime = currentTime;
                minTimeDifference = timeDifference;
            }
        }

        const closeMin = closestTime && closestTime.slice(-2);
        const targetMin = targetTime && targetTime.slice(-2);

        const isCurrentMinLong = (+closeMin > +targetMin)
            || ((closeMin === "00") && +targetMin > 30);

        return { closestTime, isCurrentMinLong };
    }

    // Вычисляет разницу в минутах между двумя временами в формате "HH:mm"
    function calculateMinutesDifference(time1: string, time2: string): number {
        const minutes1 = timeToMinutes(time1);
        const minutes2 = timeToMinutes(time2);

        return Math.abs(minutes1 - minutes2);
    }

    // Конвертирует время в формате "HH:mm" в минуты
    function timeToMinutes(time: string): number {
        if (!time) return 0;

        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    function getPositionOfTime(timeline: string[], containerWidth: number, idxContainer: number) {
        const timeBlockWidth = (containerWidth) / (timeline.length - 1);// -1

        const idxContainerLocal = idxContainer === -1 ? 1 : idxContainer;

        return idxContainerLocal * +(timeBlockWidth).toFixed(1);
    }

    function getDateEnd(dateStart: string, dateEnd: string): string {
        return String(+getDateStart(dateEnd) - +getDateStart(dateStart));
    }

    function onClickAddedBooking(item: any) {
        onClickPropsAdd(item);
    }

    function onClickOpenEditDialog(item: any, isDisabled?: boolean) {
        // if (isDisabled) return;

        const timeTo = moment(item.start_date).unix();
        const currentTime = moment().unix();

        // if (currentTime > timeTo) return;

        onClickPropsAdd(item, true);
    }

    function isDisabledCalendarBlock(from: number, widthBlock: number, obj?: any) {
        if (obj) {
            const dateProps = new Date(obj.date);
            const currentDate = new Date();

            if (
                (dateProps.getDate() > currentDate.getDate())
                || (dateProps.getMonth() > currentDate.getMonth())
                || (dateProps.getFullYear() > currentDate.getFullYear())
            ) return false;
        }

        const left = (from / fontSizeLocal) * fontSizeLocal;
        const width = (widthBlock / fontSizeLocal) * fontSizeLocal;

        return currentTime > (left + width);
    }

    function onHoverEvent(is: boolean, event: any, item: any) {
        if (isHovered && !is) {
            const yPos = event.clientY - (eventPopup && eventPopup.clientY || 0);
            const xPos = event.clientX - (eventPopup && eventPopup.clientX || 0);

            if ((yPos < 10) || (xPos < 10)) return;
        }

        setIsHovered(is);

        setEventPopup(event);
    }

    return (
        <div className="calendar__events">
            <div className={"calendar__events__scroll-items"} ref={blockAdd}>
                <div
                    className="calendar__events-current-time"
                    style={{
                        left: `${currentTime}px`,
                        display: getIsShowCurrentTime() ? "none" : "block"
                    }}
                ></div>

                {/*<div*/}
                {/*    className="calendar__events-current-time calendar-current-time-fon"*/}
                {/*    style={{*/}
                {/*        left: `${currentTime - 258}px`,*/}
                {/*        display: getIsShowCurrentTime() ? "none" : "block"*/}
                {/*    }}*/}
                {/*></div>*/}

                <div className="calendar__events-time-items">
                    <div className="calendar__events-time" ref={time}>
                        {dateWorkList.map((i: string, idx) => {
                            if (idx % 2 !== 0) {
                                return (
                                    <div
                                        className="calendar__events-time-block-half"
                                        key={"calendar__events-time-block" + idx}
                                    ></div>
                                );
                            }

                            return (
                                <div
                                    className="calendar__events-time-block"
                                    key={"calendar__events-time-block" + idx}
                                >
                                    {i.slice(0, 5)}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="calendar__events-items">
                    {list
                    .map((item, idx) => ({ ...item, indicator: idx }))
                    .map((item, idx) => {
                        if (!item) return;

                        const widthBlock = +getDateEnd(moment(item.start_date).format("HH:mm"), moment(item.end_date).format("HH:mm"));

                        return (
                            <div
                                className="calendar__events-item"
                                key={"calendar__events-block-" + idx}
                            >
                                <div className="calendar__events-container">
                                    <div className={"calendar__events-blocks"}>
                                        <div
                                            className={`calendar__events-block `}//${isDisabledCalendarBlock(+getDateStart(moment(item.start_date).format("HH:mm")), widthBlock, item) ? "disabled" : ""}`}
                                            style={{
                                                left: `${+getDateStart(moment(item.start_date).format("HH:mm")) / fontSizeLocal}rem`,
                                                width: `${widthBlock / fontSizeLocal}rem`,
                                                // backgroundColor: item.color
                                            }}
                                            onClick={() => onClickOpenEditDialog(
                                                {
                                                    bookingId: item.id,
                                                    ...item,
                                                },
                                                isDisabledCalendarBlock(
                                                    +getDateStart(moment(item.start_date).format("HH:mm")),
                                                    widthBlock,
                                                    item
                                                )
                                            )}
                                        >
                                            <CalendarItem
                                                data={widthBlock > 100 ? item : {}}
                                                isEvents
                                                isDay
                                            />
                                        </div>
                                    </div>

                                    {/*<div*/}
                                    {/*    className={'calendar__events-block-add'}*/}
                                    {/*    style={{*/}
                                    {/*        // top: position.y - 25,*/}
                                    {/*        left: `${(position.x / fontSizeLocal)}rem`,*/}
                                    {/*        // opacity: (+currentTime > position.x) ? 0 : 1,*/}
                                    {/*        visibility: (((+currentTime - 40) >= position.x) || (currentTime === 0)) ? 'hidden' : 'visible'*/}
                                    {/*    }}*/}
                                    {/*    onClick={() => onClickAddedBooking(i)}*/}
                                    {/*>*/}
                                    {/*    +*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CalendarEventsDay;
