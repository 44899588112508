import React, { FC, useEffect, useState } from "react";
import noImageBanner from "../../../img/noimage.png";
import { CoinCompetitionIcon } from "../../../img";
import { Trackbar } from "../../ui/trackbar";

interface IPollsPreviewPage {
    data: any;
    answersData: any;
}

export const PollsPageView: FC<IPollsPreviewPage> = (
    {
        data,
        answersData
    }) => {

    const [upload, setUpload] = useState("");

    const handleImageUpload = (file: File): void => {
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === "string") {
                    setUpload(reader.result);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (typeof data.image === "string") {
            setUpload(data.image);
        } else {
            handleImageUpload(data.image as File);
        }
    }, [data.image]);

    function moveFirstToEnd(array: any) {
        if (array) {
            if (array?.length === 0) return array;
            const [firstItem, ...rest] = array;
            return [...rest, firstItem];
        }
    }


    return (
        <div className="polls-create-preview__content">
            <div className="polls-create-preview__img">
                <img src={upload || noImageBanner} />
            </div>

            <div className="polls-create-preview__desc">
                <div className={"d-flex align-items-center gap-3"}>
                    <h3 style={{ fontSize: data.title ? "" : "2rem" }}
                        className="polls-create-preview__title">{data.title || "Название"}</h3>
                </div>

                <div className="polls-create-preview__answers">
                    {!!answersData?.length && answersData.map((item: any, idx: number) => {
                        return (
                            <div key={`answer-item-${idx}`}>
                                <h2 className="polls-create-preview__answers__title">{item.text}</h2>

                                <div className="polls-create-preview__answers__list">
                                    {item.type !== "trackbar" && (
                                        <>
                                            {moveFirstToEnd(item?.answers)?.map((answer: any, _idx: number) => (
                                                <div
                                                    key={`answer-item-${idx}-${_idx}`}
                                                    className="polls-modal-question__block"
                                                >
                                                    {answer.id == 0 && answer.personal_answer ? (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                checked={false}
                                                                disabled={true}
                                                                onChange={() => ""}
                                                            />

                                                            <p className="polls-create-preview__answers__text">Поле Другое</p>
                                                        </>
                                                    ) : ''}

                                                    {answer.id != 0 && answer?.text  && (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                checked={false}
                                                                disabled={true}
                                                                onChange={() => ""}
                                                            />

                                                            <p className="polls-create-preview__answers__text">{answer.text}</p>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>

                                {item.type === "trackbar" && (
                                    <div>
                                        <Trackbar
                                            disabled={true}
                                            min={item.min || 0}
                                            max={item.max || 10}
                                            step={1}
                                            value={item.min}
                                            onChange={(e: any) => ""}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                {!!+data?.awarding_enabled && (
                    <div className="polls-create-preview__point">
                        <h1 className="polls-create-preview__point__title">Награда:</h1>

                        <div className="polls-create-preview__point__stats">
                            <CoinCompetitionIcon />

                            <p>{data?.award_amount}</p>
                        </div>
                    </div>
                )}

                <div className="polls-create-preview__button">
                    Ответить
                </div>
            </div>
        </div>
    );
};