import VacationHeader from "./VacationHeader";
import { VacationLeftContent } from "./VacationLeftContent";
import { CalendarBlock } from "../../../components/vacation/CalendarBlock";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsVacation, getMonth, setBookingList, setIsVacation } from "../../../redux";
import { $getVacationBooking } from "../../../api/requests/vacation";
import { useParams } from "react-router-dom";
import {
    $getMedicalCalendarList,
    $getMedicalIndexList
} from "../../../api/requests/services/medical-leave";
import moment from "moment/moment";
import { $getTripsCalendarList } from "../../../api/requests/services/trip";

export const VacationPage = () => {
    const dispatch = useDispatch();
    const month = useSelector(getMonth);
    const isVacationUpdate = useSelector(getIsVacation);

    const [calendarBtn, setCalendarBtn] = useState("month");
    const [page, setPage] = useState(1);
    const { key } = useParams();

    useEffect(() => {
        if (month) {
            if (key === "vacation") getRequestBooking();

            if (key === "medical") getRequestMedical();

            if (key === "business") getRequestBusiness();
        }

        return () => {
            dispatch(setBookingList([]));
        }
    }, [month, isVacationUpdate, key]);

    function getRequestBooking() {
        const requestData = {
            page: page,
            date: month,
        }

        $getVacationBooking(
            requestData,
        ).then(res => {
            if (!res?.data) return;

            const updateSuccessList = [...res.data.success].map(item => ({
                ...item,
                isSuccess: true,
                isStatus: true,
                type: "vacation"
            }))

            const updateProgressList = [...res.data.in_progress].map(item => ({
                ...item,
                isStatus: true,
                isProgress: true,
                type: "vacation"
            }))

            const filterList = [...updateSuccessList, ...updateProgressList];

            dispatch(setBookingList(filterList));
            dispatch(setIsVacation(false));
        });
    }

    function getRequestMedical() {
        const requestData = {
            start_date: moment(month).startOf("month").format("YYYY-MM-DD"),
            end_date: moment(month).endOf("month").format("YYYY-MM-DD"),
        }

        $getMedicalIndexList(
            requestData,
        ).then(res => {
            if (!res?.data) return;

            const filterList = [...res.data].map(item => ({
                ...item,
                date_from: item.start_date,
                date_to: item.end_date,
                isSuccess: item.status === "approved",
                isProgress: item.status === "pending",
                type: "medical",
                isStatus: true,
                user: {
                    ...item.user,
                    position: item?.title,
                    staff: {
                        title: item?.user?.title
                    }
                },
            })) || []

            dispatch(setBookingList(filterList));
            dispatch(setIsVacation(false));
        });
    }

    function getRequestBusiness() {
        const requestData = {
            start_date: moment(month).startOf("month").format("YYYY-MM-DD"),
            end_date: moment(month).endOf("month").format("YYYY-MM-DD"),
        }

        $getTripsCalendarList(
            requestData,
        ).then(res => {
            if (!res?.data) return;

            const filterList = [...res.data].map(item => ({
                ...item,
                date_from: item.start_date,
                date_to: item.end_date,
                isSuccess: item.status === "approved",
                isProgress: item.status === "pending",
                type: "business",
                isStatus: true,
                types: item.type,
                user: {
                    ...item.user,
                    position: item?.title,
                    staff: {
                        title: item?.user?.title
                    }
                },
            })) || []

            dispatch(setBookingList(filterList));
            dispatch(setIsVacation(false));
        });
    }

    return (
        <div className={"vacation__wrapper"}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="vacation">
                            <VacationHeader setCalendarBtn={setCalendarBtn}/>

                            <div className={"vacation__content"}>
                                <div className={"vacation__content__left"}>
                                    <VacationLeftContent />
                                </div>

                                <div className="vacation__content__right">
                                    <CalendarBlock/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};