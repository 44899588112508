import {
    ArrowExitIcon,
    ThreePeopleIcon,
    StarIcon
} from "../../img";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { isMd } from "../../utils";

import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { $toggleUserFavorites } from "../../api/requests/favorites";

interface IEmployeesContent {
    id: number;
    name: string;
    text: string;
    position: string;
    photo: string;
    is_favorite: boolean;
    number: string;
    email: string;
    birth: string;
    skills: any;
    leader: any;
    replacer: any;
    uniq?: string;
}

const MyTeamPage = () => {
    const isMobile = isMd();
    const isMobileSecond = window.screen.width < 570;
    const navigate = useNavigate();

    const personalData = useSelector(getPersonData);

    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [categorySelect, setCategorySelect] = useState<any | null>(null);

    const [employeesContent, setEmployeesContent] = useState<IEmployeesContent[]>([])
    const [filterUserList, setFilterUserList] = useState<IEmployeesContent[]>([])

    useEffect(() => {
        if (personalData?.id && personalData?.structure) {
            const categoryList = [...personalData?.structure?.team?.staff]
            .map((item: any) => ({
                ...item,
                value: item.title,
                label: item.title,
            }))
            .filter((item, index, self) =>
                index === self.findIndex((t) => t.title === item.title)
            )

            const userList = [...personalData?.structure?.team?.staff].map((item: any) => ({
                ...item.user,
                text: personalData?.structure?.title,
                position: item?.title,
            }))

            setEmployeesContent(userList)
            setFilterUserList(userList)
            setCategoryList(categoryList)
        }
    }, [personalData]);

    const handleLike = (id: number, is_favorite: boolean) => {
        $toggleUserFavorites({ type: 'user', type_id: id, add_to_favorites: !is_favorite }, {
            newApi: true,
        }).then(res => {
            const updatedList: any = employeesContent?.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        is_favorite: !item.is_favorite
                    }
                } else {
                    return item
                }
            })

            setEmployeesContent(updatedList)
            setFilterUserList(updatedList)
        });
    };

    function handleCategory(select: any) {
        setCategorySelect(select);

        const userList = select.value ? [...employeesContent].filter((item: any) => item.position === select.value) : [...employeesContent];

        setFilterUserList(userList)
    }

    const handleNavigation = () => {
        navigate(-1);
    };

    return (
        <div className='container employees'>
            <div className='layout-inner__header birthdays-header'>
                {!isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Моя команда</h1>
                    </div>
                )}

                <div className='employees-header__right'>
                    <Dropdown
                        className='workspace-select employees-header__right__select'
                        value={categorySelect}
                        placeholder={'Показать все'}
                        options={[
                            {
                                label: "Показать все",
                                value: ""
                            },
                            ...categoryList
                        ]}
                        onChange={handleCategory}
                    />

                    {!isMobileSecond && (
                        <div
                            className='employees-header__structure cursor-pointer'
                            onClick={() => navigate("/structure")}
                        >
                            <ThreePeopleIcon />
                            <p>Структура компании</p>
                        </div>
                    )}
                </div>
            </div>

            <div className='employees-content'>
                {filterUserList && filterUserList?.map((
                    item,
                    idx
                ) => (
                    <div className='employees-block' key={idx} >
                        <div className='employees-block__top'>
                            <div
                                className={"structure__content__item-avatar"}
                                style={item?.photo ? {
                                    backgroundImage: `url(${item?.photo})`,
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover"
                                } : {}}
                            >
                                {!item?.photo && <p>{item?.name?.charAt(0)}</p>}
                            </div>

                            <div
                                onClick={() => {
                                    handleLike(item.id, item?.is_favorite)
                                }}
                                className={`employees-block__likeIcon ${item?.is_favorite ? 'active' : ''}`}
                            >
                                <StarIcon opacity={item?.is_favorite ? 1 : 0.2} />
                            </div>
                        </div>

                        <div
                            className='employees-block__content'
                            onClick={() => {
                                item?.uniq && navigate(`/user/${item.uniq}`)
                            }}
                        >
                            <h1 className='employees-block__title'>{item?.name}</h1>

                            <p className='employees-block__text'>{item?.text}</p>

                            <p className='employees-block__position'>{item?.position}</p>
                        </div>
                    </div>
                ))}
            </div>

            {isMobileSecond && (
                <div
                    className='employees-header__structure'
                    onClick={() => navigate("/structure")}
                >
                    <ThreePeopleIcon />
                    <p>Структура компании</p>
                </div>
            )}
        </div>
    )
}

export default MyTeamPage;