import DialogContainer from "../../ui/dialog/DialogContainer";
import React, { FC, useEffect, useState } from "react";
import { CloseIcon, SearchIcon } from "../../../img";
import TextField from "../../ui/form/Fields/TextField";
import { withDebounce } from "../../../functions";
import { BlankAva } from "../../ui/avatars/BlankAva";
import Button from "../../ui/form/Buttons/Button";
import { BlankImage } from "../../ui/blank-image/BlankImage";

interface IEventMembersModal {
    isOpen: boolean;
    onClose: () => void;
    members: any,
}

export const EventMembersModal: FC<IEventMembersModal> = (
    {
        isOpen,
        onClose,
        members
    }) => {

    const [searchValue, setSearchValue] = useState<string>("");

    const filteredList = members.filter((item: any) =>  item.name.toLowerCase().includes(searchValue.toLowerCase()))

    function onChangeSearch(value: string) {
        setSearchValue(value || '');
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={onClose}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
        >
            <div className={"contests__winner"}>
                <div className="services-create-modal__header" style={{ marginBottom: 20 }}>
                    <h5 className="services-create-modal__header__title">Участники</h5>

                    <div className="services-create-modal__header__close" onClick={onClose}>
                        <CloseIcon color="#000" />
                    </div>
                </div>

                <div className={"events-modal__members__search"}>
                    <TextField
                        value={searchValue}
                        onChange={(value: string) => {
                            setSearchValue(value);
                            withDebounce(() => onChangeSearch(value));
                        }}
                        placeholder={"Поиск"}
                        prepend={<SearchIcon color={"black"} />}
                    />
                </div>

                <div className={"contests__winner__list"}>
                    {filteredList && filteredList.map((item: any, idx: number) => (
                        <div key={idx} className="layout-vacation-modal__block">

                            <div className="layout-vacation-modal__block__right">
                                <BlankImage image={item.photo}/>

                                <div className="layout-vacation-modal__block__desc">
                                    <h1>{item?.name}</h1>

                                    <p className="layout-vacation-modal__block__desc__position">{item?.job_title}</p>

                                    <p className="layout-vacation-modal__block__desc__department">{item?.department?.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </DialogContainer>
    );
};