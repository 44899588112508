import { FC, useState } from "react";
import NewsDateIcon from "../../img/icons/news/NewsDateIcon";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon, StarIcon, ThreePeopleIcon } from "../../img";
import TextOverflow from "../../utils/text-overflow/TextOverflow";
import { BlankImage } from "../ui/blank-image/BlankImage";
import { getYearMonthDay } from "../../utils";
import CheckDropdownIcon from "../../img/icons/CheckDropdownIcon";
import { CheckboxIcon } from "../../img/icons/CheckboxIcon";
import { $toggleUserFavorites } from '../../api/requests/favorites'

interface DropdownItem {
    label: string;
    value: number | string;
}

interface PollsCard {
    id: number;
    title: string;
    image: string;
    category_title: string;
    date: string;
    views: number;
    likes_count: number;
    comments_count: number;
    status?: number;
    complicated?: number;
    end_date?: any;
    user_answers?: any[];
    is_done?: string;
    is_favorite: number;
}

interface IPollsCard {
    isLikeIcon?: boolean;
    data: PollsCard;
}

export const PollsCard: FC<IPollsCard> = ({
                                              isLikeIcon = false,
                                              data
                                          }) => {
    const isMobile = window.screen.width <= 600;
    const {
        id,
        title,
        image,
        category_title,
        end_date,
        views,
        likes_count,
        comments_count,
        status,
        complicated,
        date,
        is_done,
        is_favorite,
    } = data;
    const [isLiked, setIsLiked] = useState<boolean>(!!is_favorite)

    const now: any = Date.now() / 1000;

    const statusData =
        status == 0 || date > now
            ? "hidden"
            : end_date > now
                ? "active"
                : "completed";

    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "Завершённый",
            value: 0
        },
        {
            label: "Активный",
            value: 1
        }
    ]);

    const statusFind = statusList.find(item => item.value == complicated);

    const handleLike = (id: number, e: any) => {
        e.stopPropagation()

        $toggleUserFavorites({ type: 'interview', type_id: id, add_to_favorites: !isLiked }, { newApi: true }).then(res => {
            setIsLiked(!isLiked)
        })
    }

    return (
        <div className="feed-news-card">
            <div className="feed-news-card__img">
                <BlankImage image={image} classname="blogs-drafts-card__image" />

                {(status === 0 || status === 1) && (
                    <div className={`feed-news-card__active ${statusFind?.label}`}
                         style={{ background: data.status == 1 ? "" : "#FF0000" }}>
                        <div className={`${statusFind?.label}`}></div>

                        <p>{statusData == "hidden"
                            ? "Скрытый"
                            : statusData == "active"
                                ? "Активный"
                                : "Завершённый"}</p>
                    </div>
                )}
            </div>

            <div className="feed-news-card__infoBox">
                <div className="news-card__infoBox__top">
                    <h5>{TextOverflow({ title, lines: 3 })}</h5>

                    <div
                        onClick={(e) => {
                            handleLike(id, e)
                        }}
                        className={`employees-block__likeIcon ${isLiked ? 'active' : ''}`}
                    >
                        <StarIcon opacity={isLiked ? 1 : 0.2} />
                    </div>
                </div>

                <div className="feed-news-card__content">
                    {is_done && !!+is_done ? (
                        <div className="feed-news-card__dateBox feed-news-card__completed">
                            <CheckboxIcon />

                            <div>Пройден</div>
                        </div>
                    ) : (
                        <div className="feed-news-card__dateBox">
                            <NewsDateIcon
                                size={"1.25rem"}
                                style={{
                                    marginRight: isMobile ? "0.375rem" : "0.5rem",
                                    minWidth: "1.25rem"
                                }}
                            />

                            <div>До {getYearMonthDay(+end_date * 1000)}</div>
                        </div>
                    )}

                    <div className="feed-news-card__stats">
                        <div className="feed-news-card__stats__block">
                            <EyeIcon />

                            <h1>{views}</h1>
                        </div>

                        <div className="feed-news-card__stats__block">
                            <ThreePeopleIcon />

                            <h1>{comments_count}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};