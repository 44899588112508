import DialogContainer from '../../../ui/dialog/DialogContainer'
import React, { FC, useState } from 'react'
import { CloseIcon } from '../../../../img'
import { BlankAva } from '../../../ui/avatars/BlankAva'
import PlusIcon2 from '../../../../img/icons/vacation/PlusIcon2'
import { ServiceEducationsEmployeesModal } from './ServiceEducationsEmployeesModal'
import { $updateStatusEducation } from '../../../../api/requests/services/education'

interface IServicesAddingAgreerModal {
    isOpen: boolean,
    onClose: () => void,
    id?: number | string,
}

export const ServicesAddingAgreerModal: FC<IServicesAddingAgreerModal> = (
    {
        id,
        isOpen,
        onClose,
    }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [users, setUsers] = useState<any>([])

    const handleDeleteUsers = (id: string | number) => {
        const updatedUsers = users.filter((item: any) => item.id != id)

        setUsers(updatedUsers)
    }

    const handleChangeStatus = async () => {
        console.log(id, users)

        if (id && users.length) {
            await $updateStatusEducation(id, {
                status: 2,
                user_id: users[0]?.id,
            }, { newApi: true })

            onClose()
        }
    }

    return (
        <DialogContainer isOpen={isOpen} isShowCloseBtn={false} closeModal={onClose}>
            <div className="services-create-modal__header">
                <h1 className="services-create-modal__header__title">Добавить согласанта</h1>

                <div className="services-create-modal__header__close" onClick={onClose}>
                    <CloseIcon color="#000" />
                </div>
            </div>

            <div className="services-create-modal__content services-applications-create__approvers">
                {users.map((user: any) => (
                    <div className="layout-vacation-modal__block"
                         style={{
                             background: '#00000008',
                             padding: '10px',
                             borderRadius: 16,
                             position: 'relative',
                         }}
                    >
                        <div className="layout-vacation-modal__block__right">
                            {user?.photo ? (
                                <img src={user?.photo} alt="" />
                            ) : (
                                <BlankAva
                                    colorBg={'#CCD9E0'}
                                    colorText={'black'}
                                    text={user?.name}
                                />
                            )}

                            <div className="layout-vacation-modal__block__desc">
                                <h1>{user?.name}</h1>

                                <p className="layout-vacation-modal__block__desc__position">{user?.staff?.title || 'Не показано'}</p>
                            </div>
                        </div>

                        <div
                            onClick={() => handleDeleteUsers(user.id)}
                            className="services-applications-create__approvers__status" style={{ cursor: 'pointer' }}>
                            <CloseIcon color="#FF0000" />
                        </div>
                    </div>
                ))}
            </div>

            {!users.length ? (
                <div className="services-create-form__employees" style={{ marginTop: 10 }}>
                    <div className={'services-create-form__employees__bottom'}>
                        <div className="services-create-form__employees__addButton"
                             onClick={() => setIsModalOpen(true)}>
                            <PlusIcon2 />
                        </div>
                    </div>
                </div>
            ) : ''}

            <div className="services-create-modal__buttons">
                <div
                    className="services-create-modal__buttons__item services-create-modal__buttons__add"
                    onClick={() => handleChangeStatus()}
                >
                    Направить
                </div>
            </div>

            <ServiceEducationsEmployeesModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={users}
                setData={setUsers}
            />
        </DialogContainer>
    )
}