import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeProfileVarsData,
    getPersonData,
    changePersonData,
    resetProfile, store, changeToken, changeMainPage
} from "../redux";
import Cookies from "js-cookie";
import Preloader from "../components/ui/preloader/Preloader";
import FooterHome from "../components/header/FooterHome";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../utils/scroll/ScrollToTop";
import { $getPersonalPage } from "../api/requests/user";
import { useVskAccess } from "../hooks";
import Button from "../components/ui/form/Buttons/Button";
import { LogoutIcon } from "../img/icons/LogoutIcon";

const TrainerLayout = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const localPersonData = useSelector(getPersonData);

    const [personalData, setPersonalData] = useState(localPersonData || []);

    const { jsonData } = useVskAccess({ data: personalData });

    const defaultColorScheme = {
        primaryColor: "#00A7B5", //blueColor
        secondaryColor: "#00A7B5", //lightBlue
        thirdColor: "#E9F0F9", //grayColor
        fourthColor: "#FFF" //whiteColor
    };

    useEffect(() => {
        !localPersonData && getFuncPersonData();

    }, [location]);

    useEffect(() => {
        if (jsonData) {
            dispatch(changePersonData(jsonData));
            dispatch(changeProfileVarsData({
                colors: jsonData.workspace_design
                    ? JSON.parse(jsonData.workspace_design)
                    : defaultColorScheme,
            }));
        }
    }, [jsonData]);

    function getFuncPersonData() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return console.error("error person data");

            setPersonalData(res.data);
        });
    }

    if (!localPersonData)
        return null

    return (
        <>
            <header className={"header header__trainer"}>
                <div className="header__items">
                    <div className={"container-fluid"}>
                        <div className={"header__item"}>
                            <div className="header__left">
                                <div className={"header__logo"}>
                                    <img
                                        src="/images/logo.png"
                                        alt="logo"
                                    />
                                </div>
                            </div>

                            <div className={"header__right"}>
                                <div className={"header__other"}>
                                    <Button
                                        text={<LogoutIcon color={"rgba(255,0,0,0.5)"} />}
                                        className="btn btn-icon p-0"
                                        onClick={() => {
                                            Cookies.remove("token");
                                            localStorage.removeItem("token");
                                            dispatch(changeToken(""))
                                            dispatch(resetProfile());
                                            dispatch(changeMainPage(null));
                                            setTimeout(() => navigate("/auth"), 300);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className={"inner__layout inner__layout__trainer"}>
                <Outlet />
            </div>

            <FooterHome className={"footer__trainer"} />

            <ScrollToTop />
        </>
    );
};

export default TrainerLayout;
