import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { getBookingData } from "../../../redux";
import {
    getCurrentDayCalendar,
    getInitialViewCalendar,
    onClickOpenBookingActionModal,
} from "../../../redux/reducers/booking/bookingRoomsSlice";
import { findTime, timeFormat } from "../../../utils/date/hourdsTime";
import FullCalendarComponent from "../../../components/ui/form/FullCalendar/FullCalendarComponent";
import {
    FullCalendarComponentDay
} from "../../../components/ui/form/FullCalendar/FullCalendarComponentDay";
import { getSelected } from "../../../redux/reducers/workplace/workplaceSlice";
import { failureNotify } from "../../../utils";

interface ServicesBookingContentCalendarProps {
    floors: any[];
    bookingList?: any[];
}

const ServicesBookingContentCalendar = (
    {
        floors,
        bookingList
    }: ServicesBookingContentCalendarProps
) => {
    const dispatch = useDispatch();

    const office = useSelector(getBookingData);
    const selectDay = useSelector(getCurrentDayCalendar);
    const typeCalendar = useSelector(getInitialViewCalendar);
    const { meet, floor } = useSelector(getSelected);

    const [bookingListUpdate, setBookingListUpdate] = useState<any[]>([]);

    useEffect(() => {
        if (bookingList) {
            const list = bookingList.map((i) => {
                return {
                    ...i,
                    resourceId: "room-1",
                    title: i.description,
                    start: getDate(i.date, getTimeDate(i.start_time)),
                    end: getDate(i.date, getTimeDate(i.end_time)),
                    startTimes: getTimeDate(i.start_time),
                    endTimes: getTimeDate(i.end_time),

                    backgroundColor: i.color,
                    backgroundActionColor: i.actionColor
                };
            });

            setBookingListUpdate([...list]);
        }
    }, [bookingList, typeCalendar, selectDay]);

    function getDate(date: string, time: string) {
        return `${date}T${time}:00`;
    }

    function getTimeDate(timestamp: number) {
        return timeFormat(findTime(timestamp - 60)?.time || findTime(timestamp)?.time || "");
    }

    function mapDayList(list: any[]) {
        if (!list || !list.length) return [];

        const dateNow = new Date(selectDay).getDate() || new Date().getDate();

        return list.filter((i) => {
            const day = new Date(i.date).getDate();

            return day === dateNow;
        });
    }

    function changeListByRooms(arr: any[]) {
        const list: any[] = [];

        arr.forEach((i) => {
            if (i.rooms) {
                const roomsList = i.rooms.map((item: any) => {
                    return {
                        ...item,
                        floor: i.number
                    };
                });

                list.push(...roomsList);
            }
        });

        return list;
    }

    function onClickCalendar(item: any) {
        const room = changeListByRooms(floors).find((i) => i.id === item.idRoom);
        const booking = item;

        const roomUpdate = {
            ...room,
            booking,
            color: booking.color,
            actionColor: booking.actionColor,
            isEdit: true
        };

        dispatch(onClickOpenBookingActionModal(roomUpdate));
    }

    function onClickNewMeetingCalendar(item: any) {
        const room = changeListByRooms(floors).find((i) => i.id === item.idRoom) || meet;
        const booking = {
            ...item,
            start_time: moment.duration(moment(item.date).format("HH:mm:ss")).asSeconds() || 0
        };

        const roomUpdate = {
            ...room,
            booking
        };

        if (!floor) return failureNotify("Выберите этаж");

        dispatch(onClickOpenBookingActionModal(roomUpdate));
    }

    return (
        <div className={"services__booking__content"}>
            {typeCalendar === "timeGridWeek" ? (
                <FullCalendarComponent
                    slotMinTime={findTime(office?.start_time)?.time}
                    slotMaxTime={findTime(office?.end_time)?.time}
                    selectedDate={selectDay}
                    meetRooms={bookingListUpdate}
                    onClickCalendar={(item) => onClickCalendar(item)}
                    onClickCalendarNew={(item) => onClickNewMeetingCalendar(item)}
                />
            ) : (
                <FullCalendarComponentDay
                    slotMinTime={findTime(office?.start_time)?.time}
                    slotMaxTime={findTime(office?.end_time)?.time}
                    selectedDate={selectDay}
                    meetRooms={mapDayList(bookingListUpdate)}
                    onClickCalendar={(item) => onClickCalendar(item)}
                    onClickCalendarNew={(item) => onClickNewMeetingCalendar(item)}
                />
            )}
        </div>
    );
};

export default ServicesBookingContentCalendar;
