import DialogContainer from "../../ui/dialog/DialogContainer";
import React, { FC, useEffect, useState } from "react";
import { CloseIcon, HamburgerIcon, PenIcon, PlusIcon2 } from "../../../img";
import { Controller, useForm } from "react-hook-form";
import TextField from "../../ui/form/Fields/TextField";
import { IServicesField } from "../../../api/requests/services/interface";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { IPollsAnswers } from "../../../api/requests/polls/interface";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import CountField from "../../ui/form/Fields/CountField";
import { failureNotify } from "../../../utils";
import { TrashIcon } from "../../../img/icons/TrashIcon";

interface IPollsCreateQuestionModal {
    isOpen: boolean,
    onClose: () => void,
    data: any,
    setAnswersData: any,
    answersData: any,
    isComplicated?: boolean,
}

interface DropdownItem {
    label: string;
    value: number | string;
}

export const PollsCreateQuestionModal: FC<IPollsCreateQuestionModal> = (
    {
        isOpen,
        onClose,
        data,
        setAnswersData,
        answersData,
        isComplicated
    }) => {
    const [questionsData, setQuestionsData] = useState(answersData);
    const getItem = questionsData?.find((item: any) => item?.question_id == data?.question_id);

    const {
        control,
        formState: { isValid },
        watch
    } = useForm<any>();

    const nextOptions = questionsData.filter((item: any) => item?.question_id != getItem?.question_id)
    .map((question: any, idx: number) => {
        return {
            label: question?.text,
            value: question?.question_id
        };
    });

    const getPersonalAnswer = () => {
        const parent = questionsData?.find((item: any) => item.question_id === data.question_id);
        if (!parent) return null;
        return parent?.answers?.find((child: any) => child.id == 0);
    };

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Единственный вариант ответа",
            value: "radio"
        },
        {
            label: "Несколько вариантов ответа",
            value: "checkbox"
        },
        {
            label: "Количественный ползунок",
            value: "trackbar"
        }
    ]);

    const addAnswers = (key: string) => {
        let list;

        if (key === "radio" || key === "checkbox") {
            list = [
                { id: 0, text: "", next: "", personal_answer: 0 },
                { id: 1, text: "", next: "" }
            ];
        }

        return list;
    };


    const handleChangeValues = (key: string, value: any) => {
        const updatedList = questionsData.map((item: any) =>
            item?.question_id === data?.question_id
                ? {
                    ...item,
                    [key]: value,
                    ...(value === "trackbar" && { min: 0, max: 0 }),
                    ...(value === "trackbar"
                        ? { answers: undefined }
                        : (value === "radio" || value === "checkbox")
                            ? { answers: addAnswers(value), min: undefined, max: undefined }
                            : {})
                }
                : item
        );

        setQuestionsData(updatedList);
    };


    const handleChangeCountValues = (key: string, value: any) => {
        if (/^\d*$/.test(value)) {
            value = value?.replace(/^0+(?!$)/, "");

            if (value?.startsWith(".")) {
                value = 0 + value;
            }

            handleChangeValues(key, value);
        }
    };

    const handleChangeAnswerValues = (id: number, type: string, value: any) => {
        setQuestionsData((prevData: any) =>
            prevData.map((item: any) => {
                if (item?.question_id == data?.question_id) {
                    return {
                        ...item,
                        answers: item.answers.map((child: any) => {
                            if (child.id == id) {
                                return { ...child, [type]: value };
                            }
                            return child;
                        })
                    };
                }
                return item;
            })
        );
    };

    const handleAddAnswer = () => {
        const lastId = getItem.answers.length > 0 ? getItem.answers[getItem.answers.length - 1].id : 0;

        const newItem = {
            id: lastId + 1,
            text: "",
            next: ""
        };

        setQuestionsData((prevData: any) =>
            prevData.map((item: any) => {
                if (item.question_id == data?.question_id) {
                    return {
                        ...item,
                        answers: [...item.answers, newItem]
                    };
                }
                return item;
            })
        );
    };

    const onDragEnd = (result: any) => {
        const { destination, draggableId, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === "group") {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...getItem?.answers];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            const updatedList = workValue.map((item, idx) => {
                return {
                    ...item,
                    id: idx
                };
            });

            setQuestionsData((prevData: any) =>
                prevData.map((item: any) => {
                    if (item.question_id == data?.question_id) {
                        return {
                            ...item,
                            answers: updatedList
                        };
                    }
                    return item;
                })
            );
            return;
        }
    };

    const handleDeleteAnswer = (itemToDelete: number) => {
        setQuestionsData((prevData: any) =>
            prevData.map((item: any) => {
                if (item.question_id === data.question_id) {
                    return {
                        ...item,
                        answers: item.answers.filter(
                            (child: any) => child.id !== itemToDelete
                        )
                    };
                }
                return item;
            })
        );
    };

    useEffect(() => {
            setQuestionsData(answersData);
    }, [isOpen, answersData]);

    function addFieldData() {
        if (getItem?.max <= getItem?.min) {
            failureNotify("Минимум больше максимума");
        } else if (!getItem.text) {
            failureNotify("Заголовок пуст");
        } else if (
            (getItem.type === "checkbox" || getItem.type === "radio") &&
            !getItem?.answers
            ?.slice(1)
            ?.every((item: any) => item.text !== "")
        ) {
            failureNotify("Значения вариантов пусты");
        } else {
            onClose();
            setAnswersData(questionsData);
        }
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={onClose}
            isShowCloseBtn={false}
        >
            {data && (
                <>
                    <div className="services-create-modal__header">
                        <h1
                            className="services-create-modal__header__title">{`Вопрос №${data?.question_id}`}</h1>

                        <div className="services-create-modal__header__close" onClick={onClose}>
                            <CloseIcon color="#000" />
                        </div>
                    </div>

                    <div className="services-create-modal__content">
                        <form className="services-create-form">
                            <Controller
                                name="text"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        value={getItem?.text}
                                        className=""
                                        title="Заголовок"
                                        placeholder="Введите заголовок"
                                        name="text"
                                        onChange={(e) => handleChangeValues("text", e)}
                                    />
                                )}
                            />

                            <Controller
                                name="type"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Текстовая строка"}
                                        label={"Тип"}
                                        options={categoryList}
                                        value={categoryList?.find((item) => item?.value === getItem?.type)}
                                        onChange={(e) => handleChangeValues("type", e.value)}
                                        defaultValue={categoryList[0]}
                                    />
                                )}
                            />

                            {getItem.type === "trackbar" ? (
                                <div className="workspace-settings__meeting-meet__add-block">
                                    <div className={"d-flex gap-3"}>
                                        <Controller
                                            name="min"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <CountField
                                                    value={getItem?.min || 0}
                                                    label="Значение от"
                                                    onChange={(e) => e.toLocaleString().length <= 5 && handleChangeCountValues("min", e.toLocaleString())}
                                                    isInput
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="max"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <CountField
                                                    value={getItem?.max || 0}
                                                    label="Значение до"
                                                    onChange={(e) => e.toLocaleString().length <= 5 && handleChangeCountValues("max", e.toLocaleString())}
                                                    isInput
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="polls-create-answers">
                                        <p className="polls-create-answers__label">Варианты
                                            ответа</p>

                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="ROOT" type="group">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {getItem?.answers?.map((item: any, idx: number) => (
                                                            <Draggable
                                                                draggableId={`items-${idx}-block-${item?.question_id}`}
                                                                key={`items-${idx}-block-${item?.question_id}`}
                                                                index={idx}
                                                            >
                                                                {(_provided) => (
                                                                    <>
                                                                        <div
                                                                            ref={_provided.innerRef}
                                                                            className={`polls-create-answers__block start`}
                                                                            style={{alignItems: 'start !important'}}
                                                                            {..._provided.draggableProps}
                                                                        >
                                                                            {item.id !== 0 && (
                                                                                <>
                                                                                    <div
                                                                                        {..._provided.dragHandleProps}
                                                                                        className="polls-create-answers__block__left"
                                                                                        style={{marginTop: 5}}
                                                                                    >
                                                                                        <div

                                                                                        >
                                                                                            <HamburgerIcon />
                                                                                        </div>


                                                                                        <p>{idx}</p>
                                                                                    </div>

                                                                                    <div  className="polls-create-answers__block__right">
                                                                                        <div
                                                                                            className="polls-create-answers__block__input"
                                                                                            key={idx}>
                                                                                            <input
                                                                                                type="text"
                                                                                                value={item.text}
                                                                                                onChange={(e) =>
                                                                                                    handleChangeAnswerValues(
                                                                                                        item.id,
                                                                                                        "text",
                                                                                                        e.target.value
                                                                                                    )
                                                                                                }
                                                                                            />

                                                                                            {idx !== 1 && (
                                                                                                <div
                                                                                                    className="services-create-modal__list__item__close"
                                                                                                    onClick={() => handleDeleteAnswer(item.id)}>
                                                                                                    <CloseIcon
                                                                                                        color={"#FF0000"} />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>

                                                                                        {(isComplicated && getItem.type === "radio") && (
                                                                                                <Controller
                                                                                                    name="next"
                                                                                                    control={control}

                                                                                                    rules={{
                                                                                                        required: {
                                                                                                            value: false,
                                                                                                            message: ""
                                                                                                        }
                                                                                                    }}
                                                                                                    render={({ field: { onChange, value } }) => (
                                                                                                        <div
                                                                                                            style={{ pointerEvents: "auto" }}
                                                                                                            onMouseDown={(e) =>{ e.stopPropagation(); e.preventDefault()}
                                                                                                        } >
                                                                                                            <Dropdown
                                                                                                                className={"form-select"}
                                                                                                                placeholder={"Перейти к вопросу"}
                                                                                                                label={"Перейти к вопросу"}
                                                                                                                options={nextOptions}
                                                                                                                value={value || {
                                                                                                                    label: questionsData.find((question: any) => question.question_id == item?.next)?.text
                                                                                                                }}
                                                                                                                onChange={(e) =>  handleChangeAnswerValues(
                                                                                                                    item.id,
                                                                                                                    "next",
                                                                                                                    e.value
                                                                                                                )}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                />
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Draggable>
                                                        ))}

                                                        {provided.placeholder}

                                                        <div
                                                            className="polls-create-answers__button"
                                                            onClick={() => handleAddAnswer()}>
                                                            <PlusIcon2 color={"#007BFF"}
                                                                       size={32} />
                                                        </div>
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>

                                    <Controller
                                        name="extra"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <ToggleBlock
                                                isChecked={getPersonalAnswer()?.personal_answer}
                                                className="polls-create__form-toggle"
                                                desc="Поле “Другое”"
                                                handleChange={(e) => handleChangeAnswerValues(
                                                    0,
                                                    "personal_answer",
                                                    getPersonalAnswer()?.personal_answer ? 0 : 1
                                                )}
                                            />
                                        )}
                                    />
                                </>
                            )}

                            <span className="polls-create__form__line"></span>

                            {isComplicated && (
                                <Controller
                                    name="next"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Следующий вопрос"}
                                            label={"Следующий вопрос"}
                                            options={nextOptions}
                                            value={value || {
                                                label: questionsData.find((item: any) => item.question_id == getItem.next)?.text
                                            }}
                                            onChange={(e) => handleChangeValues(
                                                "next",
                                                e.value
                                            )}
                                        />
                                    )}
                                />
                            )}


                            {/*{data?.answers?.map((item: any) => (*/}
                            {/*    <Controller*/}
                            {/*        name="text"*/}
                            {/*        control={control}*/}
                            {/*        rules={{*/}
                            {/*            required: {*/}
                            {/*                value: true,*/}
                            {/*                message: ""*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*        render={({ field: { value, onChange } }) => (*/}
                            {/*            <TextField*/}
                            {/*                value={item?.text}*/}
                            {/*                className=""*/}
                            {/*                title=""*/}
                            {/*                placeholder="Введите заголовок"*/}
                            {/*                name="text"*/}
                            {/*                onChange={onChange}*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*    />*/}
                            {/*))}*/}

                            <div className="services-create-modal__buttons">
                                <div onClick={addFieldData}
                                     className="services-create-modal__buttons__item services-create-modal__buttons__add">
                                    Сохранить
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </DialogContainer>
    );
};