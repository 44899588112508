import {useNavigate} from "react-router-dom";
import {isMd} from "../../../utils";
import React, {useEffect, useState} from "react";
import {$getUserFavoritesAll, $toggleUserFavorites} from "../../../api/requests/favorites";
import {ArrowExitIcon, StarIcon} from "../../../img";
import {BlankImage} from "../../../components/ui/blank-image/BlankImage";
import { BlankAva } from '../../../components/ui/avatars/BlankAva'
import { changeHeaderTitle } from "../../../redux/reducers/header/reducer";
import { useDispatch } from "react-redux";

export const FavoritesUsers = () => {
    const navigate = useNavigate();
    const isMobile = isMd()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [ data, setData ] = useState<any>([]);

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleLike = (id: number, e: any) => {
        e.stopPropagation()
        setIsLoading(true)

        $toggleUserFavorites({type: 'user', type_id: id, add_to_favorites: false}, {newApi: true}).then(res => {
            setIsLoading(false)
        })
    };


    useEffect(() => {
        $getUserFavoritesAll('user', {newApi: true}).then(res => {
            setData(res.data)
        })
    }, [isLoading])

    useEffect(() => {
        dispatch(changeHeaderTitle("Пользователи"))
    }, [])

    return (
        <div className="container">
            <div className='favorites-employees'>
                <div className='layout-inner__header profile-header'>
                    { !isMobile && (
                        <div className='layout-inner__header__title'>
                            <div className='header__exitIcon' onClick={ handleNavigation }>
                                <ArrowExitIcon />
                            </div>
                            <h1>Пользователи</h1>
                        </div>
                    ) }
                </div>

                <div className='favorites-employees-content'>
                    { data.map((
                        item: any,
                        idx: number
                    ) => (
                        <div className="employees-block" key={idx}>
                            <div className="employees-block__top">
                                {item.cover ? (
                                    <img src={item.cover} alt=""
                                         className="employees-block__image" />
                                ) : (
                                    <BlankAva
                                        colorBg={"#CCD9E0"}
                                        colorText={"black"}
                                        text={item.title}
                                    />
                                )}

                                <div
                                    onClick={(e) => {
                                        handleLike(item.id, e);
                                    }}
                                    className={`employees-block__likeIcon active`}
                                >
                                    <StarIcon opacity={1} />
                                </div>
                            </div>

                            <div
                                className="employees-block__content"
                                onClick={() => {
                                    item?.uniq && navigate(`/user/${item.uniq}`)
                                }}
                            >
                                <h1 className="employees-block__title">{item?.title}</h1>

                                {item.job && (
                                    <>
                                        <p className='employees-block__text'>{item?.job?.department}</p>

                                        <p className='employees-block__position'>{item?.job?.title}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}