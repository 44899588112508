import { useNavigate } from "react-router-dom";
import { SportDateData, SportViewItemData } from "../../api/requests/sport/interface";
import { BlankImage } from "../../components/ui/blank-image/BlankImage";
import CheckIcon from "../../img/icons/vacation/CheckIcon";

interface SportContentItemProps extends SportViewItemData {
    isTrainerRole?: boolean;
    isMain?: boolean;
    selectSchedule?: any;
    handleChangeDateWorkout?: (select: any) => void;
}

const TrainingContentItem = (
    {
        id,
        trainer,
        image,
        title,
        seats,
        description,
        schedules,
        isTrainerRole,
        handleChangeDateWorkout,
        selectSchedule,
        isMain
    }: SportContentItemProps
) => {
    const navigate = useNavigate();

    const scheduleList = isMain ? schedules.slice(0, 3) : schedules;

    return (
        <div
            className="sport__content-card-block"
            onClick={() => isMain && navigate(isTrainerRole ? `/trainer/view/${id}` : `/sport/view/${id}`)}
        >
            <BlankImage
                image={image}
                classname={"sport__content-card-block__image"}
            />

            <div className="sport__content-card-block-content">
                <div className="sport__content-card-block-content__top">
                    <h2 className="sport__content-card-block-content__title">
                        {title}
                    </h2>

                    <div
                        className={`sport__content-card-block-content__subtitle`}>
                        Осталось мест: {selectSchedule?.seats || seats}
                    </div>
                </div>

                <div className="sport__content-card-block-content__text">
                    {description}
                </div>

                <div className="sport__content-card-block-content__bottom">
                    {scheduleList && scheduleList.map((item, idx) => (
                        <div
                            key={`date-item-${idx}`}
                            className="sport__content-card-block-content__bottom__block"
                            onClick={() => handleChangeDateWorkout && handleChangeDateWorkout(item)}
                        >
                            {(selectSchedule && selectSchedule?.id === item.id) && (
                                <span className={"btn-check"}>
                                    <CheckIcon color={"white"} size={38} />
                                </span>
                            )}
                            <p>{item.week}</p>
                            {item.date && <hr />}
                            {item.date && <p>{item.date}</p>}
                            {item.date && <hr />}
                            <p>{item.time}</p>
                        </div>
                    ))}
                </div>

                {!isTrainerRole && (
                    <div
                        className="sport__content-card-user"
                        onClick={() => {
                            trainer?.uniq && navigate(`/user/${trainer.uniq}`)
                        }}
                    >
                        <BlankImage
                            image={trainer.photo}
                            classname={"sport__content-card-user__image"}
                        />

                        <div className="sport__content-card-user__content">
                            <h1 className="sport__content-card-user__content__name">{trainer.name}</h1>
                            <p className="sport__content-card-user__content__position">{trainer.position}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrainingContentItem;