import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { getPersonData } from "../../../redux";
import {
    getPopupData,
    getSelected,
    setShapeData
} from "../../../redux/reducers/workplace-management/workplaceManagementSlice";
import { Controller, useForm } from "react-hook-form";
import { successNotify } from "../../../utils";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Button from "../../ui/form/Buttons/Button";
import TextField from "../../ui/form/Fields/TextField";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import HashtagsField from "../../ui/form/Hashtags/HashtagsField";
import {
    $getMapSettingBookingData, $mapSettingBookingCreate,
    $mapSettingWorkplaceCreate, $mapSettingWorkplaceDelete,
    $mapSettingWorkplaceEdit
} from "../../../api/requests/workplace/workplace-add";

interface FormData {
    isEdit: boolean;
    number: string;
    number_name: string;
    equipment: string[];
    is_department?: number;
    is_permanent?: number;
}

interface WorkplaceCreateDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedProps: any;
}

const WorkplaceCreateDialog = (
    {
        isOpen,
        onClose,
        selectedProps
    }: WorkplaceCreateDialogProps
) => {
    const { id: user_id } = useSelector(getPersonData);

    const { request } = selectedProps;
    const isMine = !request?.member || (!request.is_is_department && request?.member?.id === user_id);

    const dispatch = useDispatch();

    const { city, building, floor } = useSelector(getSelected);

    const editData = useSelector(getPopupData);

    const isEdit = !!editData?.isEdit;

    const [currentDate, setCurrentDate] = useState();
    const [range, setRange] = useState({
        booking_date: currentDate || moment().format('yyyy-MM-DD'),
        booking_end_date:
            moment(currentDate).add(1, 'days').format('yyyy-MM-DD') || moment().add(1, 'days').format('yyyy-MM-DD'),
    });

    const initialBooking = {
        isEdit,
        number: editData?.number || editData?.request?.place,
        number_name: editData?.number_name || editData?.request?.number_name || "",
        equipment: editData?.equipment || request?.request?.peripheries || [],
        is_department: request?.request?.is_department
    };

    const [numberList, setNumberList] = useState<any[]>([]);
    const [bookingRequest, setBookingRequest] = useState<any>([]);
    const [period, setPeriod] = useState(false);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { isValid },
    } = useForm<FormData>(
        {
            values: {
                ...initialBooking
            }
        }
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        if (floor && floor?.workplace_count) {
            const listWorkplace = Array.from({ length: floor.workplace_count }, (_, i) => i)
            const listDropdown = listWorkplace.map(index => {
                const count = index + 1;
                const isValid = floor?.workplaces?.find((w: any) => w.number === count);

                return {
                    label: count,
                    value: count,
                    disabled: !!isValid
                }
            })

            setNumberList(listDropdown);
        }
    }, [floor]);

    useEffect(() => {
        if (editData?.id) {
            $getMapSettingBookingData(editData?.id).then(res => {
                const request = res?.data?.map((item: any) => {
                    return {
                        booking_date: item?.booking_date,
                        start_time: item?.start_time,
                        end_time: item?.end_time,
                        for_user_id: item?.for_user_id,
                        member: item?.for_user,
                    };
                });

                if (request?.length > 0) {
                    setBookingRequest(request);
                }
            })
        }
    }, []);

    function onSubmit(data: FormData) {
        const requestData = {
            ...data,
        }

        if (isEdit) return handleEdit(requestData)

        handleCreate(
            {
                ...requestData,
            }
        )
    }

    function handleCreate(requestData: any) {
        $mapSettingWorkplaceCreate(building.id, floor.id, requestData).then(res => {
            if (res && !res.code) {
                successNotify(res.message);

                dispatch(
                    setShapeData({
                        id: res?.data?.id,
                        ...watch(),
                        type: 'workplace',
                        isCreate: true,
                    }),
                );

                handleClosePopup();
            } else {
                handleClosePopup();
            }
        });
    }

    function handleEdit(requestData: any) {
        if (!building?.id || !floor?.id || !editData?.id) return;

        $mapSettingWorkplaceEdit(building?.id, floor?.id, editData?.id, requestData).then(res => {
            if (
                !watch().is_permanent &&
                bookingRequest?.length > 0 &&
                bookingRequest[0]?.start_time &&
                bookingRequest[0]?.end_time
            ) {
                const bookings = period ? bookingRequest?.map((item: any) => {
                    if (item?.booking_date === range?.booking_date) {
                        return {
                            ...item,
                            ...range,
                        };
                    }

                    return item;
                }) : bookingRequest

                $mapSettingBookingCreate(res?.data?.id, { bookings }).then();
            }

            dispatch(
                setShapeData({
                    ...watch(),
                    type: 'workplace',
                    isEdit: true,
                    isCreate: false,
                }),
            );
        });
    }

    function handleDelete() {
        $mapSettingWorkplaceDelete(building?.id, floor?.id, editData?.id).then(res => {
            dispatch(
                setShapeData({
                    id: editData?.id || res?.data?.id,
                    ...watch(),
                    type: 'workplace',
                    isRemove: true,
                    isEdit: false,
                    isCreate: false,
                }),
            );

            handleClosePopup();
        });
    }

    function handleClosePopup() {
        onClose();
        reset();
    }

    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                setIsOpen={handleClosePopup}
                widthProps={427}
                label={`${isEdit ? "Редактировать" : "Добавить"} рабочее место`}
                classNameHeader={"pb-10"}
            >
                <div className={"workplace__booking"}>
                    <div className={"workplace__booking__head"}>
                        <div className={"workplace__booking__address"}>
                            {`${city?.name || ""}, ${building?.name || ""}, ${floor?.number}-й этаж`}
                        </div>
                    </div>

                    <form
                        className={"workplace__booking__form d-flex flex-column gap-5"}
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => checkKeyDown(e)}
                    >
                        {request?.is_department && (
                            <div className={"workplace__booking__department"}>
                                {`Место закреплено за департаментом: `}

                                <span>{request?.department_title}</span>
                            </div>
                        )}

                        {(!request?.is_is_department) && (
                            <div className={"d-flex flex-column gap-5"}>
                                <Controller
                                    name={"number"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете номер"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            label="Номер места"
                                            placeholder={"Выберете номер"}
                                            options={numberList}
                                            value={getValue(numberList, value)}
                                            onChange={(select) => onChange(select.value)}
                                        />
                                    )}
                                />

                                <Controller
                                    name={"number_name"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Добавьте название"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            title="Название места"
                                            placeholder={"Добавьте название"}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="equipment"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <HashtagsField
                                            value={value}
                                            title="Периферия"
                                            name="equipment"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                {/*<Controller*/}
                                {/*    name="is_department"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: false,*/}
                                {/*            message: ""*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <ToggleBlock*/}
                                {/*            isChecked={!!value}*/}
                                {/*            className=""*/}
                                {/*            desc="Закрепить за подразделением"*/}
                                {/*            handleChange={(value) => onChange(value ? 1 : 0)}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}
                            </div>
                        )}

                        {isMine && (
                            <div className={"workplace__booking__btns"}>
                                <Button
                                    type={"submit"}
                                    text={isEdit ? "Сохранить" : "Добавить"}
                                    className={"btn btn-primary w-100"}
                                    disabled={!isValid}
                                />

                                {isEdit ? (
                                    <Button
                                        text={"Удалить"}
                                        className={"btn btn-red ml-0 w-100"}
                                        onClick={handleDelete}
                                    />
                                ) : (
                                    <Button
                                        text={"Отменить"}
                                        className={"btn btn-light ml-0 w-100"}
                                        onClick={handleClosePopup}
                                    />
                                )}
                            </div>
                        )}
                    </form>
                </div>
            </DialogContainer>
        </>
    );
};

export default WorkplaceCreateDialog;