import { FC } from "react";
import { IBaseIconProps } from "../types";

export const SliderFeedIcon: FC<IBaseIconProps> = (
    {
        size =  20,
        color = "black"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 21 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.2857 18.4V12.6C12.2857 12.4409 12.3489 12.2883 12.4614 12.1757C12.574 12.0632 12.7266 12 12.8857 12H18.6857C18.8448 12 18.9974 12.0632 19.11 12.1757C19.2225 12.2883 19.2857 12.4409 19.2857 12.6V18.4C19.2857 18.5591 19.2225 18.7117 19.11 18.8243C18.9974 18.9368 18.8448 19 18.6857 19H12.8857C12.7266 19 12.574 18.9368 12.4614 18.8243C12.3489 18.7117 12.2857 18.5591 12.2857 18.4ZM1.28571 18.4V12.6C1.28571 12.4409 1.34892 12.2883 1.46144 12.1757C1.57396 12.0632 1.72658 12 1.88571 12H7.68571C7.84484 12 7.99745 12.0632 8.10997 12.1757C8.22249 12.2883 8.28571 12.4409 8.28571 12.6V18.4C8.28571 18.5591 8.22249 18.7117 8.10997 18.8243C7.99745 18.9368 7.84484 19 7.68571 19H1.88571C1.72658 19 1.57396 18.9368 1.46144 18.8243C1.34892 18.7117 1.28571 18.5591 1.28571 18.4ZM12.2857 7.4V1.6C12.2857 1.44087 12.3489 1.28826 12.4614 1.17574C12.574 1.06321 12.7266 1 12.8857 1H18.6857C18.8448 1 18.9974 1.06321 19.11 1.17574C19.2225 1.28826 19.2857 1.44087 19.2857 1.6V7.4C19.2857 7.55913 19.2225 7.71174 19.11 7.82426C18.9974 7.93679 18.8448 8 18.6857 8H12.8857C12.7266 8 12.574 7.93679 12.4614 7.82426C12.3489 7.71174 12.2857 7.55913 12.2857 7.4ZM1.28571 7.4V1.6C1.28571 1.44087 1.34892 1.28826 1.46144 1.17574C1.57396 1.06321 1.72658 1 1.88571 1H7.68571C7.84484 1 7.99745 1.06321 8.10997 1.17574C8.22249 1.28826 8.28571 1.44087 8.28571 1.6V7.4C8.28571 7.55913 8.22249 7.71174 8.10997 7.82426C7.99745 7.93679 7.84484 8 7.68571 8H1.88571C1.72658 8 1.57396 7.93679 1.46144 7.82426C1.34892 7.71174 1.28571 7.55913 1.28571 7.4Z"
                stroke={'currentColor'} strokeWidth="2" />
        </svg>
    );
};