import { useNavigate } from "react-router-dom";
import { ArrowExitIcon, SearchIcon } from "../../../img";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import TextField from "../../../components/ui/form/Fields/TextField";
import StructureContent from "./StructureContent";
import { $getStructureList } from "../../../api/requests/structure";
import { setStructureCompany } from "../../../redux";
import { useDispatch } from "react-redux";
import { isMd } from "../../../utils";

interface DropdownItem {
    label: string;
    value: number | string;
}

const StructurePage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const dispatch = useDispatch();

    const [structureList, setStructureList] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);
    const [categorySelect, setCategorySelect] = useState<DropdownItem | null>(null);

    const [departmentId, setDepartmentId] = useState<any>(null);
    const [searchDepartment, setSearchDepartment] = useState<any[]>([]);
    const [isFiltered, setIsFiltered] = useState("");

    const [searchValue, setSearchValue] = useState<string>("");

    const handleNavigation = () => {
        navigate(-1);
    };

    useEffect(() => {
        init();
    }, []);

    function init() {
        $getStructureList().then(res => {
            if (!res) return;

            const getMain = res?.data?.[0];
            const dropdownList = [...res?.data]
                .filter(({ title }: any) => title.toLowerCase().includes(isFiltered.toLowerCase()))
                .map((item: any) => ({
                    ...item,
                    label: item.title,
                    value: item.department_id
                }))

            setDepartmentId(getMain?.department?.department_id || getMain?.department_id);

            setSearchDepartment(dropdownList);
            setCategorySelect(dropdownList[0]);

            dispatch(
                setStructureCompany({
                    structureMain: getMain
                })
            );
        });
    }

    function handleChangeCategoryDropdown(select: DropdownItem) {
        setCategorySelect(select);

        const filterList = [...structureList].filter(item => {
            const isStatus = categorySelect ? (item.status === categorySelect?.value) : true;

            if (select.value === "all") return isStatus;

            return (item.category_id === select.value); //&& isStatus;
        });

        setFilterList(filterList);
        setDepartmentId(select.value)

        dispatch(
            setStructureCompany({
                structureMain: searchDepartment.find((branch) => branch?.department_id === select.value),
            }),
        );
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        if (!value.length) return navigate(`/structure`)

        navigate(`/structure?search=${value}`)
    }

    return (
        <div className="favorites-news structure">
            <div className="container layout-inner__header profile-header structure__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Организационно-штатная структура</h1>
                    </div>
                )}

                <div className="layout-inner__header__selects structure__header">
                    <Dropdown
                        className="structure__header__dropdown"
                        placeholder={"Головная компания"}
                        options={searchDepartment}
                        value={categorySelect}
                        onChange={handleChangeCategoryDropdown}
                        isSearchable
                    />

                    <div className={"form-group contests__application__search"}>
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"#00000080"} />}
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <StructureContent
                    departmentId={departmentId}
                    setDepartmentVacation={() => {
                    }}
                    handleClosePopup={() => {
                    }}
                />
            </div>
        </div>
    );
};

export default StructurePage;