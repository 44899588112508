import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import {
    GetGroupsUsersResponseData,
    GroupsUsersRequestData, SetRoleGroupsUsersRequestData
} from "./interface";

export const $getGroupsUsersList = (
    data: GroupsUsersRequestData,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/groups/users", data, {
    handler
});

export const $setRoleGroupUsers = (
    data: SetRoleGroupsUsersRequestData,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/groups/setRole", data, {
    handler
});