import React, { useEffect, useState } from "react";
import { getYearMonthDay } from "../../../utils";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import {
    $getCitiesAvailable,
    $getWorkplaceBuildings,
} from "../../../api/requests/workplace";
import { useDispatch, useSelector } from "react-redux";
import {
    getSelected,
    setBuildings, setCurrentDate, setFloors, setPlan,
    setSelected
} from "../../../redux/reducers/workplace/workplaceSlice";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface ServicesWorkplaceModalRedirectProps {
    isOpen: boolean;
    onClose: () => void
}

interface DropdownItem {
    label: string;
    value: number | string;
}

interface FormData {
    city?: any;
    date?: string;
    building?: any;
    floor?: any;
}

const ServicesWorkplaceModalRedirect = (
    {
        isOpen,
        onClose
    }: ServicesWorkplaceModalRedirectProps
) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { city, building: buildingState, floor: floorState } = useSelector(getSelected);

    const [citiesDropdownData, setCitiesDropdownData] = useState<DropdownItem[]>([]);
    const [buildingsDropdownData, setBuildingsDropdownData] = useState<DropdownItem[]>([]);
    const [floorsDropdownData, setFloorsDropdownData] = useState<DropdownItem[]>([]);

    const {
        handleSubmit,
        control,
        formState: { isValid },
        setValue
    } = useForm<FormData>(
        {
            values: {
                date: moment().format("DD.MM.YYYY"),
                city,
                building: buildingState,
                floor: floorState,
            }
        }
    );

    const getDropdownItem = (data: any, value: any) => {
        return data?.map((item: any) => {
            return {
                ...item,
                value: item.id,
                label: value === "number" ? `Этаж ${item[value]}` : item[value]
            };
        });
    };

    useEffect(() => {
        $getCitiesAvailable({ newApi: true }).then((res) => {
            if (!res.data) return;

            setCitiesDropdownData(getDropdownItem(res?.data, "name"));
        });

        dispatch(setCurrentDate(moment().format("YYYY-MM-DD")));
    }, []);

    useEffect(() => {
        if (city) {
            $getWorkplaceBuildings(city?.value, null).then(res => {
                if (!res) return;

                setBuildingsDropdownData(getDropdownItem(res?.data, "name"));
                dispatch(setBuildings(res?.data));
            });
        }
    }, [city]);

    const handleSelect = (type: string, select: any) => {
        if (type === "city") {
            setValue("building", "");
            setValue("floor", "");

            dispatch(setSelected({ city: select, building: null, floor: null }));
        }

        if (type === "building") {
            setValue("floor", "");

            dispatch(setSelected({ building: select, floor: null }));
            dispatch(setFloors(select?.floors));

            setFloorsDropdownData(getDropdownItem(select?.floors, "number"));
        }

        if (type === "floor") {
            dispatch(setSelected({ floor: select }));

            if (select?.map) {
                dispatch(setPlan(select?.map?.filename_url));
            }
        }
    };

    function setDate(value: Date) {
        dispatch(setCurrentDate(moment(value).format("YYYY-MM-DD")));
    }

    function onSubmit(data: FormData) {
        navigate("workplace")
    }

    return (
        <div className="services-workplace">
            <DialogContainer
                isOpen={isOpen}
                setIsOpen={onClose}
                widthProps={427}
                label={"Выберите офис"}
                classNameHeader={"pb-10"}
            >
                <div className={"services-workplace-modal"}>
                    <p className={'text'}>Сперва выберите город в котором находится нужный вам офис</p>

                    <div className="services-workplace-modal-content">
                        <form className="" onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="city"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Все"}
                                        label={"Город"}
                                        options={[
                                            ...citiesDropdownData
                                        ]}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            handleSelect("city", e)
                                        }}
                                        isSearchable={true}
                                    />
                                )}
                            />

                            <Controller
                                name="building"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Все"}
                                        label={"Офис"}
                                        options={buildingsDropdownData}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            handleSelect("building", e);
                                        }}
                                        isSearchable={true}
                                    />
                                )}
                            />

                            <Controller
                                name="floor"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Все"}
                                        label={"Этаж"}
                                        options={floorsDropdownData}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            handleSelect("floor", e);
                                        }}
                                        isSearchable={true}
                                    />
                                )}
                            />

                            <Controller
                                name="date"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Выберете дату"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <DatePickerForm
                                        className=""
                                        label="Дата"
                                        placeholder={"Не выбрано"}
                                        onChange={(e) => {
                                            onChange(e)
                                            setDate(e);
                                        }}
                                        isIcon
                                        isRightIcon
                                        minDate={new Date()}
                                        value={value}
                                    />
                                )}
                            />

                            <Button
                                type={"submit"}
                                text={"Забронировать"}
                                className={"btn btn-primary w-100"}
                                disabled={!isValid}
                            />
                        </form>
                    </div>
                </div>
            </DialogContainer>
        </div>
    );
};

export default ServicesWorkplaceModalRedirect;