import React, { useMemo, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import ContestCreateEditPreview from "../ContestsCreateEdit/ContestCreateEditPreview";
import { CloseIcon } from "../../../img";
import TextField from "../../../components/ui/form/Fields/TextField";
import { Controller, useForm } from "react-hook-form";
import FileClipField from "../../../components/ui/form/FileField/FileClipField";
import { useLocation, useNavigate } from "react-router-dom";
import ContestsViewWinner from "./ContestsViewWinner";
import { useSelector } from "react-redux";
import { BackIcon } from "../../../img/icons/form/BackIcon";
import {
    $downloadExelContests,
    $editContest,
    $supplyApplication
} from "../../../api/requests/contest";
import { failureNotify, successNotify } from "../../../utils";
import $ from "jquery";
import { getCurrentRole, getPersonData } from "../../../redux";
import downloadExel from "../../../utils/file/download-exel";
import { CheckboxIcon } from "../../../img/icons/CheckboxIcon";

interface FormDataS {
    comment: string;
    files?: any;
}
interface RequestData {
    text: string;
    attachments?: any;
}

interface ContestsPageViewProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
    catsList?: any;
    onSuccess?: (id?: any) => void;
}

let busy = false;
let tmpHtml = '';
const ContestsPageView = (
    {
        data,
        isOpen,
        onClose,
        catsList,
        onSuccess
    }: ContestsPageViewProps
) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentRole = useSelector(getCurrentRole);
    const personalData = useSelector(getPersonData);
    const isError = data?.error;

    const searchParams = new URLSearchParams(location.search);
    const contest_id = searchParams.get("view");

    const isAdmin = currentRole === 1 || personalData?.admin_module?.find((key: string) => key === "contests");
    const dataUpdate = useMemo(() => ({
        ...data,
        name: data?.title,
        date_end: data?.date_end,
        category_id: {
            label: data?.category_title
        },
        points_win: data?.points,
        points_participation: data?.awards,
        peoples: data?.peoples,
    }), [data]);

    const [statusSelectItem, setStatusSelectItem] = useState("start");
    const [uploadFileList, setUploadFileList] = useState<any[]>([]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid }
    } = useForm<FormDataS>({
        values: {
            comment: ''
        }
    });

    function handleOpenSupply() {
        setStatusSelectItem("supply");
    }

    function handleOpenStart() {
        setStatusSelectItem("start");
    }

    function handleOpenPeople() {
        navigate("application?view=" + contest_id);
    }

    function onSubmit(data: any) {
        // setStatusSelectItem("start");

        $supplyApplication({
            id: contest_id,
            text: data.comment,
            attachments: data?.files?.map((item: any) => item.file)
        }, { formData: true }).then(res => {
            if (!res || res.error) {
                return;
            }

            if (typeof res[0] != 'undefined') {
                res.id = res[0].id
            }

            successNotify("Успешно");
            reset()
            setUploadFileList([]);
            onSuccess && onSuccess(res?.id);
        }).catch(() => {
            console.log('apiError');
            failureNotify('Ошибка, попробуй ещё раз.');
        })
    }

    function handleUploadList() {
        if (uploadFileList) {
            uploadFileList.forEach((item: any) => {
                if (item.file) {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        if (reader.result && typeof reader.result === "string") {
                            setUploadFileList((prev: any[]) => {
                                if (prev) return [...prev, { name: item.file.name, file: reader.result }];

                                return [{ file: reader.result }];
                            });
                        }
                    };

                    reader.readAsDataURL(item.file);
                }
            });
        }
    }

    function handleCloseModal() {
        onClose();
        setStatusSelectItem("start");
    }

    function handleUploadTable() {
        $downloadExelContests(dataUpdate.id).then(res => {
            if (res.response) return;

            downloadExel(res, `${dataUpdate.title}.xlsx`);
        })
    }

    if (isError) {
        return <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"contests__view"}>
                <div className={"contests__view__close"} onClick={handleCloseModal}>
                    <CloseIcon color="black" />
                </div>
                <div className="contests__create__preview-item">
                    <div className="contests__create__preview-item__header">
                        <div className={"d-flex align-items-center gap-3 text-center"}>
                            <h3>Конкурс не найден</h3>
                        </div>
                    </div>
                </div>
            </div>
        </DialogContainer>
    }

    const now = Date.now() / 1000;
    let statusFix = dataUpdate.status == '0' || parseInt(dataUpdate.date) > now ? '0' : parseInt(dataUpdate.date_end) > now ? '1' : '2';
    let isAnswered = dataUpdate.comments_array?.find((i: any) => i.user_id == personalData?.id)
    const commentsData = dataUpdate?.comments_array || null;
    const usersWinList: any = commentsData && [...commentsData].filter(item => item.winner == '1')
    // const usersOtherList: any = commentsData && [...commentsData].filter(item => item.winner == "0")

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={false}
            classNameModal={"p-0 contests__view"}
            widthProps={427}
        >
            {/*<div className={`contests__view__header`}>*/}
            {/*    <div className={"contests__view__header-block"}>*/}
            {/*        <p>{dataUpdate.title}</p>*/}

            {/*        <button*/}
            {/*            onClick={handleCloseModal}*/}
            {/*            className={"contests__view__header-block-close"}*/}
            {/*        >*/}
            {/*            <CloseIcon color={"black"} opacity={"1"} />*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={"contests__view__items"}>
                {statusSelectItem === "start" && (
                    <>
                        <ContestCreateEditPreview
                            data={dataUpdate}
                            handleSubmit={handleOpenSupply}
                            isHideBtn={isAdmin}
                            catsList={catsList}
                            closeModal={(
                                <div className={"contests__view__close"} onClick={handleCloseModal}>
                                    <CloseIcon />
                                    {/* <CloseIcon color={statusSelectItem === "winner" ? "black" : "white"} /> */}
                                </div>
                            )}
                        />

                        {usersWinList && usersWinList.length != 0 && (
                            <div className={"contests__completed__content"}>
                                <h4>Победитель</h4>

                                <div className={"contests__completed__list"}>

                                    <div className={"contests__completed__list__win"}>
                                        {usersWinList.map((item: any, idx: number) => (
                                            <div key={idx} className="layout-vacation-modal__block">
                                                <div
                                                    className="layout-vacation-modal__block__right">
                                                    <img src={item.photo} alt="" />

                                                    <div
                                                        className="layout-vacation-modal__block__desc">
                                                        <h1>{item.name}</h1>
                                                        <p className="layout-vacation-modal__block__desc__position">{item.position}</p>
                                                        <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                                                    </div>
                                                </div>
                                                {item.reason && (
                                                    <div className={"contests__completed__reason"}>
                                                        <h5>Причина победы</h5>
                                                        <p>{item.reason}</p>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}


                        {(statusFix != '2' && !isAnswered) && (
                            <div className="contests__view__btn__application">
                                <Button
                                    text={"Подать заявку"}
                                    className={"btn btn-primary w-100"}
                                    onClick={handleOpenSupply}
                                />
                            </div>
                        )}

                        {(statusFix != '2' && isAnswered) && (
                            <div className="contests__view__btn__application">
                                <Button
                                    leftIcon={<CheckboxIcon color={"black"}/>}
                                    text={"Заявка подана"}
                                    className={"btn btn-light w-100"}
                                    disabled={true}
                                />
                            </div>
                        )}

                        {/* {isAdmin && (
                            <div className={"contests__view__btn__application"}>
                                <Button
                                    text={"Заявки"}
                                    rightIcon={<span className={"contests__view__btn__count"}>6</span>}
                                    className={"btn btn-primary w-100"}
                                    onClick={handleOpenWinner}
                                />
                            </div>
                        )} */}

                        <div className={"contests__view__btns"} style={{
                            borderTop: "0.0625rem solid rgba(0, 0, 0, 0.1019607843)",
                            paddingTop: "1rem"
                        }}>
                            {isAdmin && (
                                <Button
                                    text={"Редактировать"}
                                    className={"btn btn-light w-100"}
                                    onClick={() => dataUpdate.id && navigate(`edit?contest_id=${dataUpdate.id}`)}
                                />
                            )}

                            <Button
                                text={isAdmin ? "Заявки" : "Участники"}
                                className={`btn btn-${isAdmin ? "primary" : "light"} w-100`}
                                onClick={handleOpenPeople}
                                rightIcon={isAdmin ? <span
                                    className={"contests__view__btn__count"}>{dataUpdate?.comments_array?.length}</span> : ''}
                            />
                        </div>

                        {isAdmin && statusFix == '2' && (
                            <div className={"contests__view__btn__application"}>
                                <Button
                                    text={"Выгрузить таблицу участников"}
                                    className={"btn btn-primary w-100"}
                                    onClick={handleUploadTable}
                                />
                            </div>
                        )}
                    </>
                )}

                {statusSelectItem === "supply" && (<>
                    <div className={"contests__view__close"}
                         style={{ left: '15px', top: '1rem', width: "25px" }}
                         onClick={handleOpenStart}>
                        <BackIcon color={"black"} />
                    </div>
                    <div className="contests__create__preview-items">
                        <div className="contests__create__preview-img">
                            <img src={dataUpdate.image} />
                        </div>

                        <div className="contests__create__preview-item contests__view__block">
                            <h4>Подача заявки</h4>

                            <form id="supplyForm" onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="comment"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите ответ"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            name="comment"
                                            value={value}
                                            className=""
                                            title="Ваш ответ"
                                            placeholder={"Введите ответ"}
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                {!!+data?.allow_attach && (
                                    <Controller
                                        name="files"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: "Прикрепите файл"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <FileClipField
                                                accept={".pdf, .doc, .jpg, .jpeg, .png, .gif"}
                                                label={"Прикрепите файл (Не обязательно)"}
                                                upload={value}
                                                setUpload={(select) => onChange(select(value))}
                                                // isSingleDoc
                                            />
                                        )}
                                    />
                                )}

                                <Button
                                    disabled={!isValid}
                                    type={"submit"}
                                    text={"Отправить"}
                                    className={"btn btn-primary w-100 supplySubmit"}
                                />
                            </form>
                        </div>
                    </div>
                </>)}

            </div>
        </DialogContainer>
    );
};

export default ContestsPageView;