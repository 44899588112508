import { useNavigate } from 'react-router-dom'
import { isMd } from '../../../utils'
import React, { useEffect, useState } from 'react'
import { $getUserFavoritesAll, $toggleUserFavorites } from '../../../api/requests/favorites'
import { ArrowExitIcon, StarIcon } from '../../../img'
import { BlankImage } from '../../../components/ui/blank-image/BlankImage'

export const FavoritesBirthdays = () => {
    const navigate = useNavigate()
    const isMobile = isMd()

    const [isLoading, setIsLoading] = useState(false)
    const [birthdaysData, setBirthdaysData] = useState<any>([])

    const handleNavigation = () => {
        navigate(-1)
    }

    const handleLike = (id: number, e: any) => {
        e.stopPropagation()
        setIsLoading(true)

        $toggleUserFavorites({ type: 'birthday', type_id: id, add_to_favorites: false }, { newApi: true }).then(res => {
            setIsLoading(false)
        })
    }


    useEffect(() => {
        $getUserFavoritesAll('birthday', { newApi: true }).then(res => {
            setBirthdaysData(res.data)
        })
    }, [isLoading])

    return (
        <div className="container">
            <div className="favorites-employees">
                <div className="layout-inner__header profile-header">
                    {!isMobile && (
                        <div className="layout-inner__header__title">
                            <div className="header__exitIcon" onClick={handleNavigation}>
                                <ArrowExitIcon />
                            </div>
                            <h1>Дни рождения</h1>
                        </div>
                    )}
                </div>

                <div className="favorites-employees-content">
                    {birthdaysData.map((
                        item: any,
                        idx: number,
                    ) => (
                        <div className="employees-block" key={idx}>
                            <div className="employees-block__top">
                                <BlankImage image={item.cover} classname="employees-block__image" />

                                <div
                                    onClick={(e) => {
                                        handleLike(item.id, e)
                                    }}
                                    className={`employees-block__likeIcon active`}
                                >
                                    <StarIcon opacity={1} />
                                </div>
                            </div>

                            <div className="employees-block__content" onClick={() => {
                                // setDialog(true)
                                // setActivePerson({...item})
                            }}>
                                <h1 className="employees-block__title">{item?.title}</h1>

                                {/*<p className='employees-block__text'>{ item?.text }</p>*/}

                                {/*<p className='employees-block__position'>{ item?.position }</p>*/}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}