import { AcceptedIcon, ArrowExitIcon, RejectedIcon } from "../../../../img";
import React, { useEffect, useState } from "react";
import { isMd } from "../../../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { $uploadServiceApplicationFile } from "../../../../api/requests/services";
import DatePickerForm from "../../../../components/ui/form/DatePickerForm";
import { DownloadIcon } from "../../../../img/icons/DownloadIcon";
import FileClipField from "../../../../components/ui/form/FileField/FileClipField";
import { UploadIcon } from "../../../../img/icons/files/UploadIcon";
import Button from "../../../../components/ui/form/Buttons/Button";
import Dropdown from "../../../../components/ui/form/Dropdown/Dropdown";
import { $getMedicalLeaveDiapason } from "../../../../api/requests/services/medical-leave";
import CompleteModal from "../../../../components/ui/dialog/CompleteModal";
import TextField from "../../../../components/ui/form/Fields/TextField";
import {
    $createServiceTrip,
    $getServiceTripItem,
    $getServiceTripsTypes
} from "../../../../api/requests/services/trip";
import moment from "moment";
import { BlankAva } from "../../../../components/ui/avatars/BlankAva";

export const ServicesTripCreate = () => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const location = useLocation();
    const pathname = location.pathname;

    const { id: tripId } = useParams();
    const isView: boolean = pathname.includes("view");


    const [uploadFileList, setUploadFileList] = useState<any>(undefined);

    const [isAlreadySent, setIsAlreadySend] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [typesList, setTypesList] = useState([]);

    const [tripData, setTripData] = useState<any>({});

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors, isValid },
        trigger
    } = useForm<any>();

    const btnListRemove = [
        {
            text: "Отлично",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    const getFileName = (url: string) => {
        const parts: any = url?.split("/");
        return parts[parts?.length - 1];
    };

    useEffect(() => {
        $getServiceTripsTypes({ newApi: true }).then(res => {
            if (!res.data) return;

            const transformedArray: any = Object.entries(res.data).map(([key, label], idx) => ({
                key,
                label,
                value: idx
            }));

            setTypesList(transformedArray);
        });
    }, []);

    useEffect(() => {
        if (tripId) {
            $getServiceTripItem(+tripId, { newApi: true }).then(res => {
                if (res.data) {
                    setIsAlreadySend(true);
                    setTripData(res?.data);
                } else {
                    setIsAlreadySend(false);
                }
            });
        }
    }, [isView]);

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === "string") {
                        $uploadServiceApplicationFile({ file: item.file }, {
                            newApi: true,
                            formData: true
                        }).then(res => {
                            setUploadFileList({ file: reader.result });
                            setValue("document_file", res.data.url);
                        });
                    }
                };

                reader.readAsDataURL(item.file);
            }
        });
    }

    function onSubmit(obj: any) {
        const updatedList = {
            ...obj,
            start_date: moment(obj.start_date || new Date()).format("YYYY-MM-DD"),
            date_to: moment(obj.end_date || new Date()).format("YYYY-MM-DD"),
            type: obj.type.key
        };

        $createServiceTrip(updatedList, { newApi: true }).then(res => {
            if (!res.data) return;
            setIsModalOpen(true);
        });
    }

    return (
        <>
            <CompleteModal
                icon={"😊"}
                title={"Успешно"}
                text={"Вы успешно отправили заявку на командировку. Ожидайте уведомление о результате"}
                isOpen={isModalOpen}
                setIsOpen={() => navigate("/services/trip/list?activeType=1")}
                btnList={btnListRemove}
                onClick={() => navigate("/services/trip/list?activeType=1")}
            />

            <div className="layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="container layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate("/services/trip/list")}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Заявление на командировку</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <div className="services-applications-create__block__left">
                        <h5 className="services-applications-create__title">Заявление</h5>

                        {tripData?.status && (
                            <div
                                className={`services-applications-create__status ${tripData?.status === "Рассмотрение" ? "waiting" : (tripData?.status === "Одобрено" ? "accepted" : "rejected")}`}
                            >
                                {tripData?.status}
                            </div>
                        )}
                    </div>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit((obj) => onSubmit(obj))}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <Controller
                                name="type"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: "Выберете дату"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        disabled={isAlreadySent}
                                        className={"form-select"}
                                        placeholder={"Тип командировки"}
                                        label={"Тип сервиса"}
                                        options={typesList}
                                        value={value || typesList.find((item: any) => item.label === tripData?.type)}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <div className="services-applications-create__dates">
                                <Controller
                                    name={"start_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            disabled={isAlreadySent}
                                            startDate={value || tripData.start_date}
                                            minDate={new Date()}
                                            maxDate={watch().end_date || tripData?.start_date}
                                            className=""
                                            label="Дата начала"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                                <Controller
                                    name={"end_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            disabled={isAlreadySent}
                                            startDate={value || tripData.end_date}
                                            className=""
                                            minDate={watch().start_date}
                                            label="Дата завершения"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                            </div>

                            <Controller
                                name={"reason"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Напишите название"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        disabled={isAlreadySent}
                                        value={value || tripData?.reason}
                                        className=""
                                        title={"Основание"}
                                        placeholder={"Введите причину "}
                                        name="name"
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <div
                                className={`services-applications-create__file ${isAlreadySent ? "isLocked" : ""}`}>
                                <div className="services-applications-create__file__header">
                                    <p className="services-applications-create__file__header__label">Заявление</p>

                                    {/*<div className='services-applications-create__file__header__right'>*/}
                                    {/*    <p  className='services-applications-create__file__header__text'>Скачать заявку</p>*/}

                                    {/*    <DownloadIcon size={18}/>*/}
                                    {/*</div>*/}
                                </div>

                                <Controller
                                    name="document_file"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileClipField
                                            accept={".docx, .png, .jpg, .pdf, .xlsx"}
                                            label={""}
                                            upload={uploadFileList}
                                            setUpload={handleUploadList}
                                            isLockFile={uploadFileList || isAlreadySent}
                                            uploadName={getFileName(uploadFileList?.file || tripData?.document_file || "")}
                                            text={"Загрузите заполненную заявку"}
                                            icon={<UploadIcon size={44} />}
                                            isSingleDoc
                                        />
                                    )}
                                />
                            </div>

                            {tripData?.approvers?.length > 0 && (
                                <div className="services-applications-create__file">
                                    <div className="services-applications-create__file__header">
                                        <p className="services-applications-create__file__header__label">Согласующие</p>
                                    </div>

                                    <div className="services-applications-create__approvers">
                                        {tripData?.approvers?.map((user: any) => (
                                            <div className="layout-vacation-modal__block"
                                                 style={{
                                                     background: "#00000008",
                                                     padding: "10px",
                                                     borderRadius: 16,
                                                     position: "relative"
                                                 }}
                                            >
                                                <div className="layout-vacation-modal__block__right">
                                                    {user?.photo ? (
                                                        <img src={user?.photo} alt="" />
                                                    ) : (
                                                        <BlankAva
                                                            colorBg={"#CCD9E0"}
                                                            colorText={"black"}
                                                            text={user?.name}
                                                        />
                                                    )}

                                                    <div className="layout-vacation-modal__block__desc">
                                                        <h1>{user?.name}</h1>

                                                        <p className="layout-vacation-modal__block__desc__position">{user?.position || "Не показано"}</p>

                                                        <p className="layout-vacation-modal__block__desc__department">{moment(user?.date).format("DD.MM.YYYY HH:mm")}</p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="services-applications-create__approvers__status">
                                                    {user.status === "approved" && (
                                                        <AcceptedIcon />
                                                    )}

                                                    {user.status === "rejected" && (
                                                        <RejectedIcon />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {!isView && (
                                <Button
                                    disabled={
                                        !watch().start_date ||
                                        !watch().end_date ||
                                        !watch().type ||
                                        !watch().document_file ||
                                        !watch().reason
                                    }
                                    type={"submit"}
                                    text={"Отправить"}
                                    className={"btn btn-primary w-100"}
                                />
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};