import moment from "moment";

import Button from "../../../components/ui/form/Buttons/Button";

import noImageBanner from "../../../img/noimage.png";
import { CoinCompetitionIcon, EyeIcon } from "../../../img";
import { ShareIcon } from "../../../img/icons/ShareIcon";
import { ThreePeopleBorderIcon } from "../../../img/icons/employees/ThreePeopleBorderIcon";
import { ClockIcon } from "../../../img/icons/ClockIcon";
import { CreateContestFormData } from "./index";
import { successNotify } from "../../../utils";
import { ReactNode, useEffect, useState } from "react";


interface ContestCreateEditPreviewProps {
    data: CreateContestFormData;
    handleSubmit?: () => void;
    isHideBtn?: boolean;
    catsList?: any;
    closeModal?: ReactNode;
}

const ContestCreateEditPreview = (
    {
        data,
        handleSubmit,
        isHideBtn,
        catsList,
        closeModal
    }: ContestCreateEditPreviewProps
) => {
    const statusList = [
        {
            key: "1",
            value: "Активный"
        },
        {
            key: "2",
            value: "Завершенный"
        },
        {
            key: "3",
            value: "Принимаю участие"
        },
        {
            key: "0",
            value: "Скрытый"
        }
    ];
    
    const now = Date.now() / 1000;
    let statusFix = data.status == '0' || parseInt(data.date) > now ? '0' : parseInt(data.date_end) > now ? '1' : '2';
    const statusFind = statusList.find(item => item.key == statusFix);
    const catFind = catsList?.find((o:any) => o.value == data.category_id)?.label || 'Все'
    
    let dt_to = "..."
    if(data.date_end){
        if(typeof data.date_end == 'number'){
            dt_to = moment.unix(data.date_end).format('DD.MM.yyyy')
        }
        if(typeof data.date_end == 'object'){
            dt_to = moment(data.date_end).format('DD.MM.yyyy')
        }
        if(typeof data.date_end == 'string'){
            dt_to = data.date_end;
        }
    }
    
    const handleCopy = () => {
        const url = window.location.href; // Get the current page URL
        navigator.clipboard.writeText(url)
        .then(() => {
            successNotify('Ссылка скопирована')
        })
        .catch((err) => {
        });
    };

    const [upload, setUpload] = useState("");

    const handleImageUpload = (file: File): void => {
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === 'string') {
                    setUpload(reader.result)
                }
            }

            reader.readAsDataURL(file)
        }
    }

    useEffect(() => {
        if (typeof data.image === "string") {
            setUpload(data.image)
        } else {
            handleImageUpload(data.image as File)
        }
    }, [data.image]);

    return (
        <div className="contests__create__preview-items">
            <div className="contests__create__preview-img feed-news-card__img">
                <img src={upload || noImageBanner} />

                {statusFind?.value && (
                    <div className={`feed-news-card__active ${statusFind?.key}`}>
                        <div className={`${statusFind?.key}`}></div>

                        <p>{statusFind?.value || "Неопределен"}</p>
                    </div>
                )}

                {closeModal && closeModal}
            </div>

            <div className="contests__create__preview-item">
                <div className="contests__create__preview-item__header">
                    <div className={"d-flex align-items-center gap-3"}>
                        <h3>{data.title || "Название"}</h3>

                        <Button
                            text={<ShareIcon />}
                            className={"btn btn-icon"}
                            onClick={handleCopy}
                        />
                    </div>

                    {(data.category_id || data.category_id == 0) && (
                        <div
                            className="contests__create__preview-item__header-category"
                        >
                            <div
                                className={"contests__create__preview-item__header-category-item"}>
                                {data.category_id?.label || catFind}
                            </div>
                        </div>
                    )}
                </div>

                <div className="contests__create__preview-item__content">
                    <p className={"contests__create__preview-item__content__text"}>{data.text}</p>

                    {(!!data.points || !!data.awards) && (
                        <div
                            className="contests__create__preview-item__awards">
                            <h4>Награды</h4>

                            <div className="d-flex gap-3">
                                <div className="w-50 block">
                                    <p className={"title"}>За участие</p>

                                    <div className={"d-flex align-items-center gap-3"}>
                                        <CoinCompetitionIcon />

                                        <p>{data.awards || 0}</p>
                                    </div>
                                </div>

                                <div className="w-50 block">
                                    <p className={"title"}>За победу</p>

                                    <div className={"d-flex align-items-center gap-3"}>
                                        <CoinCompetitionIcon />

                                        <p>{data.points || 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="contests__create__preview-item__footer">
                        <div className={"date"}>
                            <ClockIcon />

                            <p>До {dt_to}</p>
                        </div>

                        <div className={"view"}>
                            <div className={"d-flex"}>
                                <EyeIcon />

                                <p>{data.views || 0}</p>
                            </div>

                            <div className={"d-flex"}>
                                <ThreePeopleBorderIcon />

                                <p>{data?.comments_count || 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContestCreateEditPreview;