import { FC, useEffect, useState } from "react";
import { ArrowRightIcon } from "../../../img";
import { FeedGroupsSlider } from "./FeedGroupsSlider";
import { isMd } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMainPageData } from "../../../redux";
import { $showGroupById } from "../../../api/requests/groups";

interface GroupData {
    id: string;
    title: string;
    image: string;
    status: string;
    role: string;
    content_image: string;
    content_date: string;
}

export const FeedGroupsContent: FC = ( ) => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const mainData = useSelector(getMainPageData);

    const [groupsContent, setGroupsContent] = useState<any[]>([]);

    const changeList = (list: GroupData[]) => {
        return Object.values(
            list.reduce<Record<string, { id: string, list: GroupData[] }>>((acc, item) => {
                if (!acc[item.id]) {
                    acc[item.id] = { id: item.id, list: [] };
                }
                acc[item.id].list.push(item);
                return acc;
            }, {})
        )
    }

    useEffect(() => {
        mainData?.groups && init();
    }, [])

    function init() {
        setGroupsContent(changeList(mainData?.groups))
    }

    if (!groupsContent.length) return null;

    return (
        <div className='feed-groups'>
            <div className='feed-controls feed-groups__controls'>
                <h3>Группы</h3>

                <div className="layout-right-block__header__link" onClick={() => navigate("/groups")}>
                    <h4>Все группы</h4>
                    <ArrowRightIcon color='#000'/>
                </div>
            </div>

            <div className='feed-groups__container'>
                {groupsContent.slice(0, 3).map((item, idx) => (
                    <div
                        key={`groups-item-${idx}`}
                        onClick={() => {
                            $showGroupById({ id: item.id }, { formData: true }).then(res => {
                                if (!res) return;

                                navigate(`/?group_id=${item.id}`);
                            })
                        }}
                    >
                        <FeedGroupsSlider
                            slidersNumber={item.list.length}
                            // slidersNumber={filteredBanners.length + partners?.length}
                            slides={[...item.list]}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}