import {IApiHandlerParams} from "../../../interface";
import api from "../../../index";
import {IServiceVacationReq} from "../vacation/interface";

export const $getServicesSubstituteUsers = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/staffreplace/getUsers`, {
        handler
    });

export const $getAllSubstituteUsers = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/staffreplace/getAllReplaces`, {
        handler
    });

export const $getServicesSubstituteReplacement = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/staffreplace/`, {
        handler
    });

export const $getStaffReplacementById = (
    params: { id: string },
    handler?: IApiHandlerParams
): Promise<any> => api
    .get("api/v1/staffreplace", {
        // mock: require('./mocks/staff.replace.id.json'),
        request: { params },
        handler: {
            newApi: true,
            ...handler
        }
    });

export const $createServiceReplacer = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .post("api/v1/staffreplace/replace", data, {
        handler
    });