import { API_URL } from "../../../../../api/config";

const escapeHtml = require('escape-html');

function generateBlockHtml(block: any) {
	let html = '';

	switch (block.type) {
		case 'paragraph':
			const alignStyle = block.tunes?.anyTuneName ? `style="text-align: ${block.tunes.anyTuneName.alignment}"` : '';
			html += `<p ${alignStyle}>${block.data.text}</p>`;
			break;
		case 'header':
			html += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
			break;
		case 'list':
			const tag = block.data.style === 'ordered' ? 'ol' : 'ul';
			let itemsHtml = '';
			block.data.items.forEach((item: any) => {
				const itemText = item.content.replace(/<br>/g, "\n");
				itemsHtml += `<li>${itemText}</li>`;
			});
			html += `<${tag}>${itemsHtml}</${tag}>`;
			break;
		case 'collapse':
			const title = block.data.title || '';
			const content = block.data.content || '';

			return `
        <details>
          <summary>${title}</summary>
          <p>${content}</p>
        </details>
          `;
		case 'ckeditor':
			const itemText = block.data.text.replace(/contenteditable="true"/g, "");

			html += `${itemText}`;
			break;
		case 'table':
			const hasHeading = !!block.data.withHeadings;
			let tableHtml = '<div class="table-container"><table>';
			// добавляем заголовок таблицы, если есть
			if (hasHeading && block.data.content.length > 0) {
				tableHtml += '<thead><tr>';
				block.data.content[0].forEach((header: string) => {
					tableHtml += `<th>${header}</th>`;
				});
				tableHtml += '</tr></thead>';
			}
			// добавляем строки таблицы
			tableHtml += '<tbody>';
			for (let i = 1; i < block.data.content.length; i++) {
				tableHtml += '<tr>';
				block.data.content[i].forEach((cell: string) => {
					tableHtml += `<td>${cell}</td>`;
				});
				tableHtml += '</tr>';
			}
			tableHtml += '</tbody></table></div>';
			html += tableHtml;
			break;
		case 'image':
			const stretchedClass = block.data.stretched ? " stretched" : "";
			const withBackgroundClass = block.data.withBackground ? " with-background" : "";
			const withBorderClass = block.data.withBorder ? " with-border" : "";
			const bigClass = block.data.big ? " big" : "";
			const mediumClass = block.data.medium ? " medium" : "";
			const smallClass = block.data.small ? " small" : "";
			const imageClass = `image${stretchedClass}${withBackgroundClass}${withBorderClass}${bigClass}${mediumClass}${smallClass}`;

			html += `
			<a href="${escapeHtml(block.data.file.url)}" target="_blank" data-fancybox="gallery" data-caption="${block.data.caption || ''}">
				<img src="${escapeHtml(block.data.file.url)}" alt="${escapeHtml(block.data.caption)}" class="${imageClass}">
			</a>
			`;
			break;
		case 'quote':
			html += `<blockquote>${block.data.text}</blockquote>  ${block.data.caption}`;
			break;
		case 'embed':
			switch (block.data.service) {
				case "vimeo":
					html +=  `<iframe src="${block.data.embed}" height="${block.data.height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
					break;
				case "youtube":
					html +=  `<iframe width="${block.data.width}" height="${block.data.height}" src="${block.data.embed}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
					break;
			}
			break;
		case 'attaches':
			const index = block.data.file.title.indexOf('.');
			let result = block.data.file.title;
			if (index !== -1) {
				result = block.data.file.title.substring(index + 1);
			}
			html += `
			<div class="cdx-attaches cdx-attaches--with-file">
				<div class="cdx-attaches__file-icon">
					<p>${result}</p>
				</div>
				<div class="cdx-attaches__file-info">
					<div class="cdx-attaches__title">${block.data.title || block.data.file.title}</div>
					<div class="cdx-attaches__size" data-size="KiB">${(block.data.file.size / 1024).toFixed(1)}</div>
				</div>
				<a class="cdx-attaches__download-button" href="${block.data.file.url}" target="_blank"></a>
			</div>`
			break;
		case 'warning':
			html += `<div class="warning">${escapeHtml(block.data.title)} ${escapeHtml(block.data.message)}</div>`;
			break;
		case 'checklist':
			let checklistHtml = '<ul>';
			block.data.items.forEach((item: any) => {
				const checked = item.checked ? 'checked' : '';
				checklistHtml += `<li><input type="checkbox" ${checked} disabled>${item.text}</li>`;
			});
			checklistHtml += '</ul>';
			html += checklistHtml;
			break;
		case 'gallery':
			const galleryConfig = block.data.config || "standard";
			const countItemEachRow = block.data.countItemEachRow || block?.data?.files?.length;

			let galleryHtml = '<div class="gallery">';
			block?.data?.files?.forEach((item: any, index: number) => {
				const isFirstInRow = index % countItemEachRow === 0;
				const isLastInRow = (index + 1) % countItemEachRow === 0 || (index + 1) === block?.data?.files?.length;

				const galleryItemHtml = `
					<a href="${item.url}" target="_blank" data-fancybox="gallery" data-caption="${item.title || ''}">
						<img src="${item.url}" alt="${item.title || ''}" class="gallery-item">
					</a>
				`;

				if (isFirstInRow) {
					galleryHtml += '<div class="gallery-row row">';
				}

				galleryHtml += `<div class="gallery-col col">${galleryItemHtml}</div>`;

				if (isLastInRow) {
					galleryHtml += '</div>';
				}
			});
			galleryHtml += '</div>';

			html += galleryHtml;
			html += `<span>${block?.data?.caption || ''}</span>`;
			break;
		case 'columns':
			const columnHtml = block.data.cols.reduce((acc: any, col: any) => {
				const colHtml = col.blocks.reduce((colAcc: any, colBlock: any) => {
					switch (colBlock.type) {
						case 'paragraph':
							const alignStyle = colBlock.data.tunes?.anyTuneName ? `style="text-align: ${colBlock.data.tunes.anyTuneName.alignment}"` : '';
							return `${colAcc}<p ${alignStyle}>${colBlock.data.text}</p>`;
						case 'header':
							return `${colAcc}<h${colBlock.data.level}>${colBlock.data.text}</h${colBlock.data.level}>`;
						default:
							console.warn(`Unknown block type: ${colBlock.type}`);
							return colAcc;
					}
				}, '');
				return `${acc}
						<div class="columns__block ce-editorjsColumns_col">
							<div class="ce-block__content">${colHtml}</div>
						</div>
					`;
			}, '');
			html += `<div class="columns ce-editorjsColumns_wrapper">${columnHtml}</div>`;
			break;
		case 'layout':
		case 'twoColumns':
		case 'threeColumns':
		case 'fourColumns':
			const layoutContent = block.data.itemContent;

			const layoutHtml = Object.keys(layoutContent)
				.map((key) => {
					const colHtml = EditorJsToHtml({ blocks: layoutContent[key].blocks });

					return `
						<div class="columns__block ce-editorjsColumns_col">
							<div class="ce-block__content">${colHtml}</div>
						</div>
					`;
				})
				.join("");

			const columnClass = `columns-${Object.keys(layoutContent).length}`;
			html += `<div class="columns ${columnClass} ce-editorjsColumns_wrapper">${layoutHtml}</div>`;
			break;
		default:
			console.warn(`Неизвестный тип блока: ${block.type}`);
	}

	return html;
}

export function EditorJsToHtml(data: any) {
	let html = '';

	const isToggleItems = data?.blocks?.findIndex((i: any) => i.type === 'toggleBlock');

	data?.blocks?.forEach((block: any) => {
		if (block.type === 'toggleBlock') {
			const statusClass = block.data.status === 'open' ? 'is-opened' : 'is-closed';

			html += `
				<div class="toggle-block ${statusClass}">
					<div class="toggle-block__title">${escapeHtml(block.data.text)}</div>`;

			if (!!block?.data?.items) {
				html += '<div class="toggle-block__content">';
				data?.blocks?.forEach((nestedBlock: any, idx: number) => {
					if ((nestedBlock.type === 'toggleBlock') || (isToggleItems && (isToggleItems >= idx))) return;

					html += generateBlockHtml(nestedBlock);
				});
				html += '</div>';
			}
			html += '</div>';
		} else {
			if (isToggleItems !== undefined) {
				const count = data?.blocks[isToggleItems]?.data?.items;

				for (let i = 1; i <= count; i++) {
					if (!!count && (block?.id === data?.blocks[isToggleItems + i]?.id)) return;
				}
			}

			html += generateBlockHtml(block);
		}
	});

	return html;
}

export class CKEditorTool {
	private data: any;
	private config: any;
	private api: any;
	private element: any;
	private editor: any;

	static get isInline() {
		return false;
	}

	constructor({ data, config, api }: any) {
		this.data = data;
		this.config = config;
		this.api = api;
		this.element = null;
		this.editor = null;
	}

	static get toolbox() {
		return {
			title: 'Таблица',
			icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-width="2" d="M10 5V18.5"></path><path stroke="currentColor" stroke-width="2" d="M5 10H19"></path><rect width="14" height="14" x="5" y="5" stroke="currentColor" stroke-width="2" rx="4"></rect></svg>'
		};
	}

	render() {
		this.element = document.createElement('div');

		this.element.innerHTML = `<figure class="table">
				<table>
						<tbody>
								<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
								</tr>
								<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
								</tr>
								<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
								</tr>
								<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
								</tr>
								<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
								</tr>
						</tbody>
				</table>
		</figure>`;

		const ckEditorRef = require('@ckeditor/ckeditor5-build-balloon');

		const chooseUrlForUpload = () => {
			return `${API_URL}/applications/createImage`;
		};

		this.editor = ckEditorRef?.create(this.element, {
			...this.config,
			simpleUpload: {
				uploadUrl: chooseUrlForUpload(),

				headers: {
					'X-CSRF-TOKEN': 'CSRF-Token',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				onUpload: () => console.log('onUpload'),
			},
		});

		if (this.data && Object.keys(this.data).length) {
			this.editor.then((i: any) => {
				const textUpdate = this.data.text.replace(/<br\b[^>]*>|<br\s*\/?>/gi, '');

				i.setData(textUpdate);
			});
		}

		return this.element;
	}


  destroy() {
    if (this.editor && typeof this.editor.destroy === 'function') {
      this.editor.destroy().then(() => {
        this.editor = null;
      });
    } else {
      console.error('CKEditor instance was not correctly initialized or is already destroyed');
    }
  }

	/**
	 * Return Block Data
	 * @param {Element} blockContent - element returned by Render method
	 * @return {object}
	 */
	save(blockContent: any) {
		const modifiedContent = blockContent.cloneNode(true);

		const divElements = modifiedContent.querySelectorAll('div');
		divElements.forEach((div: any) => {
			div.parentNode.removeChild(div);
		});

		const tableElement = modifiedContent.querySelector('figure');
		const html = tableElement ? tableElement.outerHTML : '';

		return {
			text: html
		};
	}
}

const errorMessageImage = 'Не удалось загрузить изображение. Максимальный размер картинки 5мб.';

export const EditorJsLocal = {
	messages: {
		ui: {
			"popover": {
				"Filter": 'Поиск',
				"Nothing found": "Ничего не найдено"
			},
			"blockTunes": {
				"toggler": {
					"Click to tune": "Нажмите, чтобы настроить",
					"or drag to move": "или перетащите"
				},
			},
			"inlineToolbar": {
				"converter": {
					"Convert to": "Конвертировать в"
				},
			},
			"toolbar": {
				"toolbox": {
					"Add": "Добавить"
				}
			},
			"color": {
				"btn": "Сохранить",
				"save": "Сохранить",
			},
			"btn:save": "Сохранить",
		},

		tools: {
			"color": {
				"btn:save": "Сохранить",
			},
			"list": {
				"Unordered": "Маркированный",
				"Ordered": "Нумерованный"
			},
			"header": {
				"Heading 1": "Заголовок 1",
				"Heading 2": "Заголовок 2",
				"Heading 3": "Заголовок 3",
				"Heading 4": "Заголовок 4",
				"Heading 5": "Заголовок 5",
				"Heading 6": "Заголовок 6",
			},
			"image": {
				"Select an Image": "Выберите изображение",
				"With border": 'С границами',
				"Stretch image": 'Растянуть',
				"With background": 'С фоном',
				"Caption": 'Подпись',
				"Add Image": "Добавьте изображение",
				"Couldn’t upload image. Please try another.": errorMessageImage
			},
			"quote": {
				"Align Left": "Слево",
				"Align Center": "В центр",
				"Align Right": "Справо",
			},
			"table": {
				"Add column to left": 'Добавить столбец слева',
				"Add column to right": 'Добавить столбец справа',
				"Delete column": 'Удалить столбец',
				"Add row above": 'Добавить строку вверх',
				"Add row below": 'Добавить строку вниз',
				"Delete row": 'Удалить строку',
				"With headings": 'C заголовками',
				"Without headings": 'Без заголовков',
			},
			"gallery": {
				"Caption": 'Название',
				"Can not upload an image, try another": errorMessageImage,//
				"Add Image": "Добавьте изображение",
				" Add Image": "Добавьте изображение",
				'Delete': 'Удалить'
			},
			"toggle": {
				"Toggle": "Аккордеон"
			},
			'toggle-block': {
				'Toggle block': 'Toggle block',
				'Content': 'Content',
				'Toggle': 'Аккордеон'
			},
			"link": {
				"Add a link": "Вставьте ссылку"
			},
			"tooltip": {
				"Add a tooltip": "Вставьте подсказку"
			},
			"changeCase": {
				"Change Case": "Изменить регистр",
				titleCase: "Title sCase",
				"lower case": "lower scase",
				upperCase: "UPPER sCASE",
				localeLowerCase: "localé lower casé",
				localeUpperCase: "LöCALE UPPER CASE",
				sentenceCase: "Sentence case",
				toggleCase: "tOOGLE cASE",
			}
		},

		toolNames: {
			"Text": "Текст",
			"Heading": "Заголовок",
			"List": "Список",
			"Warning": "Примечание",
			"Checklist": "Чеклист",
			"Quote": "Цитата",
			"Image": "Картинка",
			"Columns": "Блоки",
			"Attachment": "Файл",
			"Table": "Таблица",
			"Link": "Ссылка, чтобы закрепить нажмите \"Enter\"",
			"Marker": "Маркеровать текст",
			"Carousel": "Галлерея",
			"Bold": "Жирный",
			"Italic": "Курсив",
			"InlineCode": "Моноширинный",
			"Toggle": "Аккордеон",
			"Color": "Цвета",
			"Convert To": "Конвертировать в",
			"Hyperlink": "Гиперссылка",
			"Underline": "Подчеркивать",
			"ChangeCase": "Изменить регистр",
			"Tooltip": "Подсказка, чтобы закрепить нажмите \"Enter\"",
			"Layout": '1 столбец',
			"Add Image": "Добавьте изображение",
			"Strikethrough": "Зачеркнуть"
		},

		blockTunes: {
			"delete": {
				"Delete": "Удалить",
				"Click to delete": 'Нажмите, чтобы удалить'
			},
			"moveUp": {
				"Move up": "Переместить вверх"
			},
			"moveDown": {
				"Move down": "Переместить вниз"
			},
		},
	}
}
