import DialogContainer from '../../../ui/dialog/DialogContainer'
import React, { FC } from 'react'
import { CloseIcon } from '../../../../img'
import TextField from '../../../ui/form/Fields/TextField'
import { Controller, useForm } from 'react-hook-form'
import { $updateStatusEducation } from '../../../../api/requests/services/education'
import TextArea from "../../../ui/form/Fields/TextArea";

interface IServicesImprovementModal {
    id?: number | string,
    isOpen: boolean,
    onClose: () => void,
}

export const ServicesImprovementModal: FC<IServicesImprovementModal> = ({ isOpen, id, onClose }) => {
    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue,
    } = useForm<any>()

    const finalize = (id: number | string | undefined) => {
        if(!id) return

        const data = {
            status: -2,
            reason: watch()?.reason,
        }

        $updateStatusEducation(id, data, { newApi: true }).then(res => {
            if (!res) return

            onClose()
        })
    }

    return (
        <DialogContainer isOpen={isOpen} isShowCloseBtn={false} closeModal={onClose}>
            <div className="services-create-modal__header">
                <h1 className="services-create-modal__header__title">Отправить на доработку</h1>

                <div className="services-create-modal__header__close" onClick={onClose}>
                    <CloseIcon color="#000" />
                </div>
            </div>

            <div className={'services-show-modal__content'}>
                <Controller
                    name="reason"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: '',
                        },
                    }}
                    render={({ field: { value, onChange } }) => (
                        <TextArea
                            value={value}
                            className=""
                            title="Комментарий"
                            placeholder={"Укажите причину"}
                            onChange={onChange}
                        />
                    )}
                />

            </div>

            <div className="services-create-modal__buttons">
                <div className="services-create-modal__buttons__item services-create-modal__buttons__add"
                     onClick={() => finalize(id)}
                >
                    Направить
                </div>
            </div>
        </DialogContainer>
    )
}