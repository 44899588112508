import React, { useEffect, useState } from "react";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import DialogContainer from "../../ui/dialog/DialogContainer";
import { QRCodeSVG } from "qrcode.react";
import { vCard } from 'vcards-ts';
import { useSelector } from "react-redux";
import { successNotify } from "../../../utils";
import { CloseIcon, MenuIcon, TelegramIcon } from "../../../img";
import { UploadIcon } from "../../../img/icons/UploadIcon";
import Button from "../../ui/form/Buttons/Button";
import { ProfileQrCodeSmallIcon } from "../../../img/icons/layout-components";
import { $getBusinessCard } from "../../../api/requests/user";
import { getPersonData } from "../../../redux";
import { CopyIcon } from "../../../img/icons/CopyIcon";
import { VkIcon } from "../../../img/icons/social/VkIcon";
import { useLocation } from "react-router-dom";

interface VisitModalProps {
    isOpen: boolean;
    onClose?: () => void;
    setIsOpen: () => void;
}

const VisitModal = (
    {
        isOpen,
        onClose,
        setIsOpen,
    }: VisitModalProps
) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("user_id") || null;
    const wsId = searchParams.get("ws_id") || null;
    const token = searchParams.get("token") || null;

    const [toggle, setToggle] = useState({ qr: true, info: false, share: false });

    const [QR, setQR] = useState("");
    const [url, setUrl] = useState("");

    const [businessCardData, setBusinessCardData] = useState<any>(null);

    const personalData = useSelector(getPersonData);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            successNotify("Ссылка скопирована в буфер обмена");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    useEffect(() => {
        if (
            (personalData?.id && personalData?.workspace_id && personalData?.token)
            || (userId && wsId && token)) {
            $getBusinessCard({
                user_id: userId || personalData?.user_id || personalData?.id,
                ws_id: wsId || personalData?.ws_id || personalData?.workspace_id,
                token: token || personalData?.token || personalData?.token,
            }).then(res => {
                setBusinessCardData(res)
            });
        }
    }, [personalData, token]);

    useEffect(() => {
        if (businessCardData) {
            const card = new vCard();

            // @ts-ignore
            card?.photo?.attachFromUrl(businessCardData?.photo, "JPEG");
            card.firstName = businessCardData?.firstname || "";
            card.lastName = businessCardData?.lastname || "";
            card.cellPhone = businessCardData?.phone ? `+${businessCardData?.phone}` : "";
            card.email = businessCardData?.mail || "";
            card.title = personalData?.job_position || "";

            // @ts-ignore
            card?.logo?.attachFromUrl(businessCardData?.image, "JPEG");
            card.organization = businessCardData?.organization_name || "";
            card.workPhone = businessCardData?.organization_phone ? `+${businessCardData?.organization_phone}` : "";
            card.url = businessCardData?.organization_url || "";

            setQR(card.getFormattedString());

            setUrl(
                personalData?.job_position
                    ? `${window.location?.origin}/business-card?user_id=${businessCardData?.id}&ws_id=${personalData?.workspace_id}&token=${personalData?.token}&job_position=${personalData?.job_position}`
                    : `${window.location?.origin}/business-card?user_id=${businessCardData?.id}&ws_id=${personalData?.workspace_id}&token=${personalData?.token}`
            );
        }
    }, [businessCardData, personalData]);

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={420}
            isFilter={!!token}
        >
            <div className="visit__modal">
                {!token && (
                    <div className={"contests__view__close"} onClick={setIsOpen}>
                        <CloseIcon color={"white"} />
                    </div>
                )}

                <div className="visit__modal__image">
                    <BlankImage image={businessCardData?.photo} />
                </div>

                <div className="visit__modal__head">
                    <div className="visit__modal__title">
                        {businessCardData?.name || businessCardData?.organization_name}
                    </div>

                    {personalData?.staff?.title && (
                        <div className="visit__modal__subtitle">
                            {personalData?.staff?.title}
                        </div>
                    )}
                </div>

                <div className="visit__modal__body">
                    <div className="visit__modal__buttons">
                        <Button
                            leftIcon={<ProfileQrCodeSmallIcon color={toggle.qr ? "white" : "#00A7B5"}/>}
                            text={"QR-визитка"}
                            className={`btn w-100 ${toggle.qr ? "btn-primary" : "btn-secondary"}`}
                            onClick={() => setToggle({
                                qr: true,
                                info: false,
                                share: false
                            })}
                        />

                        <Button
                            leftIcon={<MenuIcon color={toggle.info ? "white" : "#00A7B5"}/>}
                            text={"Данные визитки"}
                            className={`btn w-100 ml-0 ${toggle.info ? "btn-primary" : "btn-secondary"}`}
                            onClick={() => setToggle({
                                info: true,
                                qr: false,
                                share: false
                            })}
                        />
                    </div>

                    {toggle.qr && (
                        <div className="visit__modal__qr">
                            <QRCodeSVG className="visit__modal__qrcode" value={QR} />

                            <div className="visit__modal__qr-note">
                                Отсканируй QR-визитку камерой <br /> мобильного устройства для
                                добавления нового контакта
                            </div>
                        </div>
                    )}

                    {toggle.info && (
                        <div className="visit__modal__info">
                            {(businessCardData?.phone || businessCardData?.organization_phone) && (
                                <div className="visit__modal__info-field">
                                    <div className="visit__modal__info-label">Номер телефона
                                    </div>
                                    <div
                                        className="visit__modal__info-text">{`+${businessCardData?.phone || businessCardData?.organization_phone}`}</div>
                                </div>
                            )}

                            {businessCardData?.mail && (
                                <div className="visit__modal__info-field">
                                    <div className="visit__modal__info-label">E-mail</div>
                                    <div
                                        className="visit__modal__info-text">{businessCardData?.mail}</div>
                                </div>
                            )}

                            {businessCardData?.organization_url && (
                                <div className="visit__modal__info-field">
                                    <div className="visit__modal__info-label">Сайт</div>
                                    <div
                                        className="visit__modal__info-text">{businessCardData?.organization_url}</div>
                                </div>
                            )}

                            {businessCardData?.organization_name && (
                                <div className="visit__modal__info-field">
                                    <div className="visit__modal__info-label">Компания</div>
                                    <div
                                        className="visit__modal__info-text">{businessCardData?.organization_name}</div>
                                </div>
                            )}
                        </div>
                    )}

                    {toggle.share && (
                        <div className="visit__modal__share">
                            <div className="visit__modal__share-socials">
                                <a
                                    href={`https://t.me/share/url?url=${url}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <TelegramIcon/>
                                    Telegram
                                </a>
                                <a
                                    href={`https://vk.com/share.php?url=${url}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <VkIcon/>
                                    В контакте
                                </a>
                            </div>

                            <div className="visit__modal__share-link">
                                <div className="visit__modal__info-field">
                                    <div className="visit__modal__info-label">Ссылка</div>
                                    <div className="visit__modal__info-text">{url}</div>

                                    <Button
                                        text={<CopyIcon/>}
                                        className={`btn-icon visit__modal__copy`}
                                        onClick={handleCopy}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {!toggle.share && (
                        <div className="visit__modal__button-vertical">
                            <button
                                type="button"
                                className="btn btn-icon w-100"
                                onClick={() => setToggle({ share: true, qr: false, info: false })}
                            >
                                <UploadIcon />
                                Поделиться
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </DialogContainer>
    );
};

export default VisitModal;