import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getPersonData } from '../../../../redux'
import { IUsers } from '../../../../api/requests/user/interface'
import DialogContainer from '../../../ui/dialog/DialogContainer'
import { CloseIcon, SearchIcon } from '../../../../img'
import TextField from '../../../ui/form/Fields/TextField'
import { withDebounce } from '../../../../functions'
import { BlankAva } from '../../../ui/avatars/BlankAva'
import Button from '../../../ui/form/Buttons/Button'
import { $getServiceEducationCollaborators } from '../../../../api/requests/services/education'

interface ContestsViewCompletedProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
    setData: any;
}

export const ServiceEducationsEmployeesModal = (
    {
        data,
        isOpen,
        onClose,
        setData,
    }: ContestsViewCompletedProps) => {
    const { workspace_id: wsId } = useSelector(getPersonData)

    const [usersList, setUsersList] = useState<any[]>([])
    const [filteredList, setFilteredList] = useState<any[]>([])

    const [selectedUsers, setSelectedUsers] = useState<any[]>([])
    const [selectedUsersIndexes, setSelectedUsersIndexes] = useState<any>([])

    const [searchValue, setSearchValue] = useState<string>('')

    const handleAddData = () => {
        setSelectedUsersIndexes([])
        setSelectedUsers([])
        // setSearchValue('')
        // setUsersList([])
        setData(selectedUsers)

        console.log(selectedUsers)
        onClose()
    }

    useEffect(() => {
        if (wsId) {
            $getServiceEducationCollaborators({ search: searchValue }, { newApi: true }).then(res => {
                if (!res.data) return

                setUsersList(res.data)
            })
        }
    }, [searchValue])

    useEffect(() => {
        const updatedList: any = usersList.filter(item => !selectedUsers.some((s: any) => s.id == item.id))

        setFilteredList([...selectedUsers, ...updatedList])
    }, [usersList, selectedUsers])

    useEffect(() => {
        if (data) {
            setSelectedUsers(data)
            setSelectedUsersIndexes(data?.map((item: any) => item.id))
        }
    }, [data])

    function onChangeSearch(value: string) {
        setSearchValue(value)
    }

    function onChangeCheckbox(item: IUsers) {
        if (selectedUsersIndexes.includes(item.id)) {
            setSelectedUsersIndexes((prev: any) => [...prev].filter(i => i !== item.id))
            setSelectedUsers((prev: any) => [...prev].filter(i => i.id !== item.id))
        } else {
            setSelectedUsersIndexes((_: any) => [item.id])
            setSelectedUsers([item])
        }
    }

    function closeModal() {
        setSearchValue('')
        setUsersList([])
        setFilteredList([])
        setSelectedUsersIndexes([])
        setSelectedUsers([])
        onClose()
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={closeModal}
            isShowCloseBtn={false}
            classNameModal={'p-0'}
            widthProps={427}
        >
            <div className={'contests__winner'}>
                <div className="services-create-modal__header" style={{ marginBottom: 20 }}>
                    <h3 className="services-create-modal__header__title">Пользователи</h3>

                    <div className="services-create-modal__header__close" onClick={onClose}>
                        <CloseIcon color="#000" />
                    </div>
                </div>

                <div className={'contests__winner__search'}>
                    <TextField
                        value={searchValue}
                        onChange={(value: string) => {
                            setSearchValue(value)
                            withDebounce(() => onChangeSearch(value))
                        }}
                        placeholder={'Поиск'}
                        prepend={<SearchIcon color={'black'} />}
                        className={`search__block-textField`}
                    />
                </div>

                <div className={'contests__winner__list'}>
                    {filteredList.map((item, idx) => (
                        <div key={idx} className="layout-vacation-modal__block">
                            <input
                                type="checkbox"
                                checked={selectedUsersIndexes.includes(item.id)}
                                onChange={() => onChangeCheckbox(item)}
                            />

                            <div className="layout-vacation-modal__block__right">
                                {item?.photo ? (
                                    <img src={item?.photo} alt="" />
                                ) : (
                                    <BlankAva
                                        colorBg={'#CCD9E0'}
                                        colorText={'black'}
                                        text={item?.name}
                                    />
                                )}

                                <div className="layout-vacation-modal__block__desc">
                                    <h1>{item.name}</h1>

                                    <p className="layout-vacation-modal__block__desc__position">
                                        {item?.staff?.title}
                                    </p>

                                    <p className="layout-vacation-modal__block__desc__department">
                                        {item?.structure?.title}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={'contests__winner__btns'}>
                    <Button
                        text={'Выбрать пользователя'}
                        className={'btn btn-primary w-100'}
                        onClick={() => handleAddData()}
                        disabled={!selectedUsersIndexes.length}
                    />
                </div>
            </div>
        </DialogContainer>
    )
}