import {useNavigate} from "react-router-dom";
import {isMd} from "../../../utils";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {$getUserFavoritesAll} from "../../../api/requests/favorites";
import {changeHeaderTitle} from "../../../redux/reducers/header/reducer";
import {ArrowExitIcon} from "../../../img";
import {FavoritesNewsCard} from "../../../components/favorites";

export const FavoritesDiscounts = () => {
    const navigate = useNavigate();
    const isMobile = isMd()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [ partnersData, setPartnersData ] = useState([]);

    const handleNavigation = () => {
        navigate(-1);
    };

    useEffect(() => {
        $getUserFavoritesAll('partner', {newApi: true}).then(res => {
            setPartnersData(res.data)
        })

    }, [isLoading])

    useEffect(() => {
        dispatch(changeHeaderTitle("Витрина скидок"))
    }, [])

    return (
        <div className='favorites-news'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Витрина скидок</h1>
                    </div>
                ) }
            </div>

            <div className='container favorites-news-content'>
                { partnersData.map((
                    item: any,
                    idx
                ) => (
                    <div onClick={() => navigate(`/discount/show/${item.id}`)} key={idx}>
                        <FavoritesNewsCard
                            data={item}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            type={"partner"}
                        />
                    </div>
                )) }
            </div>
        </div>
    )
}