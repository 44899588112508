import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { fabric } from "fabric";

import {
    $getWorkplaceMapData,
    $getWorkplaceMapOfUser,
    $pinImportWorkplaceMap,
} from "../../../api/requests/workplace";

import { getWorkplaceShape } from "./GetWorkplaceShape";
import { getMeetingRoomShape } from "./getMeetingRoomShape";
import { getRoomShape } from "./GetRoomShape";
import { setDrawnShapeSettings } from "./utils";
import { successNotify } from "../../../utils";
import { withDebounce } from "../../../functions";

import BookingWorkplaceDialog from "./components/BookingWorkplaceDialog";

import {
    getCurrentDate,
    getOpenPopup,
    getPlan,
    getSelected,
    getPopupData,
    getPlacePosition,
    getSelectedUser,
    setCurrentDate,
    setFloors,
    setPlan,
    setSelected,
    setPopupData,
    setOpenPopup,
    setSelectedUser,
    setBuildings
} from "../../../redux/reducers/workplace/workplaceSlice";
import { getPersonData } from "../../../redux";
import RefreshIcon from "../../../img/icons/RefreshIcon";
import WorkplaceAvailablePlaces from "./components/WorkplaceAvailablePlaces";

export const ServicesWorkplaceMap = () => {
    const navigate = useNavigate();

    const { workspace_id: wsId } = useSelector(getPersonData);

    const { city, building, floor, isLoading: isLoadingFloor } = useSelector(getSelected);

    const currentDate = useSelector(getCurrentDate);

    const plan = useSelector(getPlan);
    const openPopup = useSelector(getOpenPopup);
    const popupData = useSelector(getPopupData);
    const placePosition = useSelector(getPlacePosition);
    const selectedUser = useSelector(getSelectedUser);

    const containerRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

    const [canvas, setCanvas] = useState<any>();
    const [props, setProps] = useState<any>({});

    const dispatch = useDispatch();

    const [isLoadingMap, setIsLoadingMap] = useState(false);

    const [isUpdateData, setIsUpdateData] = useState(false);

    const importPinned = (data: any) => {
        $pinImportWorkplaceMap(data, { formData: true }).then(res => {
            if (!res) return;

            getData();
            successNotify(res?.message);
        });
    };

    const [startMovement, setStartMovement] = useState({ x: 0, y: 0 });
    const [lastMovement, setLastMovement] = useState({ x: 0, y: 0 });

    const [isMouseDown, setIsMouseDown] = useState(false);

    const [size, setSize] = useState({ width: 1440, height: 700 });

    // Отлавливание клика
    const handleShapeClick = (shape: any) => {
        if (!shape) return;

        dispatch(setPopupData(shape.details));

        if (shape.details.type === "workplace") {
            dispatch(setOpenPopup());

            setTimeout(() => handleDragEnd(), 500)
        }

        if (shape.details.type === "meeting-room") {
            setTimeout(() => {
                dispatch(setSelected({ city, building, floor }));
            }, 200)

            navigate(
                `/services/booking?id=${shape.details.request.room.id}`
            );
        }
    };

    const refreshObject = (object: any) => {
        if (object.details.type === "workplace") {
            const workplace = props?.workplaces?.find((w: any) => +w?.number === +object?.details?.request?.place);

            const today = moment(currentDate).format("yyyy-MM-DD");
            const todayBooking = workplace?.bookings?.find((b: any) => b.date_in_string === today);
            const todayInitiator =
                todayBooking?.items?.length > 0
                    ? todayBooking.items[0]?.for_user
                    : null;

            object.details.access = workplace?.access;
            object.details.is_department = !!workplace?.department;
            object.details.department_title = workplace?.department?.title;
            object.details.is_pinned = !!workplace?.for_user;
            object.details.member = workplace?.for_user || todayInitiator;

            if (!object.details.member) {
                object.details.request = {
                    place: object?.details?.request?.place,
                    number_name: object.details.request?.number_name,
                    peripheries: object.details.request?.peripheries,
                };
            } else if (todayBooking?.items && todayBooking.items.length > 0) {
                object.details.booking = {
                    ...object.details?.booking,
                    id: todayBooking.items[0].id,
                    start_time: todayBooking.items[0].start_time,
                    end_time: todayBooking.items[0].end_time,
                };
            }

            getWorkplaceShape(canvasRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY,
                });

                if (!item.details.access && item.details.is_department) {
                    item.set({
                        visible: false,
                    });
                }

                canvas.remove(object);

                canvas.add(item);
                setDrawnShapeSettings(item, true);
                renderAll();
            });
        }

        if (object.details.type === "meeting-room") {
            getMeetingRoomShape(canvasRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY
                });

                canvas.remove(object);

                canvas.add(item);
                renderAll();
            });
        }

        if (object.details.type === "room") {
            getRoomShape(canvasRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY
                });

                canvas.remove(object);
                canvas.add(item);
                renderAll();
            });
        }

        setDrawnShapeSettings(object);
    };

    const getBookedObjectById = (id: string) => {
        return getBookedWorkplaces(floor, currentDate).find(
            item => item.workplace.id === id
        );
    };

    const refreshAllObjects = () => {
        canvas.forEachObject((object: any) => {
            if (object) {
                const bookedObject = getBookedObjectById(object.details.id);

                if (object.details.type === "workplace" && bookedObject) {
                    const updateObj = {
                        ...object,
                        details: {
                            ...object.details,
                            member: bookedObject.items[0].for_user,
                            booking: bookedObject.items[0],
                        },
                    }

                    refreshObject(updateObj);

                    handleShapeEdit(updateObj);
                } else {
                    refreshObject(object);

                    handleShapeEdit(object);
                }
            }
        });

        withDebounce(() => setIsLoadingMap(false));
    };

    const handleShapeEdit = (shape: any) => {
        shape.editable = true;
        shape.hasControls = true;
        shape.hasBorders = true;
        shape.hasRotatingPoint = false;

        shape?.setControlsVisibility && shape.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tl: true,
            tr: true,
            br: true,
            bl: true
        });
    };

    const getData = () => {
        if (floor) {
            withDebounce(() => {
                $getWorkplaceMapData(
                    floor ? floor.building_id : 0,
                    { date: currentDate },
                    { newApi: true }
                ).then(res => {
                    if (res && res?.data?.floors) {
                        const thisFloor = res.data.floors.find((item: any) => item.id === floor?.id);

                        thisFloor && setProps(thisFloor);
                    }
                });
            });
        }
    };

    const initBG = () => {
        if (!canvas) return;

        // Устанавливаем размеры Canvas
        canvas.setDimensions({
            width: size.width,
            height: size.height
        });

        let zoom = size.width / 1440;
        if (zoom < 1) {
            zoom = 1;
        }

        canvas.setZoom(zoom);
    };

    const initBGImage = (onSuccess: any) => {
        canvas.selection = false;

        initBG();

        fabric.Image.fromURL(plan, (img: any) => {
            img.scaleToWidth(1440);
            img.scaleToHeight(700);

            img.set({
                top: 0,
                left: 0,
                right: 0
            });

            let itemJson = localStorage.getItem("booking-front");
            const item = itemJson ? JSON.parse(itemJson) : null;

            if (item?.floor) {
                item.floor.map = {
                    ...item.floor.map,
                    width: img.width,
                    height: img.height
                };

                // localStorage.setItem("booking-front", JSON.stringify(item));
            }

            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            if (onSuccess) onSuccess();
        });
    };

    const renderAll = () => {
        canvas.renderAll();
    };

    const handleResize = () => {
        setIsLoadingMap(true);

        initBGImage(() => {
            canvas.renderAll();
            getData();
        });
    };

    const handleMoveStart = (e: any) => {
        const touches = e.changedTouches;

        handleDown(e);

        for (let i = 0; i < touches.length; i++) {
            setStartMovement({
                x: touches[i].clientX,
                y: touches[i].clientY
            });
        }
    };

    const handleMove = (e: any) => {
        const width = window.innerWidth;

        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            const diffX = lastMovement.x + (startMovement.x - touches[i].clientX);
            const resultDiffX =
                diffX + width > 1440 ? 1440 - width : diffX < 0 ? 0 : diffX;

            const diffY = lastMovement.y + (startMovement.y - touches[i].clientY);
            const resultDiffY = diffY > 200 ? 200 : diffY < 0 ? 0 : diffY;

            canvasRef.current.style.transform = `translate(${-resultDiffX}px, ${-resultDiffY}px)`;
        }
    };

    const handleMoveEnd = (e: any) => {
        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            setLastMovement({
                x: lastMovement.x + startMovement.x - touches[i].clientX,
                y: lastMovement.y + startMovement.y - touches[i].clientY
            });
        }
    };

    const handleDragStart = (e: any) => {
        if (!containerRef.current) return;
        const slider: any = containerRef.current;
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const { scrollLeft } = slider;
        const { scrollTop } = slider;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
        setIsMouseDown(true);
        document.body.style.cursor = "grabbing";
    };

    const handleDragEnd = () => {
        setIsMouseDown(false);
        if (!containerRef.current) return;
        document.body.style.cursor = "default";
    };

    const handleDrag = (e: any) => {
        if (!isMouseDown || !containerRef.current) return;
        e.preventDefault();

        const slider: any = containerRef.current;

        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;

        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    };

    const handleDown = (event: any) => {
        const activeObject = canvas.getActiveObject();
        event.preventDefault();

        if (activeObject) {
            setTimeout(() => setIsMouseDown(false), 200);
            setTimeout(() => setIsMouseDown(false), 0);
            setTimeout(() => setIsMouseDown(false), 500);

            handleShapeClick(activeObject);
        }
    };

    const getBookedWorkplaces = (floor: any, currentDate: any) => {
        let bookedBookings: any[] = [];

        floor?.workplaces?.forEach((workplace: any) => {
            workplace.bookings.forEach((booking: any) => {
                if (booking.date_in_string === currentDate && !!booking.items.length) {
                    bookedBookings = [...bookedBookings, { ...booking, workplace }];
                }
            });
        });

        return bookedBookings;
    }

    const handleZoomIn = () => {
        if (size.width * 1.2 <= 1440 * 3) {
            setSize({ width: size.width * 1.2, height: size.height * 1.2 });
        }
    };

    const handleZoomOut = () => {
        if (size.width / 1.2 >= 1440) {
            setSize({ width: size.width / 1.2, height: size.height / 1.2 });
        }
    };

    const handleZoomReset = () => {
        if (canvas) {
            const shapes = canvas.getObjects();

            shapes.forEach((item: any) => {
                if (item?.details?.type === "workplace") {
                    item.set({
                        opacity: 1,
                        scaleX: 1,
                        scaleY: 1
                    });
                }
            });
            canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
            canvas.renderAll();
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setPlan(null));
            dispatch(setBuildings(null));
            dispatch(setSelected({
                city: null,
                building: null,
                floor: null,
                isLoading: false,
            }));
            dispatch(setSelectedUser(null));
            dispatch(setFloors(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (props.map && props.map.json_map) {
            const data = JSON.parse(props.map.json_map);

            if (data) {
                canvas.loadFromJSON(data, () => {
                    initBGImage(() => {
                        refreshAllObjects();
                    });
                });
            } else {
                initBGImage(() => {
                    refreshAllObjects();
                });
            }
        }
    }, [props, size]);

    useEffect(() => {
        if (selectedUser) {
            $getWorkplaceMapOfUser(
                floor?.id || 0,
                {
                    user_id: selectedUser?.booked_user?.id || selectedUser?.id
                },
                { newApi: true }
            ).then(res => {
                const shapes = canvas.getObjects();

                shapes.forEach((item: any) => {
                    if (item?.details?.type === 'workplace') {
                        if (item?.details?.id !== (res?.data?.id || selectedUser?.id)) {
                            item.set({
                                opacity: 0.5,
                            });
                        } else {
                            item.set({
                                opacity: 1,
                                scaleX: 1.5,
                                scaleY: 1.5,
                            });
                            // zoom into the coordinates of this item
                            canvas.zoomToPoint(
                                new fabric.Point(
                                    item.left + item.width / 2,
                                    item.top + item.height / 2,
                                ),
                                2,
                            );
                        }
                    }
                });

                canvas.renderAll();
            });
        } else {
            dispatch(setSelectedUser(null));

            if (canvas) {
                const shapes = canvas.getObjects();

                shapes.forEach((item: any) => {
                    if (item?.details?.type === 'workplace') {
                        item.set({
                            opacity: 1,
                            scaleX: 1,
                            scaleY: 1,
                        });
                    }
                });

                canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
                canvas.renderAll();
            }
        }
    }, [selectedUser]);

    useEffect(() => {
        let itemJson: any = localStorage.getItem("booking-front");
        const savedWsId: any = localStorage.getItem("booking-front-ws-id");

        // reload if change workspace
        // if (!savedWsId || (parseInt(savedWsId, 10) !== wsId) && wsId) {
        //     localStorage.setItem("booking-front", "");
        //     localStorage.setItem("booking-front-ws-id", wsId.toString());
        //     itemJson = null;
        //     window.location.reload();
        // }

        if (!itemJson) return;

        const item = JSON.parse(itemJson);

        if (item) {
            dispatch(setSelected(item));
            dispatch(setFloors(item?.building?.floors));
            dispatch(setPlan(item?.floor?.map?.filename_url));
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        if (placePosition) {
            const shapes = canvas.getObjects();

            shapes.forEach((item: any) => {
                if (item?.details?.type === 'workplace') {
                    if (item?.details?.id !== placePosition?.id) {
                        item.set({
                            opacity: 0.5,
                        });
                    } else {
                        item.set({
                            opacity: 1,
                            scaleX: 1.5,
                            scaleY: 1.5,
                        });

                        canvas.zoomToPoint(
                            new fabric.Point(
                                item.left + item.width / 2,
                                item.top + item.height / 2,
                            ),
                            2,
                        );
                    }
                }
            });

            canvas.renderAll();
        }
    }, [placePosition])

    useEffect(() => {
        if (!currentDate) {
            const date = moment(new Date()).format("yyyy-MM-DD");
            dispatch(setCurrentDate(date));
        }
    }, [currentDate]);

    useEffect(() => {
        if (canvas) {
            handleResize();
            window.addEventListener('resize', () => withDebounce(() => handleResize()));
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [canvas]);

    /**
     * Step - 1
     */
    useEffect(() => {
        if (plan) {
            // @ts-ignore
            setCanvas(new fabric.Canvas("canvas"), {
                fireRightClick: true
            });
        }
    }, [plan]);
    /**
     * Step - 2
     */
    useEffect(() => {
        if (canvas) {
            canvas?.clear();
        }

        if (canvas && floor && floor.building_id) {
            initBGImage(() => {
                canvas.renderAll();
                getData();
            });
        }
    }, [floor, currentDate]);
    /**
     * Step - 3
     */
    useEffect(() => {
        if (isLoadingFloor && canvas) {
            canvas.getActiveObjects().forEach((o: any) => {
                canvas.remove(o);
            });
        }
    }, [isLoadingFloor]);
    /**
     * Step - 4
     */
    useEffect(() => {
        if (isUpdateData) {
            getData();
        }
    }, [isUpdateData]);

    return (
        <div className={"workspace-map mt-3"}>
            <div
                className={"workspace-map-container"}
                onTouchStart={handleMoveStart}
                onTouchMove={handleMove}
                onTouchEnd={handleMoveEnd}
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onMouseMove={handleDrag}
                ref={containerRef}
            >
                {plan && (
                    <div
                        id="map"
                        className={"workspace-map-block"}
                        ref={canvasRef}
                        onMouseDown={handleDown}
                        style={{
                            opacity: (isLoadingFloor || isLoadingMap) ? 0 : 1
                        }}
                    >
                        <canvas className={"workspace-map-canvas"} id="canvas" />
                    </div>
                )}

                {!plan && (
                    <div className={"workspace-map-empty"}>
                        <img
                            className={"emptyImage"}
                            src={"/images/icons/ImgEmptyBooking.svg"}
                            alt="План этажа"
                        />

                        <p className={"emptyText"}>Выберите город, офис и этаж</p>
                    </div>
                )}
            </div>

            <div className={"workplace__booking__zoom__container"}>
                <div className={"workplace__booking__zoom__block"}>
                    <div className={"workplace__booking__zoom"} onClick={handleZoomIn}>
                        +
                    </div>
                    <div className={"workplace__booking__zoom"} onClick={handleZoomOut}>
                        -
                    </div>
                </div>

                <div className={"workplace__booking__zoom__reset"} onClick={handleZoomReset}>
                    <div className={"workplace__booking__zoom workplace__booking__zoom__block"}>
                        <RefreshIcon />
                    </div>
                </div>
            </div>

            <BookingWorkplaceDialog
                isOpen={openPopup}
                onClose={() => dispatch(setOpenPopup())}
                selectedProps={{
                    city,
                    building,
                    floor,
                    request: popupData,
                    selectedMember: {
                        photo: popupData?.photo,
                        name: popupData?.name
                    }
                }}
            />

            <WorkplaceAvailablePlaces/>
        </div>
    );
};
