import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    changePersonData, changeProfileVarsData,
    getMainPageData,
    getPersonData
} from "../redux";
import Cookies from "js-cookie";
import { $mainPageNewData } from "../api/requests/main";
import Preloader from "../components/ui/preloader/Preloader";
import HeaderHome from "../components/header/HeaderHome";
import AsideHome from "../components/header/AsideHome";
import FooterHome from "../components/header/FooterHome";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../utils/scroll/ScrollToTop";
import { Slider } from "../components/slider/Slider";
import { useVskAccess } from "../hooks";
import { $getPersonalPage } from "../api/requests/user";
import { getGroupCreated, setGroupCreated } from "../redux/reducers/workplace/workplaceSlice";
import CompleteModal, { BtnDataItem } from "../components/ui/dialog/CompleteModal";
import { HomeIcon } from "../img/icons/menu/HomeIcon";
import { UserMaleIcon } from "../img/icons/menu/UserMaleIcon";
import { BlogIcon, CalendarIcon, NewsIcon, ServicesIcon } from "../img";
import { SportIcon } from "../img/icons/menu/SportIcon";
import { EventsIcon } from "../img/icons/menu/EventsIcon";
import { IdeasIcon } from "../img/icons/menu/IdeasIcon";
import { PollsIcon } from "../img/icons/menu/PollsIcon";
import { MediaIcon } from "../img/icons/menu/MediaIcon";
import { GroupsMenuIcon } from "../img/icons/menu/GroupsMenuIcon";
import { FavoriteIcon } from "../img/icons/others/FavoriteIcon";
import { PieChartIcon } from "../img/icons/menu/PieChartIcon";
import { FileIcon } from "../img/icons/menu/FileIcon";
import { BagIcon } from "../img/icons/menu/BagIcon";
import { CompetitionIcon } from "../img/icons/menu/CompetitionIcon";

interface IMainLayout {
    isScrolled: boolean;
}

const MainLayout: FC<IMainLayout> = ({ isScrolled }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const mainData = useSelector(getMainPageData);
    const localPersonData = useSelector(getPersonData);
    const isGroupCreated = useSelector(getGroupCreated);

    const searchParams = new URLSearchParams(location.search);

    const groupId = searchParams.get("group_id") || localPersonData?.group_id;

    const token = Cookies.get("token") || localStorage.getItem("token");

    const [scroll, setScroll] = useState<any>(false);

    const [listMenu, setListMenu] = useState([
        {
            img: (active: boolean) => (
                <HomeIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Главная страница",
            key: "home",
            count: 0,
            active: true,
            link: "/"
        },
        {
            img: (active: boolean) => (
                <UserMaleIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Моя страница",
            key: "profile",
            count: 0,
            active: false,
            backColorCount: "#FFD600",
            colorCount: "#000000",
            link: "/profile"
        },
        {
            img: (active: boolean) => (
                <BlogIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Блог",
            key: "blogs",
            count: 0,
            active: false,
            link: "/blogs"
        },
        {
            img: (active: boolean) => (
                <CalendarIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Календарь",
            key: "calendar",
            count: 0,
            active: false,
            link: "/calendar"
        },
        {
            img: (active: boolean) => (
                <SportIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Спорт",
            key: "sport",
            count: 0,
            active: false,
            link: "/sport"
        },
        {
            img: (active: boolean) => (
                <NewsIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Новости",
            key: "news",
            count: 0,
            active: false,
            backColorCount: "#C7EF71",
            colorCount: "#000000",
            link: "/news"
        },
        {
            img: (active: boolean) => (
                <EventsIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Мероприятия",
            key: "events",
            active: false,
            count: 0,
            link: "/events"
        },
        {
            img: (active: boolean) => (
                <CompetitionIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Конкурсы",
            key: "competitions",
            key2: "contests",
            active: false,
            count: 0,
            link: "/competitions"
        },
        {
            img: (active: boolean) => (
                <IdeasIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Идеи",
            key: "ideas",
            active: false,
            count: 0,
            link: "/ideas"
        },
        {
            img: (active: boolean) => (
                <PollsIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Опросы",
            key: "polls",
            active: false,
            count: 0,
            link: "/polls"
        },
        {
            img: (active: boolean) => (
                <MediaIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Медиафайлы",
            key: "media-files",
            active: false,
            count: 0,
            link: "/media-files"
        },
        {
            img: (active: boolean) => (
                <GroupsMenuIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Мои группы",
            key: "groups",
            active: false,
            count: 0,
            link: "/groups"
        },
        {
            img: (active: boolean) => (
                <FavoriteIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : ""}
                    strokeColor={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Избранное",
            key: "favorites",
            active: false,
            count: 0,
            link: "/favorites"
        },
        // {
        //     img: (active: boolean) => (
        //         <BagIcon
        //             opacity={active ? 1 : 0.3}
        //             color={active ? "#00A7B5" : "black"}
        //         />
        //     ),
        //     text: "Корп. Магазин",
        //     key: "favorites",
        //     active: false,
        //     count: 0,
        //     link: "https://cstore-itelma-dev.svoi.club/"
        // },
        {
            img: (active: boolean) => (
                <PieChartIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Аналитика",
            key: "analytics",
            active: false,
            count: 0,
            link: "/analytics/activity"
        },
        {
            img: (active: boolean) => (
                <FileIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Файлы",
            key: "files",
            active: false,
            count: 0,
            link: "/files",
            isAdmin: true
        },
        {
            img: (active: boolean) => (
                <ServicesIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Мои сервисы",
            key: "services",
            active: false,
            count: 0,
            link: "/services"
        },
        {
            img: (active: boolean) => (
                <GroupsMenuIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Новые сотрудники",
            key: "new-employees",
            active: false,
            count: 0,
            link: "/new-employees"
        },
        {
            img: (active: boolean) => (
                <GroupsMenuIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Структура компании",
            key: "structure",
            active: false,
            count: 0,
            link: "/structure"
        },
    ]);

    const btnList = [
        {
            key: 1,
            text: "Перейти к настройкам",
            className: "btn-primary w-100"
        },
        {
            key: 2,
            text: "Отмена",
            className: "btn-light w-100"
        }
    ]

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isActive, setIsActive] = useState("");
    const [modal, setModal] = useState('')
    const [localLocation, setLocalLocation] = useState<any>(null)

    const [personalData, setPersonalData] = useState(localPersonData || []);

    const { jsonData } = useVskAccess({ data: personalData });

    useEffect(() => {
        setIsActive(location.pathname);

        if ((location.pathname === localLocation?.pathname) && (location.search === localLocation?.search)) return;

        if (!localPersonData) return getFuncPersonData();

        if (groupId) {
            setTimeout(() => {
                getMainPage();

                getFuncPersonData();

                setLocalLocation(location);
            }, 500)
        }
    }, [location]);

    useEffect(() => {
        if (mainData) {
            const updateList = [...listMenu].map(item => {
                if (localPersonData?.group_id !== 0) {
                    if (item.key === "polls") return {
                        ...item,
                        value: !!+mainData?.group_interviews ? 0 : 1
                    }

                    if (mainData[`group_${item.key}`]) return {
                        ...item,
                        value: !!+mainData[`group_${item.key}`] ? 0 : 1
                    }
                }

                if ((localPersonData?.group_id === 0) && mainData[`workspace_${item.key}`]) return {
                    ...item,
                    value: !!+mainData[`workspace_${item.key}`] ? 0 : 1
                }

                if (mainData?.disabled_items?.length) return {
                    ...item,
                    value: mainData.disabled_items?.includes(item.key) ? 1 : 0
                }

                return item
            });

            const reorderedState = [...updateList]?.sort(
                (a, b) => mainData?.menu_sort?.indexOf(a.key) - mainData?.menu_sort?.indexOf(b.key)
            );

            setListMenu(reorderedState)
        }

    }, [mainData])

    useEffect(() => {
        if (mainData) return;

        getMainPage();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setScroll(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, []);

    useEffect(() => {
        const defaultColorScheme = {
            primaryColor: "#00A7B5", //blueColor
            secondaryColor: "#00A7B5", //lightBlue
            thirdColor: "#E9F0F9", //grayColor
            fourthColor: "#FFF" //whiteColor
        };

        if (jsonData) {
            dispatch(changePersonData(jsonData));
            dispatch(changeProfileVarsData({
                colors: jsonData.workspace_design
                    ? JSON.parse(jsonData.workspace_design)
                    : defaultColorScheme,
            }));
        }
    }, [jsonData]);

    function getMainPage() {
        $mainPageNewData().then((res) => {
            if (!res || res.error) return;
            
            dispatch(changeMainPage(res));
        });
    }

    function getFuncPersonData() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return console.error("error person data");

            setPersonalData(res.data);
        });
    }

    function openMenu(): void {
        setIsOpenMenu(!isOpenMenu);
    }

    function openSearchModal(): void {
        setModal("search");
    }


    function onClickMenuFooter(i: any): void {
        if (i.key === "navigation") openMenu();
        if (i.key === "search") openSearchModal();
    }

    function handleClickModalBtn(selectBtn: BtnDataItem) {
        if (selectBtn.key === 1) {
            navigate("/groups/setting/settings");
        }

        dispatch(setGroupCreated(false));
    }

    if (!token) {
        return <Navigate to={"/auth"} replace />;
    }

    if (!localPersonData || !mainData)
        return null

    return (
        <>
            <HeaderHome openMenu={openMenu} modal={modal} setModal={setModal}/>

            <Slider isScrolled={scroll} />

            <AsideHome
                isActiveOpen={isOpenMenu}
                isActiveLocal={isActive}
                openMenu={openMenu}
                menu={listMenu}
            />

            <div className={`main ${isScrolled ? "main-scrolled" : ""}`}><Outlet /></div>

            <FooterHome
                isOpenMenu={isOpenMenu}
                openMenu={openMenu}
                onClickMenuFooter={onClickMenuFooter}
                setSearchModalOpen={openSearchModal}
            />

            <ScrollToTop />

            <CompleteModal
                isOpen={isGroupCreated}
                setIsOpen={() => dispatch(setGroupCreated(false))}
                icon={"⚙️"}
                title={"Настройка"}
                text={"Для того чтобы ваша группа стала активной, для начала Вам необходимо ее настроить"}
                btnList={btnList}
                onClick={handleClickModalBtn}
                isFilter
            />
        </>
    );
};

export default MainLayout;
