import {
    ArrowExitIcon,
    SearchIcon,
} from "../../img";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Dropdown, { DropdownItem } from "../../components/ui/form/Dropdown/Dropdown";
import { isMd } from "../../utils";
import TextField from "../../components/ui/form/Fields/TextField";
import ModalCatBlogs from "../../components/categories/ModalCatBlogs";
import DiscountCard from "../../components/discount/DiscountCard";
import {
    $getDiscountCategoriesList,
    $getDiscountCitiesList,
    $getDiscountList
} from "../../api/requests/discount";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import HorizonNavigate from "../../components/navigation/HorizonNavigate";
import { withDebounce } from "../../functions";

export const DiscountPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const currentRole = useSelector(getCurrentRole);
    const personalData = useSelector(getPersonData);

    const isAdmin = currentRole === 1;

    const [filterList, setFilterList] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);
    const [categoryValue, setCategoryValue] = useState({ value: "", label: "Все категории" });

    const [cityList, setCityList] = useState<DropdownItem[]>([]);
    const [citySelect, setCitySelect] = useState({ value: "", label: "Все города" });

    const [searchValue, setSearchValue] = useState<string>("");

    const [isOpenCatModal, setIsOpenCatModal] = useState(false);

    useEffect(() => {
        personalData?.id && init();
    }, [personalData]);

    useEffect(() => {
        personalData?.id && withDebounce(() => getMediaFiles());
    }, [categoryValue, citySelect, searchValue]);

    function init() {
        getMediaFiles();
        getCategories();
        getCities();
    }

    function getMediaFiles() {
        const request = {
            id: personalData.workspace_id,
            admin: isAdmin ? 1 : 0,
            city_id: citySelect?.value,
            cat_id: categoryValue?.value,
            search: searchValue,
            dash: isAdmin ? 1 : 0,
            limit: 30
        }

        $getDiscountList(request).then(res => {
            if (!res) return;

            setFilterList(res);
        });
    }

    function getCategories() {
        const request = {
            id: personalData.workspace_id,
        }

        $getDiscountCategoriesList(request).then(res => {
            if (!res || !res?.length) return;

            setCategoryList(
                [...res].map(i => ({
                    ...i,
                    label: i.title.length > 30 ? i.title.slice(0, 30) + "..." : i.title,
                    value: i.id
                }))
            );
        });
    }

    function getCities() {
        const request = {
            id: personalData.workspace_id,
        }

        $getDiscountCitiesList(request).then(res => {
            if (!res || !res?.length) return;

            setCityList(
                [...res].map(i => ({
                    ...i,
                    label: i.city,
                    value: i.id
                }))
            );
        });
    }

    function handleUpdateData(select: any) {
        const filterListUpdate = [...filterList].map(item => {
            if (item.id === select.id) return { ...select };

            return item;
        });

        setFilterList(filterListUpdate);
    }

    return (
        <div className="sport">
            {!isMobile && <HorizonNavigate isScroll={false} />}

            <div className="container">
                <div className="layout-inner__header discount__header">
                    {!isMobile && (
                        <div className="layout-inner__header__title">
                            <div className="header__exitIcon" onClick={() => navigate(-1)}>
                                <ArrowExitIcon />
                            </div>

                            <h1>Витрина скидок</h1>
                        </div>
                    )}

                    <div className="media-files-controllers">
                        <Dropdown
                            options={[
                                {
                                    value: "",
                                    label: "Все города"
                                },
                                ...cityList
                            ]}
                            placeholder={"Выберете город"}
                            value={citySelect}
                            onChange={setCitySelect}
                        />

                        <Dropdown
                            options={[
                                {
                                    value: "",
                                    label: "Все категории"
                                },
                                ...categoryList
                            ]}
                            placeholder={"Выберете категорию"}
                            value={categoryValue}
                            onChange={setCategoryValue}
                        />

                        <div className="media-files-controllers__input media-files-search">
                            <TextField
                                value={searchValue}
                                onChange={setSearchValue}
                                placeholder={"Поиск"}
                                prepend={<SearchIcon color={"rgba(0,0,0,0.5)"} />}
                                className={`search__block-textField`}
                            />
                        </div>
                    </div>
                </div>

                <div className="media-files-content__items">
                    {filterList.map((item, idx) => (
                        <DiscountCard
                            data={item}
                            key={idx}
                            setUpdateData={(select) => handleUpdateData(select)}
                        />
                    ))}
                </div>
            </div>

            <ModalCatBlogs
                isOpen={isOpenCatModal}
                setIsOpen={() => setIsOpenCatModal(false)}
                catList={categoryList}
                setCatList={setCategoryList}
                route={"mediaGalleries"}
            />
        </div>
    );
};