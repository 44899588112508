import React, { FC, useEffect, useState } from "react";
import DialogContainer from "../../ui/dialog/DialogContainer";
import { CloseIcon, SearchIcon } from "../../../img";
import { ProfileBalanceIcon } from "../../../img/icons/layout-components";
import TextField from "../../ui/form/Fields/TextField";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { $searchUsers } from "../../../api/requests/user";
import { IUsers } from "../../../api/requests/user/interface";
import { BlankImage } from "../../ui/blank-image/BlankImage";

interface IEventSelectAuthorModal {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (data: any) => void;
}

export const EventSelectAuthorModal: FC<IEventSelectAuthorModal> = (
    {
        isOpen,
        onClose,
        onSelect
    }
) => {

    const [searchValue, setSearchValue] = useState<string>("");
    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        setSearchValue("");
    }, [isOpen]);

    function onChangeSearch(value: string) {
        setSearchValue(value);

        if (value.length <= 2)
            return;

        $searchUsers(value, { newApi: true }).then(res => {
            if (!res.data) return;

            setUsers(res.data);
        });
    }

    return (
        <DialogContainer isOpen={isOpen} closeModal={onClose} isShowCloseBtn={false}>
            <div className="events-modal__header">
                <h5 className="ideas-modal-points__title">Выбор организатора</h5>

                <div className="events-modal__closeIcon" onClick={onClose}>
                    <CloseIcon color={"#000"} />
                </div>
            </div>

            <div className="events-modal__content">
                <div className="events-modal__search">
                    <TextField
                        value={searchValue}
                        onChange={onChangeSearch}
                        placeholder={"Поиск"}
                        prepend={<SearchIcon color={"rgba(0,0,0,0.5)"} />}
                        className={`search__block-textField`}
                    />
                </div>

                <div className="events-modal__users">
                    {users.map((user) => (
                        <div className="events-modal__user" key={user.id}
                             onClick={() => onSelect({...user, image: user.photo})}>
                            <div className="events-modal__user__avatar">
                                <BlankImage image={user.photo} />
                            </div>

                            <div className="events-modal__user__content">
                                <h2 className="events-modal__user__name">{user.name}</h2>
                                <div className="events-modal__user__position">{user.job?.name}</div>
                                <div
                                    className="events-modal__user__department">{user.job?.department}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </DialogContainer>
    );
};