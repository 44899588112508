import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import moment from "moment/moment";

interface IWork {
    position: string,
    department: string,
    workplace: string,
    birth: string,
    number: string,
    email: string,
    skills: ISkills[]
}

interface Props {
    personalData: any;
}

interface ISkills {
    id: number,
    value: string
}

export const PersonalWorkBlock = (
    {
        personalData
    }: Props
) => {
    const [data, setData] = useState<any>([]);

    const [hashtags, setHashtags] = useState<any>([]);

    useEffect(() => {
        if (personalData) {
            personalData?.my_job && setData(personalData.my_job);

            setData([
                {
                    "id": 1,
                    "name": "Должность",
                    "sort": 5,
                    "is_hidden": 0,
                    "hidden_in_type": 0,
                    "value": personalData?.staff?.title || "Отсутствует"
                },
                {
                    "id": 2,
                    "name": "Департамент",
                    "sort": 5,
                    "is_hidden": 0,
                    "hidden_in_type": 0,
                    "value": personalData?.structure?.title || "Отсутствует"
                },
                {
                    "id": 3,
                    "name": "Дата рождения",
                    "sort": 5,
                    "is_hidden": 0,
                    "hidden_in_type": 0,
                    "value": personalData?.birth || "Отсутствует"
                },
                {
                    "id": 4,
                    "name": "Номер телефона",
                    "sort": 5,
                    "is_hidden": 0,
                    "hidden_in_type": 0,
                    "value": personalData?.phone || "Отсутствует"
                },
                // {
                //     "id": 5,
                //     "name": "email",
                //     "sort": 5,
                //     "is_hidden": 0,
                //     "hidden_in_type": 0,
                //     "value": personalData?.mail ? personalData?.mail : 'Отсутствует'
                // },
                {
                    "id": 6,
                    "name": "Город",
                    "sort": 6,
                    "is_hidden": 0,
                    "hidden_in_type": 2,
                    "value": personalData?.city || "Отсутствует"
                }
            ]);
        }
    }, [personalData]);

    useEffect(() => {
        if (personalData?.hashtags) {
            if (!personalData?.hashtags.length) return;

            if (Array.isArray(personalData?.hashtags)) {
                return setHashtags(personalData?.hashtags);
            }

            const hashtagsNon = personalData?.hashtags?.replace(/#/g, "");

            setHashtags(hashtagsNon.split(","));
        }
    }, [personalData?.hashtags]);

    return (
        <div className="profile-work">
            {personalData?.about ? (
                <div
                    key={`data-profile-work`}
                    className="profile-work__block">
                    <p className="profile-work__block__placeholder">Обо мне</p>

                    <h1 className="profile-work__block__value">{personalData.about}</h1>
                </div>
            ) : ""}

            <div className="profile-work__content">
                {data.map((item: any, idx: number) => (
                    <div
                        key={`data-profile-work-${idx}`}
                        className="profile-work__block">
                        <p className="profile-work__block__placeholder">{item.name}</p>

                        <h1 className="profile-work__block__value">{item.value}</h1>
                    </div>
                ))}
            </div>

            <div className="profile-work__line"></div>

            <div className="profile-work__skills">
                <h1 className="profile-work__skills__title">Навыки</h1>

                <div className="profile-work__skills__content">
                    {hashtags.length ? hashtags.map((name: string, idx: number) => (
                        <div key={`skills-item-${idx}`} className="profile-work__skills__block">
                            {name}
                        </div>
                    )) : <div>Навыки не добавлены</div>}
                </div>
            </div>
        </div>
    );
};