import { FC } from "react";
import { isMd } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { useDetectSwipes } from "../../../hooks";
import { HeaderSearchBar } from "./HeaderSearchBar";
import { HeaderSearchBlock } from "./HeaderSearchBlock";
import { CloseIcon } from "../../../img";
import { $showGroupById } from "../../../api/requests/groups";
import { IUser } from "../../../api/requests/upload/interface";

interface IHeaderSearchPopup {
    isSearchResults: any,
    user?: IUser,
    setSearchResults: any,
    updateUser?: any,
    setPopup: any,
    setSearch: any,
    isLoading: boolean,
    isSearch: string,
}

export const HeaderSearchPopup: FC<IHeaderSearchPopup> = (
    {
        isSearchResults = {},
        setSearchResults,
        updateUser,
        setPopup,
        isSearch,
        isLoading,
        setSearch
    }
) => {
    const profileData = useSelector(getPersonData);

    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    // const { handleSendData, isObject, resetData, isData } = useSendData('workspaces/set');
    const isMobile = isMd();
    const { onTouchEnd, onTouchMove, onTouchStart } = useDetectSwipes({
        func: () => setPopup(false),
        direction: "bottom"
    });

    const getModuleName = pathname.split("/")[1];

    const checkForSamePage = (url: string) => {
        if (pathname === url) {
            setPopup(false);
            return;
        }
        navigate(url);
        // saveResultOnClick();
    };

    const handleSendData = (data: object = {}, url: string) => {

    };

    function handleOpenEdit(select: any) {
        $showGroupById({ id: select.id }, { formData: true }).then(res => {
            if (!res) return;

            navigate(`/?group_id=${select.id}`);
        })
    }

    const items = [
        {
            data: isSearchResults?.workspaces,
            isEmpty: isSearchResults?.workspaces?.length <= 0,
            onClick: async ({ role, id }: any) => {
                if (+role === 1 || +role === 4) {
                    setSearchResults(null);
                    // await handleSendData({ id });
                    // updateUser();
                } else {
                    checkForSamePage(`/welcome?id=${id}`);
                }
            },
            blockTitle: "Рабочее пространство",
            isFirst: pathname === "/workspaces",
            key: "workspaces"
        },
        {
            data: isSearchResults?.users,
            isEmpty: isSearchResults?.users?.length <= 0,
            onClick: ({ uniq }: any) => checkForSamePage(`/user/${uniq}`),//?workspace_id=${profileData.workspace_id}`),
            blockTitle: "Пользователи",
            isFirst: pathname === "/user" || pathname === "/profile" || pathname === "/control-users",
            key: "users"
        },
        {
            data: isSearchResults?.groups,
            isEmpty: isSearchResults?.groups?.length <= 0,
            onClick: async ({ role, id }: any) => {
                if (+role === 1 || +role === 3 || +role === 2) {
                    setSearchResults(null);
                    handleOpenEdit({ id });
                    // await handleSendData({ id }, "groups/view");
                    // updateUser();
                } else {
                    checkForSamePage(`/groups`)//join-group?id=${id}`);
                }
            },
            blockTitle: "Группы",
            isFirst: pathname === "/groups" || pathname === "/group-settings",
            key: "groups"
        },
        {
            data: isSearchResults?.partners,
            isEmpty: isSearchResults?.partners?.length <= 0,
            onClick: ({ id }: any) => checkForSamePage(`/place?id=${id}`),
            blockTitle: "Партнеры",
            isFirst: pathname === "/cafeteria",
            key: "partners"
        },
        {
            data: isSearchResults?.news,
            isEmpty: isSearchResults?.news?.length <= 0,
            onClick: ({ category_id, id }: any) => {
                checkForSamePage(
                    `/news/show/${id}`//?workspace_id=${profileData.workspace_id}&category_id=${category_id}&group_id=${profileData.group_id}`
                );
            },
            blockTitle: "Новости",
            isFirst: getModuleName === "news",
            isContent: true,
            key: "news"
        },
        {
            data: isSearchResults?.contests,
            isEmpty: isSearchResults?.contests?.length <= 0,
            onClick: ({ category_id, id }: any) =>
                checkForSamePage(
                    `/contests/show?workspace_id=${profileData.workspace_id}&category_id=${category_id}&group_id=${profileData.group_id}&id=${id}`
                ),
            blockTitle: "Конкурсы",
            isFirst: getModuleName === "contests",
            isContent: true,
            key: "contests"
        },
        {
            data: isSearchResults?.ideas,
            isEmpty: isSearchResults?.ideas?.length <= 0,
            onClick: ({ category_id, id }: any) =>
                checkForSamePage(
                    `/ideas/show/${id}`//?workspace_id=${profileData.workspace_id}&category_id=${category_id}&group_id=${profileData.group_id}`
                ),
            blockTitle: "Идеи",
            isFirst: getModuleName === "ideas",
            isContent: true,
            key: "ideas"
        },
        {
            data: isSearchResults?.interviews,
            isEmpty: isSearchResults?.interviews ? isSearchResults.interviews.length <= 0 : true,
            onClick: ({ category_id, id }: any) =>
                checkForSamePage(
                    `/polls?id=${id}`
                ),
            blockTitle: "Опросы",
            isFirst: getModuleName === "interviews",
            isContent: true,
            key: "interviews"
        },
        {
            data: isSearchResults?.application_samples,
            isEmpty: isSearchResults?.application_samples ? isSearchResults.application_samples.length <= 0 : true,
            onClick: ({ id }: any) =>
                checkForSamePage(
                    `/services/show/${id}`
                ),
            blockTitle: "Сервисы",
            isFirst: getModuleName === "application_samples",
            isContent: true,
            key: "application_samples"
        }
    ];

    return (
        <div
            className="header-search-popup"
            style={{
                backgroundColor: `#fff`,
                color: `#000`
            }}
            id="search-filter"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            {isMobile && (
                <div className="header-search-popup__header">
                    <h5>Поиск</h5>

                    <div className="header-search-popup__header__closeIcon"
                         onClick={() => setPopup(false)}>
                        <CloseIcon color={"#000"} />
                    </div>
                </div>
            )}
            {isMobile && (
                <HeaderSearchBar
                    isSearch={isSearch}
                    isLoading={isLoading}
                    setPopup={setPopup}
                    setSearch={setSearch}
                />
            )}

            {items.map((item, index) => {
                if (item.isEmpty) return null;
                if (!item.isFirst) return null;

                return (
                    <HeaderSearchBlock
                        data={item}
                        blockTitle={item.blockTitle}
                        key={index}
                    />
                )
            })}

            {items.map((item, index) => {
                if (item.isFirst) return null;
                if (item.isEmpty) return null;

                return (
                    <HeaderSearchBlock
                        data={item}
                        blockTitle={item.blockTitle}
                        key={index}
                    />
                )
            })}

            <div style={isMobile ? { marginBottom: "24px" } : {}} />
        </div>
    );
};