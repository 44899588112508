import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { BlankImage } from "../ui/blank-image/BlankImage";
import { isMd } from "../../utils";

interface CongratulatedCardProps {
    data?: any[];
}

export const CongratulatedCard = (
    {
        data
    }: CongratulatedCardProps
) => {
    const personalData = useSelector(getPersonData);
    const isMobile = isMd();

    const [peopleList, setPeopleList] = useState<any[]>([])

    useEffect(() => {
        const peopleList = data ? data : personalData?.badges?.personal || []

        if (peopleList) {
            const filterData = [...peopleList]
            .map(item => item.sender)
            .filter((item: any, index: number, list: any[]) =>
                list.findIndex((_item: any) => _item.id === item.id) === index)

            setPeopleList(filterData)
        }
    }, [personalData, data]);

    return (
        <div className='layout-congratulations'>
            <div className='layout-congratulations__header'>
                <h1>Поздравили</h1>

                <p>{peopleList.length} {isMobile ? "статусов" : ''}</p>
            </div>

            {!!peopleList?.length && (
                <div className='layout-congratulations__content'>
                    {(isMobile ? peopleList.slice(0, 3) : peopleList.slice(0, 6)).map((item, idx) => (
                        <div className={`layout-congratulations__block ${idx === 0 ? 'first' : ''}`}
                             key={idx}>
                            <BlankImage image={item?.photo} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}