"use client";

import React, { useState, useEffect, ReactNode } from "react";
import {
    $getInfiniteScrollItems,
    $getNewInfiniteScrollItems
} from "../../api/requests/infinite-scroll";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { withDebounce300 } from "../../functions";

const LIMIT = 30;

interface InfiniteScrollProps {
    params?: any,
    url: string,
    data?: any,
    setData: any,
    isNewApi?: boolean,
    children?: ReactNode;
}

export default function InfiniteScroll(
    {
        url,
        params,
        setData,
        data,
        isNewApi = true,
        children
    }: InfiniteScrollProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const { workspace_id: wsId } = useSelector(getPersonData);
    const [offset, setOffset] = useState(0);
    const limit = 30;

    const [currentPage, setCurrentPage] = useState(1);

    const fetchData = async (page?: number) => {
        if (isNewApi) {
            $getNewInfiniteScrollItems(url, { page, ...params }, { newApi: true }).then(response => {
                setIsLoading(true);
                setData((prevData: any) => [...prevData, ...response.data.data]);

                if (response.data.data.length < response.data.per_page) {
                    setHasMore(false);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            $getInfiniteScrollItems(url, {
                workspace_id: wsId,
                offset,
                limit, ...params
            }, { formData: true }).then((response) => {
                setIsLoading(true);
                const newData = response;

                if (Array.isArray(response)) {
                    setData([...data, ...response]);
                } else {
                    setData(response);
                }

                if (newData.length < limit) {
                    setHasMore(false);
                } else {
                    setOffset((prevOffset) => prevOffset + limit);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        if (!isNewApi && wsId) {
            withDebounce300(() => fetchData());
        }

    }, [wsId]);

    useEffect(() => {
        if (isNewApi && wsId) {
            withDebounce300(() => fetchData(currentPage));
        }
    }, [currentPage]);

    const isAtBottom = () => {
        return (
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight
        );
    };

    const handleScroll = () => {
        if (isNewApi) {
            if (
                isAtBottom() ||
                isLoading ||
                !hasMore
            ) {
                return;
            }

            setCurrentPage((prevPage) => prevPage + 1);
        } else {
            if (isAtBottom() && !isLoading && hasMore && wsId) {
                withDebounce300(() => fetchData());
            }
        }

    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isLoading, hasMore, offset, params, currentPage]);

    return (
        <>
            {children}
        </>
    );
}
