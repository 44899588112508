import { ArrowExitIcon } from "../../img";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMd } from "../../utils";
import {
    $addFolder, $deleteFileItem,
    $deleteFolder,
    $getFileCategories,
    $getFileCategoryById
} from "../../api/requests/files";
import { FolderIcon } from "../../img/icons/files/FolderIcon";
import {
    FileCategoryData,
    FileCategoryDetailsData, FileCategoryItemData, ICreateRequestFolder
} from "../../api/requests/files/interface";
import { useDispatch, useSelector } from "react-redux";
import { store, updateRouteTitle } from "../../redux";
import {
    getToAddFile,
    getToAddFolder, setIsOpenCreateBanner,
    setToAddFile, setToAddFolder
} from "../../redux/reducers/workplace/workplaceSlice";
import { FileCategorySettingsModal } from "../../components/files/modal/FileCategorySettingsModal";
import { FileSettingsModal } from "../../components/files/modal/FileSettingsModal";
import { AddFileModal } from "../../components/files/modal/AddFileModal";
import { FileCategoryRenameInput } from "../../components/files/input/FileCategoryRenameInput";
import CompleteModal, { BtnDataItem } from "../../components/ui/dialog/CompleteModal";
import { FileRenameInput } from "../../components/files/input/FileRenameInput";
import { MoveFileModal } from "../../components/files/modal/MoveFileModal";
import { EditFileModal } from "../../components/files/modal/EditFileModal";

export const FilesPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const dropdownRef = useRef<any>(null);
    const [selectedFolder, setSelectedFolder] = useState<FileCategoryData | null>(null);
    const [selectedFile, setSelectedFile] = useState<FileCategoryItemData | null>(null);

    const toAddFolder = useSelector(getToAddFolder);
    const updateFolderRef = useRef<any>(null);
    const [toEditFolder, setToEditFolder] = useState<FileCategoryData | null>(null);
    const [isDeleteFolderOpen, setIsDeleteFolderOpen] = useState<number | null>(null);

    const toAddFile = useSelector(getToAddFile);
    const updateFileRef = useRef<any>(null);
    const [toEditFile, setToEditFile] = useState<FileCategoryItemData | null>(null);
    const [isDeleteFileOpen, setIsDeleteFileOpen] = useState<number | null>(null);
    const [isMoveFileOpen, setIsMoveFileOpen] = useState<number | null>(null);

    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch();

    const [category, setCategory] = useState<FileCategoryDetailsData>();

    const { id: currentId } = useParams();

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSelectedFolder(null);
                setSelectedFile(null);
            }

            if (updateFolderRef.current && !updateFolderRef.current.contains(event.target)) {
                store.dispatch(setToAddFolder(false));
                setToEditFolder(null);
            }

            if (updateFileRef.current && !updateFileRef.current.contains(event.target)) {
                setToEditFile(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (toEditFolder)
            setSelectedFolder(null);

        if (toEditFile)
            setSelectedFile(null);
    }, [toEditFolder, toEditFile]);

    useEffect(() => {
        init();
    }, [currentId, toAddFile]);

    function init() {
        getCategories();

        setToEditFolder(null);
        setToEditFile(null);
    }

    function getCategories() {
        $getFileCategoryById(currentId ? currentId : "1", { newApi: true }).then((data) => {
            let category: FileCategoryDetailsData = data.data;

            dispatch(updateRouteTitle({ key: "files_show", title: category.name }));

            setCategory(category);
        });
    }

    function showCategorySettings(e: any, item: FileCategoryData) {
        e.preventDefault();
        setSelectedFolder(item);
    }

    function openCategory(item: FileCategoryData) {
        navigate(`/files/show/${item.id}`);
    }

    function showFileSettings(e: any, item: FileCategoryItemData) {
        e.preventDefault();
        setSelectedFile(item);
    }

    function openFile(item: FileCategoryItemData) {

    }

    function closeUpload(toUpdate: boolean) {
        store.dispatch(setToAddFile(false));
        setToEditFile(null);

        if (toUpdate) {
            init();
        }
    }

    function closeMove(toUpdate: boolean) {
        setIsMoveFileOpen(null);

        if (toUpdate) {
            init();
        }
    }

    const deleteBtnList = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-danger w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    function handleDeleteFolder(select: BtnDataItem) {
        if (select.key == 0) {
            $deleteFolder(isDeleteFolderOpen!!, { newApi: true }).then(() => {
                init();
                setIsDeleteFolderOpen(null);
            });
        } else {
            setIsDeleteFolderOpen(null);
        }
    }

    function handleDeleteFile(select: BtnDataItem) {
        if (select.key == 0) {
            $deleteFileItem(isDeleteFileOpen!!, { newApi: true }).then(() => {
                init();
                setIsDeleteFileOpen(null);
            });
        } else {
            setIsDeleteFileOpen(null);
        }
    }

    function isActive(item: FileCategoryData | FileCategoryItemData | null): boolean {
        if (selectedFile == null && selectedFolder == null && !toAddFolder)
            return true;

        if (toAddFolder || toEditFolder || toEditFile)
            return false;

        return item == selectedFolder || item == selectedFile;
    }

    return (
        <div className="files">
            <AddFileModal
                isOpen={toAddFile}
                onClose={closeUpload}
                categoryId={category?.id ?? 1}
            />

            <EditFileModal
                isOpen={toEditFile != null}
                onClose={closeUpload}
                item={toEditFile} />

            <MoveFileModal
                isOpen={isMoveFileOpen != null}
                onClose={closeMove}
                currentFileId={isMoveFileOpen}
                currentFolderId={currentId ?? "1"} />

            <CompleteModal
                icon={"🗑"}
                title={"Вы уверены, что хотите удалить папку?"}
                isOpen={isDeleteFolderOpen != null}
                setIsOpen={() => setIsDeleteFolderOpen(null)}
                btnList={deleteBtnList}
                onClick={handleDeleteFolder}
            />

            <CompleteModal
                icon={"🗑"}
                title={"Вы уверены, что хотите удалить файл?"}
                isOpen={isDeleteFileOpen != null}
                setIsOpen={() => setIsDeleteFileOpen(null)}
                btnList={deleteBtnList}
                onClick={handleDeleteFile}
            />

            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{category?.id == 1 ? "Файлы" : category?.name}</h1>
                    </div>
                )}
            </div>

            <div className="container files__content">
                {toAddFolder && (
                    <div ref={updateFolderRef}>
                        <FileCategoryRenameInput
                            categoryId={category?.id}
                            refresh={init} />
                    </div>
                )}

                {category?.children && category.children.map((item) => {
                        return toEditFolder == item ? (
                            <div ref={updateFolderRef}>
                                <FileCategoryRenameInput
                                    categoryId={category?.id}
                                    item={item}
                                    refresh={init} />
                            </div>
                        ) : (
                            <div
                                className={`files__controller ${!isActive(item) && "files__controller__disabled"}`}>
                                <div className="files__item"
                                     onContextMenu={(e) => showCategorySettings(e, item)}
                                     onClick={() => openCategory(item)}>

                                    <FolderIcon />
                                    <p className="files__item__name">{item.name}</p>
                                </div>

                                {selectedFolder == item && (
                                    <div ref={dropdownRef}>
                                        <FileCategorySettingsModal
                                            item={selectedFolder}
                                            onRename={() => setToEditFolder(item)}
                                            onClose={() => setSelectedFolder(null)}
                                            onDelete={() => setIsDeleteFolderOpen(item.id)} />
                                    </div>
                                )}
                            </div>
                        );
                    }
                )}

                {category?.items && category.items.map((item) => (
                    <div
                        className={`files__controller ${!isActive(item) && "files__controller__disabled"}`}>
                        <div className="files__item"
                             onContextMenu={(e) => showFileSettings(e, item)}
                             onClick={() => openFile(item)}>
                            <p className="files__item__name">{item.name}</p>
                        </div>

                        {selectedFile == item && (
                            <div ref={dropdownRef}>
                                <FileSettingsModal
                                    item={selectedFile}
                                    onReplace={() => setToEditFile(item)}
                                    onDelete={() => setIsDeleteFileOpen(item.id)}
                                    onMove={() => setIsMoveFileOpen(item.id)}
                                    onClose={() => setSelectedFile(null)} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};