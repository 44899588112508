import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import {
    HamburgerIcon,
} from "../../../img";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import Button from "../../ui/form/Buttons/Button";
import { TrashIcon } from "../../../img/icons/TrashIcon";
import {
    $createNamesField,
    $deleteNamesField,
    $editNamesField,
    $getNamesList
} from "../../../api/requests/workspace-settings/field-users";
import { NamesField } from "../../../api/requests/workspace-settings/field-users/interface";
import { withDebounce } from "../../../functions";
import DialogDragAndDropContainer from "../../ui/dialog/DialogDragAndDropContainer";

interface WorkspaceSettingFieldsModalModulesProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const WorkspaceSettingFieldsModalModules = (
    {
        isOpen,
        setIsOpen
    }: WorkspaceSettingFieldsModalModulesProps
) => {
    const personalData = useSelector(getPersonData);

    const [state, setState] = useState<NamesField[]>([]);

    useEffect(() => {
        $getNamesList().then(res => {
            setState(res)
        })
    }, []);

    function changeCheckboxes(select: any) {
        const requestData: any = {
            ...select,
            hidden_in_type: select.hidden_in_type ? 0 : 1
        };

        handleEdit(requestData)
    }

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'modules-setting') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue: NamesField[] = [...state];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            const requestData = {
                ...deletedItem,
                sort: targetIndex,
            };

            handleEdit(requestData);
        }
    }

    function handleEdit(requestData: NamesField) {
        $editNamesField(requestData, { formData: true }).then(res => {
            if (!res) return;

            setState(res);
        });
    }

    function handleChangeSelect(value: string, select: NamesField) {
        const updateObj = {
            ...select,
            name: value,
        }

        withDebounce(() => handleEdit(updateObj));

        const updateList = [...state].map(item => {
            if (item.id === select.id) return {
                ...updateObj,
            }

            return item
        });

        setState(updateList);
    }

    function handleDeleteModule(select: NamesField) {
        if (!select?.id) return;

        $deleteNamesField({ id: select.id }, { formData: true }).then(res => {
            if (!res) return;

            setState(res)
        })
    }
    function handleAdd() {
        const request = {
            title: "Новая категория",
            name: "Новое поле",
        }

        $createNamesField(request, { formData: true }).then(res => {
            if (!res) return;

            setState((prevState) => ([res, ...prevState]))
        })
    }

    return (
        <DialogDragAndDropContainer
            closeModal={() => setIsOpen(false)}
            isOpen={isOpen}
            label={"Настройка полей"}
            classNameModal={""}
            classNameHeader={"workspace-settings-users__field__header"}
            widthProps={427}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='list-setting' type="modules-setting">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={"workspace-settings-group__modules pl-0 pt-1 pb-2"}
                        >
                            {state.map((item, index) => (
                                <div key={`${item.id}-item-${index}`} className={"menuBlock"}>
                                    <Draggable
                                        draggableId={`${item.id}-item-${index}`}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={"drag-and-drop__block workspace-settings-group__modules-block"}
                                            >
                                                <div className={"left w-100 justify-content-between"}>
                                                    <div className={"left"}>
                                                        <HamburgerIcon color={"#00000080"} />

                                                        <input
                                                            value={item.name}
                                                            className={"workspace-settings-group__modules-field"}
                                                            onChange={(e) => handleChangeSelect(e.target.value, item)}
                                                            autoFocus
                                                        />
                                                    </div>

                                                    <div className={"right"}>
                                                        <Button
                                                            className="btn btn-text ml-auto"
                                                            text={<span style={{
                                                                opacity: 0.5,
                                                                display: "flex"
                                                            }}><TrashIcon /></span>}
                                                            onClick={() => handleDeleteModule(item)}
                                                        />

                                                        <ToggleBlock
                                                            desc={""}
                                                            isChecked={!!item.hidden_in_type}
                                                            handleChange={() => changeCheckboxes(item)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                </div>
                            ))}

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className={"pt-3 mt-1"}>
                <Button
                    text={"Добавить"}
                    className={"btn btn-primary w-100"}
                    onClick={handleAdd}
                />
            </div>
        </DialogDragAndDropContainer>
    )
}

export default WorkspaceSettingFieldsModalModules;