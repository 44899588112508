import { useDispatch, useSelector } from "react-redux";

import moment, { Moment } from "moment";
import { getDateByFormat, isMd } from "../../../utils";
import {
    getPersonData,
    getProfileData,
    getProfileVarsData,
    setOpenPopup,
    setPopupData
} from "../../../redux";
import { vacationColors, vacationStatus, vacationStatusColor } from "./constans";
import Button from "../../../components/ui/form/Buttons/Button";
import React, { useRef, useState } from "react";
import { ArrowRightIcon } from "../../../img";
import EventsCalendarModal from "../../../components/calendar/EventsCalendarModal";
import { LocationIcon } from "../../../img/icons/LocationIcon";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";

interface VacationUserData {
    panel: string;
    expand: string;
    handleExpand: (val: boolean | string) => void
    data: any;
}

export const EventsCalendarUser = (
    {
        panel,
        expand,
        handleExpand,
        data
    }: VacationUserData
) => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const currentUser = useSelector(getPersonData);

    const isAdmin = currentUser?.workspace_role?.role === 1;

    const vars = useSelector(getProfileVarsData);

    const contentHeight = useRef<any>(null);

    const validDate = moment(data?.start_date).valueOf() > moment().valueOf();

    const formatDate = (start: string, end: string) => {
        return start && end && `${moment(start)?.format("DD.MM")} - ${moment(end)?.format("DD.MM")}`;
    };

    const handleOpenBookingPopup = () => {
        dispatch(
            setPopupData({
                ...data
            })
        );
        dispatch(setOpenPopup());
    };

    function handleChangeAccordion() {
        if (isMobile) {
            handleOpenBookingPopup();
        } else {
            handleExpand(panel === expand ? false : panel)
        }
    }

    return (
        <div
            className={"accordion__wrapper"}
        >
            <div
                className={`accordion__question-container`}
                id={`panel-${panel}-header`}
                onClick={handleChangeAccordion}
            >
                <div className={"accordion__title"}>
                    <div
                        className={"accordion__avatar"}
                        style={{
                            backgroundColor: `${vars.colors.secondaryColor}1a`
                        }}
                    >
                        {data?.cover && <img src={data?.cover} />}
                    </div>

                    <div className={"accordion__info"}>
                        <div className={"accordion__name"}>{data?.title || "Запись"}</div>

                        <div className={"accordion__position"}>{data?.category?.title ? data.category.title : "Без категории"}</div>

                        <div className={"d-flex gap-1"}>
                            <div
                                className={"accordion__status accordion__status__primary"}
                                // style={{
                                //     backgroundColor: vars.colors[vacationStatusColor[status]] ? vars.colors[vacationStatusColor[status]] : vacationStatusColor[status]
                                // }}
                            >
                                {getDateByFormat(data?.start_date, "DD MMMM")}
                            </div>

                            <div
                                className={"accordion__status"}
                                // style={{
                                //     backgroundColor: vars.colors[vacationStatusColor[status]] ? vars.colors[vacationStatusColor[status]] : vacationStatusColor[status]
                                // }}
                            >
                                {getDateByFormat(data?.start_date, "HH:mm")}
                            </div>
                        </div>
                    </div>
                </div>

                {!isMobile && (
                    <div
                        className={`accordion__arrow ${(!isMobile && expand === panel) ? "active" : ""}`}
                    >
                        <ArrowRightIcon color={"#00000080"} />
                    </div>
                )}
            </div>

            <div
                ref={contentHeight}
                className={`answer-container ${(!isMobile && expand === panel) ? "active" : ""}`}
                style={
                    (!isMobile && expand === panel)
                        ? { height: contentHeight?.current?.scrollHeight || 0 }
                        : { height: "0px" }
                }
            >
                <div className={"accordion__content"}>
                    <div className="">
                        <div className="events-show-editor__content">
                            <div className="events-show-info__content">
                                <div
                                    className="mt-3"
                                    dangerouslySetInnerHTML={{ __html: data?.description }}
                                />
                            </div>
                        </div>

                        <div className="events-show-extra">
                            <h2 className="events-show-extra__title">
                                Дата проведения
                            </h2>

                            <div className="events-show-extra__desc">
                                {getDateByFormat(data?.start_date, "HH:mm, DD MMMM")}
                            </div>
                        </div>

                        <div className="events-show-extra">
                            <h2 className="events-show-extra__title">
                                Место проведения
                            </h2>

                            <div className="events-show-extra__desc">
                                <LocationIcon />
                                {data?.location}
                            </div>
                        </div>

                        <div className="events-show-extra">
                            <h2 className="events-show-extra__title">
                                Организатор
                            </h2>

                            <div className="events-show-author w-100">
                                <BlankImage image={data?.author?.image} />

                                <div className="events-show-author__content">
                                    <div className="events-show-author__name">
                                        {data?.author?.name}
                                    </div>
                                    <div className="events-show-author__role">
                                        {data?.author?.job_title}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        {(!data?.is_participating && validDate && data?.type !== "only_introductory") && (
                            <Button
                                text={"Записаться"}
                                className={"btn btn-light accordion__button accordion__action ml-0"}
                                onClick={handleOpenBookingPopup}
                            />
                        )}

                        <Button
                            text={"Перейти в мероприятие"}
                            className={"btn btn-light accordion__button accordion__action ml-0"}
                            onClick={() => navigate(`/events/show/${data?.id}`)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
