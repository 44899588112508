import { ArrowExitIcon } from "../../../img";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
import { $getDraftBlogs } from "../../../api/requests/blogs";
import { BlogsDraftCard } from "../../../components/blogs";
import { isMd } from "../../../utils";

export const BlogsDraftsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [data, setData] = useState<IBlogsRes[]>([])

    const handleNavigation = () => {
        navigate('/blogs');
    };


    useEffect(() => {
        $getDraftBlogs({newApi: true}).then(res => {
            if (!res.data) return;

            setData(res?.data?.data)
        })
    }, [])

    return (
        <div className='blogs-drafts'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Черновик</h1>
                    </div>
                ) }
            </div>

            <div className='container blogs-drafts-content'>
                {data.map((draft, idx) => (
                    <div  onClick={ () => navigate(  `/blogs/draft/show/${draft.id}`) } key={idx}>
                        <BlogsDraftCard data={draft}/>
                    </div>
                ))}
            </div>
        </div>
    )
}