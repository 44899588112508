import { useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPersonData, updateRouteTitle } from '../../../redux'
import {
    $getNewsItem,
    $getNewsList,
    $newsCustomLike, $newsLike,
    $writeNewsLogs,
} from '../../../api/requests/news'
import { ArrowExitIcon, CommentIcon, LikeIcon, ShareIcon, StarIcon } from '../../../img'
import { NewsCard } from '../../../components/news'
import { ClockIcon } from '@mui/x-date-pickers'
import { CommentMessenger } from '../../../components/comment-messenger'
import { isMd, successNotify } from '../../../utils'
import moment from 'moment'
import Button from '../../../components/ui/form/Buttons/Button'
import { LikeNewsIcon } from '../../../components/ui/like-icon/LikeIcon'
import { EyeIcon2 } from '../../../img/icons/news/EyeIcon2'
import { $toggleUserFavorites } from '../../../api/requests/favorites'
import { LikedIcon } from "../../../img/icons/news/LikedIcon";
import { EditorShow } from "../../../utils/field/editor-show";

export const NewsShowPage = () => {
    const isMobile = isMd()
    const profileData = useSelector(getPersonData)

    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const dispatch = useDispatch()

    const { id: newsId } = useParams()

    const [newsList, setNewsList] = useState([])
    const [newsData, setNewsData] = useState<any>([])

    const [isCommentLoading, setIsCommentLoading] = useState(false)

    const [isLikesValidate, setIsLikesValidate] = useState(
        (newsData?.users_like && !!+newsData?.users_like) || false,
    )

    const [isLikesCustomValidate, setIsLikesCustomValidate] = useState(
        (newsData?.users_custom_like && !!+newsData?.users_custom_like) || false,
    )

    const [isLiked, setIsLiked] = useState<boolean>(false)

    const hashtagsList = newsData.hashtags ? newsData.hashtags.split(',') : []

    const isTypeLike = newsData?.like_type && +newsData?.like_type > 0

    useEffect(() => {
        $getNewsList({
            workspace_id: profileData?.workspace_id,
            offset: 0,
            limit: 30,
        }, { formData: true }).then(res => {
            if (!res) return

            setNewsList(res)
        })
    }, [newsId])

    useEffect(() => {
        getNews()
    }, [isCommentLoading, newsId])

    useEffect(() => {
        dispatch(updateRouteTitle({ key: 'news_show', title: newsData?.title }))
    }, [newsData])

    function getNews() {
        if (!newsId) return

        $getNewsItem({ id: newsId }, { formData: true }).then(res => {
            if (!res) return

            setNewsData(res)
            setIsLikesValidate(!!res.users_like)
            setIsLikesCustomValidate(!!res.users_custom_like)
            setIsLiked(!!res.is_favorite)
        })
    }

    function handleShare() {
        const url = window.location.href
        navigator.clipboard.writeText(url)

        successNotify('Ссылка успешно скопирована').then()
    }

    const handleLike = (id: number | string, e: any) => {
        e.stopPropagation()

        $toggleUserFavorites({ type: 'news', type_id: id, add_to_favorites: !isLiked }, { newApi: true }).then(res => {
            setIsLiked(!isLiked)
        })
    }

    function requestWriteLogs() {
        if (!newsData?.id) return

        $writeNewsLogs({
            news_id: newsData.id,
            type: 1,
            title: 'Мне нравится',
        }, { newApi: true }).then()
    }

    function changeCustomLike() {
        if (!newsData?.id) return

        $newsCustomLike({
            id: newsData?.id,
            like: isLikesCustomValidate ? 0 : 1,
        }, { formData: true }).then((res) => {
            getNews()
        })

        requestWriteLogs()

        setIsLikesCustomValidate(!isLikesCustomValidate)
    }

    function handleLikes() {
        if (!newsData?.id) return

        $newsLike({
            id: newsData?.id,
            like: isLikesValidate ? 0 : 1,
        }, { formData: true }).then((res) => {
            getNews()
        })

        setIsLikesValidate(!isLikesValidate)
    }

    return (
        <div className="news-show">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{newsData?.title}</h1>
                    </div>
                )}
            </div>

            <div className="container news-show-content">
                <div className="news-show-content__left">
                    <div className="news-show-info">
                        <div className="news-show-info__image"
                             style={{ backgroundImage: `url(${newsData?.image})` }}
                        />

                        <div className="news-show-info__content">
                            <div className="news-show-info__header">
                                {isMobile && (
                                    <h5 className="news-show-info__title">{newsData?.title}</h5>
                                )}

                                <div
                                    className="news-show-info__header__practice"
                                >
                                    {newsData?.category_title ? newsData.category_title : 'Без категории'}
                                </div>

                                <div className="news-show-info__header__types-container">
                                    <div className="news-show-info__header__types">
                                        {hashtagsList.map((item: string, idx: number) => (
                                            <div
                                                key={`news-hashtags-item-${idx}`}
                                                className="news-show-info__header__types__item"
                                            >
                                                #{item}
                                            </div>
                                        ))}
                                    </div>

                                    {newsId ? (
                                        <div
                                            onClick={e => handleLike(newsId, e)}
                                            className={`blogs-card-block-content__likeIcon ${isLiked ? 'active' : ''}`}
                                        >
                                            <StarIcon opacity={!isLiked ? 0.3 : 1} />
                                        </div>
                                    ) : ''}
                                </div>
                            </div>

                            <div className="news-show-info__editor news-show-editor">
                                <EditorShow
                                    htmlData={newsData?.text}
                                    className={"contests__create__preview-item__content__text"}
                                />
                            </div>

                            {newsData?.date && (
                                <div className="news-show-info__date">
                                    <ClockIcon />
                                    <p>{moment(newsData?.date * 1000).format('DD MMMM, YYYY HH:mm')}</p>
                                </div>
                            )}

                            {isMobile && (
                                <div className="news-show-info__stats">
                                    <div className="news-show-info__stats__block">
                                        <LikeIcon color="#000" size={20} />

                                        <p>{newsData?.likes_count}</p>
                                    </div>

                                    <div className="news-show-info__stats__block">
                                        <CommentIcon color="#000" size={20} />

                                        <p>{newsData?.comments_count}</p>
                                    </div>

                                    <div className="news-show-info__stats__block">
                                        <EyeIcon2 color="#000" size={20} />

                                        <p>{newsData?.views}</p>
                                    </div>
                                </div>
                            )}

                            <div className="news-show-info__bottom">
                                <div className="news-show-info__buttons">
                                    <button
                                        style={{
                                            color: isLikesValidate ? '#000' : '#FFF',
                                            background: isLikesValidate ? 'rgba(0, 0, 0, 0.03)' : '#00A7B5',
                                        }}
                                        className={`news-show-info__buttons__likeButton`}
                                        onClick={handleLikes}
                                    >
                                        <LikeIcon
                                            size={24}
                                            color={isLikesValidate ? '#000' : '#FFF'}
                                        /> <p>{isLikesValidate ? 'Отменить' : 'Мне нравится'}</p>
                                    </button>

                                    <button
                                        className="news-show-info__buttons__shareButton"
                                        onClick={handleShare}
                                    >
                                        <ShareIcon size={24} color="#00A7B5" /> <p>Поделиться</p>
                                    </button>

                                    {!!isTypeLike && (
                                        <Button
                                            leftIcon={<LikeNewsIcon
                                                itemContent={newsData}
                                                fill={isLikesCustomValidate ? 'white' : 'black'}
                                            />}
                                            text={newsData?.like_name}
                                            className={`btn ${isLikesCustomValidate ? 'btn-primary' : 'btn-light'}`}
                                            onClick={() => changeCustomLike()}
                                        />
                                    )}
                                </div>

                                {!isMobile && (
                                    <div className="news-show-info__stats">
                                        <div className="news-show-info__stats__block"
                                             style={{ opacity: isLikesValidate && 1 }}>
                                            {isLikesValidate ? (
                                                <LikedIcon/>
                                            ) : (
                                                <LikeIcon
                                                    opacity={1}
                                                    color={ '#000'}
                                                    size={20}
                                                />
                                            )}

                                            <p>{newsData?.likes_count}</p>
                                        </div>

                                        <div className="news-show-info__stats__block">
                                            <CommentIcon color="#000" size={20} />

                                            <p>{newsData?.comments_count}</p>
                                        </div>

                                        <div className="news-show-info__stats__block">
                                            <EyeIcon2 color="#000" size={20} />

                                            <p>{newsData?.views}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {(newsData?.comments_array) ? (
                        <CommentMessenger
                            comments_data={newsData?.comments_array}
                            id={newsData.id}
                            setIsLoading={setIsCommentLoading}
                            url={'/news'}
                            notification_type={'news_comment'}
                            isAvailable={newsData.comments == 1}
                        />
                    ) : null}
                </div>

                <div className="news-show-content__right">
                    {newsList.slice(0, 4).map((
                        item: any,
                        idx,
                    ) => (
                        <div onClick={() => navigate(`/news/show/${item.id}`)} key={`news-show-right-item-${idx}`}>
                            <NewsCard data={item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}