import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ProfileQrCodeSmallIcon: FC<IBaseIconProps> = ({
    size = 22,
    color= '#000'
}) => {
    return (
        <svg width={size} height={size} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d="M14.25 11V13.75M11.5 2.75V5.5M17 11V13.75M11.5 16.5H19.75M17 19.25H19.75M6 11H8.75M6 5.51008L6.00917 5.5M11.5 11.0101L11.5092 11M3.25 11.0101L3.25917 11M11.5 8.26008L11.5092 8.25M11.5 13.7601L11.5092 13.75M14.25 19.2601L14.2592 19.25M11.5 19.2601L11.5092 19.25M19.75 11.0101L19.7592 11M19.75 13.7601L19.7592 13.75M17 5.51008L17.0092 5.5M6 16.5101L6.00917 16.5M8.75 3.3V7.7C8.75 7.84587 8.69205 7.98576 8.58891 8.08891C8.48576 8.19205 8.34587 8.25 8.2 8.25H3.8C3.65413 8.25 3.51424 8.19205 3.41109 8.08891C3.30795 7.98576 3.25 7.84587 3.25 7.7V3.3C3.25 3.15413 3.30795 3.01424 3.41109 2.91109C3.51424 2.80795 3.65413 2.75 3.8 2.75H8.2C8.34587 2.75 8.48576 2.80795 8.58891 2.91109C8.69205 3.01424 8.75 3.15413 8.75 3.3ZM19.75 3.3V7.7C19.75 7.84587 19.6921 7.98576 19.5889 8.08891C19.4858 8.19205 19.3459 8.25 19.2 8.25H14.8C14.6541 8.25 14.5142 8.19205 14.4111 8.08891C14.3079 7.98576 14.25 7.84587 14.25 7.7V3.3C14.25 3.15413 14.3079 3.01424 14.4111 2.91109C14.5142 2.80795 14.6541 2.75 14.8 2.75H19.2C19.3459 2.75 19.4858 2.80795 19.5889 2.91109C19.6921 3.01424 19.75 3.15413 19.75 3.3ZM8.75 14.3V18.7C8.75 18.8459 8.69205 18.9858 8.58891 19.0889C8.48576 19.1921 8.34587 19.25 8.2 19.25H3.8C3.65413 19.25 3.51424 19.1921 3.41109 19.0889C3.30795 18.9858 3.25 18.8459 3.25 18.7V14.3C3.25 14.1541 3.30795 14.0142 3.41109 13.9111C3.51424 13.8079 3.65413 13.75 3.8 13.75H8.2C8.34587 13.75 8.48576 13.8079 8.58891 13.9111C8.69205 14.0142 8.75 14.1541 8.75 14.3Z"
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
