import pad from '../pad/pad';
import moment from "moment/moment";

export const formatDate = (createdAtString: string): string => {
    const createdAt: Date = new Date(createdAtString);
    const now: Date = new Date();

    const millisecondsDiff = now.getTime() - createdAt.getTime();
    const secondsDiff = Math.floor(millisecondsDiff / 1000);
    const daysDiff = Math.floor(secondsDiff / 86400);

    if (daysDiff > 14) {
        // If more than 2 weeks, return date in "DD.MM" format
        const dd = String(createdAt.getDate()).padStart(2, '0');
        const mm = String(createdAt.getMonth() + 1).padStart(2, '0');
        return `${dd}.${mm}`;
    }

    let timeAgo = '';

    if (secondsDiff < 60) {
        timeAgo = `${secondsDiff} сек`;
    } else if (secondsDiff < 3600) {
        const minutes = Math.floor(secondsDiff / 60);
        timeAgo = `${minutes} мин`;
    } else if (secondsDiff < 86400) {
        const hours = Math.floor(secondsDiff / 3600);
        timeAgo = `${hours} час${hours % 10 === 1 && hours % 100 !== 11 ? '' : (hours % 10 >= 2 && hours % 10 <= 4 ? 'а' : 'ов')}`;
    } else {
        const days = Math.floor(secondsDiff / 86400);
        timeAgo = `${days} д${days % 10 === 1 && days % 100 !== 11 ? 'ень' : (days % 10 >= 2 && days % 10 <= 4 ? 'ня' : 'ней')}`;
    }

    return timeAgo;
};

export function getYearMonthDayAndTime(date: any) {
    const getDate = new Date(date)
    const year = getDate.getFullYear()
    const month = getDate.getMonth() + 1
    const day = getDate.getDate()
    const hour = getDate?.getHours()
    const minutes = getDate?.getMinutes()

    return `${pad(day)}-${pad(month)}-${year} ${pad(hour)}:${pad(minutes)}`
}

export function getYearMonthDay(date: any) {
    const getDate = new Date(date);
    const year = getDate.getFullYear();
    const month = getDate.getMonth() + 1;
    const day = getDate.getDate();

    return `${pad(day)}.${pad(month)}.${year}`;
}

export const modDate = (month: string): string => {
    switch (month) {
        case '01':
            return 'января'

        case '02':
            return 'февраля'

        case '03':
            return 'марта'

        case '04':
            return 'апреля'

        case '05':
            return 'мая'

        case '06':
            return 'июня'

        case '07':
            return 'июля'

        case '08':
            return 'августа'

        case '09':
            return 'сентября'

        case '10':
            return 'октября'

        case '11':
            return 'ноября'

        case '12':
            return 'декабря'

        default:
            return 'января'
    }
}

// from Mon Jan 19 1970 15:13:17 GMT-0500 (-05) to { month: '01', day: '01' etc}
export const calcDate = (date: number) => {
    const dateJs = date ? new Date(date * 1000) : new Date()
    const isFuture = new Date(date).getTime() > new Date().getTime() / 1000

    const curDay = new Date().getDate()
    const curMonth = new Date().getMonth() + 1
    const curYear = new Date().getFullYear()

    const targetDay = new Date(dateJs).getDate()
    const targetMonth = new Date(dateJs).getMonth() + 1
    const targetYear = new Date(dateJs).getFullYear()

    const isSameMonth = targetMonth === curMonth && curYear === targetYear
    const checkIsToday = isSameMonth && curDay === targetDay
    const checkIsYesterday = isSameMonth && curDay - 1 === targetDay

    return {
        month: `${dateJs.getMonth() + 1}`.padStart(2, '0'),
        day: `${dateJs.getDate()}`.padStart(2, '0'),
        hour: `${dateJs.getHours()}`.padStart(2, '0'),
        minute: `${dateJs.getMinutes()}`.padStart(2, '0'),
        year: `${dateJs.getFullYear()}`.padStart(2, '0'),
        isToday: checkIsToday,
        isYesterday: checkIsYesterday,
        isFuture,
    }
}

export const daysOfWeek = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

export const getWeekDay = (date: string | Date) => {
    const dateObj = new Date(date);

    return daysOfWeek[date instanceof Date ? date.getDay() : dateObj.getDay()];
}

export const getDateByFormat = (date: Date | string | number, format: string): string => {
    return date ? moment(date).format(format) : "..."
}
