import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface initialStateType {
    cities: any[];
    buildings: any[];
    floors: any[];
    selected: any;
    currentDate: any;
    openPopup: boolean;
    openDetailsPopup: boolean;
    plan: any;
    shapeData: any;
    popupData: any;
    isWorkplace: boolean;
    zoomByPlace: any;
    groupRemoveId: number | string;
    groupCreated: boolean;
    currentGroup: any;
    selectedUser: any;
    isOpenCreateBanner: boolean;
    toAddFolder: boolean;
    toAddFile: boolean;
    search: string;
}

const initialState: initialStateType = {
    cities: [],
    buildings: [],
    floors: [],
    selected: {
        city: null,
        building: null,
        floor: null,
        meet: null,
        isLoading: false
    },
    plan: null,
    currentDate: "",
    openPopup: false,
    openDetailsPopup: false,
    shapeData: null,
    popupData: null,
    isWorkplace: false,
    zoomByPlace: null,
    groupRemoveId: "",
    groupCreated: false,
    currentGroup: null,
    selectedUser: null,
    isOpenCreateBanner: false,
    toAddFolder: false,
    toAddFile: false,
    search: ""
};

export const workplaceSlice = createSlice({
    name: "workplace",
    initialState,
    reducers: {
        setCities: (state, { payload }: PayloadAction<any>) => {
            state.cities = payload;
        },
        setBuildings: (state, { payload }: PayloadAction<any>) => {
            state.buildings = payload;
        },
        setFloors: (state, { payload }: PayloadAction<any>) => {
            state.floors = payload;
        },
        setSelected: (state, { payload }: PayloadAction<any>) => {
            state.selected = { ...state.selected, ...payload };
            // localStorage.setItem('booking-front', JSON.stringify({ ...state.selected, ...payload }));
        },
        setCurrentDate: (state, { payload }: PayloadAction<any>) => {
            state.currentDate = payload;
        },
        setOpenPopup: (state) => {
            state.openPopup = !state.openPopup;
        },
        setOpenDetailsPopup: (state) => {
            state.openDetailsPopup = !state.openDetailsPopup;
        },
        setPlan: (state, { payload }: PayloadAction<any>) => {
            state.plan = payload;
        },
        setShapeData: (state, { payload }: PayloadAction<any>) => {
            state.shapeData = payload;
        },
        setPopupData: (state, { payload }: PayloadAction<any>) => {
            state.popupData = payload;
        },
        setIsWorkplace: (state, { payload }: PayloadAction<any>) => {
            state.isWorkplace = payload;
        },
        setPlacePosition: (state, { payload }: PayloadAction<any>) => {
            state.zoomByPlace = payload;
        },
        setGroupRemoveId: (state, { payload }: PayloadAction<any>) => {
            state.groupRemoveId = payload;
        },
        setGroupCreated: (state, { payload }: PayloadAction<any>) => {
            state.groupCreated = payload;
        },
        setCurrentGroupData: (state, { payload }: PayloadAction<any>) => {
            state.currentGroup = payload;
        },
        setIsOpenCreateBanner: (state, { payload }: PayloadAction<any>) => {
            state.isOpenCreateBanner = payload;
        },
        setToAddFolder: (state, { payload }: PayloadAction<any>) => {
            state.toAddFolder = payload;
        },
        setToAddFile: (state, { payload }: PayloadAction<any>) => {
            state.toAddFile = payload;
        },
        setSelectedUser: (state, { payload }: PayloadAction<any>) => {
            state.selectedUser = payload;
        },
        setSearch: (state, { payload }: PayloadAction<any>) => {
            state.search = payload;
        }
    }
});

export const {
    setSelectedUser,
    setBuildings,
    setFloors,
    setSelected,
    setCurrentDate,
    setOpenPopup,
    setOpenDetailsPopup,
    setPlan,
    setPopupData,
    setIsWorkplace,
    setPlacePosition,
    setGroupRemoveId,
    setGroupCreated,
    setCurrentGroupData,
    setIsOpenCreateBanner,
    setToAddFolder,
    setToAddFile,
    setSearch
} = workplaceSlice.actions;

export const getSelectedUser = (state: RootState) => state.workplace.selectedUser;
export const getBuildings = (state: RootState) => state.workplace.buildings;
export const getFloors = (state: RootState) => state.workplace.floors;
export const getSelected = (state: RootState) => state.workplace.selected;
export const getCurrentDate = (state: RootState) => state.workplace.currentDate;
export const getPlan = (state: RootState) => state.workplace.plan;
export const getPopupData = (state: RootState) => state.workplace.popupData;
export const getOpenPopup = (state: RootState) => state.workplace.openPopup;
export const getOpenDetailsPopup = (state: RootState) => state.workplace.openDetailsPopup;
export const getIsWorkplace = (state: RootState) => state.workplace.isWorkplace;
export const getPlacePosition = (state: RootState) => state.workplace.zoomByPlace;
export const getGroupRemoveId = (state: RootState) => state.workplace.groupRemoveId;
export const getGroupCreated = (state: RootState) => state.workplace.groupCreated;
export const getCurrentGroupData = (state: RootState) => state.workplace.currentGroup;
export const getIsOpenCreateBanner = (state: RootState) => state.workplace.isOpenCreateBanner;
export const getToAddFolder = (state: RootState) => state.workplace.toAddFolder;
export const getToAddFile = (state: RootState) => state.workplace.toAddFile;

export const getSearch = (state: RootState) => state.workplace.search;

export const workplaceSliceReducer = workplaceSlice.reducer;
