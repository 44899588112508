import HorizonNavigate from "../../components/navigation/HorizonNavigate";
import React, { useEffect, useState } from "react";
import CalendarDays from "../../components/calendar/CalendarDays";
import TrainingContentItem from "./TrainingContentItem";
import { $getSportList } from "../../api/requests/sport";
import {
    SportDateData,
    SportItemData,
    SportResponseDateData,
    SportViewItemData
} from "../../api/requests/sport/interface";
import { getWeekDay, isMd, isTillMd } from "../../utils";
import Preloader from "../../components/ui/preloader/Preloader";
import { useSelector } from "react-redux";
import { getCurrentRole } from "../../redux";
import moment from "moment/moment";
import InfiniteScroll from "../../components/ui/InfiniteScroll";

interface TrainingPageProps {
    isTrainerRole?: boolean;
}

const TrainingPage = (
    {
        isTrainerRole = false
    }: TrainingPageProps
) => {
    const isMobile = isTillMd();
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [currentDay, setCurrentDay] = useState(moment().format("yyyy-MM-DD"));
    const [sportContentList, setSportContentList] = useState<SportViewItemData[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const transformDateData = (data: SportResponseDateData[]): SportDateData[] => {
        return data?.map((item) => {
            const dateObj = new Date(item.date_from_at);
            const weekDay = getWeekDay(item.date_from_at);
            const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}`; // Format date as dd.mm
            const timeStart = new Date(item.date_from_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const timeEnd = new Date(item.date_to_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const time = `${timeStart} - ${timeEnd}`;

            return {
                week: weekDay,
                // date: formattedDate,
                time: time,
            };
        });
    }

    const transformContentData = (list: SportItemData[]): SportViewItemData[] => {
        return [...list].map(item => {
            return {
                ...item,
                schedules: transformDateData(item.schedules),
                seats: item.seats - (item?.unique_users?.length || 0),
            }
        })
    }

    useEffect(() => {
        setIsLoading(true);
        init();
    }, [currentDay]);

    function init() {
        const requestData = {
            page: 1,
            date: isTrainerRole ? undefined : currentDay
        }

        $getSportList(
            isAdmin || isTrainerRole,
            requestData,
            { newApi: true }
        ).then(res => {
            setIsLoading(false);
            if (!res.data?.data) return;

            setSportContentList(transformContentData(res.data.data))
        })
    }

    return (
        <InfiniteScroll
            url={isAdmin ? "backend/v1/trainings" : `api/v1/trainings`}
            setData={setSportContentList}
            data={sportContentList}
            params={{date: isTrainerRole ? undefined : currentDay}}
        >
            <div className={"sport"}>
                {(!isTrainerRole && !isMobile) && <HorizonNavigate isScroll={false} />}

                <div className="container sport__container">
                    <div className="sport__head">
                        <div className="row">
                            {!isMobile && (
                                <div className="col-md-2 d-flex align-items-center">
                                    <h2 className="sport__head-title">
                                        {isTrainerRole ? "Посещаемость" : "Спорт"}
                                    </h2>
                                </div>
                            )}

                            {!isTrainerRole && (
                                <div className="col-md-10 col-12">
                                    <div className={"sport__head__days"}>
                                        <CalendarDays
                                            currentDay={currentDay}
                                            setCurrentDay={setCurrentDay}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="sport__content">
                        <div className="row">
                            {(sportContentList.length && !isLoading) ? sportContentList.map((item, idx) => (
                                <div
                                    key={`sport-content-item-${idx}`}
                                    className="col-md-4 sport__content-card"
                                >
                                    <TrainingContentItem
                                        {...item}
                                        isTrainerRole={isTrainerRole}
                                        isMain
                                    />
                                </div>
                            )) : (
                                <div className={"empty__list w-100 mt-5"}>
                                    <h4>Нет тренировок</h4>
                                </div>
                            )}

                            {isLoading && null}
                        </div>
                    </div>
                </div>
            </div>
        </InfiniteScroll>
    );
};

export default TrainingPage;