import React from "react";
import { FC, useState, useEffect, ReactNode } from "react";
import { BlankImage } from "../ui/blank-image/BlankImage";
import {useNavigate} from "react-router-dom";

interface ISlides {
    id: number;
    layout?: any;
    type?: string;
    image: string;
    image_mobile?: string
    src?: string;
    // title?: string,
    // text?: string
}

interface IFeedGroupSlider {
    slidersNumber: number;
    slides: ISlides[];
}

export const SliderBanners: FC<IFeedGroupSlider> = ({
    slidersNumber,
    slides
}) => {
    const navigate = useNavigate();
    const [ isCurSlide, setCurSlide ] = useState(0);

    const [ isSlideChange, setSlideChange ] = useState(false);

    useEffect(() => {
        let interval: any;
        if (isSlideChange) {
            clearInterval(interval);
            setSlideChange(false);
            return;
        }
        interval = setInterval(
            () =>
                setCurSlide((s) => {
                    if (s === slidersNumber - 1) return 0;
                    return s + 1;
                }),
            5000,
        );

        return () => {
            clearInterval(interval);
        };
    }, [ isSlideChange ]);

    const handleSlideChange = (index: number) => {
        setCurSlide(index);
        setSlideChange(true);
    };

    const [ touchStart, setTouchStart ] = useState(null);
    const [ touchEnd, setTouchEnd ] = useState(null);

    const minSwipeDistance = 50;

    const onTouchStart = (e: any) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe || isRightSwipe) {
            const isLeft = isLeftSwipe;

            setCurSlide((s) => {
                if (isLeft && s === 0) return slidersNumber - 1;
                if (!isLeft && s === slidersNumber - 1) return 0;
                return isLeft ? s - 1 : s + 1;
            });

            setSlideChange(true);
        }
    };

    return (
        <div className='slider-banners'
             onTouchStart={ onTouchStart }
             onTouchMove={ onTouchMove }
             onTouchEnd={ onTouchEnd }
        >

            {/*<div className='slider-banners__linear'>*/}

            {/*</div>*/}
            {/*<div className='slider-banners__backgroundContent'>*/}

            {/*</div>*/}


            <div className='slider-banners__dotContainer' style={ {
                position: 'absolute',
                right: '1rem', left: 'unset'
            } }>
                { [ ...new Array(slidersNumber) ].map((
                    _,
                    index
                ) => (
                    <div
                        key={ index }
                        className={ `slider-banners__dot ${ isCurSlide === index ? 'slider-banners__activeDot' : '' }` }
                        style={ {
                            backgroundColor: isCurSlide === index ? '#ffffff' : 'rgba(255, 255, 255, 0.5)',
                            opacity: isCurSlide === index ? 'unset' : '.2',
                            border:
                                isCurSlide === index
                                    ? `1px solid #ffffff`
                                    : `1px solid  rgba(255, 255, 255, 0.5)`,
                        } }
                        onClick={ () => handleSlideChange(index) }
                    />
                )) }
            </div>

            {slides && slides.map((
                    item,
                    index
                ) =>
                    <React.Fragment key={ index }>
                        <div className={ `fade ${ index === isCurSlide 
                            ? "fade-enter-active" 
                            : "fade-exit-active" }` }
                        >
                            <BlankImage
                              image={item.image}
                              classname='removeBackFace'
                              style={ {position: "absolute"} }
                              onClick={ () => {
                                  if (!slides[isCurSlide].src) return;

                                  if ((slides[isCurSlide].src as string).includes("http") || (slides[isCurSlide].src as string).includes("https")) {
                                      window.open(slides[isCurSlide].src, "_blank");
                                  } else {
                                      window.open(`//${ slides[isCurSlide].src }`, "_blank");
                                  }
                              } }
                            />
                            {/*<img*/}
                            {/*    src={ image }*/}
                            {/*    className="removeBackFace"*/}
                            {/*   */}
                            {/*    onClick={ () => {*/}
                            {/*        if (!src) return;*/}

                            {/*        if (src.includes("http") || src.includes("https")) {*/}
                            {/*            window.open(src, "_blank");*/}
                            {/*        } else {*/}
                            {/*            window.open(`//${ src }`, "_blank");*/}
                            {/*        }*/}
                            {/*    } }*/}
                            {/*/>*/}
                        </div>
                        <div className='slider-banners__content'>
                            {/*<h1>*/}
                            {/*    { title }*/}
                            {/*</h1>*/}

                            {/*<p>*/}
                            {/*    { text }*/}
                            {/*</p>*/}
                        </div>
                    </React.Fragment>
            ) }

            {/*<img*/ }
            {/*    // src={chooseImage()}*/ }
            {/*    src={slides[0]?.background || slides[0]?.image}*/ }
            {/*    className="removeBackFace"*/ }
            {/*    style={{ visibility: 'hidden' }}*/ }
            {/*/>*/ }
        </div>
    )
}