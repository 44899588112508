import TextField from "../../components/ui/form/Fields/TextField";
import {
    ContestIcon,
    InfoIcon,
    NewsIcon,
    SettingIcon
} from "../../img";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import ToggleBlock from "../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../components/ui/form/Buttons/Button";
import WorkspaceStatuses from "../../components/workspace-users/WorkspaceStatuses";
import { StateData } from "./groups/WorkspaceSettingGroupModules";
import { ProfileQrCodeSmallIcon } from "../../img/icons/layout-components";
import RadioBlock, { ListRadioData } from "../../components/ui/form/Toggle/RadioBlock";
import WorkspaceSettingsUserUpload
    from "../../components/workspace-users/general/WorkspaceSettingsUserUpload";
import { useDispatch, useSelector } from "react-redux";
import { changeMainPage, getMainPageData, getPersonData, getProfileVarsData } from "../../redux";
import {
    WorkspaceSettingSocModal
} from "../../components/workspace/settings/WorkspaceSettingSocModal";
import { $mainPageData, $mainPageNewData, $workSpaceEdit } from "../../api/requests/main";
import { successNotify } from "../../utils";
import { IdeasIcon } from "../../img/icons/menu/IdeasIcon";
import { VkIcon } from "../../img/icons/social/VkIcon";
import { TelegramIcon } from "../../img/icons/social/TelegramIcon";
import { InstagramIcon } from "../../img/icons/social/InstagramIcon";
import { FacebookIcon } from "../../img/icons/social/FacebookIcon";
import { BirthdayIcon } from "../../img/icons/menu/BirthdayIcon";

interface FormData {
    title: string;
    status: number;
    main_group_title: string;
    adaptation?: string;
    count?: number;
    no_system_news: number;
    groups_first_then_news: number;
    qr_code_enabled: number;
}

const WorkspaceSettingAll = () => {
    const dispatch = useDispatch();

    const vars = useSelector(getProfileVarsData);
    const personalData = useSelector(getPersonData);
    const mainData = useSelector(getMainPageData);

    const statuses = [
        {
            label: "Бесплатный",
            value: 2
        },
        {
            label: "По коду",
            value: 3
        },
        {
            label: "По почте",
            value: 4
        },
        {
            label: "Вручную",
            value: 5
        },
        {
            label: "Закрытый",
            value: 6
        }
    ];

    const [editData, setEditData] = useState<any>(null);

    const [modulesList, setModulesList] = useState<StateData[]>([
        {
            name: "Новости",
            url: "/news",
            icon: <NewsIcon />,
            key: "news",
            value: 0
        },
        {
            name: "Конкурсы",
            url: "/competitions",
            icon: <ContestIcon />,
            key: "contests",
            value: 0
        },
        // {
        //     name: "Опросы",
        //     url: "/polls",
        //     icon: <PollsIcon />,
        //     key: "interviews",
        //     value: 0,
        // },
        {
            name: "Группы",
            url: "/groups",
            icon: <InfoIcon />,
            key: "groups",
            value: 0
        },
        {
            name: "Идеи",
            url: "/ideas",
            icon: <IdeasIcon />,
            key: "ideas",
            value: 0
        },
        {
            name: "Дни рождения",
            url: "/birthday",
            icon: <BirthdayIcon />,
            key: "birthday",
            value: 0
        },
        // {
        //     name: "Спорт",
        //     url: "/sport",
        //     icon: <SportIcon />,
        //     key: "sport",
        //     value: 0,
        // },
        // {
        //     name: "Блоги",
        //     url: "/blogs",
        //     icon: <BlogIcon />,
        //     key: "posts",
        //     value: 0,
        // },
    ]);

    const [socials, setSocials] = useState([
        {
            key: "soc_vk",
            social: <VkIcon/>,
            name: "Вконтакте",
            url: "",
            defaultUrl: "http://vk.com/"
        },
        {
            key: "soc_telegram",
            social: <TelegramIcon/>,
            name: "Телеграмм",
            url: "",
            defaultUrl: "https://telegram.org/"
        },
        {
            key: "soc_instagram",
            social: (
                <InstagramIcon/>
            ),
            name: "Инстаграмм",
            url: "",
            defaultUrl: "https://instagram.com/"
        },
        {
            key: "soc_facebook",
            social: (
                <FacebookIcon/>
            ),
            name: "Фейсбук",
            url: "",
            defaultUrl: "http://facebook.com/"
        }
    ]);

    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [isOpenSettingLinkSoc, setIsOpenSettingLinkSoc] = useState(false);
    const [settingLinkSoc, setSettingLinkSoc] = useState(null);

    const [radioNewsList, setRadioNewsList] = useState<ListRadioData[]>([
        {
            key: 1,
            desc: "Группы перед блоком Новости"
        },
        {
            key: 0,
            desc: "Новости перед блоком Группы"
        }
    ]);

    const {
        handleSubmit,
        control
    } = useForm<FormData>(
        {
            values: {
                title: editData?.title,
                // adaptation: editData?.adaptation || undefined,
                status: editData?.workspace_status && +editData?.workspace_status,
                main_group_title: editData?.main_group_title,
                // count: editData?.count,
                no_system_news: +editData?.no_system_news,
                groups_first_then_news: +editData?.groups_first_then_news,
                qr_code_enabled: +editData?.qr_code_enabled
            }
        }
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value === val);
        }
        return null;
    };

    const removeUndefinedKeys = (obj: any) => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== undefined)
        );
    };

    useEffect(() => {
        if (personalData?.id && mainData?.design) init();
    }, [personalData, mainData]);

    function init() {
        setEditData({ ...personalData, ...mainData });

        const updateSocList = [...socials].map(item => ({
            ...item,
            url: mainData[item.key]
        }));

        setSocials(updateSocList);

        const updateModuleList = [...modulesList].map(item => ({
            ...item,
            value: +mainData[`workspace_${item.key}`]
        }));

        setModulesList(updateModuleList);
    }

    function onSubmit(data: FormData) {
        const requestData: any = {
            ...removeUndefinedKeys(data),
            id: editData.workspace_id
        };

        $workSpaceEdit(
            requestData,
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            $mainPageData().then((res) => {
                if (!res || res.error) return;

                dispatch(changeMainPage(res));
            });

            successNotify("Настройки успешно сохранены").then();
        });
    }

    function changeCheckboxes(select: any) {
        const updateList = [...modulesList].map(item => {
            if (item.key === select.key) return {
                ...item,
                value: select.value ? 0 : 1
            };

            return item;
        });

        const requestData: any = {
            [`${select.key}`]: select.value ? 0 : 1,
            id: editData.workspace_id
        };

        $workSpaceEdit(
            requestData,
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            setModulesList(updateList);
        });
    }

    function changeCheckboxesSoc(select: any) {
        const updateList = [...socials].map(item => {
            if (item.social === select.social) return {
                ...item,
                url: !!select.url ? "" : select.defaultUrl
            };

            return item;
        });

        const requestData: any = {
            [select.key]: !!select.url ? "" : select.defaultUrl,
            id: editData.workspace_id
        };

        $workSpaceEdit(
            requestData,
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            setSocials(updateList);

            $mainPageNewData().then((res) => {
                if (!res || res.error) return;

                dispatch(changeMainPage(res));
            });
        });
    }

    function handleEditSocModal(select: any) {
        setIsOpenSettingLinkSoc(true);
        setSettingLinkSoc(select);
    }

    return (
        <div className={"workspace-settings-users__field"}>
            <div className={"workspace-settings-users__field-block"}>
                <form
                    className={"workspace-settings-users__accrual w-100"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}>
                        <h3>Название и тип</h3>

                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: "Введите название"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className="mb-3"
                                    title="Название организации"
                                    placeholder={"Введите название"}
                                    name="title"
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="status"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: "Выберете доступ"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select mb-3"}
                                    label={"Доступ"}
                                    placeholder={"Вручную"}
                                    options={statuses}
                                    value={getValue(statuses, value)}
                                    onChange={(select) => onChange(select.value)}
                                    isSearchable
                                />
                            )}
                        />

                        <Controller
                            name="main_group_title"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: "Введите название"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className="mb-3"
                                    title="Название общей группы"
                                    placeholder={"Введите название"}
                                    name="main_group_title"
                                    onChange={onChange}
                                />
                            )}
                        />
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Статусы</h3>

                        <WorkspaceStatuses />
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}>
                        <h3>Модули</h3>

                        <div className={"workspace-settings__modules"}>
                            {modulesList.map((item, index) => (
                                <div
                                    key={`${item.key}-item-${index}`}
                                    className={"drag-and-drop__block workspace-settings__modules-block"}
                                >
                                    <div className={"left w-100 justify-content-between"}>
                                        <div className={"left"}>
                                            {item.icon}

                                            <p>{item.name}</p>
                                        </div>

                                        <ToggleBlock
                                            className={"gap-0"}
                                            desc={""}
                                            isChecked={!!item.value}
                                            handleChange={() => changeCheckboxes(item)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <hr />

                    {/*<div className={"workspace-settings-users__accrual-block"}>*/}
                    {/*    <h3>Адаптация</h3>*/}

                    {/*    /!*<div className={"d-flex gap-5 my-3"}>*!/*/}
                    {/*    /!*    <Controller*!/*/}
                    {/*    /!*        name="count"*!/*/}
                    {/*    /!*        control={control}*!/*/}
                    {/*    /!*        rules={{*!/*/}
                    {/*    /!*            required: {*!/*/}
                    {/*    /!*                value: false,*!/*/}
                    {/*    /!*                message: ""*!/*/}
                    {/*    /!*            }*!/*/}
                    {/*    /!*        }}*!/*/}
                    {/*    /!*        render={({ field: { onChange, value } }) => (*!/*/}
                    {/*    /!*            <CountField*!/*/}
                    {/*    /!*                value={value}*!/*/}
                    {/*    /!*                label="Отображение ссылки"*!/*/}
                    {/*    /!*                onChange={onChange}*!/*/}
                    {/*    /!*                disabled*!/*/}
                    {/*    /!*            />*!/*/}
                    {/*    /!*        )}*!/*/}
                    {/*    /!*    />*!/*/}

                    {/*    /!*    <Controller*!/*/}
                    {/*    /!*        name="count"*!/*/}
                    {/*    /!*        control={control}*!/*/}
                    {/*    /!*        rules={{*!/*/}
                    {/*    /!*            required: {*!/*/}
                    {/*    /!*                value: false,*!/*/}
                    {/*    /!*                message: "Выберете"*!/*/}
                    {/*    /!*            }*!/*/}
                    {/*    /!*        }}*!/*/}
                    {/*    /!*        render={({ field: { onChange, value } }) => (*!/*/}
                    {/*    /!*            <Dropdown*!/*/}
                    {/*    /!*                className={"form-select"}*!/*/}
                    {/*    /!*                label={"Периодичность"}*!/*/}
                    {/*    /!*                options={whoList}*!/*/}
                    {/*    /!*                value={getValue(whoList, value)}*!/*/}
                    {/*    /!*                onChange={(select) => onChange(select.value)}*!/*/}
                    {/*    /!*                // onInputChange={(value) => setSearch(value)}*!/*/}
                    {/*    /!*                isSearchable*!/*/}
                    {/*    /!*            />*!/*/}
                    {/*    /!*        )}*!/*/}
                    {/*    /!*    />*!/*/}
                    {/*    /!*</div>*!/*/}

                    {/*    <Controller*/}
                    {/*        name="adaptation"*/}
                    {/*        control={control}*/}
                    {/*        rules={{*/}
                    {/*            required: {*/}
                    {/*                value: false,*/}
                    {/*                message: "Введите название"*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        render={({ field: { onChange, value } }) => (*/}
                    {/*            <TextField*/}
                    {/*                value={value || ""}*/}
                    {/*                className="mt-1"*/}
                    {/*                title="Ссылка на источник"*/}
                    {/*                prepend={<ClipIcon />}*/}
                    {/*                placeholder={"Введите ссылку"}*/}
                    {/*                name="adaptation"*/}
                    {/*                onChange={onChange}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    {/*<hr />*/}

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}
                    >
                        <h3>Отображение системных новостей</h3>

                        <div className={"workspace-settings__modules"}>
                            <Controller
                                name="no_system_news"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleBlock
                                        isChecked={!!value}
                                        desc="Не показывать системные новости"
                                        handleChange={(value) => onChange(value ? 1 : 0)}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}
                    >
                        <h3>Отображение блоков на главной</h3>

                        <div className={"workspace-settings__modules"}>
                            <Controller
                                name="groups_first_then_news"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <RadioBlock
                                        list={radioNewsList}
                                        isChecked={value}
                                        handleChange={onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}>
                        <h3>Настройка соц. сетей в футере</h3>

                        <div className={"workspace-settings__modules"}>
                            {socials.map((item, index) => (
                                <div
                                    key={`${item.social}-item-${index}`}
                                    className={"drag-and-drop__block workspace-settings__modules-block"}
                                >
                                    <div className={"left w-100 justify-content-between"}>
                                        <div className={"left"}>
                                            {item.social}

                                            <p>{item.name}</p>
                                        </div>

                                        <div className={"right"}>
                                            <div
                                                className={"setting cursor-pointer"}
                                                onClick={() => handleEditSocModal(item)}
                                            >
                                                <SettingIcon color={"#00A7B5"} />
                                            </div>

                                            <ToggleBlock
                                                className={"gap-0"}
                                                desc={""}
                                                isChecked={!!item.url}
                                                handleChange={() => changeCheckboxesSoc(item)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}
                    >
                        <h3>Настройка кнопок приложения в футере</h3>

                        <div className={"workspace-settings__modules"}>
                            <Controller
                                name="qr_code_enabled"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleBlock
                                        imgLeft={<ProfileQrCodeSmallIcon />}
                                        isChecked={!!value}
                                        desc="QR Code"
                                        handleChange={(value) => onChange(value ? 1 : 0)}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Загрузка пользователей</h3>

                        <WorkspaceSettingsUserUpload
                            colorScheme={vars}
                            uploadFileUrl={"backend/v1/workspaces/users/import"}
                            downloadFileUrl={"api/workspaces/download_new_ws_users"}
                            typeDoc={"users"}
                            setIsUploadingFile={setIsUploadingFile}
                            isUploadingFile={isUploadingFile}
                        />
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Загрузка баллов и монет</h3>

                        <WorkspaceSettingsUserUpload
                            colorScheme={vars}
                            uploadFileUrl={"backend/v1/workspaces/users/import-balances-points"}
                            secondDownloadUrl={"backend/v1/workspaces/users/import-balances-points-add"}
                            downloadFileUrl={"api/workspaces/download_ws_users"}
                            typeDoc={"balances_points"}
                            textBtnName={"Заменить документ"}
                            setIsUploadingFile={setIsUploadingFile}
                            isUploadingFile={isUploadingFile}
                        />
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-toggle d-flex gap-3 mt-4"}>
                        <Button
                            type={"submit"}
                            text={"Сохранить"}
                            className={"btn btn-primary w-100"}
                        />
                    </div>
                </form>
            </div>

            <WorkspaceSettingSocModal
                isOpen={isOpenSettingLinkSoc}
                onClose={() => setIsOpenSettingLinkSoc(false)}
                data={settingLinkSoc}
            />
        </div>
    );
};

export default WorkspaceSettingAll;