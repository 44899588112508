import { FC } from "react";
import { IBaseIconProps } from "../types";

export const VkIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "black"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_526_2327)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.7514 7.389C21.6614 7.813 21.3634 8.433 20.8494 9.289C20.4309 9.98327 20.0032 10.672 19.5664 11.355C19.0514 12.098 18.8994 12.315 18.9034 12.297C18.8134 12.432 18.7764 12.517 18.7764 12.512C18.7764 12.486 18.7964 12.526 18.8584 12.599L19.1624 12.936C20.9074 14.73 21.8834 15.96 22.1364 16.75C22.2424 17.174 22.1914 17.554 21.9454 17.844C21.7104 18.122 21.3654 18.25 20.9484 18.25H18.8734C18.5034 18.25 18.1614 18.13 17.8934 17.92C17.7034 17.794 17.4334 17.523 17.0404 17.08C16.7383 16.7353 16.4262 16.3995 16.1044 16.073C15.2574 15.27 14.6454 14.875 14.3744 14.875C14.2954 14.875 14.2714 14.881 14.2964 14.86C14.3544 14.81 14.3474 14.836 14.3424 15.008C14.3234 15.14 14.3124 15.49 14.3124 16.028L14.3104 16.9C14.3474 17.342 14.2164 17.714 13.8784 17.955C13.5614 18.167 13.0614 18.25 12.3354 18.25C11.1414 18.25 9.94244 17.897 8.74444 17.198C7.55444 16.503 6.51544 15.515 5.64044 14.25C4.8577 13.2 4.17188 12.081 3.59144 10.907C3.19013 10.1149 2.83845 9.2987 2.53844 8.463C2.36244 7.835 2.27344 7.391 2.27344 7.098C2.27344 6.403 2.74844 6 3.47644 6H5.55044C5.92244 6 6.23444 6.095 6.46744 6.306C6.67544 6.495 6.82544 6.779 6.93744 7.143C7.25744 8.058 7.64544 8.949 8.10444 9.823C8.51144 10.613 8.91444 11.253 9.31244 11.738C9.51644 11.988 9.68544 12.154 9.79244 12.245C9.79554 12.1817 9.79688 12.1184 9.79644 12.055L9.79744 9.027C9.78525 8.74314 9.71515 8.46477 9.59144 8.209C9.51843 8.05571 9.4249 7.91307 9.31344 7.785C9.08244 7.505 8.95344 7.246 8.95344 6.975C8.95332 6.84043 8.9824 6.70743 9.03869 6.5852C9.09497 6.46296 9.17711 6.3544 9.27944 6.267C9.47644 6.092 9.71744 6 9.98044 6H13.2504C13.6104 6 13.9104 6.12 14.0994 6.39C14.2524 6.609 14.3124 6.899 14.3124 7.273V11.386C14.4014 11.329 14.5504 11.192 14.7424 10.981C15.1654 10.513 15.5924 9.929 16.0184 9.234C16.3144 8.734 16.5994 8.186 16.8804 7.577L17.2174 6.907C17.4574 6.323 17.9324 6 18.5584 6H20.6324C21.5114 6 21.9724 6.556 21.7524 7.389H21.7514Z"
                    stroke={color}
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_526_2327">
                    <rect width="24" height="24" fill="white" transform="translate(0.273438)" />
                </clipPath>
            </defs>
        </svg>
    );
};