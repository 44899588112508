import { ArrowExitIcon } from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PollsCard, PollsQuestionsModal } from "../../components/polls";
import { $getPollsList } from "../../api/requests/polls";
import { IPolls, IPolls2 } from "../../api/requests/polls/interface";
import { isMd } from "../../utils";
import InfiniteScroll from "../../components/ui/InfiniteScroll";

export const PollsPage = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id: any = searchParams.get("id")
    const isMobile = isMd();

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [selectedData, setSelectedData] = useState<IPolls2>()
    const [pollsData, setPollsData] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const _params = {
        workspace_id: 1620,
        offset: 0,
    };

    const [params, setParams] = useState(_params);

    const handleSelectItem = (item: IPolls2) => {
        setIsOpenModal(true)
        setSelectedData(item)
    }
    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        $getPollsList().then(res => {
            if (!res) return;

            const filteredList = res.filter((item: any) => item.status == 1)

            setPollsData(filteredList)
        })
    }, [isLoading])

    return (
        <InfiniteScroll
            data={pollsData}
            setData={setPollsData}
            url={'api/interviews/listinterviews'}
            isNewApi={false}
        >
            <div className='polls'>
                <div className='container layout-inner__header profile-header'>
                    { !isMobile && (
                        <div className='layout-inner__header__title'>
                            <div className='header__exitIcon' onClick={ handleNavigation }>
                                <ArrowExitIcon />
                            </div>
                            <h1>Опросы</h1>
                        </div>
                    ) }
                </div>

                <div className='container polls-content'>
                    {pollsData.map((item: any, idx) => (
                        <div key={idx} onClick={() => navigate(`/polls?id=${item.id}`)}>
                            <PollsCard data={item} />
                        </div>
                    ))}
                </div>

                {id && (
                    <PollsQuestionsModal
                        isOpenModal={!!id}
                        onClose={() => navigate('/polls')}
                        setIsLoading={setIsLoading}
                    />
                )}
            </div>
        </InfiniteScroll>
    )
}