import { useLocation, useNavigate } from "react-router-dom";
import { isMd } from "../../../utils";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../../redux";
import React, { useEffect, useState } from "react";
import { $getEventList } from "../../../api/requests/events";
import TextField from "../../../components/ui/form/Fields/TextField";
import { ArrowExitIcon, SearchIcon } from "../../../img";
import { EventCard } from "../../../components/events/card/EventCard";

export const EventsDraftPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();

    const profileData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get("catId") || undefined;

    const [eventsData, setEventsData] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [isOpenCatModal, setIsOpenCatModal] = useState(false);

    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(() => {
        init();
    }, [catId]);

    function init() {
        getEventsList();
    }

    function getEventsList() {
        $getEventList({ category_id: catId }, { newApi: true }).then((res: any) => {
            if (!res.data.data) return;

            setEventsData(res.data.data.filter((item: any) => !item.is_active));
            setFilterList(res.data.data.filter((item: any) => !item.is_active));
        });
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const filterList = [...eventsData].filter(item => {
            return (item.title.toLowerCase().includes(value.toLowerCase()));
        });

        setFilterList(filterList);
    }

    return (
        <div className="events">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Черновик</h1>
                    </div>
                )}

                <div className="news-search">
                    <div className="search__block-field">
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"black"} />}
                            className={`search__block-textField`}
                        />
                    </div>
                </div>
            </div>

            <div className="container events-content">
                <div className="events-content__items">
                    {filterList.map((item, idx) => (
                        <div key={idx} onClick={() => navigate(`/events/edit/${item?.id}`)}>
                            <EventCard data={item} stopRedirect/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};