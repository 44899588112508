export const ReloadIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path
                    d="M5.62115 15C6.63914 18.1939 9.55463 20.5 12.9924 20.5C17.2768 20.5 20.75 16.9183 20.75 12.5C20.75 8.08172 17.2768 4.5 12.9924 4.5C10.121 4.5 7.61399 6.10879 6.27267 8.5M5.62115 7.71845L6.68971 8.75008M8.62879 9.5H4.75V5.5L8.62879 9.5Z"
                    stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    )
}