import React, { FC, Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isMd } from "../../utils";
import {
    CloseIcon,
    HamburgerIcon, LogoFooterIcon,
    SearchIcon,
    SliderFeedIcon,
    SliderServicesIcon
} from "../../img";
import { ShopIcon } from "../../img/icons/ShopIcon";
import { useSelector } from "react-redux";
import { getMainPageData } from "../../redux";
import { VkIcon } from "../../img/icons/social/VkIcon";
import { TelegramIcon } from "../../img/icons/social/TelegramIcon";
import { FacebookIcon } from "../../img/icons/social/FacebookIcon";
import { InstagramIcon } from "../../img/icons/social/InstagramIcon";
import { $storeToken } from "../../api/requests/auth";
import redirectUrl from "../../utils/redirect/redirectUrl";
import { HomeIcon } from "../../img/icons/menu/HomeIcon";
import { SliderHomeIcon } from "../../img/icons/slider/SliderHomeIcon";

interface FooterHomeProps {
    openMenu?: () => void;
    setSearchModalOpen?: () => void;
    onClickMenuFooter?: (i: any) => void;
    isOpenMenu?: boolean;
    className?: string;
}

const FooterHome: FC<FooterHomeProps> = (
    {
        isOpenMenu,
        openMenu,
        onClickMenuFooter,
        className = ""
    }
) => {
    const location = useLocation();
    const isMobile: boolean = isMd();

    const mainData = useSelector(getMainPageData);

    const [mobileFooterRouters, setMobileFooterRouters] = useState([
        {
            id: 0,
            title: "Главная",
            route: "/",
            icon: <SliderHomeIcon />
        },
        {
            id: 6,
            title: "Сервисы",
            route: "/services",
            icon: <SliderServicesIcon />
        },
        {
            id: 1,
            title: "Корп. маг.",
            route: "",
            icon: <ShopIcon opacity={1} />
        },

        {
            id: 4,
            key: "search",
            title: "Поиск",
            route: "",
            icon: <SearchIcon />
        },

        {
            id: 7,
            key: "navigation",
            title: "Навигация",
            route: "",
            icon: <HamburgerIcon opacity={1} size={28} />
        }

        // {
        //     id: 3,
        //     title: 'Справки',
        //     route: '/reference',
        //     icon: <SliderReferenceIcon />
        // },
        //
        // {
        //     id: 3,
        //     title: 'Отпуска',
        //     route: '/vacation',
        //     icon: <SliderWorkspaceIcon />
        // },
    ]);

    const isNoHome: string[] = ["home"];

    useEffect(() => {
        const updateList = [...mobileFooterRouters].map(item => {
            if (item.key === "navigation") return {
                ...item,
                icon: isOpenMenu
                    ? <CloseIcon color={"#00A7B5"} strokeWidth={2} opacity={1} />
                    : <HamburgerIcon opacity={1} size={28} />
            };

            return item;
        });

        setMobileFooterRouters(updateList);
    }, [isOpenMenu]);

    function fetchTokenForStore() {
        $storeToken({ newApi: true }).then(res => {
            if (!res) return;

            let token = res?.data?.token;

            redirectUrl(`${process.env.REACT_APP_CSTORE_URL}/auth/${token}`);
        });
    }

    return (
        <>
            {!isMobile && (
                <footer className={`footer ${className}`}>
                    <div className={"container-fluid"}>
                        <div className={"footer__item"}>
                            <Link to={"/"} className={"footer__logo"}>
                                <LogoFooterIcon />
                            </Link>

                            <div className={"footer__social"}>
                                {mainData?.soc_vk && (
                                    <Link
                                        to={mainData?.soc_vk}
                                        className={"footer__social-block"}
                                    >
                                        <VkIcon />
                                    </Link>
                                )}

                                {mainData?.soc_facebook && (
                                    <Link
                                        to={mainData?.soc_facebook}
                                        className={"footer__social-block"}
                                    >
                                        <FacebookIcon />
                                    </Link>
                                )}

                                {mainData?.soc_telegram && (
                                    <Link
                                        to={mainData?.soc_telegram}
                                        className={"footer__social-block"}
                                    >
                                        <TelegramIcon />
                                    </Link>
                                )}

                                {/*<Link*/}
                                {/*    to={mainData.soc_twiter}*/}
                                {/*    className={'footer__social-block'}*/}
                                {/*>*/}
                                {/*    <img*/}
                                {/*        width={35}*/}
                                {/*        height={35}*/}
                                {/*        src="/images/icons/social/tw.svg"*/}
                                {/*        alt="tw"*/}
                                {/*        // layout={'responsive'}*/}
                                {/*    />*/}
                                {/*</Link>*/}

                                {mainData?.soc_instagram && (
                                    <Link
                                        to={mainData?.soc_instagram}
                                        className={"footer__social-block"}
                                    >
                                        <InstagramIcon />
                                    </Link>
                                )}
                            </div>

                            <div className={"footer__mobile"}>
                                {!!+mainData?.qr_code_enabled && (
                                    <div className={"footer__mobile-block"}>
                                        <img
                                            src="/images/qr.png"
                                            alt="play-market"
                                            // layout={'responsive'}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </footer>
            )}

            {isMobile && (
                <div className={`menu__footer ${className}`}>
                    <div className="menu__footer__block">
                        <div className="menu__footer__items">
                            {mobileFooterRouters.map((item: any, idx: number) =>
                                item.key === "home" ? (
                                    ""
                                ) : (
                                    <Fragment key={idx}>
                                        {item.route ? (
                                            <Link
                                                to={item.route}
                                                key={
                                                    "list-footer-menu__footer-item-" +
                                                    idx
                                                }
                                                className={`menu__footer__item ${
                                                    isNoHome.includes(item.key)
                                                        ? "d-none"
                                                        : ""
                                                }`}
                                            >
                                                <button
                                                    onClick={() =>
                                                        onClickMenuFooter && onClickMenuFooter(item)
                                                    }
                                                    className={`menu__footer__item ${location.pathname === item.route ? "active" : ""}`}
                                                >
                                                    <div className="menu__footer__item__icon">
                                                        {item.icon}
                                                    </div>

                                                    <div className="menu__footer__item__text">
                                                        <p>{item.title}</p>
                                                    </div>
                                                </button>
                                            </Link>
                                        ) : (
                                            <div
                                                key={
                                                    "list-footer-menu__footer-item-" +
                                                    idx
                                                }
                                                className={`menu__footer__item ${
                                                    isNoHome.includes(item.key)
                                                        ? "d-none"
                                                        : ""
                                                }`}
                                            >
                                                <button
                                                    onClick={() => {
                                                        if (item.id === 1) {
                                                            fetchTokenForStore();

                                                            return;
                                                        }

                                                        onClickMenuFooter && onClickMenuFooter(item);
                                                    }}
                                                    className={`menu__footer__item ${((location.pathname === item.route) || (item.key === "navigation" && isOpenMenu)) ? "active" : ""}`}
                                                >
                                                    <div className="menu__footer__item__icon">
                                                        {item.icon}
                                                    </div>

                                                    <div className="menu__footer__item__text">
                                                        <p>{item.title}</p>
                                                    </div>
                                                </button>
                                            </div>
                                        )}
                                    </Fragment>
                                )
                            )}
                        </div>
                    </div>

                    {/*<div*/}
                    {/*    className={`spinner__wrapper ${*/}
                    {/*        isOpenMenu ? 'active' : ''*/}
                    {/*    }`}*/}
                    {/*    onClick={openMenu}*/}
                    {/*></div>*/}
                </div>
            )}
        </>
    );
};

export default FooterHome;
