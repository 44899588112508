import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import {
    IResponseCitiesAvailable,
    IResponseWorkplaceBuildings,
    IWorkplaceBuildings,
    ParamsData
} from "./interface";

export const $getCitiesAvailable = (
    handler?: IApiHandlerParams
): Promise<IResponseCitiesAvailable> =>
    api.get("api/v1/booking/available_cities", {
        handler
    });

export const $getWorkplaceBuildings = (
    id: number,
    params: any,
    handler?: IApiHandlerParams
): Promise<IResponseWorkplaceBuildings> =>
    api.get(`api/v1/booking/available_cities/${id}/buildings`, {
        request: { params },
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $getWorkplaceMapData = (
    id: number,
    params: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`backend/v1/booking/building/${id || 0}`, {
        request: { params },
        handler
    });

export const $searchWorkplaceBooking = (
    floor_id: string,
    params?: ParamsData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/booking/workplaces/${floor_id}/booked-users`, {
        request: { params },
        handler
    });

export const $headerSearchPost = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`api/workspaces/mainpage`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler
    });

export const $getWorkplaceMapSearch = (
    id: number,
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/booking/workplaces/${id}`, {
        request: { params },
        handler
    });

export const $getWorkplaceMapOfUser = (
    id: number,
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/booking/workplaces/${id}/by-user`, {
        request: { params },
        handler
    });

export const $updateWorkplaceMap = (
    floor: any,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.put(`backend/v1/booking/building/${floor ? floor.building_id : 0}/floor/${floor ? floor.id : 0}/map`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler
    });

export const $pinImportWorkplaceMap = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`backend/v1/booking/import-pinned-workplaces`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler
    });

export const $getTableWorkCalendar = (
    floorId: string,
    params: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/booking/workplaces/${floorId}/calendar`, {
        // mock: require('./mocks/work.calendar.json'),
        request: { params },
        handler
    });

export const $deleteWorkplaceMapData = (
    buildId: number,
    floorId: number,
    workplaceId: number,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.delete(`backend/v1/booking/building/${buildId || 0}/floor/${floorId}/workplace/${workplaceId}`, {
        handler
    });
