import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { $getNewEmployees } from "../../api/requests/employees";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";

export const EmployeesCard = () => {
    const navigate = useNavigate();
    const [employeesList, setEmployeesList] = useState<any[]>([]);

    const personData = useSelector(getPersonData);

    useEffect(() => {
        init();
    }, [])

    function init() {
        const requestData: any = {
            workspace_id: personData?.workspace_id || null,
            // offset: 0,
            // limit: 30
        }

        $getNewEmployees(requestData).then(res => {
            if (!res || res.error) return;
            setEmployeesList(res.data);
        })
    }

    return (
        <div  className='layout-thanks' onClick={() => navigate('/new-employees')}>
            <div className='layout-thanks__title'>
                Новые сотрудники
            </div>

            <div className='layout-thanks__list'>
                {employeesList?.slice(0, 2).map((item,  index) => {
                    return (
                        <div key={index} className='layout-thanks__item'>
                            {item?.image && <img src={item?.image} />}

                            {!item?.image && item?.name && <div className='layout-thanks__name'>{item?.name?.slice(0, 1)}</div>}
                        </div>
                    );
                })}

                {employeesList?.length > 2 && <div className='layout-thanks__count'>{`+${employeesList.length - 2}`}</div>}
            </div>
        </div>
    )
}
