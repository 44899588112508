import { ArrowExitIcon, ArrowRightIcon, HeartIcon, StarIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { $getEmployees } from "../../api/requests/employees";
import { $getFavoritesNews, $getNewsList } from "../../api/requests/news";
import { PollsCard } from "../../components/polls";
import { CompetitionsCard } from "../../components/competitions";
import { NewsCard } from "../../components/news";
import { isMd } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { changeListLength, getPersonData } from "../../redux";
import { $getUserFavoritesPreview, $toggleUserFavorites } from "../../api/requests/favorites";
import { FavoritesNewsCard } from "../../components/favorites";
import { BlankAva } from "../../components/ui/avatars/BlankAva";
import { BlankImage } from "../../components/ui/blank-image/BlankImage";
import { changeHeaderTitle } from "../../redux/reducers/header/reducer";
import DatePickerForm from "../../components/ui/form/DatePickerForm";
import { Controller, useForm } from "react-hook-form";
import moment from "moment/moment";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

export const FavoritesPage = () => {
    const profileData = useSelector(getPersonData);

    const navigate = useNavigate();
    const isMobile = isMd();

    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid, errors },
        setValue
    } = useForm();

    const [usersData, setUsersData] = useState([]);
    const [employeesData, setEmployeesData] = useState<any>([]);
    const [newsData, setNewsData] = useState([]);
    const [partnersData, setPartnersData] = useState([]);
    const [ideasData, setIdeasData] = useState([]);
    const [birthdaysData, setBirthdaysData] = useState([]);
    const [interviewsData, setInterviewsData] = useState([]);
    const [contestsData, setContestsData] = useState([]);
    const [blogsData, setBlogsData] = useState([]);
    const [mediaGalleryData, setMediaGalleryData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const handleNavigation = () => {
        navigate(-1);
    };

    const handleLike = (id: number) => {
        setIsLoading(true);
        $toggleUserFavorites({
            type: "user",
            type_id: id,
            add_to_favorites: false
        }, { newApi: true }).then(res => {
            setIsLoading(false);
        });
    };


    const getList = (list: any, type: string) => {
        return list.find((item: any) => item.type === type);

    };

    useEffect(() => {
        $getUserFavoritesPreview({ newApi: true }).then((res) => {
            if (!res.data) return;

            setEmployeesData(getList(res.data, "staff").items);
            setUsersData(getList(res.data, "user").items);
            setNewsData(getList(res.data, "news").items);
            setIdeasData(getList(res.data, "idea").items);
            setBirthdaysData(getList(res.data, "birthday").items);
            setInterviewsData(getList(res.data, "interview").items);
            setContestsData(getList(res.data, "contest").items);
            setBlogsData(getList(res.data, "blog").items);
            setMediaGalleryData(getList(res.data, "mediaGallery").items);
            setPartnersData(getList(res.data, "partner").items);
        });

    }, [isLoading]);

    useEffect(() => {
        dispatch(changeHeaderTitle("Избранное"));
    });

    return (
        <div className={`${!isMobile && "container"}`}>
            <div className="favorites">
                <div className="layout-inner__header profile-header">
                    {!isMobile && (
                        <div className="layout-inner__header__title">
                            <div className="header__exitIcon" onClick={handleNavigation}>
                                <ArrowExitIcon />
                            </div>
                            <h1>Избранное</h1>
                        </div>
                    )}

                    <div className={"favorites-datepicker"}>
                        <Controller
                            name="date"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DatePickerForm
                                    value={value}
                                    className=""
                                    label=""
                                    placeholder={"Дата добавления"}
                                    name="date"
                                    onChange={onChange}
                                    isIcon
                                    isRightIcon
                                    errors={errors}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className="favorites-content">
                    {usersData && usersData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h5 className="favorites-content__items__header__title">Пользователи</h5>

                                <div onClick={() => navigate("/favorites/users")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {usersData.map((
                                    item: any,
                                    idx: number
                                ) => (
                                    <div className="employees-block" key={idx}>
                                        <div className="employees-block__top">
                                            {item.cover ? (
                                                <img src={item.cover} alt=""
                                                     className="employees-block__image" />
                                            ) : (
                                                <BlankAva
                                                    colorBg={"#CCD9E0"}
                                                    colorText={"black"}
                                                    text={item.title}
                                                />
                                            )}

                                            <div
                                                onClick={() => {
                                                    handleLike(item.id);
                                                }}
                                                className={`employees-block__likeIcon active`}
                                            >
                                                <StarIcon opacity={1} />
                                            </div>
                                        </div>

                                        <div
                                            className="employees-block__content"
                                            onClick={() => {
                                                item?.uniq && navigate(`/user/${item.uniq}`);
                                            }}
                                        >
                                            <h1 className="employees-block__title">{item?.title}</h1>

                                            {item.job && (
                                                <>
                                                    <p className="employees-block__text">{item?.job?.department}</p>

                                                    <p className="employees-block__position">{item?.job?.title}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {newsData && newsData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h5 className="favorites-content__items__header__title">Новости</h5>

                                <div onClick={() => navigate("/favorites/news")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {newsData.map((
                                    item: any,
                                    idx: number
                                ) => (
                                    <div onClick={() => navigate(`/news/show/${item.id}`)}
                                         key={idx}>
                                        <FavoritesNewsCard
                                            data={item}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            type={"news"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {partnersData && partnersData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h5 className="favorites-content__items__header__title">Витрина скидок</h5>

                                <div onClick={() => navigate("/favorites/discounts")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {partnersData.map((
                                    item: any,
                                    idx: number
                                ) => (
                                    <div onClick={() => navigate(`/discount/show/${item.id}`)}
                                         key={idx}>
                                        <FavoritesNewsCard
                                            data={item}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            type={"partner"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {ideasData && ideasData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h5 className="favorites-content__items__header__title">Идеи</h5>

                                <div onClick={() => navigate("/favorites/ideas")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {ideasData.slice(0, 4).map((
                                    item: any,
                                    idx
                                ) => (
                                    <div onClick={() => navigate(`/ideas/show/${item.id}`)}
                                         key={idx}>
                                        <FavoritesNewsCard
                                            data={item}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            type={"idea"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {interviewsData && interviewsData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h1 className="favorites-content__items__header__title">Опросы </h1>

                                <div onClick={() => navigate("/favorites/polls")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {interviewsData.slice(0, 4).map((
                                    item: any,
                                    idx
                                ) => (
                                    <div onClick={() => navigate(`/polls?id=${item.id}`)}
                                         key={idx}>
                                        <FavoritesNewsCard
                                            data={item}
                                            key={idx}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            type={"interview"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {contestsData && contestsData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h1 className="favorites-content__items__header__title"> Конкурсы </h1>

                                <div onClick={() => navigate("/favorites/competitions")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {contestsData.slice(0, 4).map((item: any, idx) => (
                                    <div onClick={() => navigate(`/competitions?view=${item.id}`)}
                                         key={idx}>
                                        <FavoritesNewsCard
                                            data={item}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            type={"contest"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {blogsData && blogsData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h1 className="favorites-content__items__header__title"> Блоги </h1>

                                <div onClick={() => navigate("/favorites/blogs")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {blogsData.slice(0, 4).map((item: any, idx) => (
                                    <div key={idx}
                                         onClick={() => navigate(`/blogs/show/${item.id}`)}>
                                        <FavoritesNewsCard
                                            data={item}
                                            key={idx}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            type={"blog"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {mediaGalleryData && mediaGalleryData.length > 0 && (
                        <div className="favorites-content__items">
                            <div className="favorites-content__items__header">
                                <h1 className="favorites-content__items__header__title"> Медиа-галерея </h1>

                                <div onClick={() => navigate("/favorites/media-galleries")}
                                     className="favorites-content__items__header__button">
                                    <h4>Открыть все</h4>
                                    <ArrowRightIcon color={"#000"} />
                                </div>
                            </div>

                            <div className="favorites-content__items__content">
                                {mediaGalleryData.slice(0, 4).map((item: any, idx) => (
                                    <div onClick={() => navigate(`/media-files/show/${item.id}`)}
                                         key={idx}>
                                        <FavoritesNewsCard
                                            data={item}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            type={"mediaGallery"}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};