import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ContestCardProps {
    data: any;
    isNavigate?: boolean;
}

export const ContestCard = (
    {
        data,
        isNavigate = true
    }: ContestCardProps
) => {
    const navigate = useNavigate();

    // const [data, setData] = useState([
    //     {
    //         id: 1,
    //         title: 'Маршрут одного дня',
    //         checkbox: ''
    //     },
    //     {
    //         id: 2,
    //         title: 'Выходные с ночёвкой',
    //         checkbox: ''
    //     },
    //     {
    //         id: 3,
    //         title: 'Праздники (несколько дней)',
    //         checkbox: ''
    //     }
    // ])

    return (
        <div className='layout-contest'>
            <div className='layout-contest__header'>
                <img src="/testImages/contest/contest-1.png" alt="" />

                <h1>{data?.title}</h1>
            </div>

            {/*<div className='layout-contest__content'>*/}
            {/*    {data.map((item, idx) => (*/}
            {/*        <div className='layout-contest__block' key={idx}>*/}
            {/*            <input type="checkbox" />*/}

            {/*            <h3>{item.title}</h3>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</div>*/}

            <button
                className='layout-contest__button'
                onClick={() => isNavigate && navigate(`/polls?id=${data?.id}`)}
            >
                Пройти опрос
            </button>
        </div>
    )
}