import React, { FC, useState } from "react";
import { ProfileFileIcon, ProfileQrCodeSmallIcon } from "../../img/icons/layout-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import Button from "../ui/form/Buttons/Button";
import VisitModal from "./visits/VisitModal";

interface IProfileCard {
    data?: any
}
export const ProfileCard: FC<IProfileCard> = ({
  data
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const localPersonData = useSelector(getPersonData);

    const [ openPopup, setOpenPopup ] = useState(false);

    return (
        <>
            <div className='layout-profile'>
                <div className='layout-profile__avaBox'  /*  onClick={() => push('/profile-settings')} */
                     style={ {cursor: 'pointer'} }>
                    {localPersonData?.photo ? (
                      <img src={ localPersonData?.photo } className='layout-profile__avatar'
                           style={ {marginRight: '0'} } />
                    ) : (
                      <div className='layout-profile__avatar__noneImage'>
                          {localPersonData?.name && localPersonData?.name[0]}
                      </div>
                    )}

                </div>

                <div className='layout-profile__nameBlock'>
                    <h1>{localPersonData?.lastname} {localPersonData?.firstname} {localPersonData?.middle_name}</h1>

                    <div className={"layout-profile__nameBlock-status"}>
                        <img src={localPersonData?.custom_status?.icon} />

                        <p>{localPersonData?.custom_status?.title}</p>
                    </div>
                </div>


                {location.pathname === '/profile' ? (
                    <div className='layout-profile__buttons'>
                        <button
                            className='layout-profile__buttons__qrCodeButton'
                            onClick={() => setOpenPopup(true)}
                        >
                            <ProfileQrCodeSmallIcon color='#00A7B5'/>
                            <p>Моя визитка</p>
                        </button>

                        <Button
                            text={'Редактировать'}
                            className={"btn btn-light"}
                            onClick={() => navigate('edit')}
                        />
                    </div>
                ) : (
                    <button className='layout-profile__businessCard' type='button'
                            onClick={ () => navigate('profile') }>
                        Моя страница
                    </button>
                ) }
            </div>

            {openPopup && (
                <VisitModal
                    isOpen={openPopup}
                    setIsOpen={() => setOpenPopup(false)}
                    onClose={() => setOpenPopup(false)}
                />
            )}
        </>
    )
}
