import api from '../../../api'
import { IApiHandlerParams } from '../../interface'
import { AuthSignData, AuthSmsCodeData, ConfirmBodyData, RegBodyData } from "./interface";

export const $authSign = (
    data: AuthSignData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/account/login', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })
export const $registration = (
    data: RegBodyData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/account/registration', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })
export const $confirm = (
    data: ConfirmBodyData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/account/confirm', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $authGetSms = (
    data: AuthSmsCodeData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('/auth/send-sms', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $setProfileData = (data?: any, handler?: IApiHandlerParams): Promise<any> =>
    api.post('api/account/edit', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $storeToken = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get( 'api/v1/store/auth/temporary', {
    handler,
})


export const $resetPassword = (
    data: { mail: string },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post( 'api/account/forgotpassword', data,{
        handler,
    })
