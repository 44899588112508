import React, { useEffect, useState } from "react";
import { $getWorkspaceUsers, $setRoleUsers } from "../../api/requests/workspace-settings";
import { ArrowRightIcon, SettingIcon, StarIcon } from "../../img";
import { ProfileBalanceIcon } from "../../img/icons/layout-components";
import { getPersonData } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardSkeleton } from "../ui/skeleton/CardSkeleton";
import Button from "../ui/form/Buttons/Button";
import { GetGroupsUsersResponseData } from "../../api/requests/groups/users/interface";
import { CheckboxIcon } from "../../img/icons/CheckboxIcon";
import { BlankImage } from "../ui/blank-image/BlankImage";
import WorkspaceUsersAccrual from "./WorkspaceUsersAccrual";
import { $toggleUserFavorites } from "../../api/requests/favorites";
import { getSearch, setSearch } from "../../redux/reducers/workplace/workplaceSlice";
import { IUsers } from "../../api/requests/user/interface";
import InfiniteScroll from "../ui/InfiniteScroll";

export const WorkspaceUsersBlock = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const profileData = useSelector(getPersonData);
    const search = useSelector(getSearch);

    const btnTitle = [
        {
            text: "Пользователи",
            key: 1
        },
        {
            text: "Тренера",
            key: 4
        },
        {
            text: "Администраторы",
            key: 3
        }
    ]

    const [btnSettingList, setBtnSettingList] = useState<any[]>([
        {
            id: 1,
            text: "Назначить администратором",
            className: "btn btn-secondary w-100 justify-content-between",
            rightIcon: <ArrowRightIcon color={"rgba(10,9,11,0.3)"}/>,
            active: false,
            children: [
                {
                    id: 1,
                    key: "all",
                    text: "Вся организация",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 2,
                    key: "news",
                    text: "Новости",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 3,
                    key: "ideas",
                    text: "Идеи",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 4,
                    key: "contests",
                    text: "Конкурсы",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 5,
                    key: "interviews",
                    text: "Опросы",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 6,
                    key: "media-files",
                    text: "Медиагалерея",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 7,
                    key: "blogs",
                    text: "Блог",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 8,
                    key: "sport",
                    text: "Спорт",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 9,
                    key: "files",
                    text: "Файлы",
                    className: "btn btn-secondary",
                    active: false,
                },
                {
                    id: 10,
                    key: "events",
                    text: "Мероприятия",
                    className: "btn btn-secondary",
                    active: false,
                },
            ],
        },
        // {
        //     id: 2,
        //     text: "Роль",
        //     key: "role",
        //     className: "btn btn-secondary w-100 justify-content-between",
        //     rightIcon: <ArrowRightIcon color={"rgba(10,9,11,0.3)"}/>,
        //     active: false,
        //     children: [
        //         {
        //             id: 1,
        //             key: "user",
        //             text: "Пользователь портала",
        //             className: "btn btn-secondary",
        //             active: false,
        //         },
        //         {
        //             id: 2,
        //             key: "admin",
        //             text: "Руководитель",
        //             className: "btn btn-secondary",
        //             active: false,
        //         },
        //         {
        //             id: 3,
        //             key: "trainer",
        //             text: "Тренер",
        //             className: "btn btn-secondary",
        //             active: false,
        //         }
        //     ],
        // }
    ])

    const [activeTab, setActiveTab] = useState(1);

    const [usersData, setUsersData] = useState<IUsers[]>([]);
    const [activePerson, setActivePerson] = useState<GetGroupsUsersResponseData | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isOpenAccrual, setIsOpenAccrual] = useState<GetGroupsUsersResponseData | null>(null);

    useEffect(() => {
        if (profileData?.workspace_id) init();
    }, [activeTab, search]);

    function init() {
        setActivePerson(null);

        const requestData = {
            id: profileData.workspace_id,
            limit: 30,
            forceToSend: 0,
            offset: 0,
            type: activeTab,
            search
        };

        setIsLoading(true);

        $getWorkspaceUsers(requestData, { formData: true }).then(res => {
            setIsLoading(false);

            if (res?.error) return;

            setUsersData(res.users);
        });
    }

    function handleChangeHeader(key: number) {
        setActiveTab(key);
        dispatch(setSearch(""));
        setActivePerson(null);
    }

    function handleClickSettingModal(select: any, isChild?: boolean) {
        if (!activePerson) return;

        const findChild = btnSettingList.find(i => i.children);

        const findActiveChildKeys = isChild && findChild
            ? findChild.children?.filter((child: any) => child.active).map((child: any) => child.key)
            : []

        const updateModule = findActiveChildKeys.includes(select.key)
            ? findActiveChildKeys.filter((key: any) => key !== select.key)
            : [...findActiveChildKeys, select.key]

        const requestData = {
            workspace_id: profileData.workspace_id,
            user_id: activePerson.id,
            role: +activePerson?.role === 1 ? 4 : ((select.key === "all") ? 1 : 4),
            module: (select.key === "all")
                ? []
                : ((isChild && findChild) ? JSON.stringify(updateModule) : [])
        }

        if (select.children) return handleSettingSave(select, isChild);

        $setRoleUsers(requestData, { formData: true }).then(res => {
            if (res.error || res.response) return;

            activePerson && handleSettingSave({
                ...select,
                admin_module: updateModule
            }, isChild);

            // @ts-ignore
            if (["delete", "all"].includes(select.key) || requestData.module?.includes("all")) {
                setActivePerson(null);
                init();
            }
        })
    }

    function handleSettingSave(select: any, isChild?: boolean) {
        const updateList = btnSettingList.map(item => {
            if (isChild && item.children.length) return {
                ...item,
                children: [...item.children].map(child => {
                    if (select?.admin_module.includes(child.key)) return {
                        ...child,
                        active: select.admin_module.includes(child.key)
                    }

                    return {
                        ...child,
                        active: false
                    }
                })
            }

            return {
                ...item,
                active:  item?.id === select?.id ? !item.active : false,
                children: [...item?.children].map(child => {
                    if (activePerson?.admin_module?.includes(child.key)) return {
                        ...child,
                        active: activePerson?.admin_module?.includes(child?.key)
                    }

                    return child
                })
            }
        })

        setBtnSettingList(updateList);
    }

    function handleOpenSetting(user: any) {
        setActivePerson(activePerson?.id === user.id ? null : user);

        const updateList = btnSettingList.map(item => {
            if (item.children.length) return {
                ...item,
                children: [...item.children].map(child => {
                    if (user?.admin_module?.includes(child.key)) return {
                        ...child,
                        active: true
                    }

                    if ([1, 2].includes(+user.role) && (child.key === "all")) return {
                        ...child,
                        active: true
                    }

                    return {
                        ...child,
                        active: false
                    }
                })
            }

            return {
                ...item,
            }
        })

        setBtnSettingList(updateList)
    }

    function handleLike (id: number, isLiked?: number) {
        $toggleUserFavorites(
            {
                type: 'user',
                type_id: id,
                add_to_favorites: !isLiked
            },
            {
                newApi: true,
            }
        ).then(res => {
            if (res.error || res.response) return;

            const updatedList: any = usersData?.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        is_favorite: !!item.is_favorite ? 0 : 1
                    }
                } else {
                    return item
                }
            })

            setUsersData(updatedList)
        });
    }

    function handleUpdateData(res: any) {
        setIsLoading(false);

        if (res?.error) return;

        setUsersData([...usersData, ...res.users]);
    }

    return (
        <>
            <div className={"workspace-settings-header"}>
                {btnTitle.map((item, idx) => (
                    <div
                        key={`work-btn-title-item-${idx}`}
                        onClick={() => handleChangeHeader(item.key)}
                        className={`workspace-settings-header-text ${activeTab === item.key ? "active" : ""}`}
                    >
                        {item.text}
                    </div>
                ))}
            </div>

            <InfiniteScroll
                url={'api/workspaces/users'}
                data={usersData}
                setData={handleUpdateData}
                isNewApi={false}
                params={{
                    id: profileData.workspace_id,
                    // limit: 30,
                    forceToSend: 0,
                    // offset: 0,
                    type: activeTab,
                    search
                }}
            >
                <div className="workspace-settings-users">
                    {isLoading && [1, 2, 3, 4].map((item, idx) => (
                        <div key={`work-setting-user-${idx}`} className="workspace-settings-users__skeleton">
                            <CardSkeleton />
                        </div>
                    ))}

                    {!isLoading && usersData?.map((user, idx) => (
                        <div
                            key={`user-data-item-${idx}`}
                            className={`workspace-settings-user employees-block ${user.id === activePerson?.id ? "active" : ""}`}
                            onClick={() => {
                                if (activePerson) {
                                    if (activePerson.id !== user.id) return
                                }

                                user?.uniq && navigate(`/user/${user.uniq}`)
                            }}
                            style={{
                                zIndex: activePerson ? (activePerson.id === user.id ? 1 : 0) : 1,
                                opacity: activePerson ? (activePerson.id === user.id ? 1 : 0.5) : 1
                            }}
                        >
                            <div className="workspace-settings-user__header">
                                <BlankImage
                                    image={user?.photo}
                                    classname={"workspace-settings-user__image"}
                                />

                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        handleLike(user?.id, user?.is_favorite);
                                    }}
                                    className={`employees-block__likeIcon ${!!user?.is_favorite ? "active" : ""}`}
                                >
                                    <StarIcon opacity={!!user?.is_favorite ? 1 : 0.2} />
                                </div>
                            </div>

                            <div className="workspace-settings-user__content">
                                <h1 className="workspace-settings-user__name">{user?.name}</h1>

                                <p className="workspace-settings-user__text">
                                    {user?.department}
                                </p>

                                <p className="workspace-settings-user__text workspace-settings-user__position">{user?.position}</p>
                            </div>

                            {/*<p className="workspace-settings-user__number mt-auto">+{user?.phone}</p>*/}

                            <div className="workspace-settings-user__line mt-auto"></div>

                            <div className="workspace-settings-user__bottom">
                                <div
                                    className="workspace-settings-user__points"
                                    // onClick={(event) => {
                                    //     event.stopPropagation();
                                    //
                                    //     setIsOpenAccrual(user);
                                    // }}
                                >
                                    <ProfileBalanceIcon size={"1.5rem"} />

                                    <p>{user?.user_points}</p>
                                </div>

                                <Button
                                    text={<SettingIcon color="#00A7B5" />}
                                    className={"btn-icon"}
                                    onClick={(event) => {
                                        event.stopPropagation();

                                        if (activePerson) {
                                            if (activePerson.id !== user.id) return
                                        }

                                        handleOpenSetting(user);
                                    }}
                                />
                            </div>

                            {activePerson?.id === user.id && (
                                <div className={"setting__modal bottom left"}>
                                    {btnSettingList.map((item, idx) => (
                                        <div
                                            key={`btn-setting-item-${idx}`}
                                            className={"position-relative w-100"}
                                        >
                                            <Button
                                                text={item.text}
                                                className={item.className}
                                                onClick={(event) => {
                                                    event.stopPropagation();

                                                    handleClickSettingModal(item);
                                                }}
                                                rightIcon={item.rightIcon}
                                            />

                                            {(!!item.children.length && item.active) && (
                                                <div className={"setting__modal top-0 left-100"}>
                                                    {item.children.map((_item: any, _idx: number) => (
                                                        <Button
                                                            key={`btn-setting-children-item-${idx}-${_idx}`}
                                                            text={_item.text}
                                                            className={_item.className}
                                                            leftIcon={_item.active && <CheckboxIcon/>}
                                                            onClick={(event) => {
                                                                event.stopPropagation();

                                                                handleClickSettingModal(_item, true);
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {!usersData.length && (
                    <div className={"empty__list"}>
                        <h4>Нет пользователей</h4>
                    </div>
                )}
            </InfiniteScroll>

            <WorkspaceUsersAccrual
                isOpen={!!isOpenAccrual}
                setIsOpen={() => setIsOpenAccrual(null)}
                onClose={() => setIsOpenAccrual(null)}
                data={isOpenAccrual}
            />
        </>
    );
};