import { AcceptedIcon, ArrowExitIcon, RejectedIcon } from '../../../../img'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getYearMonthDayAndTime, isMd } from '../../../../utils'
import { Controller, useForm } from 'react-hook-form'
import DatePickerForm from '../../../../components/ui/form/DatePickerForm'
import Button from '../../../../components/ui/form/Buttons/Button'
import FileClipField from '../../../../components/ui/form/FileField/FileClipField'
import { UploadIcon } from '../../../../img/icons/files/UploadIcon'
import { $uploadServiceApplicationFile } from '../../../../api/requests/services'
import { BlankAva } from '../../../../components/ui/avatars/BlankAva'
import {
    $createServiceEducation, $getExampleEducation,
    $getServiceEducationItem, $replaceExampleEducation, $updateStatusEducation,
} from '../../../../api/requests/services/education'
import moment from 'moment/moment'
import { DownloadIcon } from '../../../../img/icons/DownloadIcon'
import { ServicesImprovementModal } from '../../../../components/services/services-education/modals'
import {
    ServicesAddingAgreerModal,
} from '../../../../components/services/services-education/modals/ServicesAddingAgreerModal'
import ServiceSuccessModal from '../../../../components/services/services-education/modals/ServiceSuccessModal'
import { SpinnerIcon } from '../../../../img/SpinnerIcon'
import CompleteModal from "../../../../components/ui/dialog/CompleteModal";
import { useSelector } from 'react-redux'
import { getCurrentRole, getPersonData } from '../../../../redux'
import { InWorkIcon } from '../../../../img/icons/InWorkIcon'
import { ReplaceIcon } from '../../../../img/icons/ReplaceIcon'

export const ServicesEducationCreatePage = () => {
    const personalData = useSelector(getPersonData)
    const currentRole = useSelector(getCurrentRole)

    const isAdmin = currentRole === 1
    const navigate = useNavigate()
    const isMobile = isMd()

    const location = useLocation()
    const pathname = location.pathname

    const { id: serviceId } = useParams()
    const isView: boolean = pathname.includes('view')

    const [isModalOpen, setIsModalOpen] = useState(false)

    const [minDate, setMinDate] = useState('')

    const [isAlreadySent, setIsAlreadySend] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [exampleLink, setExampleLink] = useState<string | null>(null)
    const [data, setData] = useState<any>()

    const btnListSuccess = [
        {
            text: "Отлично",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const [uploadFileList, setUploadFileList] = useState<any>(undefined)

    const { id: userId } = personalData

    const [statusesData, setStatusesData] = useState([
        {
            label: 'Доработка',
            value: -2,
        },

        {
            label: 'Отклонено',
            value: -1,
        },

        {
            label: 'Успешно',
            value: 1,
        },
    ])

    const [isOpenDialog, setIsOpenDialog] = useState(0)

    const findStatus = statusesData.find(item => item.value == data?.status)

    const getStatusText = (status: number) => {
        switch (status) {
            case -2:
                return 'Отправлено на доработку'

            case -1:
                return 'Отклонено'

            case 1:
                return 'Одобрено'

            case 2:
                return 'Добавлен доп. согласант'

            default:
                return 'На рассмотрении'
        }
    }

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors, isValid },
        trigger,
    } = useForm<any>()

    useEffect(() => {
        if (serviceId) {
            setIsAlreadySend(!(data?.status === -2 && userId === data?.user_id))
        } else {
            getFile()
        }

        trigger('file')
    }, [personalData, data])

    const btnListRemove = [
        {
            text: 'Отлично',
            key: 1,
            className: 'btn btn-light w-100',
        },
    ]

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    const getFileName = (url: string) => {
        const parts: any = url?.split('/')
        return parts[parts?.length - 1]
    }

    const handleEducation = () => {
        if (serviceId) {
            $getServiceEducationItem(+serviceId, { newApi: true }).then(res => {
                if (res.data) {
                    const data = res?.data
                    setData(data)

                    setValue('date_training_at', moment(data?.date_training_at).toDate())
                }
            })
        }
    }

    const updateStatus = async (params: {
        e: React.MouseEvent<HTMLDivElement>,
        serviceId: number | string;
        status: number;
    }) => {
        const { status, serviceId, e } = params

        e.stopPropagation()

        await $updateStatusEducation(serviceId, {
            status: status,
        }, { newApi: true })

        handleEducation()
    }

    useEffect(() => {
        handleEducation()
    }, [isView])

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader()

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === 'string') {
                        $uploadServiceApplicationFile({ file: item.file }, {
                            newApi: true,
                            formData: true,
                        }).then(res => {
                            setUploadFileList({ file: reader.result })
                            setValue('file', res.data.url)

                            if (serviceId) {
                                const updatedData = {
                                    ...data,
                                    file: res.data.url,
                                }
                                setData(updatedData)
                            }
                        })
                    }
                }

                reader.readAsDataURL(item.file)
            }
        })
    }

    const handleFile = ({ target }: any) => {
        const files = target?.files

        for (let i = 0; files.length > i; i++) {
            const file = files[i]

            if (file) {
                const reader = new FileReader()

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === 'string') {
                        $uploadServiceApplicationFile({ file: file }, {
                            newApi: true,
                            formData: true,
                        }).then(res => {
                            const link = res.data.url

                            $replaceExampleEducation({ file: link }, {
                                newApi: true,
                            }).then(res => {
                                if (!res) return

                                setExampleLink(link)
                            })
                        })
                    }
                }

                reader.readAsDataURL(file)
            }
        }
    }

    const getFile = () => {
        $getExampleEducation({newApi: true}).then(res => {
            if (!res.data) return

            setExampleLink(res.data)
        })
    }

    function onSubmit(obj: any) {
        const updatedList = {
            ...obj,
            date_training_at: moment(obj.date_training_at || new Date()).format('YYYY-MM-DD'),
            id: serviceId ?? null,
        }

        if (!updatedList.file && serviceId) {
            updatedList.file = data?.file
        }

        $createServiceEducation(updatedList, { newApi: true }).then(res => {
            if (!res) return
            setShowSuccess(true)
        })
    }

    return (
        <>
            <CompleteModal
                icon={"😊"}
                title={"Успешно"}
                text={'Вы успешно отправили заявку на больничный. Ожидайте уведомление о результате'}
                isOpen={isModalOpen}
                setIsOpen={() => navigate('/services/education/list?activeType=0')}
                btnList={btnListSuccess}
                onClick={() => navigate('/services/education/list?activeType=0')}
            />

            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate('/services/education/list?activeType=0')}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Заявка на обучение</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <div className="services-applications-create__block__left">
                        <h5 className="services-applications-create__title">Заявление</h5>

                        {serviceId && findStatus?.value === -1 ? (
                            <div
                                style={{ background: '#FF0000' }}
                                className={`services-applications-create__status`}>

                                {findStatus?.label}
                            </div>
                        ) : ''}

                        {serviceId && findStatus?.value !== -1 ? (
                            <div
                                style={{ background: (findStatus?.value == 1) ? '#00A7B5' : '' }}
                                className={`services-applications-create__status ${findStatus?.value !== 1 ? 'waiting' : ''}`}>

                                {findStatus?.label || 'Рассмотрение'}
                            </div>
                        ) : ''}
                    </div>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit((obj) => onSubmit(obj))}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <div className="services-applications-create__dates">
                                <Controller
                                    name={'date_training_at'}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            disabled={isAlreadySent}
                                            startDate={value || data?.date_training_at}
                                            minDate={new Date(minDate)}
                                            className=""
                                            label="Дата начала обучения"
                                            placeholder={'Выберите дату'}
                                            onChange={onChange}
                                            value={value}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <div
                                className={`services-education-wrapper__buttons__block ${isAlreadySent ? 'isLocked' : ''}`}>
                                {!isAlreadySent ? (
                                    <p>
                                        Внимание! Если начало обучения согласовано в следующем месяце, затраты
                                        необходимо
                                        отразить в бюджете. <br />
                                        Если заявка направлена позднее 15 числа, дирекция по персоналу
                                        оставляет за собой право организовать обучение в следующем периоде.
                                    </p>
                                ) : (
                                    <p>
                                        Если заявка оформлена после 15 числа, она будет обработана не раньше чем в след.
                                        месяце
                                    </p>
                                )}
                            </div>

                            <div className={`services-applications-create__file ${isAlreadySent ? 'isLocked' : ''}`}>
                                <div className="services-applications-create__file__header">
                                    <p className="services-applications-create__file__header__label">Заявка</p>

                                    {data?.file ?
                                        <div className="services-applications-create__file__header__right">
                                            <a href={data.file}
                                               className="services-applications-create__file__header__text">
                                                Скачать заявку
                                            </a>

                                            <DownloadIcon size={18} />
                                        </div>
                                        :
                                        <div className="services-applications-create__file__header__right">
                                            {isAdmin &&
                                                <div className="services-applications-create__file__header__link">
                                                    <label className="services-applications-create__file__header__text">
                                                        <input onChange={handleFile} hidden type="file" />
                                                        Заменить пример
                                                    </label>

                                                    <ReplaceIcon size={18} />
                                                </div>
                                            }

                                            {exampleLink &&
                                                <div className="services-applications-create__file__header__link">
                                                    <a href={exampleLink}
                                                       className="services-applications-create__file__header__text">
                                                        Скачать пример
                                                    </a>

                                                    <DownloadIcon size={18} />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>

                                <Controller
                                    name="file"
                                    disabled={isAlreadySent}
                                    control={control}
                                    rules={{
                                        required: !isAlreadySent && !serviceId,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileClipField
                                            accept={'.docx'}
                                            label={''}
                                            upload={uploadFileList}
                                            setUpload={handleUploadList}
                                            isLockFile={uploadFileList || isAlreadySent}
                                            uploadName={getFileName(data?.file || '')}
                                            text={'Загрузите заполненную заявку'}
                                            icon={<UploadIcon size={44} />}
                                            isSingleDoc
                                        />
                                    )}
                                />
                            </div>

                            {data?.collaborators?.length > 0 && (
                                <div className="services-applications-create__file">
                                    <div className="services-applications-create__file__header">
                                        <p className="services-applications-create__file__header__label">Согласующие</p>
                                    </div>

                                    {data?.collaborators?.map((item: any) => (
                                        <div className="layout-vacation-modal__block"
                                             key={item.id}
                                             style={{
                                                 background: '#00000008',
                                                 padding: '10px',
                                                 borderRadius: 16,
                                                 position: 'relative',
                                             }}
                                        >
                                            <div className="layout-vacation-modal__block__right">
                                                {item?.user?.photo ? (
                                                    <img src={item?.user?.photo} alt="" />
                                                ) : (
                                                    <BlankAva
                                                        colorBg={'#CCD9E0'}
                                                        colorText={'black'}
                                                        text={item?.user?.name}
                                                    />
                                                )}

                                                <div className="layout-vacation-modal__block__desc">
                                                    <h1>{item?.user?.name}</h1>

                                                    <p className="layout-vacation-modal__block__desc__position">{item?.user?.staff?.title || 'Не указан'}</p>
                                                </div>
                                            </div>

                                            <div className="services-applications-create__approvers__status">
                                                {(item.status == 1 || data.status === 1) && (
                                                    <AcceptedIcon />
                                                )}

                                                {item.status == -1 && (
                                                    <RejectedIcon />
                                                )}

                                                {data.status !== 1 && item.status === 2 && (
                                                    <InWorkIcon />
                                                )}

                                                {item.status !== 1 && item.status !== -1 && item.status !== 2 && (
                                                    <SpinnerIcon />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {data?.logs?.length > 0 && (
                                <div className="services-view-status__list">
                                    {data?.logs?.map((item: any, idx: number) => (
                                        <div className="services-view-status" key={idx}>
                                            <div className="services-view-status__dots">
                                                {item.status == -1 ? (
                                                    <div className="services-view-status__dots__circle red"></div>
                                                ) : (
                                                    <div
                                                        className={`services-view-status__dots__circle ${item.status !== -1 && item.status !== 1 ? 'gray' : ''}`}></div>
                                                )}

                                                {idx !== data.logs.length - 1 && (
                                                    <div
                                                        className="services-view-status__dots__line"></div>
                                                )}
                                            </div>

                                            <div className="services-view-status__info">
                                                <h5 className="services-view-status__info__title">{getStatusText(item.status)}</h5>

                                                <div className="services-view-status__info__sender">
                                                    <p>Согласователь:</p>

                                                    <h5>{item?.name}</h5>
                                                </div>

                                                {item?.reason && (
                                                    <div
                                                        className="services-view-status__info__sender">
                                                        <p>Комментарий:</p>

                                                        <h5>{item?.reason}</h5>
                                                    </div>
                                                )}

                                                <p className="services-view-status__info__date">
                                                    {getYearMonthDayAndTime(item?.updated_at)}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {(!isAlreadySent) && (
                                <Button
                                    type={'submit'}
                                    text={'Отправить'}
                                    className={'btn btn-primary w-100'}
                                />
                            )}

                            {data?.can_accept && serviceId && (
                                <Button
                                    text={'Согласовать'}
                                    onClick={e => updateStatus({
                                        e,
                                        serviceId,
                                        status: 1,
                                    })}
                                    style={{ marginLeft: 0 }}
                                    className={'btn btn-primary w-100'}
                                />
                            )}

                            {data?.can_decline && serviceId ? (
                                <Button
                                    text={'Отказать'}
                                    onClick={e => updateStatus({
                                        e,
                                        serviceId,
                                        status: -1,
                                    })}
                                    style={{ marginLeft: 0 }}
                                    className={'btn btn-red w-100'}
                                />
                            ) : ''}

                            {data?.can_extra_decline && serviceId ? (
                                <Button
                                    text={'Отправить на доработку'}
                                    onClick={e => {
                                        e.stopPropagation()
                                        setIsOpenDialog(1)
                                        handleEducation()
                                    }}
                                    style={{ marginLeft: 0 }}
                                    className={'btn btn-light w-100'}
                                />
                            ) : ''}

                            {data?.can_extra_accept && serviceId ? (
                                <Button
                                    text={'Добавить согласователя'}
                                    onClick={e => {
                                        e.stopPropagation()
                                        setIsOpenDialog(2)
                                    }}
                                    style={{ marginLeft: 0 }}
                                    className={'btn btn-light w-100'}
                                />
                            ) : ''}
                        </form>
                    </div>
                </div>

                {isOpenDialog === 1 && (
                    <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                              id={serviceId}
                                              onClose={() => {
                                                  setIsOpenDialog(0)
                                                  navigate('/services/education/list?activeType=0')
                                              }} />
                )}

                {isOpenDialog === 2 && serviceId && (
                    <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                               id={serviceId}
                                               onClose={() => {
                                                   setIsOpenDialog(0)
                                                   handleEducation()
                                               }} />
                )}

                <ServiceSuccessModal
                    isOpen={showSuccess}
                    onClose={() => {
                        reset()
                        setShowSuccess(false)
                        navigate('/services/education/list?activeType=0')
                    }}
                />
            </div>
        </>
    )
}