import { Controller, useForm } from "react-hook-form";
import FileField from "../../../ui/form/FileField/FileField";
import TextField from "../../../ui/form/Fields/TextField";
import DatePickerForm from "../../../ui/form/DatePickerForm";
import Dropdown from "../../../ui/form/Dropdown/Dropdown";
import TextArea from "../../../ui/form/Fields/TextArea";
import { CloseIcon } from "../../../../img";
import Button from "../../../ui/form/Buttons/Button";
import React, { Fragment, useEffect, useState } from "react";
import { $getPersonalData, $getPersonalPage } from "../../../../api/requests/user";
import moment from "moment/moment";
import { $setProfileData } from "../../../../api/requests/auth";
import Cookies from "js-cookie";
import {
    changeMainPage,
    changePersonData,
    changeToken,
    resetProfile,
    store
} from "../../../../redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { $mainPageData } from "../../../../api/requests/main";
import { phoneNumberFieldMask } from "../../../../utils";
import MaskTextField from "../../../ui/form/Fields/MaskTextField";

export interface EditProfileField {
    id: number,
}

export interface EditProfileFormData {
    image: File | string | null;
    firstname: string;
    lastname: string;
    familyName?: string | null;
    username: string;
    password: string;
    phone?: string;
    mail: string;
    birth: string;
    gender: number;
    position?: string | null;
    company: string;
    city: string;
    about: string | null;
    skills?: String | null;
    whoSeeMyMail?: number;
    whoSeeMyBirth?: number;
    whoSeeMyProfile?: number;
    whoSeeMyPhone?: number;
    whoWriteMe?: number;
    names?: any;
    staff?: any;
}

export const ProfilePersonalEditBlock = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const genderList = [
        { label: "Мужской", value: 1 },
        { label: "Женский", value: 2 }
    ];

    const [editData, setEditData] = useState<EditProfileFormData | null>(null);
    const [groups, setGroups] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [skills, setSkills] = useState<any[]>([]);
    const [currentSkill, setCurrentSkill] = useState("");

    const valuesList = [
        { label: "Все", value: 0 },
        { label: "Никто", value: 1 }
    ];
    const birthValuesList = [
        { label: "Все", value: 0 },
        { label: "Все, кроме года", value: 2 },
        { label: "Никто", value: 1 }
    ];

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors, isValid },
        trigger
    } = useForm<any>({
        values: {
            image: editData?.image || "",
            firstname: editData?.firstname || "",
            lastname: editData?.lastname || "",
            familyName: editData?.familyName || "",
            username: editData?.username || "",
            password: editData?.password || "",
            phone: editData?.phone ? editData?.phone?.toString() : "",
            mail: editData?.mail || "",
            birth: editData?.birth || "",
            gender: editData?.gender || 0,
            position: editData?.staff?.title || "",
            company: editData?.company || "",
            city: editData?.city || "",
            about: editData?.about || "",
            whoSeeMyProfile: editData?.whoSeeMyProfile || 0,
            whoSeeMyMail: editData?.whoSeeMyMail || 0,
            whoSeeMyBirth: editData?.whoSeeMyBirth || 0,
            whoSeeMyPhone: editData?.whoSeeMyPhone || 0,
            whoWriteMe: editData?.whoWriteMe || 0
        }
    });

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if ((watch("phone") || watch("firstname")) && isLoading) setIsLoading(false);
    }, [watch("phone"), watch("firstname")]);

    function init() {
        $getPersonalPage(null, { newApi: true }).then((res) => {
            if (!res) return;

            const user = res.data;

            setEditData({
                image: user.photo,
                firstname: user.firstname,
                lastname: user.lastname,
                familyName: user.middle_name,
                username: user.username,
                password: "**********",
                mail: user.mail,
                phone: user.phone,
                birth: moment(user.birth).format("DD.MM.YYYY"),
                gender: user.sex,
                position: user.position,
                company: user.company,
                city: user.city,
                about: user.about,
                whoSeeMyProfile: user.privacy,
                whoSeeMyMail: user.privacy_mail,
                whoSeeMyBirth: user.privacy_birth,
                whoSeeMyPhone: user.privacy_phone,
                whoWriteMe: user.privacy_messenger,
                staff: user.staff
            });

            if (user?.hashtags) {
                setSkills(user.hashtags.split(", "));
            }

            if (user?.field_groups) {
                setGroups(user.field_groups);
            }
        });
    }

    function onSubmit(data?: any) {
        const profileData = {
            // photo: data?.image,
            // name: data?.firstname,
            // lastname: data?.lastname,
            // username:
            //     data?.firstname && data?.lastname
            //         ? `@${data?.firstname}${data?.lastname}`
            //         : undefined,
            // password: data?.password,
            // mail: data?.mail,
            // birth: data?.birth
            //     ? moment(data?.birth).valueOf() / 1000
            //     : undefined,
            // sex: data?.gender,
            city: data?.city,
            // user_position: data?.position,
            about: data?.about,
            phone: data?.phone,
            // privacy_mail: data?.whoSeeMyMail,
            // privacy: data?.whoSeeMyProfile,
            privacy_birth: data?.whoSeeMyBirth,
            privacy_phone: data?.whoSeeMyPhone,
            // privacy_messenger: data?.whoWriteMe,
            hashtags: skills ? skills.join(",") : "",
            names: ""
        };
        let names = {};
        if (groups) {
            groups.map((item: any, idx: number) => {
                if (item.names) {
                    item.names.map((item: any, idx: number) => {
                        let $name = "names_" + item.id;
                        if (data[$name]) {
                            Object.assign(names, { [item.id]: data[$name] });
                        }
                    });
                }
            });
        }

        profileData.names = JSON.stringify(names);

        // Filter out undefined or empty values
        // const filteredProfileData = Object.fromEntries(
        //     Object.entries(profileData).filter(
        //         ([key, value]) => value !== undefined && value !== ''
        //     )
        // )

        $setProfileData(profileData, { formData: true }).then(res => {
            $mainPageData().then((response) => {
                if (!response || response.error) return;

                dispatch(changeMainPage(response));
            });

            $getPersonalData().then((response) => {
                if (!response || response.error) return;

                dispatch(changePersonData(response?.data));
            });
        });
    }

    const pressAddSkill = (event: any) => {
        const value: string = event.target.value;

        if (event.key === "Enter" && value) {
            setSkills([...skills, value]);
            setCurrentSkill("");
        }
    };

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val || val == 0) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const handleDeleteInput = (idx: number, data: any, setData: any) => {
        const updatedList =
            data && data?.filter((item: any, index: number) => index !== idx);

        setData(updatedList);
    };

    if (isLoading) return null;

    return (
        <div className="profile-edit__mainbar">
            <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => checkKeyDown(e)}
            >
                <div className="profile-edit__header">
                    <div className="profile-edit__photo">
                        <Controller
                            name="image"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: "Прикрепите картинку"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    className={"form-image"}
                                    upload={value}
                                    label=""
                                    setUpload={onChange}
                                    disabled={true}
                                />
                            )}
                        />
                    </div>

                    <p className="profile-edit__fulname">
                        {`${editData?.firstname ?? ""} ${editData?.lastname ?? ""}\n${editData?.familyName ?? ""}`}
                    </p>
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="firstname"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Имя"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="firstname"
                                value={value}
                                className=""
                                title="Имя"
                                placeholder={"Введите имя"}
                                disabled={true}
                                name="firstname"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="lastname"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните фамилию"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="lastname"
                                value={value}
                                className=""
                                title="Фамилия"
                                placeholder={"Введите фамилию"}
                                disabled={true}
                                name="lastname"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="familyName"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните отчество"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="familyName"
                                value={value ?? ""}
                                className=""
                                title="Отчество"
                                placeholder={"Введите отчество"}
                                name="familyName"
                                onChange={onChange}
                                errors={errors}
                                disabled={true}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    {/* <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: 'Заполните пароль',
                            },
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="password"
                                value={value ?? ''}
                                className=""
                                type="password"
                                title="Пароль"
                                placeholder={'Введите пароль'}
                                name="password"
                                onChange={onChange}
                                errors={errors}
                                disabled={true}
                            />
                        )}
                    /> */}
                    <Controller
                        name="mail"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните Email"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="mail"
                                value={value ?? ""}
                                className=""
                                title="Email"
                                placeholder={"Введите Email"}
                                name="mail"
                                disabled={true}
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="gender"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете пол"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Пол не выбран"}
                                label={"Пол"}
                                options={genderList}
                                value={getValue(genderList, value)}
                                onChange={(select) => onChange(select.value)}
                                disabled={true}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name={"phone"}
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Введите телефон"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <MaskTextField
                                id="phone"
                                value={value || ""}
                                className=""
                                title="Телефон"
                                placeholder={"Личный телефон"}
                                mask={phoneNumberFieldMask}
                                name="phone"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="whoSeeMyPhone"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"не выбран"}
                                label={"Кто видит мой телефон"}
                                options={valuesList}
                                value={getValue(valuesList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name={"birth"}
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Выберете дату"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="birth"
                                value={value ?? ""}
                                className=""
                                title="Дата рождения"
                                placeholder={"Выберите дату"}
                                name="birth"
                                disabled={true}
                                onChange={onChange}
                                errors={errors}
                                // isIcon
                                // isRightIcon
                            />
                        )}
                    />

                    <Controller
                        name="whoSeeMyBirth"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"не выбран"}
                                label={"Кто видит мою дату рождения"}
                                options={birthValuesList}
                                value={getValue(birthValuesList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="position"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните должность"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="position"
                                value={value ?? ""}
                                className=""
                                title="Должность"
                                placeholder={"Введите должность"}
                                name="position"
                                onChange={onChange}
                                errors={errors}
                                disabled
                            />
                        )}
                    />

                    <Controller
                        name="city"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните город"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="city"
                                value={value ?? ""}
                                className=""
                                title="Город"
                                placeholder={"Введите город"}
                                name="city"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="about"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните описание"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextArea
                                id="about"
                                value={value ?? ""}
                                className=""
                                title="Обо мне"
                                placeholder={"Введите описание"}
                                name="about"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                </div>

                {/* Todo custom fields */}
                {groups &&
                    groups.map((item: any, idx: number) => (
                        item.names ?
                            <Fragment key={`edit-profile-groups-${idx}`}>
                                <div className="profile-edit__block">
                                    {item.names &&
                                        item.names.map((item: any, idx: number) => (
                                            <Controller
                                                key={`data-profile-fields-${idx}`}
                                                name={`names_${item.id}`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Заполните город"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        id={`custom_input_${item.id}`}
                                                        value={value ?? item.value ?? ""}
                                                        className=""
                                                        title={item.name}
                                                        placeholder={`Введите ${item.name}`}
                                                        name={`names[${item.id}]`}
                                                        onChange={onChange}
                                                        errors={errors}
                                                    />
                                                )}
                                            />
                                        ))}
                                </div>
                            </Fragment>
                            : null
                    ))}

                <div className="profile-edit__block">
                    <Controller
                        name="skills"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Добавте как минимум один навык"
                            }
                        }}
                        render={({ field: { value } }) => (
                            <>
                                <TextField
                                    id="skills"
                                    value={currentSkill}
                                    className=""
                                    title="Введите навык и нажмите Enter"
                                    name="skills"
                                    errors={errors}
                                    onChange={(val) => setCurrentSkill(val)}
                                    onKeyDown={(e: any) => pressAddSkill(e)}
                                />
                            </>
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    {skills?.map((value, idx) => (
                        <div key={idx}>
                            {value && (
                                <div className="services-create-form__hashtags__values__item">
                                    <p>{value}</p>

                                    <div
                                        onClick={() =>
                                            handleDeleteInput(
                                                idx,
                                                skills,
                                                setSkills
                                            )
                                        }
                                        className="services-create-form__hashtags__values__delete"
                                    >
                                        <CloseIcon color="#00A7B5" />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="profile-edit__bottom">
                    <Button
                        text={"Отмена"}
                        className={"btn btn-light w-100"}
                        onClick={() => navigate(-1)}
                    />

                    <Button
                        type={"submit"}
                        text={"Сохранить"}
                        className={"btn btn-primary w-100 ml-0"}
                    />
                </div>

                {/*<div className="profile-edit__links">*/}
                {/*    <a href="#">*/}
                {/*        Политика конфиденциальности персональных данных*/}
                {/*    </a>*/}
                {/*    <a href="#">*/}
                {/*        Информация о специальных предложениях партнёров*/}
                {/*    </a>*/}
                {/*</div>*/}
            </form>
        </div>
    );
};
