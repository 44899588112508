import { useLocation, useNavigate } from "react-router-dom";
import { isMd } from "../../../utils";
import { useEffect, useState } from "react";
import { IPolls2 } from "../../../api/requests/polls/interface";
import { $getPollsList } from "../../../api/requests/polls";
import { ArrowExitIcon } from "../../../img";
import { PollsCard, PollsQuestionsModal } from "../../../components/polls";

export const PollsDraftPage = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id: any = searchParams.get("id")
    const isMobile = isMd();

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [selectedData, setSelectedData] = useState<IPolls2>()
    const [pollsData, setPollsData] = useState([])

    const handleSelectItem = (item: IPolls2) => {
        setIsOpenModal(true)
        setSelectedData(item)
    }
    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        $getPollsList().then(res => {
            if (!res) return;

            const filteredList = res.filter((item: any) => item.status == 0)

            setPollsData(filteredList)
        })
    }, [])

    return (
        <div className='polls'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Черновик</h1>
                    </div>
                ) }
            </div>

            <div className='container polls-content'>
                {pollsData.map((item: any, idx) => (
                    <div key={idx} onClick={() => navigate(`/polls/drafts?id=${item.id}`)}>
                        <PollsCard data={item} />
                    </div>
                ))}
            </div>

            {id && (
                <PollsQuestionsModal
                    isOpenModal={!!id}
                    onClose={() => navigate('/polls/drafts')}
                />
            )}
        </div>
    )
}