import React, {useEffect, useState} from "react";
import {ArrowRightIcon} from "../../img";
import {IServicesApplications} from "../../api/requests/services/interface";
import {$getServiceApplications} from "../../api/requests/services";
import {ServicesApplicationsTable} from "../services";
import {getYearMonthDay, getYearMonthDayAndTime} from "../../utils";
import {useNavigate} from "react-router-dom";

export const ServicesBlock = () => {
    const isMobile = window.screen.width <= 1020
    const navigate = useNavigate();

    const [current, setCurrent] = useState(1)

    const [applicationsData, setApplicationsData] = useState<IServicesApplications[]>([]);


    useEffect(() => {
        if (current == 1 || current == 2) {
            $getServiceApplications({
                self: current == 1 || null,
                offset: 1,
                city: null
            }, {newApi: true}).then(res => {
                setApplicationsData(res?.data?.sample_applications?.data);
            });
        }
    }, [current])

    const content = applicationsData.slice(0, 5).map((item, idx) => {
        return (
            <div key={idx}>
                {isMobile ? (
                        <div key={idx} className='profile-services-content__mobileContent'>
                            <div className='profile-services-content__mobileBlock'>
                                <p className='profile-services-content__number'
                                   onClick={() => navigate(`/services/view/${item.id}`)}>{item.id}</p>

                                <div className={`services-applications__body__block__status width`}>
                                    <p className={`${item.status_name == 'Ожидает проверки' && 'waiting'} 
                            ${item.status_name == 'Выполнена' && 'accepted'} 
                            ${(item.status_name === 'Отклонена' || item.status_name === 'Отменена') && 'rejected'}
                            ${item.status_name == 'В работе' && 'work'}
                            `}
                                    >
                                        {item.status_name}
                                    </p>
                                </div>
                            </div>

                            <div>
                                <p className='profile-services-content__text'>{item?.name}</p>
                            </div>

                            <div className='profile-services-content__mobileBlock'>
                                <p className='profile-services-content__name'>{item?.executors[0]?.name || 'Не определен'}</p>

                                <p className='profile-services-content__date'>{getYearMonthDay(item?.created_at)}</p>
                            </div>
                        </div>
                    ) :
                    (
                        <div key={idx} style={{boxShadow: idx == 4 ? 'unset' : ''}}
                             className='profile-services-content__block'>

                            <p className='profile-services-content__number'
                               onClick={() => navigate(`/services/view/${item.id}`)}>{item.id}</p>

                            <p className='profile-services-content__date'>{getYearMonthDay(item?.created_at)}</p>

                            <p className='profile-services-content__text'>{item.name}</p>

                            <p className='profile-services-content__name'
                               onClick={() => navigate(`/user/${item.executors[0]?.uniq}`)}>{item?.executors[0]?.name || 'Не определен'}</p>

                            <div className={`services-applications__body__block__status width`}>
                                <p className={`${item.status_name == 'Ожидает проверки' && 'waiting'} 
                            ${item.status_name == 'Выполнена' && 'accepted'} 
                            ${(item.status_name === 'Отклонена' || item.status_name === 'Отменена') && 'rejected'}
                            ${item.status_name == 'В работе' && 'work'}
                            `}
                                >
                                    {item.status_name}
                                </p>
                            </div>
                            {/*<p className='profile-services-content__status'>{ item.status }</p>*/}
                        </div>
                    )}
            </div>
        )
    })

    return (
        <div className='profile-services-content'>
            {isMobile ? (
                <>
                    <div className='profile-services-content-mobile__header'>
                        <div onClick={() => current === 1 ? setCurrent(-1) : setCurrent(1)}
                             className={`profile-services-content-mobile__headerItem ${current === 0 ? 'opened' : ''}`}>
                            <p>Мои заявки</p>

                            <div
                                className={`profile-services-content-mobile__header__icon ${current === 0 ? 'active' : ''}`}>
                                <ArrowRightIcon/>
                            </div>
                        </div>

                        {current === 0 && (
                            <div className='profile-services__content'>
                                {content}
                            </div>
                        )}

                        <div onClick={() => current === 1 ? setCurrent(-1) : setCurrent(1)}
                             className={`profile-services-content-mobile__headerItem ${current === 1 ? 'opened' : ''}`}>
                            <p>Заявки в работе</p>

                            <div
                                className={`profile-services-content-mobile__header__icon ${current === 1 ? 'active' : ''}`}>
                                <ArrowRightIcon/>
                            </div>
                        </div>

                        {current === 1 && (
                            <div className='profile-services__content'>
                                {content}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className='profile-services-content__header'>
                        <p className={current == 1 ? 'profile-services-content__activeTitle' : ''}
                           onClick={() => setCurrent(1)}>Мои заявки</p>

                        <p className={current === 2 ? 'profile-services-content__activeTitle' : ''}
                           onClick={() => setCurrent(2)}>Заявки в работе</p>
                    </div>

                    {current == 1 && (
                        <div className='profile-services__content'>
                            {content}
                        </div>
                    )}

                    {current === 2 && (
                        <div className='profile-services__content'>
                            {content}
                        </div>
                    )}
                </>
            )}

        </div>
    )
}