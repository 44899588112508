import { AcceptedIcon, ArrowExitIcon, RejectedIcon } from '../../../img'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isMd } from '../../../utils'
import { Controller, useForm } from 'react-hook-form'
import DatePickerForm from '../../../components/ui/form/DatePickerForm'
import { useSelector } from 'react-redux'
import { getPersonData } from '../../../redux'
import { BlankAva } from '../../../components/ui/avatars/BlankAva'
import {
    $getServiceVacation,
} from '../../../api/requests/services/vacation'
import moment from 'moment/moment'
import { SearchItem } from '../../../components/ui/form/SearchItem'
import { ServicesApplicationsList } from '../../../components/services'
import {
    ServicesFormingApplicationModal,
    ServicesImprovementModal,
} from '../../../components/services/services-education/modals'
import {
    ServicesAddingAgreerModal,
} from '../../../components/services/services-education/modals/ServicesAddingAgreerModal'
import { withDebounce } from '../../../functions'
import { active } from "sortablejs";

export const ServicesVacationPage = () => {
    const navigate = useNavigate()
    const isMobile = isMd()

    const searchParams = new URLSearchParams(location.search);
    const activeType: any = searchParams.get('activeType') ;

    const personalData = useSelector(getPersonData)
    const isAcceptor = personalData?.new_vacations?.can_change_status

    const [isOpenModal, setIsOpenModal] = useState(false)

    const [isOpenDialog, setIsOpenDialog] = useState(0)

    const [list, setList] = useState<any>([])
    const [filteredList, setFilteredList] = useState<any>(list)

    const [statusesData, setStatusesData] = useState([
        {
            label: 'Отклонен',
            value: -1,
            color: '#FF5454',
        },
        {
            label: 'Рассмотрение',
            value: 0,
            color: '',
        },
        {
            label: 'Успешно',
            value: 1,
            color: '#00A7B5',
        },
    ])

    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    // const [activeType, setActiveType] = useState(0)
    const [types, setTypes] = useState<any[]>([])
    const [sender, setSender] = useState('')
    const [search, setSearch] = useState('')

    const columns = [
        {
            key: 'id',
            title: 'ID заявки',
            width: 7.5,
            class: 'id',
            render: (value: number | string) => (
                <div className="services-acceptance__block__id"
                     onClick={() => navigate(`/services/vacation/view/${value}`)}>
                    {value}
                </div>
            ),
        },
        { key: 'created_at', title: 'Дата заявки', width: 15 },
        { key: 'date_from', title: 'Дата начала', width: 15 },
        { key: 'date_to', title: 'Дата завершения', width: 15 },
        { key: 'reason', title: 'Основание', width: 15 },
        {
            key: 'user', title: 'Отправитель', width: 17.5, render: (value: any) => (
                <div className="services-acceptance__block__status">
                    {value?.photo ? (
                        <img src={value.photo} alt=""
                             className="services-acceptance__block__admin__image" />
                    ) : (
                        <BlankAva
                            size={35}
                            style={{ borderRadius: 8 }}
                            colorBg={'#CCD9E0'}
                            colorText={'black'}
                            text={value?.name}
                        />
                    )}

                    <h5 className="services-acceptance__block__admin__name">{value?.name}</h5>
                </div>
            ),
        },
        {
            key: 'file', title: 'Заявление', width: 10, render: (e: string) => (
                <div className="services-acceptance__block__file" onClick={() => downloadFile(e)}>
                    Скачать
                </div>
            ),
        },
        (activeType == 1 ?
                {
                    key: 'actions', title: '', width: 10, render: (_: any, row: any) => (
                        <div className="services-acceptance__block__status">
                            <div className="services-acceptance__block__status__item"
                            >
                                <AcceptedIcon />
                            </div>
                            <div className="services-acceptance__block__status__item">
                                <RejectedIcon /></div>
                        </div>
                    ),
                }
                :
                {
                    key: 'status', title: 'Статус', width: 15, render: (value: any, row: any) => {
                        const findStatus = statusesData?.find(item => item?.value == value)

                        return (
                            <div className={'services-applications-create__status-container'}>
                                {findStatus?.value === -1 ? (
                                    <div
                                        style={{ background: '#FF0000' }}
                                        className={`services-applications-create__status canceled`}
                                    >
                                        {findStatus?.label}
                                    </div>
                                ) : ''}

                                {findStatus?.value === 1 ? (
                                    <div
                                        style={{ background: '#00A7B5' }}
                                        className={`services-applications-create__status success`}
                                    >
                                        {findStatus?.label}
                                    </div>
                                ) : ''}

                                {findStatus?.value !== -1 && findStatus?.value !== 1 ? (
                                    <div
                                        className={`services-applications-create__status waiting`}
                                    >
                                        {findStatus?.label ?? 'Рассмотрение'}
                                    </div>
                                ) : ''}
                            </div>
                        )

                    },
                }
        ),

    ]

    const {
        control,
        formState: { isValid },
        setValue,
        watch,
    } = useForm<any>()

    useEffect(() => {
        if (activeType || activeType == 0) {
            init()
        }
    }, [activeType, watch('created_at'), watch('date_from'), watch('date_to')])

    useEffect(() => {
        setPage(1)
        setLastPage(1)
    }, [activeType])

    useEffect(() => {
        if (activeType || activeType == 0) {
            withDebounce(() => init())
        }
    }, [sender, search, page])

    useEffect(() => {
        setList([])
        setFilteredList([])
    }, [activeType])

    useEffect(() => {
        if (isAcceptor) {
            setTypes([
                {
                    id: 0,
                    key: 'applications',
                    title: 'Мои заявки',
                },

                {
                    id: 2,
                    key: 'history',
                    title: 'История заявок',
                },
            ])
        } else {
            setTypes([
                {
                    id: 0,
                    key: 'applications',
                    title: 'Мои заявки',
                },

                {
                    id: 2,
                    key: 'history',
                    title: 'История заявок',
                },
            ])
        }
    }, [personalData])

    function init() {
        const requestData = {
            list_type: +activeType,
            page,
            sender: sender || undefined,
            search: search || undefined,
            application_date: watch('created_at') ? moment(watch('created_at')).format('YYYY-MM-DD') : undefined,
            start_date: watch('date_from') ? moment(watch('date_from')).format('YYYY-MM-DD') : undefined,
            end_date: watch('date_to') ? moment(watch('date_to')).format('YYYY-MM-DD') : undefined,
        }

        $getServiceVacation(requestData, { newApi: true }).then(res => {
            const updatedList = res?.data?.data?.map((item: any) => {
                return {
                    ...item,
                    date_from: moment(item?.date_from).format('DD.MM.YYYY'),
                    date_to: moment(item?.date_to).format('DD.MM.YYYY'),
                    created_at: moment(item?.created_at).format('DD.MM.YYYY'),
                }
            })

            if (page !== 1) {
                setFilteredList([
                    ...filteredList,
                    ...updatedList
                ])

                setList([
                    ...list,
                    ...updatedList
                ])
            } else {
                setFilteredList(updatedList)
                setList(updatedList)
            }

            setLastPage(res?.data?.last_page)
        })
    }

    function handleFilterList(date_from: string, date_to: string, created_at: string) {
        const updatedList = list.filter((item: any) => {
                if (date_to || date_from || created_at) {
                    return (item.date_to === moment(date_to).format('DD.MM.YYYY') ||
                        item.date_from === moment(date_from).format('DD.MM.YYYY') ||
                        item.created_at === moment(created_at).format('DD.MM.YYYY'))
                } else {
                    return item
                }
            },
        )

        setFilteredList(updatedList)
    }

    function downloadFile(url: string) {
        const link: any = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.download = getFileName(url || '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    function getFileName(url: string) {
        const parts: any = url?.split('/')
        return parts[parts?.length - 1]
    }

    return (
        <div className="services-education">
            <div className="container services-education-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon"
                             onClick={() => navigate('/services')}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Отпуск</h1>
                    </div>
                )}

                <div className="services-education-header__right">
                    <SearchItem placeholder={'Поиск по согласователю'} onChange={() => ''} setValueProps={setSearch} />
                </div>
            </div>

            <div className="services-education-content">
                <div className="container services-education-content__top">
                    {types?.map((
                        item,
                        idx,
                    ) => (
                        <div
                            key={idx}
                            className={`services-education-content__top__block ${activeType == item.id ? 'active' : ''}`}
                            onClick={() => navigate(`/services/vacation/list?activeType=${item.id}`) }
                        >
                            {item.title}
                        </div>
                    ))}
                </div>

                <div className="services-education-wrapper">
                    <div className="container services-education-wrapper__header">
                        <div className="services-education-wrapper__buttons">
                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="user"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: '',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <div className="services-education-wrapper__search">
                                            <p className="services-education-wrapper__search__label">Отправитель</p>

                                            <SearchItem
                                                selectItem={''}
                                                setValueProps={setSender}
                                                onChange={() => {
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="created_at"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={'Не выбрано'}
                                            onChange={(e: any) => {
                                                setValue('created_at', e)
                                                // handleFilterList(watch()?.date_to, watch()?.date_from, e);
                                            }}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="date_from"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата начала"
                                            placeholder={'Не выбрано'}
                                            onChange={(e: any) => {
                                                setValue('date_from', e)
                                                // handleFilterList(e, watch()?.date_to, watch()?.created_at);
                                            }}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="date_to"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Выберете дату',
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата завершения"
                                            placeholder={'Не выбрано'}
                                            onChange={(e: any) => {
                                                setValue('date_to', e)
                                                // handleFilterList(watch().date_from, e, watch()?.created_at);
                                            }}
                                            isIcon
                                            isRightIcon
                                            isRemoving
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="services-education-wrapper__header__button"
                             onClick={() => navigate('/services/vacation/create')}>
                            Подать заявку
                        </div>
                    </div>

                    <ServicesApplicationsList
                        columns={columns}
                        list={filteredList}
                        urlRedirect={'vacation'}
                        setPage={setPage}
                        page={page}
                        lastPage={lastPage}
                    />

                </div>
            </div>

            {isOpenModal && (
                <ServicesFormingApplicationModal isOpen={isOpenModal}
                                                 onClose={() => setIsOpenModal(false)} />
            )}

            {isOpenDialog === 1 && (
                <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                          onClose={() => setIsOpenDialog(0)} />
            )}

            {isOpenDialog === 2 && (
                <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                           onClose={() => setIsOpenDialog(0)} />
            )}
        </div>
    )
}