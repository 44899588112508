import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { $authSign, $resetPassword } from "../../api/requests/auth";
import { successNotify } from "../../utils";

import Button from "../ui/form/Buttons/Button";
import TextField from "../ui/form/Fields/TextField";
import { CheckboxIcon } from "../../img/icons/CheckboxIcon";

type Inputs = {
    mail: string;
    code: string;
    password: string;
}

export default function ResetForm() {
	const navigate = useNavigate();

    const [errors, setErrors] = useState<any>(null);
    const [loginMail, setLoginMail] = useState<string>('');
    const [codeMail, setCodeMail] = useState<string>('');
    const [passwordMail, setPasswordMail] = useState<string>('');
    const [isEye, setIsEye] = useState<boolean>(false);
    const [isRequestMail, setIsRequestMail] = useState<boolean>(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidAllFields = !loginMail.length || !codeMail.length || !passwordMail.length;

    const changeTypePassword = (type: boolean): void => {
        setIsEye(!type);
    }

    useEffect(() => {
        setIsRequestMail(true);
        setCodeMail("");
    }, [loginMail]);

    function onSubmit(inputs: Inputs) {
        setErrors(null);

        const requestData = {
            ...inputs
        }

        $resetPassword(requestData, { formData: true }).then((res) => {
            if (res && !res?.error) {
                successNotify('Успешно обновлено');

				navigate('/auth');
            }
        })
    }

    function handleCheckCode() {
        if (emailRegex.test(loginMail)) {
            setErrors(null)
        } else {
            setErrors({ mail: { message: "Введите корректную почту" } })

            return;
        }

        const requestData = {
            mail: loginMail
        }

        $resetPassword(requestData, { formData: true }).then((res) => {
            if (res && !res?.error) {
                successNotify('Письмо отправлено');

                setIsRequestMail(false);
            }
        })
    }

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();

                onSubmit({ mail: loginMail, code: codeMail, password: passwordMail })
            }}
            className="auth__login__form"
        >
            <div>
                <div className="form-block">
                    <TextField
                        type={"email"}
                        value={loginMail}
                        onChange={setLoginMail}
                        label={'Почта'}
                        name={"mail"}
                        errors={errors}
                        id={"login"}
                        autoFocus
                        append={(
                            <Button
                                className="btn btn-link"
                                text={<CheckboxIcon/>}
                                onClick={handleCheckCode}
                            />
                        )}
                    />

                    <TextField
                        value={codeMail}
                        onChange={setCodeMail}
                        name={"code"}
                        label={'Код с почты'}
                        errors={errors}
                        disabled={isRequestMail}
                    />

                    <TextField
                        type={isEye ? 'text' : 'password'}
                        value={passwordMail}
                        name={"password"}
                        onChange={setPasswordMail}
                        append={(
                            <Button
                                className="btn btn-link"
                                text={
                                    <img
                                        src={
                                            isEye
                                                ? '/images/icons/eye.svg'
                                                : '/images/icons/hide-eye.svg'
                                        }
                                        width="24"
                                    />
                                }
                                onClick={() => changeTypePassword(isEye)}
                            />
                        )}
                        label={'Новый пароль'}
                        id={"password"}
                        errors={errors}
                    />
                </div>

                <div className="auth__start show">
                    <Button
                        className="btn btn-primary"
                        type={'submit'}
                        text={'Обновить'}
                        disabled={isValidAllFields}
                    />
                </div>
            </div>
        </form>
    )
}
