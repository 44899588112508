import { IApiHandlerParams } from '../../../interface'
import api from '../../../index'

export const $createServiceEducation = (
    data: any,
    handler?: IApiHandlerParams,
): Promise<any> => api
    .post('api/v1/service_trainings', data, {
        handler,
    })

export const $updateStatusEducation = (
    id: number | string,
    data: any,
    handler?: IApiHandlerParams,
): Promise<any> => api
    .put(`backend/v1/service_trainings/change_user_status/${id}`, data, {
        handler,
    })

export const $getServiceEducation = (
    params: any,
    handler?: IApiHandlerParams,
): Promise<any> => api
    .get(`api/v1/service_trainings`, {
        request: {
            params
        },
        handler,
    })

export const $getServiceEducationUsers = (
    params: any,
    handler?: IApiHandlerParams,
): Promise<any> => api
    .get(`api/v1/service_trainings/users`, {
        request: {
            params
        },
        handler,
    })

export const $getServiceEducationCollaborators = (
    params: any,
    handler?: IApiHandlerParams,
): Promise<any> => api
    .get(`api/v1/service_trainings/collaborators`, {
        request: {
            params
        },
        handler,
    })


export const $getServiceEducationItem = (
    id: number | string,
    handler?: IApiHandlerParams,
): Promise<any> => api
    .get(`api/v1/service_trainings/${id}`, {
        handler,
    })

export const $replaceExampleEducation = (
    data: any,
    handler?: IApiHandlerParams,
): Promise<any> => api
    .put(`backend/v1/service_trainings/example`, data, {
        handler,
    })

export const $getExampleEducation = (
    handler?: IApiHandlerParams,
): Promise<any> => api
    .get(`api/v1/service_trainings/example`, {
        handler,
    })