import { BlankAva } from "../../ui/avatars/BlankAva";
import { CloseIcon, MessengerSendIcon, PaperClipIcon } from "../../../img";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import { CommentMessengerChatBox } from "../../comment-messenger";
import React, { FC, useEffect, useRef, useState } from "react";
import { isTillMd } from "../../../utils";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import {
    $getBlogComments,
    $postBlogComments,
    $toggleNotifyBlogComment
} from "../../../api/requests/blogs";
import { BlogsCommentsChatBox } from "./BlogsCommentsChatBox";
import { IUsers } from "../../../api/requests/user/interface";
import { $getWorkspaceUsers } from "../../../api/requests/workspace-settings";
import { $sendCommentNotification } from "../../../api/requests/notification";

interface CommentMessengerPros {
    blog_id: number;
    is_notify: boolean,
    isCommentLoading: boolean,
    setIsCommentLoading: any,
    author_id?: number | string,
    notification_type: string
}

export const BlogsCommentsMessenger: FC<CommentMessengerPros> = (
    {
        blog_id,
        is_notify,
        setIsCommentLoading,
        isCommentLoading,
        author_id = 0,
        notification_type
    }) => {
    const isMobile = isTillMd();

    const { workspace_id: wsId } = useSelector(getPersonData);

    const profileData = useSelector(getPersonData);
    const [text, setText] = useState("");

    const [commentsData, setCommentsData] = useState([])

    const [attachments, setAttachments] = useState<any>([]);
    const [isNotificationValue, setIsNotificationValue] = useState<number>( 0);

    const [isLoading, setIsLoading] = useState(false)

    const [showDropdown, setShowDropdown] = useState(false);
    const [usersList, setUsersList] = useState<IUsers[]>([]);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    const taggedUsers = useRef<IUsers[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setText(value);

        const caret = e.target.selectionStart;

        const lastWord = value.slice(0, caret).split(" ").pop();
        if (lastWord?.startsWith("@")) {
            const query = lastWord.slice(1); // Remove "@"
            if (query) {
                fetchSuggestions(query);
            }
            setShowDropdown(true);
            setCursorPosition(caret);
        } else {
            setShowDropdown(false);
        }
    };

    const generateHTML = () => {
        let formattedText = text.trim();

        taggedUsers.current.forEach((user) => {
            const tagPattern = new RegExp(`@${user.name.trim()}`, "g");
            const userLink = `<a href="https://itelma-dev.svoi.club/user/${user.id}"><span class="hashtag">@${user.name.trim()}</span></a>`;
            formattedText = formattedText.replace(tagPattern, userLink);
        });

        return `<p>${formattedText}</p>`;
    };

    const fetchSuggestions = async (query: string) => {
        if (wsId) {
            $getWorkspaceUsers({ id: wsId, search: query }, { formData: true }).then(res => {
                if (!res.users) return;

                setUsersList(res.users)
            });
        }
    };

    const handleOptionSelect = (user: IUsers) => {
        if (textareaRef.current && cursorPosition !== null) {
            const value = text;
            const beforeCursor = value.slice(0, cursorPosition).split(" ").slice(0, -1).join(" ");
            const afterCursor = value.slice(cursorPosition);

            const newValue = `${beforeCursor} @${user.name} ${afterCursor}`.trim();
            setText(newValue);

            taggedUsers.current.push(user);
            setShowDropdown(false);
            textareaRef.current.focus();
        }
    };

    const handlePostComment = () => {
        const htmlContent = generateHTML();

        setIsLoading(true);
        if (blog_id) {
            $postBlogComments({content: htmlContent, blog_id: blog_id},  { newApi: true }).then(res => {
                setIsLoading(false);
                setText("");
            });

            taggedUsers.current.forEach((user) => {
                $sendCommentNotification(
                    {
                        user_id: user.id,
                        ws_id: wsId,
                        type: notification_type,
                        type_id: +blog_id
                    },
                    {newApi: true}).then(res => {

                })
            })
        }
    };


    const handleGetCommentImages = ({ target }: any) => {
        const file = target?.files[0];

        if (file) {
            // const imageURL = URL.createObjectURL(file);
            setAttachments([...attachments, file]);
        }
    };

    const handleDeleteCommentImages = (index: number) => {
        // const updatedList = attachments.filter((
        //     item: any,
        //     idx: number
        // ) => idx !== index);
        //
        // setAttachments(updatedList);
    };

    useEffect(() => {
        $getBlogComments(blog_id, {newApi: true}).then((res: any) => {
            if (!res.data) return;

            setCommentsData(res?.data)
        })
    }, [isLoading])

    function handleNotification() {
        setIsCommentLoading(true)

        $toggleNotifyBlogComment(blog_id, {newApi: true}).then(res => {
            setIsCommentLoading(false)
        })
        //
        // $editIdeasNotification(requestData, { formData: true }).then(res => {
        //     if (!res) return
        //
        //     setIsNotificationValue(updateNot)
        // })
    }

    return (
        <div className="news-messenger">
            <div className="news-messenger__top">
                {!isMobile && (
                    <>
                        {profileData?.photo ? (
                            <img className="news-messenger__top__avatar"
                                 src={profileData?.photo} alt="" />
                        ) : (
                            <div className="news-messenger__top__avatar">
                                <BlankAva
                                    colorBg={"#CCD9E0"}
                                    colorText={"black"}
                                    text={profileData?.name}
                                />
                            </div>
                        )}
                    </>
                )}

                <div className="news-messenger__top__textarea__right">
                    <div className="news-messenger__top__textarea">
                          <textarea
                              ref={textareaRef}
                              value={text}
                              onChange={handleInputChange}
                              placeholder="Оставить комментарий"
                              className="news-messenger__top__input"
                          />

                        <div className="news-messenger__top__textarea__buttons">
                            <label className="news-messenger__top__textarea__buttons__imageButton">
                                {/*<PaperClipIcon color={isMobile ? "#00A7B5" : "#000"} />*/}

                                <input
                                    type="file"
                                    accept={'.png, .jpg'}
                                    onChange={handleGetCommentImages}
                                    hidden
                                />
                            </label>

                            {isMobile ? (
                                <MessengerSendIcon />
                            ) : (
                                <div className="news-messenger__top__textarea__buttons__enterButton"
                                     onClick={handlePostComment}>
                                    Опубликовать
                                </div>
                            )}
                        </div>

                        {showDropdown && usersList.length > 0 && (
                            <div className='news-messenger__top__textarea__users'>
                                {usersList.map((item, idx) => (
                                    <div  className="layout-vacation-modal__block__right"
                                          onClick={() => handleOptionSelect(item)}
                                          key={idx}
                                    >
                                        {item?.photo ? (
                                            <img src={item?.photo} alt="" />
                                        ) : (
                                            <BlankAva
                                                colorBg={"#CCD9E0"}
                                                colorText={"black"}
                                                text={item?.name}
                                            />
                                        )}

                                        <div className="layout-vacation-modal__block__desc">
                                            <h1>{item?.name}</h1>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="news-messenger__top__textarea__attachments">
                        {attachments?.map((
                            item: any,
                            idx: number
                        ) => (
                            <div className="news-messenger__top__textarea__attachments__item"
                                 key={idx}>
                                <div className="closeIcon"
                                     onClick={() => handleDeleteCommentImages(idx)}>
                                    <CloseIcon color={"#FF0000"} />
                                </div>

                                <p>
                                    {idx !== attachments.length - 1 ? `${item?.name},` : item?.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {(isNotificationValue || typeof isNotificationValue === "number") && (profileData?.id == author_id) && (
                <ToggleBlock
                    isChecked={ is_notify }
                    className="ideas__create__form-toggle"
                    desc="Сообщать о новых коментариях"
                    handleChange={ handleNotification }
                />
            )}

            {blog_id && (
                <BlogsCommentsChatBox
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    blog_id={blog_id}
                    comments_data={commentsData}
                    notification_type={notification_type}
                />
            )}

            {/*{id && (*/}
            {/*    <CommentMessengerChatBox*/}
            {/*        comments_data={commentsData}*/}
            {/*        item_id={id}*/}
            {/*        url={''}*/}
            {/*        setIsLoading={setIsLoading}*/}
            {/*        isExpert={false}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    )
}