import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/ui/form/Buttons/Button";
import { $getGroupList, $joinGroup, $showGroupById } from "../../api/requests/groups";
import { Controller, useForm } from "react-hook-form";
import TextField from "../../components/ui/form/Fields/TextField";
import { failureNotify } from "../../utils";
import { BlankImage } from "../../components/ui/blank-image/BlankImage";
import { useSelector } from "react-redux";
import { getCurrentRole } from "../../redux";

const GroupJoin = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const searchParams = new URLSearchParams(location.search);
    const groupId: string = searchParams.get("id") || "";

    const [groupData, setGroupData] = useState<any>(null);
    const [isCode, setIsCode] = useState<boolean>(false);
    const [isSendApplication, setIsSendApplication] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors, isValid },
    } = useForm<any>()

    const textErrorJoinGroup = (status: number): string => {
        if (status === 0) return "Группа не активна, обратитесь к администатору";

        if (status === 4) return "Доступ к группе закрыт";

        return "";
    }

    useEffect(() => {
        setIsLoading(true);

        if (+groupId) {
            init();
        }
    }, [groupId]);

    function init() {
        if (!+groupId) return;

        $getGroupList().then(res => {
            setIsLoading(false);
            if (!res?.length) return;

            const findSelectGroup = res.find(item => +item.id === +groupId);

            if (findSelectGroup) {
                setGroupData(findSelectGroup);
                setIsCode(findSelectGroup?.status && +findSelectGroup?.status === 2 || false)
            }
        });
    }

    function onSubmit(data: any) {
        if (([0, 4].includes(+groupData?.status) || !groupData?.id) && !isAdmin) return;

        const requestData = +groupData.status === 2 ? {
            id: groupData.id,
            ...data,
        } : {
            id: groupData.id
        }

        $joinGroup(requestData, { formData: true, dontShowErrorMessage: true }).then(res => {
            if (res.error && res.error === "INCORRECT_PASS") return failureNotify("Пароль не верный");
            if (res.error || res.response) return;

            if (+groupData.status === 3) {
                setIsSendApplication(true);

                setTimeout(() => {
                    reset();
                    navigate("/groups");
                }, 5000)

                return;
            }

            handleOpenEdit(groupData.id);
        });
    }

    function handleOpenEdit(id: string) {
        $showGroupById({ id }, { formData: true }).then(res => {
            if (!res) return;

            navigate(`/?group_id=${id}`);
        })
    }

    if (isLoading) return null;

    if (!groupData) return (
        <div className={"container groups__join__container"}>
            <form className={"groups__join__form"} onSubmit={handleSubmit(onSubmit)}>
                <p className={"error"}>
                    Группа не найдена
                </p>
            </form>
        </div>
    );

    return (
        <div className={"container groups__join__container"}>
            <form className={"groups__join__form"} onSubmit={handleSubmit(onSubmit)}>
                <div className={"groups__join__header"}>
                    <BlankImage image={groupData?.image} />

                    <p>{groupData?.title || "Название отсутствует"}</p>
                </div>

                <div className={"groups__join__content"}>
                    <p>
                        Группа позволяет Вам получить доступ к многочисленным преимуществам, таким
                        как:
                    </p>

                    <div className={"groups__join__block"}>
                        <p>
                            <span>Новости</span> — оперативное получение самой свежей информации
                            напрямую от администрации группы.
                        </p>

                        <p>
                            <span>Идеи</span> — зарабатывайте баллы на креативных идеях в интересах
                            группы. Баллы можно тратить на материальные блага в кафетерии льгот.
                        </p>

                        <p>
                            <span>Конкурсы</span> — зарабатывайте баллы, выигрывая конкурсы от
                            администрации группы. Баллы можно тратить на материальные блага в
                            кафетерии льгот.
                        </p>
                    </div>

                    <p>
                        <span>Нужна помощь с входом в группу?</span><br />
                        Звоните: <a href="tel:88007751598" target={"_blank"}>8 (800) 775-15-98</a>(бесплатно
                        для РФ)
                    </p>
                </div>

                {isCode && (
                    <div>
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: "Заполните код"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className=""
                                    title="Код доступа"
                                    placeholder={"Введите код доступа"}
                                    name="password"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />
                    </div>
                )}

                <Button
                    className="btn btn-primary w-100"
                    type={"submit"}
                    text={"Вступить"}
                    disabled={[0, 4].includes(+groupData?.status) && !isAdmin}
                />

                {textErrorJoinGroup(+groupData?.status) && (
                    <p className={"error"}>
                        {textErrorJoinGroup(+groupData?.status)}
                    </p>
                )}

                {isSendApplication && (
                    <p className={"success"}>
                        Заявка на вступление успешно отправлена
                    </p>
                )}
            </form>
        </div>
    );
};

export default GroupJoin;