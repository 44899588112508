import { useDispatch, useSelector } from "react-redux";
import {
    getPersonData,
    getStructureCompany,
    setStructureCompany,
    setStructureCurrentTopHeight
} from "../../../../redux";
import { isLg, isMd } from "../../../../utils";
import Button from "../../../../components/ui/form/Buttons/Button";
import { ArrowRightIcon, EmailIcon, StarIcon, UserProfileIcon } from "../../../../img";
import { Tooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from 'react'
import { MessengerIcon } from "../../../../img/icons/structure/MessengerIcon";
import { $toggleUserFavorites } from '../../../../api/requests/favorites'

interface StructureItemProps {
    person: {
        index: number;
        count: number;
        id: string;
        department_id: string;
        department: any;
        hasChild: string;
        is_active: boolean;
        isExpand: boolean;
        isActive: boolean;
        isActiveStyle: boolean;
        setDepartment: (value: string) => void;
        user: any;
        isVacationAdmin: boolean;
        title: string;
    };
    isFavorite?: boolean;
}

export const StructureItem = (
    {
        person,
        isFavorite
    }: StructureItemProps
) => {

    const contentItem = useRef<any>(null);

    const navigate = useNavigate();
    const query = { is_admin: true };
    const { structure } = useSelector(getStructureCompany);
    const currentUser = useSelector(getPersonData);
    const dispatch = useDispatch();

    const isTablet = isLg();
    const isMobile = isMd();

    const {
        index,
        count,
        id,
        department_id,
        department,
        hasChild,
        is_active,
        isExpand,
        isActive,
        isActiveStyle,
        setDepartment,
        user,
        isVacationAdmin,
        title
    } = person || {};

    const isEnable = is_active || department?.is_active;
    const isAdmin = currentUser?.workspace_role?.role === 1;
    const pathAdmin = query?.is_admin;
    const [isLiked, setIsLiked] = useState<boolean>(user?.is_favorite)

    const handleActive = () => {
        if (!isActiveStyle && hasChild) {
            const updateArray = structure?.map((_: any, i: number) =>
                structure?.[i]?.map((item: any) => {
                    return { ...item, isActiveStyle: false };
                })
            );

            const updateIndexArray = structure?.[index]?.map((item: any) => {
                if (department?.id ? item?.department?.id === department?.id : item?.id === id) {
                    return {
                        ...item,
                        isActive: true,
                        isActiveStyle: true,
                        isHidden: isTablet && false
                    };
                }

                return {
                    ...item,
                    isActive: false,
                    isActiveStyle: false,
                    isHidden: isTablet && true
                };
            });

            const copyArray = [...updateArray];

            copyArray[index] = updateIndexArray;

            dispatch(
                setStructureCompany({
                    structure: copyArray?.slice(0, index + 1)
                })
            );

            dispatch(setStructureCurrentTopHeight(contentItem.current.getBoundingClientRect().top - 204));

            setDepartment(department_id || department?.department_id);
        }
    };

    const makeMessageUrl = ({ photo, deafultDataKeys, rest, name, id }: any) => {
        const baseUrl = `/messenger?id=${deafultDataKeys ? rest[deafultDataKeys.id] : id}&name=${
            deafultDataKeys ? rest[deafultDataKeys.name] : name
        }`;

        if (!photo) {
            return baseUrl;
        }
        return `${baseUrl}&photo=${photo}`;
    };

    function handleProfile() {
        navigate(`/user/${user?.uniq}`);
    }

    function handleMessage() {
        const { photo, name, userId } = user;

        navigate(makeMessageUrl({ photo, name, id: userId }));
    }

    const handleLike = (id: number, e: any) => {
        e.stopPropagation()

        $toggleUserFavorites({ type: 'user', type_id: id, add_to_favorites: !isLiked }, { newApi: true }).then(res => {
            setIsLiked(!isLiked)
        })
    }

    return (
        <div
            id={isActiveStyle ? "active" : ""}
            className={"structure__content__item"}
            ref={contentItem}
        >
            <div className={`structure__content__item-body ${count > 1 ? (isExpand ? "open" : "") : "open"}`}>
                <div
                    className={`structure__content__item-body-block`}
                    onClick={handleActive}
                >
                    <div
                        className={"structure__content__item-avatar"}
                        style={user?.photo ? {
                            backgroundImage: `url(${user?.photo})`,
                            backgroundPosition: "center center",
                            backgroundSize: "cover"
                        } : {}}
                    >
                        {!user?.photo && <p>{user?.name?.charAt(0)}</p>}
                    </div>

                    <div className={"structure__content__item-body-info"}>
                        <div
                            className={"structure__content__item-body-name"}
                        >
                            {user === null ? "Не зарегистрирован в СВОИ" : user?.name}
                        </div>

                        <div className={"positionStyle, structure__content__item-body-position"}>
                            <span>{department?.title}</span>
                            <span>{title}</span>
                        </div>
                    </div>

                    {user && (
                        <div className={'structure__content__item-body-favorite'}>
                            <div
                                onClick={(e) => {
                                    handleLike(user.id, e)
                                }}
                                className={`employees-block__likeIcon ${isLiked ? 'active' : ''}`}
                            >
                                <StarIcon opacity={isLiked ? 1 : 0.2} />
                            </div>
                        </div>
                    )}
                </div>

                {((!!user || isVacationAdmin) && (count > 1 ? (!!isExpand) : true)) && (
                    <div className={'structure__content__item-body-contacts'}>
                        {user?.workspace_role?.privacy === 1 && (
                            <Button
                                text={<span data-tooltip-id="my-tooltip-privacy"><UserProfileIcon/></span>}
                                className={"btn-icon structure__content__item-body-iconButton, structure__content__item-body-account"}
                                onClick={handleProfile}
                            />
                        )}

                        {user?.mail && user?.workspace_role?.privacy_mail === 1 && (
                            <Link
                                data-tooltip-id="my-tooltip-mail"
                                to={`mailto:${user?.mail}`}
                                className={"btn-icon structure__content__item-body-icon-btn, structure__content__item-body-account"}
                                onClick={handleProfile}
                            >
                                <EmailIcon />
                            </Link>
                        )}

                        <Tooltip
                            id={"my-tooltip-privacy"}
                            content="Открыть профиль"
                        />

                        <Tooltip
                            id={"my-tooltip-mail"}
                            content="Написать на почту"
                        />
                    </div>
                )}
            </div>

            {!!isEnable && (isExpand || isActive) && hasChild && (
                <div className={"d-flex align-items-center justify-content-center"}>
                    <Button
                        text={<ArrowRightIcon color={"white"} />}
                        className={"btn btn-primary subdivision"}
                        onClick={handleActive}
                    />
                </div>
            )}
        </div>
    );
};
