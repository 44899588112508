import {
    ArrowExitIcon,
    CalendarIcon,
    ReplacingIcon,
} from "../../../img";
import { useNavigate } from "react-router-dom";
import MainLayoutContent from "../../../layouts/MainLayoutContent";
import { VacationLeftContent } from "../../calendar/vacation/VacationLeftContent";
import React, { useEffect, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { isMd } from "../../../utils";
import { $getSubordinates } from "../../../api/requests/user";
import moment from "moment";
import { MedicalLeaveRecord, User, VacationRecord } from "../../../api/requests/user/interface";
import { useDispatch, useSelector } from "react-redux";
import { getBookingList, getPersonData, setBookingList } from "../../../redux";
import { $setVacationBooking } from "../../../api/requests/vacation";
import {
    $changeMedicalStatus,
    $revertMedicalStatus
} from "../../../api/requests/services/medical-leave";
import { $changeReplacementStatus } from "../../../api/requests/services/replacement";
import { BlankAva } from "../../../components/ui/avatars/BlankAva";
import { FileIcon } from "../../../img/icons/menu/FileIcon";
import { DownloadIcon } from "../../../img/icons/DownloadIcon";

export const ProfileColleagues = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const dispatch = useDispatch();

    const personalData = useSelector(getPersonData);
    const bookingList = useSelector(getBookingList);

    const [ dialog, setDialog ] = useState('closed')
    const [ activePerson, setActivePerson ] = useState<MedicalLeaveRecord>();

    const [ vacationList, setVacationList ] = useState<MedicalLeaveRecord[]>([]);

    const [ ambulanceData, setAmbulanceData ] = useState<MedicalLeaveRecord[]>([]);

    const [ replacingContent, setReplacingContent ] = useState<MedicalLeaveRecord[]>([]);

    const [userAllList, setUserAllList] = useState<any[]>([]);

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleOpenDialog = (
        item: any,
        type: string
    ) => {
        setActivePerson(item)
        setDialog(type)
    }

    const getChangeCalendarData = (item: MedicalLeaveRecord, idx: number) => {
        return {
            id: idx,
            date_from: item?.start_date,
            date_to: item?.end_date,
            // vacation_info: {
            //     unused_vacation: item?.unused_vacation,
            // },
            isProgress: item.status === 0,
            isStatus: true,
            type: item.type,
            user: {
                inner_id: item?.staff?.inner_id,
                name: item?.staff?.name,
                photo: item?.staff?.photo,
                staff: {
                    title: item?.staff?.job_title
                },
                structure: item?.staff?.department
            }
        }
    }

    const getChangeUserData = (item: User) => {
        return {
            date_from: item?.vacation?.near_vacation?.start_date,
            date_to: item?.vacation?.near_vacation?.end_date,
            vacation_info: {
                unused_vacation: item?.vacation?.unused_vacation,
            },
            isProgress: 0,
            isStatus: false,
            user: {
                inner_id: item?.inner_id,
                name: item?.name,
                photo: item?.photo,
                staff: {
                    title: item?.job_title
                },
                structure: item?.department
            }
        }
    }

    useEffect(() => {
        init();
    }, []);

    function init() {
        $getSubordinates().then(res => {
            if (!res?.data || res.error) return;

            const updateMedList = [...res.data.medical_leaves].map(item => ({
                ...item,
                isSuccess: item.status === 1,
                isProgress: item.status === 0,
                type: "medical",
            }))

            const updateVacationList = [...res.data.vacations].map(item => ({
                ...item,
                isSuccess: item.status === 1,
                isProgress: item.status === 0,
                type: "vacation",
            }))

            const updateReplacingList = [...res.data.staff_replacements].map(item => ({
                ...item,
                isSuccess: item.status === 1,
                isProgress: item.status === 0,
                type: "replacing",
            }))

            const filterList = [...updateMedList, ...updateVacationList, ...updateReplacingList]
            .map((item, idx) => getChangeCalendarData(item, idx));

            dispatch(setBookingList(filterList));

            setVacationList(updateVacationList);
            setAmbulanceData(updateMedList);
            setReplacingContent(updateReplacingList);

            setUserAllList(res.data.subordinates.map(item => getChangeUserData(item)));
        })
    }

    function handleClickApprove(select: any) {
        if (select.type === "vacation") handleVacation(select, true);
        if (select.type === "medical") handleMedical(select, true);
        if (select.type === "replacing") handleReplacing(select, true);
    }

    function handleClickReject(select: any) {
        if (select.type === "vacation") handleVacation(select, false);
        if (select.type === "medical") handleMedical(select, false);
        if (select.type === "replacing") handleReplacing(select, false);
    }

    function handleClickRevert(select: any) {
        if (!select?.id) return;

        $revertMedicalStatus(select.id).then((res) => {
            if (!res || res.error) return;

            init();
            setDialog("closed");
        })
    }

    function handleVacation(select: any, isApprove: boolean) {
        if (!select?.id) return;

        $setVacationBooking(select.id, { is_approve: isApprove }).then((res) => {
            if (!res || res.error) return;

            init();
            setDialog("closed");
        })
    }

    function handleMedical(select: any, isApprove: boolean) {
        if (!select?.id) return;

        $changeMedicalStatus(select.id, { status: isApprove ? "approved" : "rejected" }).then((res) => {
            if (!res || res.error) return;

            init();
            setDialog("closed");
        })
    }

    function handleReplacing(select: any, isApprove: boolean) {
        if (!select?.id) return;

        $changeReplacementStatus(isApprove, { replace_id: select.id }).then((res) => {
            if (!res || res.error) return;

            init();
            setDialog("closed");
        })
    }

    return (
        <div className='profile-colleagues'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>

                        <h1>Мои сотрудники</h1>
                    </div>
                ) }

                <div
                    className='profile-colleagues-header__calendar'
                    onClick={() => navigate("/calendar/vacation")}
                >
                    <CalendarIcon/>

                    <p className='profile-colleagues-header__calendar__title'>Календарь</p>

                    <span className='notification-circle notification-circle__red'>{bookingList?.length}</span>
                </div>
            </div>

            <MainLayoutContent contentGap={ 40 } leftComponents={
                <div className='vacation__content__left'>
                    <VacationLeftContent usersList={userAllList}/>
                </div>
            }>
                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Отпуск</h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { vacationList.length ? vacationList.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-block' key={ idx }>
                                <div className='profile-colleagues-block__left'>
                                    {item.staff?.photo ? (
                                        <img
                                            src={item.staff?.photo}
                                            className="profile-colleagues-block__image"
                                        />
                                    ) : (
                                        <BlankAva
                                            colorBg={"#00A7B5"}
                                            colorText={"#00A7B5"}
                                            text={item.staff?.name}
                                        />
                                    )}

                                    <div className='profile-colleagues-block__desc'>
                                        <h1 className='profile-colleagues-block__name'>{ item.staff?.name }</h1>
                                        <p className='profile-colleagues-block__position'>{ item.staff?.job_title }</p>
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__middle'>
                                    <p className='profile-colleagues-block__placeholder'>Даты отпуска</p>

                                    <div className='profile-colleagues-block__date'>
                                        с {moment(item.start_date).format("DD.MM.yyyy")} по {moment(item.end_date).format("DD.MM.yyyy")}
                                    </div>
                                </div>

                                <div
                                    onClick={ () => handleOpenDialog(item, 'vacation') }
                                    className='profile-colleagues-block__button'
                                >
                                    Согласовать
                                </div>
                            </div>
                        )) : (
                            <div className={"empty"}>Список пуст</div>
                        )}
                    </div>
                </div>

                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Работа в больничный</h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { ambulanceData.length ? ambulanceData.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-block' key={ idx }>
                                <div className='profile-colleagues-block__left'>
                                    {item.staff?.photo ? (
                                        <img
                                            src={item.staff?.photo}
                                            className="profile-colleagues-block__image"
                                        />
                                    ) : (
                                        <BlankAva
                                            colorBg={"#00A7B5"}
                                            colorText={"#00A7B5"}
                                            text={item.staff?.name}
                                        />
                                    )}

                                    <div className='profile-colleagues-block__desc'>
                                        <h1 className='profile-colleagues-block__name'>{ item.staff?.name }</h1>
                                        <p className='profile-colleagues-block__position'>{ item.staff?.job_title }</p>
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__middle'>
                                    <p className='profile-colleagues-block__workDays'>{ item?.medical_work_days } рабочих дня</p>

                                    <div className='profile-colleagues-block__ambulaneDate'>
                                        с {moment(item.start_date).format("DD.MM.yyyy")} по {moment(item.end_date).format("DD.MM.yyyy")}
                                    </div>
                                </div>

                                {item?.is_system_approved ? (
                                    <div className='profile-colleagues-block__button profile-colleagues-block__button__revert'
                                         onClick={ () => handleOpenDialog(item, 'vacation') }
                                    >
                                        Вернуть
                                    </div>
                                ) : (
                                    <div className="profile-colleagues-block__button"
                                         onClick={() => handleOpenDialog(item, "vacation")}
                                    >
                                        Согласовать
                                    </div>
                                )}
                            </div>
                        )) : (
                            <div className={"empty"}>Список пуст</div>
                        )}
                    </div>
                </div>

                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Замещающий сотрудник </h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { replacingContent.length ? replacingContent.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-replacing' key={ idx }>
                                <div className='profile-colleagues-replacing__content'>
                                    <div className='profile-colleagues-block__left'>
                                        {item.staff?.photo ? (
                                            <img
                                                src={item.staff?.photo}
                                                className="profile-colleagues-block__image"
                                            />
                                        ) : (
                                            <BlankAva
                                                colorBg={"#00A7B5"}
                                                colorText={"#00A7B5"}
                                                text={item.staff?.name}
                                            />
                                        )}

                                        <div className='profile-colleagues-block__desc'>
                                            <h1 className='profile-colleagues-block__name'>{ item.staff?.name }</h1>
                                            <p className='profile-colleagues-block__position'>{ item.staff?.job_title }</p>
                                        </div>
                                    </div>

                                    <ReplacingIcon />

                                    { item.staff_replacer && (
                                        <div className="profile-colleagues-block__left">
                                            {item.staff_replacer?.photo ? (
                                                <img
                                                    src={item.staff_replacer?.photo}
                                                    className="profile-colleagues-block__image"
                                                />
                                            ) : (
                                                <BlankAva
                                                    colorBg={"#00A7B5"}
                                                    colorText={"#00A7B5"}
                                                    text={item.staff_replacer?.name}
                                                />
                                            )}

                                            <div className="profile-colleagues-block__desc">
                                                <h1 className="profile-colleagues-block__name">{item.staff_replacer?.name}</h1>
                                                <p className="profile-colleagues-block__position">{item.staff_replacer?.job_title}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="profile-colleagues-block__button"
                                     onClick={() => handleOpenDialog(item, "replacing")}>
                                    Согласовать
                                </div>
                            </div>
                        )) : (
                            <div className={"empty"}>Список пуст</div>
                        )}
                    </div>
                </div>

                <DialogContainer
                    isOpen={dialog === "replacing" || dialog === "vacation"}
                    closeModal={() => setDialog("closed")}
                >
                    {activePerson &&
                        (
                            <>
                                {dialog === "replacing" ? (
                                    <>
                                        <div className="layout-vacation-modal__header">
                                            {dialog === "replacing" ? (
                                                <h1 className="layout-vacation-modal__title">Согласование
                                                    замещающего</h1>
                                            ) : null
                                            }
                                        </div>

                                        <div className='profile-colleagues-modal__content'>
                                            <div className='profile-colleagues-modal__content__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Замещаемый</p>

                                                <div className='profile-colleagues-block__left'>
                                                    {activePerson.staff?.photo ? (
                                                        <img
                                                            src={activePerson.staff?.photo}
                                                            className="profile-colleagues-block__image"
                                                        />
                                                    ) : (
                                                        <BlankAva
                                                            colorBg={"#00A7B5"}
                                                            colorText={"#00A7B5"}
                                                            text={activePerson.staff?.name}
                                                        />
                                                    )}

                                                    <div className='profile-colleagues-block__desc'>
                                                        <h1 className='profile-colleagues-block__name'>{ activePerson.staff?.name }</h1>
                                                        <p className='profile-colleagues-block__position'>{ activePerson.staff?.job_title }</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='profile-colleagues-modal__content__block__icon'>
                                                <ReplacingIcon />
                                            </div>

                                            { activePerson.staff_replacer && (
                                                <div
                                                    className="profile-colleagues-modal__content__block">
                                                    <p className="profile-colleagues-modal__placeholder">Замещающий</p>

                                                    <div className="profile-colleagues-block__left">
                                                        {activePerson.staff_replacer?.photo ? (
                                                            <img
                                                                src={activePerson.staff_replacer?.photo}
                                                                className="profile-colleagues-block__image"
                                                            />
                                                        ) : (
                                                            <BlankAva
                                                                colorBg={"#00A7B5"}
                                                                colorText={"#00A7B5"}
                                                                text={activePerson.staff_replacer?.name}
                                                            />
                                                        )}

                                                        <div
                                                            className="profile-colleagues-block__desc">
                                                            <h1 className="profile-colleagues-block__name">
                                                                {activePerson.staff_replacer?.name}
                                                            </h1>
                                                            <p className="profile-colleagues-block__position">
                                                                {activePerson.staff_replacer?.job_title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="profile-colleagues-modal__information">
                                            <h1>Информация</h1>

                                            <div
                                                className="profile-colleagues-modal__information__dates">
                                                <p className="profile-colleagues-modal__placeholder">Даты</p>

                                                <h1>с {moment(activePerson.start_date).format("DD.MM.yyyy")} по {moment(activePerson.end_date).format("DD.MM.yyyy")}</h1>
                                            </div>

                                            <div
                                                className="profile-colleagues-modal__information__dates">
                                                <p className="profile-colleagues-modal__placeholder">Рабочих
                                                    дней</p>

                                                <h1>0 дня</h1>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="employees-modal__top">
                                            {activePerson.staff?.photo ? (
                                                <img
                                                    src={activePerson.staff?.photo}
                                                    className="profile-colleagues-block__image"
                                                />
                                            ) : (
                                                <BlankAva
                                                    colorBg={"#00A7B5"}
                                                    colorText={"#00A7B5"}
                                                    text={activePerson.staff?.name}
                                                />
                                            )}

                                            <h1>
                                                { activePerson?.staff?.name }
                                            </h1>
                                        </div>

                                        <div className='profile-colleagues-modal__vacationContent'>
                                            <div className='profile-colleagues-modal__vacationContent__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Департамент</p>

                                                <h1 className='profile-colleagues-modal__vacationContent__block__title'>{ personalData?.structure?.title }</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__vacationContent__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Должность</p>

                                                <h1 className='profile-colleagues-modal__vacationContent__block__title'>{ activePerson?.staff.job_title }</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__vacationContent__information'>
                                                <h1 className='profile-colleagues-modal__vacationContent__information__title'>
                                                    {activePerson?.type === "vacation" ? "Согласование отпуска" : "Согласование работы в больничный"}
                                                </h1>

                                                <div className='profile-colleagues-modal__vacationContent__information__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>
                                                        {activePerson?.type === "vacation" ? "Даты отпуска" : "Даты дней работы в больничный"}
                                                    </p>

                                                    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>
                                                        с {moment(activePerson.start_date).format("DD.MM.yyyy")} по {moment(activePerson.end_date).format("DD.MM.yyyy")}
                                                    </h1>
                                                </div>

                                                {/*<div className='profile-colleagues-modal__vacationContent__information__block'>*/}
                                                {/*    <p className='profile-colleagues-modal__placeholder'>Даты по больничному листу</p>*/}

                                                {/*    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>*/}
                                                {/*        {activePerson.start_date} дней*/}
                                                {/*    </h1>*/}
                                                {/*</div>*/}

                                                {(activePerson?.medical_work_days || activePerson?.medical_work_days === 0) && (
                                                    <div
                                                        className="profile-colleagues-modal__vacationContent__information__block">
                                                        <p className="profile-colleagues-modal__placeholder">Из
                                                            них рабочих дней</p>

                                                        <h1 className="profile-colleagues-modal__vacationContent__information__block__title">
                                                            {activePerson?.medical_work_days} дня
                                                        </h1>
                                                    </div>
                                                )}
                                            </div>

                                            {activePerson?.file && (
                                                <div
                                                    className={"profile-colleagues-modal__vacationContent__file"}
                                                    onClick={() => window.open(activePerson?.file, "_blank")}
                                                >
                                                    <div className={"profile-colleagues-modal__vacationContent__file__left"}>
                                                        <FileIcon opacity={1}/>
                                                    </div>

                                                    <div className={"profile-colleagues-modal__vacationContent__file__text"}>
                                                        <p className={"title"}>Файл</p>

                                                        <p className={"text"}>{activePerson?.filename}</p>
                                                    </div>

                                                    <div className={"profile-colleagues-modal__vacationContent__file__right"}>
                                                        <DownloadIcon/>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                                <div className="profile-colleagues-modal__buttons">
                                    {activePerson.status === 0 && (
                                        <button
                                            className="profile-colleagues-modal__buttons__accept"
                                            onClick={() => handleClickApprove(activePerson)}
                                        >
                                            {activePerson?.type === "vacation" && "Согласовать"}
                                            {activePerson?.type === "medical" && "Подтверждение больничного"}
                                            {activePerson?.type === "replacing" && "Согласовать"}
                                        </button>
                                    )}

                                    {(activePerson.status === 1 && activePerson.is_system_approved) && (
                                        <button
                                            className="btn btn-light"
                                            onClick={() => handleClickRevert(activePerson)}
                                        >
                                            Вернуть
                                        </button>
                                    )}

                                    {[0].includes(activePerson.status) && (
                                        <button
                                            className="profile-colleagues-modal__buttons__reject"
                                            onClick={() => handleClickReject(activePerson)}
                                        >
                                            Отклонить
                                        </button>
                                    )}
                                </div>
                            </>
                        )
                    }
                </DialogContainer>
            </MainLayoutContent>
        </div>
    )
}