import { FC, useState } from 'react'
import NewsDateIcon from "../../img/icons/news/NewsDateIcon";
import {
    CommentIcon,
    EyeIcon,
    HeartIcon,
    LikeIcon,
    PaperClipIcon, StarIcon,
    ThreePeopleIcon
} from "../../img";
import TextOverflow from "../../utils/text-overflow/TextOverflow";
import { ContestItem } from "../../api/requests/contest/interface";
import moment from "moment";
import { $toggleUserFavorites } from '../../api/requests/favorites'

interface ICompetitionsCard {
    isLikeIcon?: boolean;
    data: ContestItem;
    handleOpenItem?: (data: ContestItem) => void
}

export const CompetitionsCard: FC<ICompetitionsCard> = (
    {
        isLikeIcon = false,
        data,
        handleOpenItem,
    }
) => {
    const isMobile = window.screen.width <= 600;

    const {
        id,
        title,
        image,
        category_id,
        category_title,
        date,
        date_end,
        views,
        likes_count,
        comments_count,
        pinned,
        status,
        is_favorite
    } = data;

    const [isLiked, setIsLiked] = useState<boolean>(!!is_favorite)
    
    const statusList = [
        {
            key: "1",
            value: "Активный"
        },
        {
            key: "2",
            value: "Завершенный"
        },
        {
            key: "3",
            value: "Принимаю участие"
        },
        {
            key: "0",
            value: "Скрытый"
        }
    ];

    const catTitle = category_title || (category_id == 0 ? 'Все' : '')
    
    const now = Date.now() / 1000;
    let statusFix = status == '0' || parseInt(date) > now ? '0' : parseInt(date_end) > now ? '1' : '2';
    const statusFind = statusList.find(item => item.key == statusFix);

    let dt_to = "..."
    if(data.date_end){
        if(typeof data.date_end == 'number'){
            dt_to = moment.unix(data.date_end).format('DD.MM.yyyy')
        }
        if(typeof data.date_end == 'object'){
            dt_to = moment(data.date_end).format('DD.MM.yyyy')
        }
        if(typeof data.date_end == 'string'){
            dt_to = data.date_end;
        }
    }

    const handleLike = (id: number, e: any) => {
        e.stopPropagation()

        $toggleUserFavorites({ type: 'contest', type_id: id, add_to_favorites: !isLiked }, { newApi: true }).then(res => {
            setIsLiked(!isLiked)
        })
    }

    return (
        <div className={`feed-news-card`} onClick={() => handleOpenItem && handleOpenItem(data)}>
            <div className={"feed-news-card__img"}>
                <img src={image} />

                {pinned && (
                    <div className={"feed-news-card__clip"}>
                        <PaperClipIcon color={"#FFFFFF"} />
                    </div>
                )}

                {statusFind && (
                    <div className={`feed-news-card__active ${statusFind?.key}`}>
                        <div className={`${statusFind?.key}`}></div>

                        <p>{statusFind?.value || "Неопределен"}</p>
                    </div>
                )}
            </div>

            <div className="feed-news-card__infoBox">
                <div className="news-card__infoBox__top">
                    <h5
                        style={{
                            minHeight: "auto"
                        }}
                    >
                        {TextOverflow({ title, lines: 3 })}
                    </h5>

                    <div
                        onClick={(e) => {
                            handleLike(id, e)
                        }}
                        className={`employees-block__likeIcon ${isLiked ? 'active' : ''}`}
                    >
                        <StarIcon opacity={isLiked ? 1 : 0.2} />
                    </div>
                </div>

                <div className="news-card__infoBox__bottom">
                    {catTitle ?
                        <div className={`feed-news-card__catTitleStyle`}>{catTitle}</div> : null}
                </div>

                <div className="news-card__infoBox__bottom mt-auto">
                    <div className="feed-news-card__content">
                        <div className="feed-news-card__dateBox">
                            <NewsDateIcon
                                size={"1.25rem"}
                                style={{
                                    marginRight: isMobile ? "0.375rem" : "0.5rem",
                                    minWidth: "1.25rem"
                                }}
                            />

                            <div>До {dt_to}</div>
                        </div>

                        <div className="feed-news-card__stats">
                            <div className="feed-news-card__stats__block">
                                <EyeIcon />

                                <h1>{views}</h1>
                            </div>

                            <div className="feed-news-card__stats__block">
                                <ThreePeopleIcon />

                                <h1>{comments_count}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};