export const EventDownloadExcelIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.469 12.8473H11.4337L11.4338 3.81195C6.44371 3.81192 2.39844 7.8572 2.39844 12.8473C2.39844 17.8373 6.44368 21.8826 11.4337 21.8826C12.2692 21.8826 13.0783 21.7692 13.8462 21.5569M15.3867 8.80727V2.11768C18.8173 2.11768 21.5984 4.89878 21.5984 8.32944V8.80727H15.3867Z"
                stroke="#252525" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
            <path
                d="M20.4016 19.1575L17.9473 21.6001M17.9473 21.6001L15.6016 19.2679M17.9473 21.6001V15.6001"
                stroke="#252525" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
        </svg>

    );
};