import { CommentIcon, EventsTypeIcon, EyeIcon, HeartIcon, LikeIcon, StarIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { FC, useEffect, useState } from "react";
import { getDateByFormat, getYearMonthDay, isTillMd } from "../../../utils";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";

export interface EventCardProps {
    cover: string;
    description: string;
    start_date: string;
    end_date: string;
    id: number;
    location: string;
    slug: string;
    title: string;
    category?: {
        title: string;
        id: number;
    }
}

interface IEventCard {
    data: EventCardProps;
    stopRedirect?: boolean;
}

export const EventCard: FC<IEventCard> = (
    {
        data,
        stopRedirect
    }) => {

    const navigate = useNavigate();

    const isMobile = isTillMd();
    const [imgSrc, setImgSrc] = useState<any>(null);

    const {
        title,
        cover: image,
        start_date: date,
        end_date,
        id,
        category
    } = data;

    useEffect(() => {
        const img = new Image();
        img.src = image;

        img.onload = () => {
            setImgSrc(image);
        };

    }, [image]);

    function handleRedirectNews() {
        if (stopRedirect) return;

        navigate(`/events/show/${id}`);
    }

    return (
        <div className="events-card" onClick={handleRedirectNews}>
            <BlankImage image={image} />

            <div className="events-card__content">

                <div className="events-card__category">
                    <EventsTypeIcon />
                    <div className="events-card__category__title">{category?.title}</div>
                </div>

                <div className="events-card__bottom">
                    <div className="events-card__title">{title}</div>
                    <div className="events-card__date">{getYearMonthDay(date && date)}{end_date && date != end_date ? ` - ${getYearMonthDay(end_date)}` : ''}</div>
                </div>
            </div>
        </div>
    );
};