import React, { FC, useEffect, useState } from "react";
import { $getServiceApplications } from "../../../api/requests/services";
import { IServicesApplications } from "../../../api/requests/services/interface";
import { getYearMonthDayAndTime } from "../../../utils";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";
import { BlankAva } from "../../ui/avatars/BlankAva";

interface IServicesApplicationsTable {
    data: IServicesApplications[];
    setOffset?: any,
    offset: number | string,
    hasMore?: boolean
}

export const ServicesApplicationsTable: FC<IServicesApplicationsTable> = (
    {
        data,
        setOffset,
        offset,
        hasMore
    }
) => {
    const navigate = useNavigate();

    function downloadMore () {
        setOffset(+offset + 30)
    }

    return (
        <div className="services-applications">
            <div className="services-applications__header">
                <div className="id">ID заявки</div>
                <div className="column colApp">Заявка</div>
                <div className="column colExecutor">Исполнитель</div>
                <div className="column colDate">Дата подачи</div>
                <div className="column colDate">Дата изменения статуса</div>
                <div className="column colDate">Статус</div>
            </div>

            <div className="services-applications__body">
                {data?.map((item, idx) => (
                    <div className="services-applications__body__block" key={idx}>
                        <p className="services-applications__body__block__id id"
                           onClick={() => navigate(`view/${item.id}`)}>{item.id}</p>

                        <p className="services-applications__body__block__date width">{item?.name}</p>

                        <div className="services-applications__body__block__profile width">
                            <BlankImage image={item?.executors[0]?.photo}
                                        classname={"services-applications__body__block__profile__image"} />

                            <p className="services-applications__body__block__profile__name"> {item?.executors[0]?.name || "Не определен"}</p>
                        </div>

                        <p className="services-applications__body__block__date width">{getYearMonthDayAndTime(item?.created_at)}</p>

                        <p className="services-applications__body__block__date width">{getYearMonthDayAndTime(item?.updated_at)}</p>

                        <div className={`services-applications__body__block__status width`}>
                            <p className={`${item.status_name == "Ожидает проверки" && "waiting"} 
                            ${item.status_name == "Выполнена" && "accepted"} 
                            ${(item.status_name === "Отклонена" || item.status_name === "Отменена") && "rejected"}
                            ${item.status_name == "В работе" && "work"}
                            `}
                            >
                                {item.status_name}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            {hasMore && data.length >= 30 && (
                <div className="services-applications__download" onClick={downloadMore}>
                    <p>
                        Показать еще
                    </p>
                </div>
            )}
        </div>
    );
};