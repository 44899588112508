export const BirthdayIcon = (
    {
        size = 24,
        color = "black",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <rect
                    x="3"
                    y="11"
                    width="18"
                    height="10"
                    rx="2"
                    stroke={color}
                    strokeWidth="2"
                />

                <path
                    d="M3.5 15C5.9 17.4 7.5 16.4995 9 15C10.5 13.5004 13.5 13.4996 15 15C16.5 16.5003 18.5 17 20.5 15"
                    stroke={color}
                />

                <rect x="8" y="8.53564" width="1" height="2.46436" rx="0.5" stroke={color} />

                <rect x="15" y="8" width="1" height="3" rx="0.5" stroke={color} />

                <path
                    d="M15.923 2.73656L15.4988 2.05817L15.075 2.73677C14.7505 3.25627 14.336 4.0709 14.2105 4.79004C14.149 5.14217 14.1389 5.56872 14.3485 5.92544C14.5844 6.32704 15.0085 6.5 15.5001 6.5C15.9917 6.5 16.4159 6.32704 16.6516 5.92522C16.861 5.56839 16.8506 5.14178 16.7889 4.78971C16.6629 4.07062 16.2478 3.25604 15.923 2.73656Z"
                    stroke={color}
                />

                <path
                    d="M8.92302 3.21605L8.49884 2.53766L8.075 3.21626C7.75052 3.73577 7.33604 4.55039 7.2105 5.26953C7.14903 5.62166 7.13894 6.04822 7.34846 6.40493C7.58435 6.80653 8.00846 6.97949 8.5001 6.97949C8.99172 6.97949 9.41591 6.80653 9.65165 6.40471C9.86099 6.04788 9.85058 5.62127 9.78888 5.26921C9.66285 4.55011 9.24784 3.73553 8.92302 3.21605Z"
                    stroke={color}
                />
            </g>
        </svg>
    );
};
