import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { Controller, useForm } from "react-hook-form";
import FileField from "../../../components/ui/form/FileField/FileField";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";
import React, { useState } from "react";
import { ClipIcon } from "../../../img/icons/ClipIcon";
import { CloseIcon } from "../../../img";
import { CreateGroupsBannerRequestData } from "../../../api/requests/groups/banners/interface";
import { $mainPageData, $mainPageNewData, $workSpaceEdit } from "../../../api/requests/main";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    changePersonData,
    changeProfile,
    getMainPageData,
    getPersonData
} from "../../../redux";
import { $getPersonalData } from "../../../api/requests/user";

export interface CreateEditBannerFormData {
    id?: number;
    image: File | string;
    image_mobile: File | string;
    src?: string;
    sort?: number;
    type?: string;
    title?: string;
    text?: string;
}

interface WorkspaceSettingGroupModalCreateEditBannerProps {
    isOpen: boolean;
    onClose: () => void;
    editData?: CreateEditBannerFormData | null;
    setEditData?: (value: any) => void,
    createRequest: any;
    editRequest: any;
    workSpaceEdit: any;
    selectKey?: string;
    typeNew?: string | number;
    idParams?: string | number;
}

const WorkspaceSettingGroupModalCreateEditBanner = (
    {
        isOpen,
        onClose,
        editData,
        createRequest,
        setEditData,
        editRequest,
        selectKey,
        typeNew,
        workSpaceEdit,
        idParams
    }: WorkspaceSettingGroupModalCreateEditBannerProps
) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const personalData = useSelector(getPersonData);
    const mainData = useSelector(getMainPageData);

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm<CreateEditBannerFormData>(
        editData ? {
            values: {
                image: editData.image,
                image_mobile: editData.image_mobile,
                src: editData.src,
                title: editData.title,
                text: editData.text
            }
        } : {
            values: {
                image: "",
                image_mobile: "",
                src: "",
                title: "",
                text: ""
            }
        }
    );

    const title = editData ? `Редактирование баннера № ${(typeof editData?.sort === "number") ? +editData.sort + 1 : ""}` : "Добавление баннера";
    const textBtn = editData ? "Сохранить" : "Добавить";

    function onSubmit(data: CreateEditBannerFormData) {
        setIsLoading(true);

        const requestData: any = selectKey === "inner" ? {
            image: data.image,
            image_mobile: data.image,
            src: data.src,
            layout: "left",
            type: editData?.type || typeNew,
            // title: data.title,
            // text: data.text,
        } : {
            image: data.image,
            image_mobile: data.image,
            src: data.src
            // title: data.title,
            // text: data.text,
        };

        if (editData) return handleEditData(requestData);

        createRequest(requestData, { formData: true }).then((res: any) => {
            setIsStatusComplete(true);

            if (selectKey === "main") {
                updateMain();

                return;
            }

            const sortList = typeof personalData?.components_sort_home === "string"
                ? (typeof JSON.parse(personalData.components_sort_home) === "string"
                    ? JSON.parse(JSON.parse(personalData.components_sort_home))
                    : JSON.parse(personalData.components_sort_home))
                : (Array.isArray(personalData?.components_sort_home)
                    ? personalData?.components_sort_home
                    : []);

            const leftList = sortList.length ? [...sortList[0]?.left] : [];

            leftList.push({
                ...res,
                key: "banner"
            });

            const list = [
                { left: leftList },
                { center: sortList[0]?.center || [] },
                { right: sortList[0]?.right || [] }
            ];

            const request = { components_sort_home: JSON.stringify(list), id: idParams }

            workSpaceEdit(
                request,
                { formData: true }
            ).then((res: any) => {
                if (!res || res.error) return;

                updateMain();
            })
        });
    }

    function updateMain() {
        $getPersonalData().then((response) => {
            if (!response || response.error) return;

            dispatch(changePersonData(response?.data));
        });

        $mainPageData().then((response) => {
            if (!response || response.error) return;

            dispatch(changeMainPage(response));
        });
    }

    function handleEditData(requestData: CreateGroupsBannerRequestData) {
        editRequest({
            ...requestData,
            id: editData?.id ? editData.id.toString() : ""
        }, { formData: true }).then((res: boolean) => {
            handleClose();
        });
    }

    function handleClose() {
        reset();
        setEditData && setEditData(null)
        setIsLoading(false);
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleClose}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal contests__winner"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно добавили баннер на главную страницу
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={() => {
                                handleClose();
                                setIsStatusComplete(false);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className={"contests__winner"}>
                        <div className={"contests__winner__header p-0"}>
                            <h3>{title}</h3>

                            <div
                                className={"d-flex align-items-center ml-auto cursor-pointer"}
                                onClick={onClose}
                            >
                                <CloseIcon color={"black"} opacity={"1"} />
                            </div>
                        </div>
                    </div>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={`contests__create__form workspace-settings-group__main-block pt-2 ${selectKey === "inner" ? "workspace-settings__banners__inner__modal__form" : ""}`}
                    >
                        <Controller
                            name="image"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Прикрепите изображение"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    className={"max-w-100 form-image"}
                                    upload={value}
                                    label="Изображение"
                                    setUpload={onChange}
                                />
                            )}
                        />

                        {/*<Controller*/}
                        {/*    name="title"*/}
                        {/*    control={control}*/}
                        {/*    render={({ field: { onChange, value } }) => (*/}
                        {/*        <TextField*/}
                        {/*            value={value || ""}*/}
                        {/*            className=""*/}
                        {/*            title="Заголовок"*/}
                        {/*            placeholder={"Введите заголовок"}*/}
                        {/*            name="title"*/}
                        {/*            onChange={onChange}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}

                        {/*<Controller*/}
                        {/*    name="text"*/}
                        {/*    control={control}*/}
                        {/*    render={({ field: { onChange, value } }) => (*/}
                        {/*        <TextArea*/}
                        {/*            value={value || ""}*/}
                        {/*            title="Описание"*/}
                        {/*            placeholder={"Введите описание"}*/}
                        {/*            name="text"*/}
                        {/*            onChange={onChange}*/}
                        {/*            rows={2}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Controller
                            name="src"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value || ""}
                                    prepend={<ClipIcon />}
                                    title="Ссылка на источник"
                                    placeholder={"Введите ссылку на источник"}
                                    name="src"
                                    onChange={onChange}
                                />
                            )}
                        />

                        <div className={"d-flex gap-3"}>
                            <Button
                                type={"submit"}
                                text={textBtn}
                                className={"btn btn-primary w-100"}
                                loading={isLoading}
                            />
                        </div>
                    </form>
                </>
            )}
        </DialogContainer>
    );
};

export default WorkspaceSettingGroupModalCreateEditBanner;