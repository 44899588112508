import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment, { Moment } from "moment";

import {
    getBookingList, getCurrentRole,
    getProfileVarsData,
    setMonth,
    setOpenPopup,
    setPopupData
} from "../../../../../redux";
import {
    StatusIconSuccess,
    StatusIconDefault
} from "../../../../../img/icons/statuses";

interface UserSingleData {
    searchUser: string;
    onClick: () => void;
    isShort: boolean;
    isSearch: boolean;
    isFor: boolean;
    isConciliate: boolean;
    isAccept: boolean;
    isRefuse: boolean;
    for_user: {
        firstname: string;
        lastname: string;
        middle_name: string;
        photo: string;
        structure: any;
        staff: any;
        vacation_info: any;
        id: string;
        name: string;
    };
}

export const UserSingle = (
    {
        isShort,
        data
    }: any
) => {
    const dispatch = useDispatch();
    const bookingList = useSelector(getBookingList);
    const vars = useSelector(getProfileVarsData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const { name, photo, structure } = data?.user || {};

    const filteredBookingsList = useMemo(() => {
        return bookingList?.filter((item: any) => item?.user_id === data?.user?.id);
    }, [bookingList]);

    const formatDate = (start: string, end: string) => {
        return start && end && `${moment(start)?.format("DD.MM")} - ${moment(end)?.format("DD.MM")}`;
    };

    const handleMonth = (date: Moment) => {
        dispatch(setMonth(moment(date).format("YYYY-MM-DD")));
    };

    const handleOpenPopup = () => {
        handleMonth(moment());

        dispatch(setPopupData(data));
        dispatch(setOpenPopup());
    };

    const style: any = {
        "--lightBlue": vars.colors.secondaryColor,
        "--whiteColor": vars.colors.fourthColor,
        "--wrapperBg": data?.isSearch ? `${vars.colors.secondaryColor}0d` : "",
        "--conciliateColor": data?.isAccept ? vars.colors.secondaryColor : data?.isRefuse ? "#ff0000" : "rgba(0, 0, 0, 0.5)"
    };

    return (
        <div
            className={`calendar__member__user__wrapper 
        ${isShort ? "short" : ""} 
        ${data?.isSearch ? "search" : ""}
        ${isShort && data?.isFor ? "for" : isShort && data?.isConciliate ? "conciliate" : ""}
        `}
            style={style}
            onClick={isAdmin && data?.isSearch ? handleOpenPopup : undefined}
        >
            <div className={`calendar__member__user__avatar ${isShort && data?.isFor ? "for" : ""}`}>
                {photo && <img src={photo} />}

                {!photo && name && name.slice(0, 1)}
            </div>

            <div className={"calendar__member__user__info"}>
                <div className={"calendar__member__user__name"}>{name}</div>

                {/*<div className={"calendar__member__user__position"}>{staff?.title || (!isFor && isConciliate && 'Администратор')}</div>*/}

                {!isShort && (
                    <>
                        <div
                            className={"calendar__member__user__department"}>{structure?.title}</div>

                        <div className={"calendar__member__user__vacation"}>
                            {filteredBookingsList?.length > 0 && (
                                <div className={"calendar__member__user__item"}>
                                    <div className={"calendar__member__user__label"}>
                                        Дата отпуска
                                    </div>

                                    <div className={"calendar__member__user__list"}>
                                        {filteredBookingsList?.map(
                                            ({
                                                 date_from,
                                                 date_to,
                                                 status
                                            }: any) => (
                                                <span
                                                    className={`calendar__member__user__date ${status === 2 && "calendar__member__user__accept"}`}
                                                >
                                                    {(date_from && date_to && formatDate(date_from, date_to))}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className={"calendar__member__user__item"}>
                                <div className={"calendar__member__user__label"}>
                                    Доступные дни отпуска
                                </div>

                                <span className={"calendar__member__user__value"}>
                                  {data?.days < 0 ? 0 : data?.days}
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {/*{!isFor && isConciliate && (*/}
            {/*    <div className={"calendar__member__user__conciliate"}>*/}
            {/*        {isAccept && <StatusIconSuccess />}*/}

            {/*        /!*{isRefuse && <StatusIconDanger />}*!/*/}

            {/*        {!isAccept && !isRefuse && <StatusIconDefault />}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};
