export const LogoFooterIcon = () => {
    return (
        <svg width="149" height="24" viewBox="0 0 149 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M42.6173 0V23.5958H38.7681V13.7194L39.1429 5.35955L27.1868 23.5958H22.6562V0H26.5053V9.87628L26.1648 18.2023L38.0871 0H42.6173Z"
                fill="black" />
            <path d="M61.1639 8.6295H55.0666V23.5957H51.2172V8.6295H45.0859V5.05603H61.1639V8.6295Z"
                  fill="black" />
            <path
                d="M81.5072 14.3257C81.5072 19.8541 77.454 24 71.8332 24C67.4049 24 63.3517 21.4384 62.5 16.8203H66.4853C67.1666 19.2472 69.3811 20.5619 71.8332 20.5619C74.797 20.5619 76.9769 18.6403 77.4879 15.7417H70.573V12.7414H77.454C76.909 9.94393 74.7625 8.09017 71.7993 8.09017C69.2786 8.09017 67.1326 9.43771 66.4853 11.7977H62.5C63.3172 7.31409 67.303 4.65112 71.7993 4.65112C77.42 4.65112 81.5072 8.79754 81.5072 14.3257Z"
                fill="black" />
            <path
                d="M101.992 5.05591V23.5957H98.1421V8.62942H91.9427L90.7163 16.3822C89.7967 21.9101 87.7191 23.9999 84.5171 23.9999C83.9037 23.9999 83.2223 23.8992 82.6094 23.7979V20.3596C82.984 20.3933 83.3587 20.4609 83.7333 20.4609C85.5386 20.4609 86.3224 19.4832 86.9013 16.0449L88.6727 5.05591H101.992Z"
                fill="black" />
            <path
                d="M126.218 5.05603V23.5957H122.368V15.843L122.641 9.40463L116.203 18.4722H116.101L109.663 9.40463L109.935 15.843V23.5957H106.086V5.05603H110.617L116.135 12.674L121.687 5.05603H126.218Z"
                fill="black" />
            <path
                d="M144.734 14.3257C144.734 10.7867 142.248 8.29235 138.978 8.29235C135.707 8.29235 133.391 10.7867 133.391 14.3257C133.391 17.899 135.741 20.3597 138.978 20.3597C142.248 20.3597 144.734 17.899 144.734 14.3257ZM148.549 5.05597V23.5957H144.7V20.4948C143.337 22.6183 141.089 24 138.16 24C133.152 24 129.508 19.9888 129.508 14.3257C129.508 8.66258 133.221 4.65112 138.126 4.65112C141.089 4.65112 143.337 6.10099 144.7 8.22463V5.05597H148.549Z"
                fill="black" />
            <path
                d="M1.27183 10.0905C0.569469 10.0905 0 9.52717 0 8.83174C0 8.13681 0.569469 7.57349 1.27183 7.57349C1.97418 7.57349 2.54365 8.13681 2.54365 8.83174C2.54365 9.52717 1.97418 10.0905 1.27183 10.0905Z"
                fill="black" />
            <path
                d="M10.9749 10.0905C10.2726 10.0905 9.70312 9.52717 9.70312 8.83174C9.70312 8.13681 10.2726 7.57349 10.9749 7.57349C11.6774 7.57349 12.247 8.13681 12.247 8.83174C12.247 9.52717 11.6774 10.0905 10.9749 10.0905Z"
                fill="black" />
            <path
                d="M10.9749 19.6901C10.2726 19.6901 9.70312 19.1265 9.70312 18.4315C9.70312 17.7362 10.2726 17.1727 10.9749 17.1727C11.6774 17.1727 12.247 17.7362 12.247 18.4315C12.247 19.1265 11.6774 19.6901 10.9749 19.6901Z"
                fill="black" />
        </svg>
    );
};