import DialogContainer from "../../ui/dialog/DialogContainer";
import React, { FC, useEffect } from "react";
import { CloseIcon } from "../../../img";
import { IBadges } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { BlankAva } from '../../ui/avatars/BlankAva'

interface IBadgesModalCard {
    isOpen: boolean;
    setIsOpen: () => void;
    onClose: (select: any) => void;
    data: IBadges[];
}

export const BadgesModalCard: FC<IBadgesModalCard> = (
    {
        isOpen,
        setIsOpen,
        onClose,
        data
    }
) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    return (
        <DialogContainer
            classNameModal="dialog"
            isOpen={isOpen}
            isShowCloseBtn={false}
            closeModal={setIsOpen}
        >
            <div className="dialog-mobileLine"></div>

            <div className="dialog-header">
                <h5 className="dialog-header__title">Благодарности</h5>

                <div onClick={setIsOpen} className="dialog-header__closeIcon">
                    <CloseIcon color={"#000"} />
                </div>
            </div>

            <div className="dialog-content">
                {data.map((item, idx) => (
                    <div
                        className="dialog-block cursor-pointer"
                        key={`badge-dialog-item-${idx}`}
                    >
                        <div
                            className="dialog-block-left"
                            onClick={() => {
                                item?.sender?.uniq && navigate(`/user/${item.sender.uniq}`)
                                setIsOpen();
                            }}
                        >
                            {item?.sender?.photo ? (
                                <img src={item?.sender?.photo} alt=""
                                     className="employees-block__image" />
                            ) : (
                                <BlankAva
                                    colorBg={"#CCD9E0"}
                                    colorText={"black"}
                                    text={item?.sender?.name || "От организации"}
                                />
                            )}

                            <div className="dialog-block-left__user">
                                <h5>{item?.sender?.name || "От организации"}</h5>

                                {/*<p>Руководитель отдела</p>*/}
                            </div>
                        </div>

                        <div
                            className="dialog-block-right__image"
                            onClick={() => {
                                const usersList = [...data].filter(_item => _item.badge_id === item.badge_id)

                                onClose({
                                    ...item,
                                    usersList: usersList
                                })
                            }}
                        >
                            <img src={item?.workspace_badge?.image} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </DialogContainer>
    );
};