import React, { useEffect, useState } from "react";
import { IBlogsRes } from "../../api/requests/blogs/interface";
import {
    $getBlogs,
    $getBlogsCategories,
    $getDashboardBlogs,
    $getDraftBlogs
} from "../../api/requests/blogs";
import { BlogsCard } from "../../components/blogs";
import { useDispatch, useSelector } from "react-redux";
import { changeListLength, getCurrentRole, getOpenPopup, getPersonData } from "../../redux";
import { SearchItem } from "../../components/ui/form/SearchItem";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import Button from "../../components/ui/form/Buttons/Button";
import { SettingIcon } from "../../img";
import HorizonNavigate from "../../components/navigation/HorizonNavigate";
import { isMd } from "../../utils";
import ModalCatBlogs from "../../components/categories/ModalCatBlogs";
import InfiniteScroll from "../../components/ui/InfiniteScroll";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const BlogsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = isMd();

    const personalData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1 || personalData?.admin_module?.find((key: string) => key === "blogs");

    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get("catId") || undefined;

    const [isOpenCatModal, setIsOpenCatModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const [filter, setFilter] = useState({
        has_vacations: null,
        status: null
    });

    const [search, setSearch] = useState("");
    const [categorySelect, setCategorySelect] = useState<any | null>(
        {
            label: "Все категории",
            value: "all"
        }
    );

    const [categoryList, setCategoryList] = useState<any[]>([]);

    const [blogsData, setBlogsData] = useState<IBlogsRes[]>([]);
    const [filteredBlogsData, setFilteredBlogsData] = useState<IBlogsRes[]>([]);

    function handleSetting() {
        setIsOpenCatModal(true);
    }

    useEffect(() => {
        // $getBlogs({ newApi: true }).then(res => {
        //     if (!res.data) return;
        //
        //     setBlogsData(res.data.data);
        // });

        $getDraftBlogs({ newApi: true }).then(res => {
            if (!res.data) return;

            // setBlogsData(res.data.data)
        });


        $getBlogsCategories({ newApi: true, formData: true }).then(res => {
            if (!res.data) return;

            const updateList: any = res?.data?.map((i: any) => ({
                ...i,
                label: i.title,
                value: i.id
            }));

            setCategoryList([...updateList]);

            if (catId) {
                setCategorySelect({
                    label: "Все категории",
                    value: "all"
                });
            }
        });

    }, [isLoading]);

    useEffect(() => {
        if (isAdmin) {
            $getDashboardBlogs({newApi: true}).then(res => {
                if (!res.data) return;


                dispatch(changeListLength(res.data.data.length))
            })
        }
    }, [isAdmin])


    useEffect(() => {
        const updatedList: any = blogsData.filter((item) => item?.category?.title === categorySelect.label);

        setFilteredBlogsData(categorySelect.value === "all" ? blogsData : updatedList);
    }, [categorySelect, categoryList, blogsData]);

    useEffect(() => {
        const normalizeString = (str: any) =>
            str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        const updatedList: any = blogsData.filter((item) => normalizeString(item.author.name).includes(normalizeString(search)));

        setFilteredBlogsData(updatedList);
    }, [search, blogsData]);

    function handleCategory(select: DropdownItem) {
        setCategorySelect(select);

        navigate(`?catId=${select.value}`);
    }

    return (
        <InfiniteScroll
            url={'api/v1/blog/content'}
            data={blogsData}
            setData={setBlogsData}
        >
            <div className="blogs">
                {!isMobile && <HorizonNavigate isScroll={false} />}

                <ModalCatBlogs
                    isOpen={isOpenCatModal}
                    setIsOpen={() => setIsOpenCatModal(false)}
                    catList={categoryList}
                    setCatList={setCategoryList}
                    route={"blog"}
                />


                <div className="blogs-header ideas-header__selects">
                    <div className="workspace-settings-users__header__search">
                        <SearchItem
                            placeholder="ФИО"
                            onChange={(value) => setSearch(value)}
                        />
                    </div>

                    <div className="services-header__dropdown ">
                        <Dropdown
                            // className="workspace-select"
                            placeholder={"Категория"}
                            options={[
                                {
                                    label: "Все категории",
                                    value: "all"
                                },
                                ...categoryList
                            ]}
                            value={categorySelect}
                            onChange={handleCategory}
                            labelBottom={isAdmin ? (
                                <div className={"dropdown__list__setting"}>
                                    <Button
                                        leftIcon={<SettingIcon color={"currentColor"} />}
                                        text={"Настройки"}
                                        className={"btn btn-primary-revert w-100"}
                                        onClick={handleSetting}
                                    />
                                </div>
                            ) : null}
                        />
                    </div>
                </div>
                {filteredBlogsData.map((
                    blog,
                    idx
                ) => (
                    <div
                         className={`blogs-item ${idx !== blogsData.length - 1 ? "blogs-item-line" : ""}`}
                         key={idx}
                    >
                        <BlogsCard data={blog} setIsLoading={setIsLoading} isLoading={isLoading}/>
                    </div>
                ))}
            </div>
        </InfiniteScroll>
    );
};