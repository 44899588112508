import React, { FC, useEffect, useRef, useState } from "react";
import {
    CloseIcon,
    LikeIcon,
    MessengerSendIcon,
    PaperClipIcon,
    PaperClipSmallIcon, StarIcon
} from "../../img";
import { ICommentsMessenger } from "../../api/requests/blogs/interface";
import { getYearMonthDay, isTillMd } from "../../utils";
import {
    $deleteComment,
    $likeComment,
    $pickExpertComment,
    $postComment
} from "../../api/requests/comment";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import { BlankAva } from "../ui/avatars/BlankAva";
import { TrashIcon } from "../../img/icons/TrashIcon";
import CompleteModal from "../ui/dialog/CompleteModal";
import moment from "moment";
import { IUsers } from "../../api/requests/user/interface";
import { $getWorkspaceUsers } from "../../api/requests/workspace-settings";
import { BlankImage } from "../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";
import { LikedIcon } from "../../img/icons/news/LikedIcon";
import { $sendCommentNotification } from "../../api/requests/notification";

interface CommentMessengerChatBoxProps {
    comments_data: ICommentsMessenger[];
    item_id: number;
    setIsLoading: any;
    url: string;
    isClip?: boolean;
    isExpert?: boolean;
    notification_type: string,
    isAvailable?: boolean,
}

export const CommentMessengerChatBox: FC<CommentMessengerChatBoxProps> = (
    {
        comments_data,
        item_id,
        setIsLoading,
        url,
        isClip,
        isExpert,
        notification_type,
        isAvailable = true,
    }
) => {
    const navigate = useNavigate();
    const isMobile = isTillMd();
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const { workspace_id: wsId, id: userId } = useSelector(getPersonData);

    const [commentsData, setCommentsData] = useState<ICommentsMessenger[]>(comments_data);

    const [text, setText] = useState("");
    const [isChildCommentOpen, setIsChildCommentOpen] = useState(-1);
    const [attachments, setAttachments] = useState<any>([]);

    const [selectedCommentId, setSelectedCommentId] = useState(-1);
    const [isLiked, setIsLiked] = useState(0);

    const [showDropdown, setShowDropdown] = useState(false);
    const [usersList, setUsersList] = useState<IUsers[]>([]);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    const taggedUsers = useRef<IUsers[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-red w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const isImage = (url: string) => {
        return /\.(jpeg|jpg|gif|png|webp|bmp)$/i.test(url);
    };

    const handleOpenChildInput = (index: number) => {
        setIsChildCommentOpen(index);
    };

    const handlePostComment = (
        parent_id: number
    ) => {
        const htmlContent = generateHTML();

        setIsLoading(true);
        $postComment({
            id: item_id,
            parent_id,
            text: htmlContent,
            attachments
        }, `${url}/createcomment`, { formData: true }).then(res => {
            setText("");
            setIsLoading(false);
            setIsChildCommentOpen(-1);
        });

        taggedUsers.current.forEach((user) => {
            $sendCommentNotification(
                {
                    user_id: user.id,
                    ws_id: wsId,
                    type: notification_type,
                    type_id: +parent_id
                },
                {newApi: true}).then(res => {

            })
        })

    };

    const handleDeleteComment = (select: any) => {
        if (select.key === 0) {
            setIsLoading(true);

            if (selectedCommentId >= 0) {
                $deleteComment({ id: selectedCommentId }, `${url}/deletecomment`, { formData: true }).then(res => {
                    setIsLoading(false);
                    setSelectedCommentId(-1);
                });
            }

            return;
        }

        setSelectedCommentId(-1);
    };

    const handleLike = (
        id: number,
        user_liked: number,
        isChild?: number
    ) => {
        const updatedList = commentsData.map((item: any) => {
            if (item.id === id && isChild) return {
                ...item,
                children: item.children.map((i: any) => {
                    if (i.id === isChild) return {
                        ...i,
                        user_liked,
                        likes: user_liked === 0 ? i.likes - 1 : i.likes + 1
                    };

                    return i;
                })
            };

            if (item.id === id) return {
                ...item,
                user_liked,
                likes: user_liked === 0 ? item.likes - 1 : item.likes + 1
            };

            return item;
        });

        setCommentsData(updatedList);

        $likeComment({
            id: isChild ? isChild : id,
            like: user_liked
        }, `${url}/commentLike`, { formData: true }).then(res => {
        });
    };

    const handleGetCommentImages = (event: any, idComment: number, isChild: number) => {
        const file = event.target?.files[0];

        if (file) {
            setAttachments([...attachments, file]);
        }
    };

    const handleDeleteCommentImages = (index: number) => {
        const updatedList = attachments.filter((
            item: any,
            idx: number
        ) => idx !== index);

        setAttachments(updatedList);
    };

    const handlePickExpert = (comment_id: number, pick: number) => {
        setIsLoading(true);

        $pickExpertComment(
            {
                id: item_id,
                comment_id,
                pick
            },
            `${url}/pickexpert`,
            { formData: true })
        .then(res => {
            setIsLoading(false);
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setText(value);

        const caret = e.target.selectionStart;

        const lastWord = value.slice(0, caret).split(" ").pop();
        if (lastWord?.startsWith("@")) {
            const query = lastWord.slice(1); // Remove "@"
            if (query) {
                fetchSuggestions(query);
            }
            setShowDropdown(true);
            setCursorPosition(caret);
        } else {
            setShowDropdown(false);
        }
    };

    const generateHTML = () => {
        let formattedText = text.trim();

        taggedUsers.current.forEach((user) => {
            const tagPattern = new RegExp(`@${user.name.trim()}`, "g");
            const userLink = `<a href="https://itelma-dev.svoi.club/user/${user.id}"><span class="hashtag">@${user.name.trim()}</span></a>`;
            formattedText = formattedText.replace(tagPattern, userLink);
        });

        return `<p>${formattedText}</p>`;
    };

    const fetchSuggestions = async (query: string) => {
        if (wsId) {
            $getWorkspaceUsers({ id: wsId, search: query }, { formData: true }).then(res => {
                if (!res.users) return;

                setUsersList(res.users);
            });
        }
    };

    const handleOptionSelect = (user: IUsers) => {
        if (textareaRef.current && cursorPosition !== null) {
            const value = text;
            const beforeCursor = value.slice(0, cursorPosition).split(" ").slice(0, -1).join(" ");
            const afterCursor = value.slice(cursorPosition);

            const newValue = `${beforeCursor} @${user.name} ${afterCursor}`.trim();
            setText(newValue);

            taggedUsers.current.push(user); // Track the tagged user
            setShowDropdown(false);
            textareaRef.current.focus();
        }
    };

    useEffect(() => {
        setCommentsData(comments_data);
    }, [comments_data]);

    useEffect(() => {
    }, [isLiked]);

    return (
        <div className="news-chat">
            <CompleteModal
                isOpen={selectedCommentId >= 0}
                setIsOpen={() => setSelectedCommentId(-1)}
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить сообщение?"}
                btnList={btnListRemove}
                onClick={handleDeleteComment}
            />
            {commentsData.map((
                item,
                idx: number
            ) => (
                <div className="news-chat__block" key={idx}>
                    <div className="news-chat__top">
                        <div
                            className={`news-chat__info ${item?.uniq ? "cursor-pointer" : ""}`}
                            onClick={() => {
                                item?.uniq && navigate(`/user/${item.uniq}`)
                            }}
                        >
                            {item.photo ? (
                                <img src={item.photo} alt="" className="news-chat__info__avatar" />
                            ) : (
                                <BlankAva
                                    colorBg={"#CCD9E0"}
                                    colorText={"black"}
                                    text={item.name}
                                />
                            )}

                            <div className="news-chat__info__desc">
                                <div className="news-chat__info__desc__left">
                                    <h1 className="news-chat__info__name">{item.name}</h1>


                                    {item.is_expert ? (
                                        <div className="news-chat__info__expert">эксперт</div>
                                    ) : ""}

                                </div>


                                <p className="news-chat__info__date">
                                    {moment(item.date ? item.date * 1000 : "").format("DD MMMM, YYYY")}</p>
                            </div>
                        </div>

                        {/*{isAdmin && (*/}
                        {/*  <div className='news-chat__top__buttons'>*/}
                        {/*    <div className='news-chat__top__buttons__item'>*/}
                        {/*      <PaperClipSmallIcon/>*/}
                        {/*    </div>*/}
                        {/*    <div className='news-chat__top__buttons__item'>*/}
                        {/*      <TrashIcon/>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*)}*/}

                        <div className="news-chat__top__buttons">
                            {isExpert && (
                                <div className="news-chat__top__buttons__item"
                                     onClick={() => handlePickExpert(+item.id, item.is_expert === 0 ? 1 : 0)}
                                >
                                    <StarIcon color={item.is_expert ? "#00A7B5" : '#fff'} strokeColor='#00A7B5'/>
                                </div>
                            )}
                            {isClip && (
                                <div className="news-chat__top__buttons__item">
                                    <PaperClipSmallIcon />
                                </div>
                            )}

                            {(isAdmin || item.user_id == userId) && (
                                <div className="news-chat__top__buttons__item"
                                     onClick={() => setSelectedCommentId(item.id)}>
                                    <TrashIcon />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="news-chat__content">
                        {item.attachments ? (
                            <div className="news-chat-child__attachment">
                                {item.attachments.map((
                                    attachment: any,
                                    idx2
                                ) => (
                                    <>
                                        {isImage(attachment.attachment) ? (
                                            <img src={attachment?.attachment} alt="" key={idx2} />
                                        ) : (
                                            <div className="news-chat-child__attachment__file">
                                                <a href={attachment.attachment} download={attachment.attachment || 'file'}>
                                                    {attachment.file_name || 'Unknown File'}
                                                </a>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        ) : null}

                        <div className="news-chat__content__text"
                             dangerouslySetInnerHTML={{ __html: item?.text }} />
                    </div>

                    {url === "/news" && (
                        <div className="news-chat__buttons">
                            {isAvailable && (
                                <div
                                    onClick={() => handleOpenChildInput(idx)}
                                    className="news-chat__buttons__answerButton">Ответить
                                </div>
                            )}

                            <div className={`news-chat__buttons__likeButton ${!item.user_liked && 'notLiked'}`}

                                 onClick={() => handleLike(item.id, item.user_liked === 0 ? 1 : 0)}
                            >
                                {item.user_liked ? (
                                    <LikedIcon/>
                                ) : (
                                    <LikeIcon size={20} color="#000" />
                                )}

                                <p>{item.likes}</p>
                            </div>
                        </div>
                    )}

                    {item.children ? (
                        <>
                            {item.children.map((
                                child: any,
                                idx3: number
                            ) => (
                                <div className="news-chat-child__block" key={idx3}>
                                    <div className="news-chat-child__leftRow"></div>

                                    <div className="news-chat-child__right">
                                        <div className="news-chat__top">
                                            <div
                                                className={`news-chat__info ${child?.uniq ? "cursor-pointer" : ""}`}
                                                onClick={() => {
                                                    child?.uniq && navigate(`/user/${child.uniq}`)
                                                }}
                                            >
                                                {child.photo ? (
                                                    <img
                                                        src={child.photo}
                                                        className="news-chat__info__avatar"
                                                    />
                                                ) : (
                                                    <BlankAva
                                                        colorBg={"#CCD9E0"}
                                                        colorText={"black"}
                                                        text={item.name}
                                                    />
                                                )}

                                                <div className="news-chat__info__desc">
                                                    <h1 className="news-chat__info__name">{child.name}</h1>

                                                    <p
                                                        className="news-chat__info__date">{moment(child.date ? child.date * 1000 : "").format("DD MMMM, YYYY")}</p>
                                                </div>
                                            </div>

                                            <div className="news-chat__top__buttons">
                                                {isExpert && (
                                                    <div className="news-chat__top__buttons__item"
                                                         onClick={() => handlePickExpert(+item.id, item.is_expert === 0 ? 1 : 0)}
                                                    >
                                                        <StarIcon />
                                                    </div>
                                                )}
                                                {isClip && (
                                                    <div className="news-chat__top__buttons__item">
                                                        <PaperClipSmallIcon />
                                                    </div>
                                                )}

                                                {(isAdmin || child.user_id == userId) && (
                                                    <div className="news-chat__top__buttons__item"
                                                         onClick={() => setSelectedCommentId(child.id)}>
                                                        <TrashIcon />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div>
                                            {child.attachments ? (
                                                <div className="news-chat-child__attachment">
                                                    {child.attachments.map((
                                                        attachment: any,
                                                        idx4: number
                                                    ) => (
                                                        <img
                                                            src={attachment?.attachment}
                                                            alt=""
                                                            key={idx4}
                                                        />
                                                    ))}
                                                </div>
                                            ) : null}

                                            <div className="news-chat__content__text"
                                                 dangerouslySetInnerHTML={{ __html: child?.text }} />
                                        </div>

                                        <div className={`news-chat__buttons__likeButton ${!child.user_liked && 'notLiked'}`}
                                             onClick={() => handleLike(item.id, child.user_liked === 0 ? 1 : 0, child.id)}
                                        >
                                            {child.user_liked ? (
                                                <LikedIcon/>
                                            ) : (
                                                <LikeIcon size={20} color="#000" />
                                            )}

                                            <p>{isLiked === 0 ? child.likes : child.likes + 1}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : null}

                    {isChildCommentOpen === idx && (
                        <div className="news-chat-child__block">
                            <div className="news-chat-child__leftRow"></div>

                            <div className="news-chat-child__right">
                                <div className="news-messenger__top__textarea">
                                 <textarea
                                     ref={textareaRef}
                                     value={text}
                                     onChange={handleInputChange}
                                     placeholder="Оставить комментарий"
                                     className="news-messenger__top__input"
                                 />
                                    {/*<input*/}
                                    {/*    type="text"*/}
                                    {/*    value={text}*/}
                                    {/*    placeholder="Оставить комментарий"*/}
                                    {/*    className="news-messenger__top__input"*/}
                                    {/*    onChange={(e) => setText(e.target.value)}*/}
                                    {/*/>*/}

                                    <div className="news-messenger__top__textarea__buttons">
                                        <label
                                            className="news-messenger__top__textarea__buttons__imageButton">
                                            <PaperClipIcon color={isMobile ? "#00A7B5" : "#000"} />

                                            <input
                                                type="file"
                                                accept=".png, .jpg"
                                                onChange={(event) => handleGetCommentImages(event, item.id, isChildCommentOpen)}
                                                hidden
                                            />
                                        </label>

                                        {isMobile ? (
                                            <MessengerSendIcon />
                                        ) : (
                                            <div
                                                className="news-messenger__top__textarea__buttons__enterButton"
                                                onClick={() => handlePostComment(item.id)}>
                                                Опубликовать
                                            </div>
                                        )}
                                    </div>

                                    {showDropdown && usersList.length > 0 && (
                                        <div className="news-messenger__top__textarea__users">
                                            {usersList.map((item, idx) => (
                                                <div className="layout-vacation-modal__block__right"
                                                     onClick={() => handleOptionSelect(item)}
                                                     key={idx}
                                                >
                                                    <BlankImage image={item?.photo}/>

                                                    <div
                                                        className="layout-vacation-modal__block__desc">
                                                        <h1>{item?.name}</h1>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className="news-messenger__top__textarea__attachments">
                                    {attachments?.map((
                                        item: any,
                                        idx: number
                                    ) => (
                                        <div
                                            className="news-messenger__top__textarea__attachments__item"
                                            key={idx}>
                                            <div className="closeIcon"
                                                 onClick={() => handleDeleteCommentImages(idx)}>
                                                <CloseIcon color={"#FF0000"} />
                                            </div>

                                            <p>
                                                {idx !== attachments.length - 1 ? `${item?.name},` : item?.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};