import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import $ from "jquery";

import ContestCreateEditPreview from "./ContestCreateEditPreview";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import TextField from "../../../components/ui/form/Fields/TextField";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../../components/ui/form/Buttons/Button";

import { ArrowExitIcon, CloseIcon, CoinCompetitionIcon } from "../../../img";
import { failureNotify, isMd, successNotify } from "../../../utils";
import { $createContest, $deleteContest, $editContest, $getContestById, $getContestCategories } from "../../../api/requests/contest";

import { useDispatch, useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../../redux";
import DatePickerTimeField from "../../../components/ui/form/DatePickerForm/DatePickerTimeField";
import { changeHeaderTitle } from "../../../redux/reducers/header/reducer";

interface DropdownItem {
    label: string;
    value: number | string;
}

export interface CreateContestFormData {
    image: File | string | null;
    title: string;
    text: string;
    date: any;
    date_end: any;
    category_id: any;
    points: string;
    awards: string;
    pinned: number;
    allow_attach: number;
    allow_see_others: number;
    views?: number;
    peoples?: number;
    status?: string;
    error?: any;
    comments_count?: any;
}

function is_array(mixed_var: any) {	// Finds whether a variable is an array
    return (mixed_var instanceof Array);
}

function empty(mixed_var: any) {   // Determine whether a variable is empty
    return (mixed_var === "" || mixed_var === 0 || mixed_var === "0" || mixed_var === null || mixed_var === false || (is_array(mixed_var) && mixed_var.length === 0));
}

const ContestsCreateEditPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = isMd();

    const profileData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const contest_id = searchParams.get("contest_id");

    const isEdit: boolean = pathname.includes("edit");

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);
    const [editData, setEditData] = useState<CreateContestFormData | null>(null);

    const [isShow, setIsShow] = useState(true);
    const [isDraft, setIsDraft] = useState(false);
    const [isError, setIsError] = useState(false);

    const testBtnCreate = isEdit ? !isDraft ? "Сохранить" : "Опубликовать" : "Опубликовать";
    const testBtnDraft = isDraft ? "Сохранить" : "В черновик";

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors }
    } = useForm<CreateContestFormData>(
        {
            values: {
                image: editData?.image || '',
                title: editData?.title || '',
                text: editData?.text || '',
                // date: editData?.date ? moment(editData?.date * 1000).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm"),
                // date_end: editData?.date_end ? moment(editData?.date_end * 1000).format("YYYY-MM-DD HH:mm") : moment().add(1, "days").format("YYYY-MM-DD HH:mm"),
                date: editData?.date || '',
                date_end: editData?.date_end || '',
                category_id: editData?.category_id || 0,
                points: editData?.points || '0',
                awards: editData?.awards || '0',
                pinned: editData?.pinned || 0,
                allow_attach: editData?.allow_attach || 0,
                allow_see_others: editData?.allow_see_others || 0,
                status: editData?.status || ''
            }
        }
    );

    const hasLoaded = useRef(false);

    const handleNavigation = () => {
        navigate(-1);
    };

    const dateFormValue = (
        date: string | number,
        isTime?: boolean,
        isNext?: boolean
    ): any => {
        if (typeof date === "number") {
            return isTime
                ? moment(date * 1000).format("YYYY-MM-DD HH:mm")
                : moment(date * 1000).format("DD.MM.yyyy")
        }

        const dateFormat = isTime
            ? moment().format("YYYY-MM-DD HH:mm")
            : moment().format("DD.MM.yyyy");

        const dateFormatNext = isTime
            ? moment().add(1, "days").format("YYYY-MM-DD HH:mm")
            : moment().add(1, "days").format("DD.MM.yyyy");

        if (isNext) return date || dateFormatNext

        return date || dateFormat
    }

    useEffect(() => {
        init(profileData);
    }, [profileData]);

    useEffect(() => {
        dispatch(changeHeaderTitle(editData ? editData.title : "Создать конкурс"));

        return () => {
            dispatch(changeHeaderTitle(""));
        }
    }, [editData]);

    function init(profileData: any) {
        if (!empty(profileData)) {
            if (!hasLoaded.current) {
                $getContestCategories(profileData?.workspace_id).then(res => {
                    if (!res) return;

                    let tmp = new Array;
                    res?.map((item: any, idx: any) => {
                        tmp.push({
                            value: item.id,
                            label: item.title,
                        });
                    })
                    hasLoaded.current = true;
                    setCategoryList(tmp)
                });
            }

            if (isEdit && contest_id) {
                $getContestById(contest_id).then(data => {
                    if (!data) return;
                    if (data?.error) {
                        setIsError(true);
                        return;
                    }

                    setIsDraft(data?.status == '0');

                    setEditData(data);
                }).catch((e) => {
                    console.log(e, 'error')
                    setIsError(true);
                });
            }
        }
    }

    async function onSubmit(data: any, status: number) {
        const requestData = {
            ...data,
            date: moment(data.date).unix(),
            date_end: moment(data.date_end).unix(),
            cat_id: data.category_id,
            text: data.text,
            pinned: data.pinned || 0,
            allow_attach: data.allow_attach || 0,
            allow_see_others: data.allow_see_others || 0,
            status: status,
        }

        if (isEdit) return handleEditData(requestData);

        $createContest(requestData).then(res => {
            if (!res?.id) return;

            successNotify("Успешно создано");
            navigate("/competitions?view=" + res.id);
            reset();
        })
    }

    function handleEditData(data: any) {
        $editContest(contest_id, data).then(res => {
            if (!res?.id) return;

            successNotify("Конкурс изменён");
            navigate("/competitions?view=" + res.id);
            reset();
        })
    }

    function handleDelete() {
        if (!confirm('Действительно удалить конкурс без возможности восстановления?')) {
            return;
        }

        $deleteContest(contest_id).then(res => {
            if (res?.error) return;

            navigate("/competitions");
        })
    }

    function handleSaveDraft() {
        let data = watch();

        const requestData = {
            ...data,
            status: 0,
        };

        onSubmit(requestData, 0).then();
    }

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') e.preventDefault()
    }

    const getSelectValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | null
    ) => {
        if (val || val == 0) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    if (isError) {
        return <div className="contests__create text-center">
            <div className="container layout-inner__header profile-header contests__create__header">
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h2>Ошибка</h2>
                    </div>
            </div>
            <br/><br/>
            <h3>Конкурс не найден</h3>
        </div>
    }

    return (
        <div className="contests__create">
            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{editData ? editData.title : (isEdit ? <div className="dot-pulse color"></div> : "Создать конкурс")}</h1>

                        {isDraft && <h5>Черновик</h5>}
                    </div>
                )}
            </div>

            <div className="contests__create__block">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                id="contestForm"
                                onSubmit={handleSubmit((data) => onSubmit(data, 1))}
                                onKeyDown={(e) => checkKeyDown(e)}
                                className="contests__create__form"
                            >
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Прикрепите картинку"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ImageField
                                            id="image"
                                            name="image"
                                            upload={value}
                                            label="Изображение"
                                            setUpload={onChange}
                                            errors={errors}
                                            isFile={true}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Напишите название"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            name="title"
                                            value={value}
                                            className=""
                                            title="Название конкурса"
                                            placeholder={"Введите название"}
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="text"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Напишите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            name="text"
                                            value={value}
                                            className=""
                                            title="Описание"
                                            placeholder={"Опишите конкурс"}
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <div className={"contests__create__form-date"}>
                                    <Controller
                                        name="date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете дату"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                name="date"
                                                value={dateFormValue(value)}
                                                className=""
                                                label="Дата начала"
                                                placeholder={"Выберите дату"}
                                                onChange={onChange}
                                                errors={errors}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerTimeField
                                                startDate={dateFormValue(value, true)}
                                                label="Начало"
                                                placeholder={"Выберите время"}
                                                onChange={onChange}
                                                isRightIcon
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="date_end"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете дату"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                name="date_end"
                                                value={dateFormValue(value, false, true)}
                                                className=""
                                                label="Дата завершения"
                                                placeholder={"Выберите дату"}
                                                onChange={onChange}
                                                errors={errors}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="date_end"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerTimeField
                                                startDate={dateFormValue(value, true, true)}
                                                label="Конец"
                                                placeholder={"Выберите время"}
                                                onChange={onChange}
                                                isRightIcon
                                            />
                                        )}
                                    />
                                </div>

                                <Controller
                                    name="category_id"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете категорию"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (<>
                                        <Dropdown
                                            name="category_id"
                                            className={"form-select"}
                                            placeholder={"Категория не выбрана"}
                                            label={"Категория"}
                                            options={[
                                                {
                                                    label: "Без категории",
                                                    value: "0"
                                                },
                                                ...categoryList
                                            ]}
                                            value={getSelectValue(
                                                [{ label: "Без категории", value: "0" }, ...categoryList]
                                                , value)}
                                            onChange={(select) => onChange(select.value)}
                                            errors={errors}
                                            isSearchable={true}
                                        /></>
                                    )}
                                />

                                <Controller
                                    name="points"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите баллы"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            name="points"
                                            value={value}
                                            className=""
                                            type={"number"}
                                            title="Баллы за победу"
                                            placeholder={"Введите баллы"}
                                            onChange={onChange}
                                            prepend={<CoinCompetitionIcon />}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="awards"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите баллы"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            name="awards"
                                            value={value}
                                            className=""
                                            type={"number"}
                                            title="Баллы за участие"
                                            placeholder={"Введите баллы"}
                                            onChange={onChange}
                                            prepend={<CoinCompetitionIcon />}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="pinned"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            name="pinned"
                                            isChecked={!!+value}
                                            className="contests__create__form-toggle"
                                            desc="Закрепить конкурс"
                                            handleChange={(value) => onChange(value ? 1 : 0)}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="allow_attach"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            name="allow_attach"
                                            isChecked={!!+value}
                                            className="contests__create__form-toggle"
                                            desc="Прикрепление файла"
                                            handleChange={(value) => onChange(value ? 1 : 0)}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="allow_see_others"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            name="allow_see_others"
                                            isChecked={!!+value}
                                            className="contests__create__form-toggle"
                                            desc="Участники видят друг друга"
                                            handleChange={(value) => onChange(value ? 1 : 0)}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3"}>
                                    {isEdit && (
                                        <Button
                                            text={"Удалить"}
                                            className={"btn btn-red w-100"}
                                            onClick={handleDelete}
                                        />
                                    )}

                                    <Button
                                        text={testBtnDraft}
                                        className={"btn btn-light w-100 submitDraft"}
                                        onClick={handleSaveDraft}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={testBtnCreate}
                                        className={"btn btn-primary w-100 submitBtn"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="contests__create__block-right">
                            <div className={"contests__create__preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="contests__create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={setIsShow}
                                />

                                {isShow && (
                                    <ContestCreateEditPreview
                                        data={watch()}
                                        catsList={[{
                                            label: "Все",
                                            value: "0"
                                        },
                                        ...categoryList]}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContestsCreateEditPage;