import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { ReactNode, useEffect, useState } from "react";
import {
    BlogIcon, CalendarIcon,
    ContestIcon, HamburgerIcon,
    InfoIcon,
    InterviewsIcon,
    MenuIcon,
    NewsIcon, ServicesIcon
} from "../../img";
import ToggleBlock from "../../components/ui/form/Toggle/ToggleBlock";
import {useDispatch, useSelector} from "react-redux";
import { $changeWorkspaceDisableItems } from "../../api/requests/workspace-settings";
import {changeMainPage, getMainPageData, getPersonData} from "../../redux";
import {$mainPageNewData, $workSpaceEdit} from "../../api/requests/main";
import {HomeIcon} from "../../img/icons/menu/HomeIcon";
import {UserMaleIcon} from "../../img/icons/menu/UserMaleIcon";
import {SportIcon} from "../../img/icons/menu/SportIcon";
import {EventsIcon} from "../../img/icons/menu/EventsIcon";
import {CompetitionIcon} from "../../img/icons/menu/CompetitionIcon";
import {IdeasIcon} from "../../img/icons/menu/IdeasIcon";
import {PollsIcon} from "../../img/icons/menu/PollsIcon";
import {MediaIcon} from "../../img/icons/menu/MediaIcon";
import {GroupsMenuIcon} from "../../img/icons/menu/GroupsMenuIcon";
import {FavoriteIcon} from "../../img/icons/others/FavoriteIcon";
import {PieChartIcon} from "../../img/icons/menu/PieChartIcon";
import {FileIcon} from "../../img/icons/menu/FileIcon";
import {useLocation} from "react-router-dom";

interface StateData {
    key: string;
    name: string;
    url: string;
    value: number;
    icon: ReactNode;
}

const WorkspaceSettingModules = () => {
    const personalData = useSelector(getPersonData);
    const location = useLocation();
    const isActiveLocal = location.pathname

    const dispatch = useDispatch()

    const mainPageData = useSelector(getMainPageData);

    const [listMenu, setListMenu] = useState([
        {
            img: (active: boolean) => (
                <HomeIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Главная страница",
            key: "home",
            value: 0,
            active: true,
            link: "/"
        },
        {
            img: (active: boolean) => (
                <UserMaleIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Моя страница",
            key: "profile",
            value: 0,
            active: false,
            backColorCount: "#FFD600",
            colorCount: "#000000",
            link: "/profile"
        },
        {
            img: (active: boolean) => (
                <BlogIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Блог",
            key: "blogs",
            value: 0,
            active: false,
            link: "/blogs"
        },
        {
            img: (active: boolean) => (
                <CalendarIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Календарь",
            key: "calendar",
            value: 0,
            active: false,
            link: "/calendar"
        },
        {
            img: (active: boolean) => (
                <SportIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Спорт",
            key: "sport",
            value: 0,
            active: false,
            link: "/sport"
        },
        {
            img: (active: boolean) => (
                <NewsIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Новости",
            key: "news",
            value: 0,
            active: false,
            backColorCount: "#C7EF71",
            colorCount: "#000000",
            link: "/news"
        },
        {
            img: (active: boolean) => (
                <EventsIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Мероприятия",
            key: "events",
            active: false,
            value: 0,
            link: "/events"
        },
        {
            img: (active: boolean) => (
                <CompetitionIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Конкурсы",
            key: "contests",
            active: false,
            value: 0,
            link: "/competitions"
        },
        {
            img: (active: boolean) => (
                <IdeasIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Идеи",
            key: "ideas",
            active: false,
            value: 0,
            link: "/ideas"
        },
        {
            img: (active: boolean) => (
                <PollsIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Опросы",
            key: "polls",
            active: false,
            value: 0,
            link: "/polls"
        },
        {
            img: (active: boolean) => (
                <MediaIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Медиафайлы",
            key: "media-files",
            active: false,
            value: 0,
            link: "/media-files"
        },
        {
            img: (active: boolean) => (
                <GroupsMenuIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Мои группы",
            key: "groups",
            active: false,
            value: 0,
            link: "/groups"
        },
        {
            img: (active: boolean) => (
                <FavoriteIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : ""}
                    strokeColor={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Избранное",
            key: "favorites",
            active: false,
            value: 0,
            link: "/favorites"
        },
        // {
        //     img: (active: boolean) => (
        //         <BagIcon
        //             opacity={active ? 1 : 0.3}
        //             color={active ? "#00A7B5" : "black"}
        //         />
        //     ),
        //     text: "Корп. Магазин",
        //     key: "favorites",
        //     active: false,
        //     count: 0,
        //     link: "https://cstore-itelma-dev.svoi.club/"
        // },
        {
            img: (active: boolean) => (
                <PieChartIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Аналитика",
            key: "analytics",
            active: false,
            value: 0,
            link: "/analytics/activity"
        },
        {
            img: (active: boolean) => (
                <FileIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Файлы",
            key: "files",
            active: false,
            value: 0,
            link: "/files",
            isAdmin: true
        },
        {
            img: (active: boolean) => (
                <ServicesIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Мои сервисы",
            key: "services",
            active: false,
            value: 0,
            link: "/services"
        },
        {
            img: (active: boolean) => (
                <GroupsMenuIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Новые сотрудники",
            key: "new-employees",
            active: false,
            value: 0,
            link: "/new-employees"
        },
        {
            img: (active: boolean) => (
                <GroupsMenuIcon
                    opacity={active ? 1 : 0.3}
                    color={active ? "#00A7B5" : "black"}
                />
            ),
            text: "Структура компании",
            key: "structure",
            active: false,
            value: 0,
            link: "/structure"
        },
    ]);


    // const [state, setState] = useState<StateData[]>([
    //     {
    //         name: "Главная страница",
    //         url: "/main",
    //         icon: <InfoIcon />,
    //         key: "home",
    //         value: 0,
    //     },
    //     {
    //         name: "Моя страница",
    //         url: "/my",
    //         icon: <InfoIcon />,
    //         key: "profile",
    //         value: 0,
    //     },
    //     {
    //         name: "Блог",
    //         url: "/posts",
    //         icon: <BlogIcon />,
    //         key: "blogs",
    //         value: 0,
    //     },
    //     {
    //         name: "Календарь",
    //         url: "/calendar",
    //         icon: <BlogIcon />,
    //         key: "calendar",
    //         value: 0,
    //     },
    //     {
    //         name: "Спорт",
    //         url: "/sport",
    //         icon: <BlogIcon />,
    //         key: "sport",
    //         value: 0,
    //     },
    //     {
    //         name: "Новости",
    //         url: "/news",
    //         icon: <NewsIcon />,
    //         key: "news",
    //         value: 0,
    //     },
    //     {
    //         name: "Конкурсы",
    //         url: "/contests",
    //         icon: <ContestIcon />,
    //         key: "contests",
    //         value: 0,
    //     },
    //     {
    //         name: "Опросы",
    //         url: "/interviews",
    //         icon: <InterviewsIcon />,
    //         key: "interviews",
    //         value: 0,
    //     },
    // ]);

    useEffect(() => {
        if (mainPageData) {
            const updateList = [...listMenu].map(item => {
                if (mainPageData.disabled_items.length)  return {
                    ...item,
                    value: mainPageData.disabled_items?.includes(item.key) ? 1 : 0
                }

                return item
            });

            const reorderedState = [...updateList]?.sort(
                (a, b) => mainPageData?.menu_sort?.indexOf(a.key) - mainPageData?.menu_sort?.indexOf(b.key)
            );

            setListMenu(reorderedState || updateList);
        }
    }, [mainPageData]);

    useEffect(() => {
        $mainPageNewData().then((res) => {
            if (!res || res.error) return;

            dispatch(changeMainPage(res));
        });
    }, [listMenu])

    function changeCheckboxes(select: any) {
        const updateList = [...listMenu].map(item => {
            if (item.key === select.key) return {
                ...item,
                value: select.value ? 0 : 1
            }

            return item
        });

        const requestData: any = {
            [`${select.key}`]: select.value ? 0 : 1,
            id: personalData.workspace_id,
        };

        $changeWorkspaceDisableItems(requestData, { formData: true, disableLoader: true }).then(res => {
            setListMenu(updateList);
        });
    }

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'modules') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...listMenu];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleEdit(targetIndex, deletedItem, workValue);
        }
    }

    function handleEdit(targetIndex: number, deletedItem: any, workValue: any[]) {
        const requestData: any = {
            id: personalData.workspace_id,
            menu_sort: [...workValue].map(i => i.key),
        };

        $workSpaceEdit(
            requestData,
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            setListMenu(workValue);
        })
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='list' type="modules">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className={"workspace-settings-group__modules"}>
                        {listMenu.map((item, index) => (
                            <div key={`${item.key}-item-${index}`} className={"menuBlock"}>
                                <Draggable draggableId={`${item.key}-item-${index}`} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={"drag-and-drop__block workspace-settings-group__modules-block"}
                                        >
                                            <div className={"left w-100 justify-content-between"}>
                                                <div className={"left"}>
                                                    <HamburgerIcon color={"#00000080"} />

                                                    {(item.img && typeof item.img === "string")
                                                        ? <img src={item.img} alt={item.text} />
                                                        : item.img(item.link === isActiveLocal)
                                                    }

                                                    <p>{item.text}</p>
                                                </div>

                                                <ToggleBlock
                                                    desc={""}
                                                    isChecked={item.value != 1 }
                                                    handleChange={() => changeCheckboxes(item)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            </div>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default WorkspaceSettingModules;