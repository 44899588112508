import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";

interface IUserData {
    data: any;
    openGratitude: () => void;
}

export const UserProfileCard = (
    {
        data,
        openGratitude
    }: IUserData
) => {
    const personalData = useSelector(getPersonData);

    const isCurrentId = data?.id === personalData?.id;

    return (
        <div className="user-profile">
            <div className="user-profile__avaBox"
                 style={{ cursor: "pointer" }}
            >
                {data?.photo ? (
                    <img src={data?.photo} className="user-profile__avatar"
                         style={{ marginRight: "0" }} />
                ) : (
                    <div className="user-profile__avatar__noneImage">
                        {data?.name[0]}
                    </div>
                )}
            </div>

            <div className="user-profile__nameBlock">
                <h1>{data?.name}</h1>

                <div className={"layout-profile__nameBlock-status"}>
                    <img src={data?.custom_status?.icon} />

                    <p>{data?.custom_status?.title}</p>
                </div>
            </div>

            {!isCurrentId && (
                <div className="user-profile__buttons">
                    <button
                        className="user-profile__buttons__item thanks"
                        onClick={openGratitude}
                    >
                        Поблагодарить
                    </button>

                    {/*<div className="user-profile__buttons__item writing">Написать</div>*/}
                </div>
            )}
        </div>
    );
};