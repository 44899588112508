import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import { IServiceTripReq } from "./interface";

export const $createServiceTrip = (
    data: IServiceTripReq,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/services/business-trips/store", data, {
    handler
});

export const $getServiceTripItem = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/business-trips/show/${id}`, {
    handler
});

export const $updateServiceTripStatus = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.put("api/v1/services/business-trips/status-change", data,{
    // mock: require('./mocks/structure.company.update.json'),
    handler
})

export const $getServiceTripsTypes = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/business-trips/types`, {
    handler
});

export const $getServiceTripsProcessing = (
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/business-trips/list/processing`, {
    request: { params },
    handler: {
        ...handler,
        newApi: true
    }
});

export const $getServiceTripsApproving = (
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/business-trips/list/approving`, {
    request: { params },
    handler: {
        ...handler,
        newApi: true
    }
});

export const $getServiceTripsHistory = (
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/business-trips/list/history`, {
    request: { params },
    handler: {
        ...handler,
        newApi: true
    }
});

export const $getTripsCalendarList = (
    params: { start_date: string, end_date: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.get("api/v1/services/business-trips/calendar", {
    // mock: require('./mocks/vacation.booking.json'),
    request: { params },
    handler: {
        ...handler,
        newApi: true
    },
})