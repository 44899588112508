import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getPopupData,
    getSelected,
    setShapeData
} from "../../../redux/reducers/workplace-management/workplaceManagementSlice";
import { Controller, useForm } from "react-hook-form";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Button from "../../ui/form/Buttons/Button";
import TextField from "../../ui/form/Fields/TextField";
import HashtagsField from "../../ui/form/Hashtags/HashtagsField";
import AddSelectUsers from "../../ui/users/AddSelectUsers";

interface FormData {
    isEdit: boolean;
    name: string;
    peripheries: string[];
    members: any[];
}

interface WorkplaceCreateCabinetDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedProps: any;
}

const WorkplaceCreateCabinetDialog = (
    {
        isOpen,
        onClose,
        selectedProps
    }: WorkplaceCreateCabinetDialogProps
) => {

    const dispatch = useDispatch();

    const { city, building, floor } = selectedProps || useSelector(getSelected);

    const previousData = useSelector(getPopupData);

    const isEdit = !!previousData?.isEdit;

    const initialBooking = {
        isEdit,
        name: previousData?.request?.name,
        peripheries: previousData?.request?.peripheries || [],
        members: previousData?.member ? [previousData?.member] : []
    };

    const [booking, setBooking] = useState<any>(null);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { isValid, errors },
    } = useForm<FormData>(
        {
            values: {
                ...initialBooking
            }
        }
    );

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        if (previousData) {
            setBooking({
                ...previousData
            });
        }
    }, [previousData]);

    function onSubmit(data: FormData) {
        const requestData = {
            ...data,
        }

        if (isEdit) return handleEdit(requestData)

        handleCreate(requestData)
    }

    function handleCreate(requestData: any) {
        dispatch(
            setShapeData({
                ...booking,
                member_id: requestData?.members?.length ? requestData.members[0].id : undefined,
                member: requestData?.members?.length ? requestData.members[0] : undefined,
                request: {
                    ...booking.request,
                    name: requestData.name,
                    peripheries: requestData.peripheries
                },
                type: 'room',
                isCreate: true,
            }),
        );

        handleClosePopup();
    }

    function handleEdit(requestData: any) {
        dispatch(
            setShapeData({
                ...booking,
                member_id: requestData.members[0].id,
                member: requestData.members[0],
                request: {
                    ...booking.request,
                    name: requestData.name,
                    peripheries: requestData.peripheries
                },
                type: 'room',
                isEdit: true,
                isCreate: false,
            }),
        );

        handleClosePopup();
    }

    function handleDelete() {
        dispatch(
            setShapeData({
                ...booking,
                type: 'room',
                isRemove: true,
                isEdit: false,
                isCreate: false,
            }),
        );

        handleClosePopup();
    }

    function handleClosePopup() {
        onClose();
        reset();
    }

    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                setIsOpen={handleClosePopup}
                widthProps={427}
                label={`${isEdit ? "Редактировать" : "Добавить"} кабинет`}
                classNameHeader={"pb-10"}
            >
                <div className={"workplace__booking"}>
                    <div className={"workplace__booking__head"}>
                        <div className={"workplace__booking__address"}>
                            {`${city?.name || ""}, ${building?.name || ""}, ${floor?.number}-й этаж`}
                        </div>
                    </div>

                    <form
                        className={"workplace__booking__form d-flex flex-column gap-5"}
                        onSubmit={handleSubmit(onSubmit)}
                        onKeyDown={(e) => checkKeyDown(e)}
                    >
                        <div className={"d-flex flex-column gap-5"}>
                            <Controller
                                name={"name"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Добавьте название"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        value={value}
                                        className=""
                                        title="Название места"
                                        placeholder={"Добавьте название"}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="peripheries"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { value, onChange } }) => (
                                    <HashtagsField
                                        value={value}
                                        title="Периферия"
                                        name="peripheries"
                                        onChange={onChange}
                                        isHash={false}
                                    />
                                )}
                            />

                            <Controller
                                name="members"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { value, onChange } }) => (
                                    <AddSelectUsers
                                        usersList={value}
                                        title="Закрепить сотрудника"
                                        setUsersList={onChange}
                                        isSingle
                                    />
                                )}
                            />
                        </div>

                        <div className={"workplace__booking__btns"}>
                            <Button
                                type={"submit"}
                                text={isEdit ? "Сохранить" : "Добавить"}
                                className={"btn btn-primary w-100"}
                                disabled={!isValid}
                            />

                            {isEdit ? (
                                <Button
                                    text={"Удалить"}
                                    className={"btn btn-red ml-0 w-100"}
                                    onClick={handleDelete}
                                />
                            ) : (
                                <Button
                                    text={"Отменить"}
                                    className={"btn btn-light ml-0 w-100"}
                                    onClick={handleClosePopup}
                                />
                            )}
                        </div>
                    </form>
                </div>
            </DialogContainer>
        </>
    );
};

export default WorkplaceCreateCabinetDialog;