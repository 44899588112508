import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { ICommentNotificationReq } from "./interface";

export const $sendCommentNotification = (
    data: ICommentNotificationReq,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/notification/tag-user", data, {
    handler
});